import { FC, useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { BugoMyPage } from '@pages/BugoMyPage/BugoMyPage';
import { ConfirmPasswordPage } from '@pages/User/ConfirmPasswordPage';
import UpgradeBugoPage from '@pages/User/UpgradeBugoPage';
import { Route, Routes } from 'react-router-dom';
import { hasConfirmPasswordAtom } from '@containers/BugoMyPageContent/components/UserModifyContent/state/userModifyContent.atom';

const UserRouter: FC = () => {
  const setHasConfirmPasswordAtom = useSetRecoilState(hasConfirmPasswordAtom);

  useEffect(() => {
    return () => {
      setHasConfirmPasswordAtom(false);
    };
  }, [setHasConfirmPasswordAtom]);

  return (
    <Routes>
      <Route path="mypage/*" element={<BugoMyPage />} />
      <Route path="confirm-password/*" element={<ConfirmPasswordPage />} />
      <Route path="upgrade/select-type/*" element={<UpgradeBugoPage />} />
    </Routes>
  );
};

export { UserRouter };
