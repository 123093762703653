import { useNavigate } from 'react-router-dom';
import Logo from 'src/assets/image/logo-gaon.png';
const LoginFormTopLogo = () => {
  const navigate = useNavigate();
  const logoHandler = () => {
    console.log('home');
    navigate('/');
  };

  return (
    <div onClick={logoHandler} className=" mx-auto cursor-pointer items-center pb-6">
      <span className="relative text-[40px] font-bold">
        <img
          src={Logo}
          className="absolute bottom-[-18px] left-[-94px] h-[86px] w-[86px]"
        ></img>
        {/* <img src={Logo} className="absolute bottom-[-5px] left-[-61px] w-[60px]"></img> */}
        가온부고
      </span>
    </div>
  );
};

export { LoginFormTopLogo };
