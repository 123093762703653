import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { nestMongoApi } from '@api/mongo/controllers';
import { AlertMethod, nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { BasicInput } from '@components/BasicInput';
import { TextAreaInput } from '@components/TextAreaInput';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { useAuth } from '@hooks/useAuth';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { notificateSuccess } from 'src/@utils/noti.utils';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';
import Modal from '@components/Modal';
import { useQueryClient } from 'react-query';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Icon } from '@components/Icon';

import { FormValue, FormValues } from '../state/bugoSendForm.interface';
import { FoldableBox } from './FoldableBox';
import { isSubmitOnBugoSendFormAtom } from '../state/bugoSendForm.atom';
import { useBugoSendFormItemChangeEffectHook } from '../state/bugoSendForm.hook';

interface BugoSendFormItemProps {
  mournerText?: string;
  index: number;
  setFocusedIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  setFocusedInput: React.Dispatch<
    React.SetStateAction<'phoneNumber' | 'mournerText' | undefined>
  >;
  defaultFold?: boolean;
  memberPhoneNumber?: string;
  memberName: string;
  lastSentPhoneNumber?: string;
}

const BugoSendFormItem: FC<BugoSendFormItemProps> = ({
  mournerText,
  index,
  setFocusedIndex,
  setFocusedInput,
  defaultFold = false,
  memberPhoneNumber,
  lastSentPhoneNumber,
  memberName,
}) => {
  const setIsSubmitOnBugoSendForm = useSetRecoilState(isSubmitOnBugoSendFormAtom);
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const { ShareKakao, ShareMessage } = Icon;
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();
  const { updateById: updatePhoneNumberById } = nestMongoApi(`${bugoRole()}/member`);
  const { updateById: updatemournerTextById } = nestMongoApi(`${bugoRole()}/bugo`);
  const { handleSubmit } = useForm<FormValue>();
  const currentFeventId = useRecoilValue(currentFeventIdAtom);
  const notificationInstance = useRecoilValue(notificationInstanceAtom);
  const fieldArray = useWatch({ control, name: 'memberData' });

  const currentFeild = fieldArray[index]!;
  const { sendRequired } = useBugoSendFormItemChangeEffectHook(index, memberPhoneNumber);
  const setOpen = useSetRecoilState(
    modalOpenAtomFamily(`bugoSend-alertMethod-modal-${currentFeild._id}`),
  );
  const currentPhoneNumber = currentFeild.phoneNumber;
  const phoneNumberLength = currentPhoneNumber.length;
  const validatePhoneNumber =
    phoneNumberLength === 0 || phoneNumberLength < 10 || phoneNumberLength > 11;

  const onSubmit: SubmitHandler<FormValue> = async (data, alertMethod) => {
    if (validatePhoneNumber) return;
    if (!data.mournerText && mournerText) data.mournerText = mournerText;
    if (currentFeild?.isPhoneNumberUpdated) {
      await updatePhoneNumberById(currentFeild._id, {
        phoneNumber: currentPhoneNumber,
      });
    }
    if (currentFeild?.isMournerTextUpdated) {
      await updatemournerTextById(currentFeild.bugoId, {
        mournerText: currentFeild?.mournerText,
      });
    }
    await nestAlertApi().bugoSend({
      bugos: [currentFeild.bugoId],
      method: alertMethod as any,
    });
    await queryClient.invalidateQueries(
      feventByIdHookUrl(currentFeventId ?? '', bugoRole()),
    );

    setIsSubmitOnBugoSendForm(true);

    notificateSuccess(
      notificationInstance,
      '전송에 성공하였습니다. 핸드폰을 확인해주세요',
    );
  };

  return (
    <>
      <FoldableBox
        openText="설정"
        name={memberName}
        lastSentPhoneNumber={lastSentPhoneNumber}
        isUpdated={sendRequired}
        defaultFold={defaultFold}
      >
        <div className="grid grid-cols-3 gap-2 max-sm:grid-cols-1 max-sm:gap-0 max-sm:gap-y-2">
          <BasicInput
            className={`px-4 ${validatePhoneNumber && 'border-red-500'}`}
            label="휴대전화번호"
            value={phoneNumberStringFormator(currentPhoneNumber)}
            isError={errors.memberData && !!errors.memberData[index]?.phoneNumber}
            {...register(`memberData.${index}.phoneNumber`, {
              required: { value: true, message: '휴대전화 번호를 입력하세요.' },
              maxLength: {
                value: 11,
                message: '휴대전화 번호는 최소 10자, 최대 11자이어야 합니다.',
              },
              minLength: {
                value: 10,
                message: '휴대전화 번호는 최소 10자, 최대 11자이어야 합니다.',
              },
              setValueAs: (phoneNumber) => phoneNumber.replace(/[^0-9]/gi, ''),
              onChange: () => {
                setFocusedInput('phoneNumber');
                setFocusedIndex(index);
              },
            })}
          />
          <Link
            to={`/bugo/${currentFeild.bugoId}`}
            className=" flex h-10 items-center justify-center self-end rounded border bg-white font-bold max-sm:order-1 max-sm:col-span-4"
          >
            부고장 미리보기
          </Link>
          <button
            type="button"
            className=" block h-10 self-end rounded bg-apricot-600 font-bold text-white max-sm:order-2"
            onClick={() => {
              if (lastSentPhoneNumber) {
                setOpen(true);
              } else {
                handleSubmit((data) => onSubmit(data, undefined))();
              }
            }}
          >
            {lastSentPhoneNumber ? '재전송' : '전송'}
          </button>
          <div className="col-span-4 ">
            <TextAreaInput
              label="개인별 상주말씀"
              placeholder="개인별 상주말씀(미작성시 고인정보에서 작성한 상주 말씀이 적용됩니다)"
              {...register(`memberData.${index}.mournerText`, {
                onChange: () => {
                  setFocusedIndex(index);
                  setFocusedInput('mournerText');
                },
              })}
            />
          </div>
        </div>
      </FoldableBox>
      <Modal
        openId={`bugoSend-alertMethod-modal-${currentFeild._id}`}
        className="w-full max-w-[320px] bg-white p-16"
      >
        <h2 className="flex justify-center text-18 font-bold">부고 재전송</h2>
        <h3 className="mt-2 flex justify-center text-16">
          <span className="font-semibold">{memberName}</span>님
        </h3>
        <div className="mt-4 flex justify-around">
          <div
            className="flex cursor-pointer flex-col items-center justify-center gap-2"
            onClick={() => {
              handleSubmit((data) => onSubmit(data, AlertMethod.Message as any))();
              setOpen(false);
            }}
          >
            <ShareMessage className=" stroke-none" />
            <p className="text-14">문자</p>
          </div>
          <div
            className="flex cursor-pointer flex-col items-center justify-center gap-2 "
            onClick={() => {
              handleSubmit((data) => onSubmit(data, AlertMethod.Kakao as any))();
              setOpen(false);
            }}
          >
            <ShareKakao className=" stroke-none" />
            <p className="text-14">카카오톡</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { BugoSendFormItem };
