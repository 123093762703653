import { FC, HTMLAttributes } from 'react';

interface MyCashInfoBoxProps extends HTMLAttributes<HTMLDivElement> {
  receiveCount: number;
  answerCount: number;
}

const MyEnquiryInfoBox: FC<MyCashInfoBoxProps> = ({
  receiveCount,
  answerCount,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`flex max-h-[68px] w-full max-w-[306px] justify-between  rounded-lg bg-white px-4 py-[3px] ${className}`}
    >
      <div className="py-2 px-4">
        <p>접수 중</p>
        <p className=" font-bold text-primary">{`${Number(
          receiveCount,
        ).toLocaleString()}`}</p>
      </div>
      <div className="py-2 px-4">
        <p>답변 완료</p>
        <p className=" font-bold text-primary">{`${Number(
          answerCount,
        ).toLocaleString()}`}</p>
      </div>
    </div>
  );
};

export { MyEnquiryInfoBox };
