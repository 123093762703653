import { SideBarProps } from '../components/MenuLinkButton';

const storeNavigationListTable = (isLogin: boolean, userId?: string): SideBarProps => {
  const guestNavi = [
    {
      title: '답례메뉴',
      subMenuList: [
        { name: '답례글 보내기', href: '/fevent/list/?purpose=courtesy' },
        { name: '답례품 보내기', href: undefined },
      ],
    },
    {
      title: '부고메뉴',
      subMenuList: [{ name: '부고 서비스', href: '/' }],
    },
    {
      title: '고객센터',
      subMenuList: [
        { name: '자주 묻는 질문', href: '/flower-store/faq' },
        { name: '1:1 문의', href: '/flower-store/enquiry' },
      ],
    },
    {
      title: '제휴신청',
      subMenuList: [{ name: '제휴신청 문의', href: undefined }],
    },
  ];

  const userNavi = [
    {
      title: '마이 페이지',
      subMenuList: [
        { name: '계정 관리', href: `/flower-store/user/mypage` },
        // { name: '적립 포인트', href: `/flower-store/user/mypage` },
      ],
    },
    {
      title: '답례메뉴',
      subMenuList: [
        { name: '답례글 보내기', href: '/fevent/list/?purpose=courtesy' },
        { name: '답례품 보내기', href: undefined },
      ],
    },
    {
      title: '부고메뉴',
      subMenuList: [{ name: '부고 서비스', href: '/' }],
    },
    {
      title: '고객센터',
      subMenuList: [
        { name: '자주 묻는 질문', href: '/flower-store/faq' },
        { name: '1:1 문의', href: '/flower-store/enquiry' },
        { name: '내 문의내역', href: `/flower-store/user/mypage?state=enquiry` },
      ],
    },
    {
      title: '제휴신청',
      subMenuList: [{ name: '제휴신청 문의', href: undefined }],
    },
  ];

  return isLogin && userId ? userNavi : guestNavi;
};

export { storeNavigationListTable };
