import { FC } from 'react';

import {
  FuneralHomeInfo,
  FuneralHomeInfoEmbed,
  Member,
  RoomInfo,
} from '@api/mongo/interfaces';
import { FeventDate } from 'src/interfaces';

import { ObituaryFeventGrid } from './ObituaryFeventGrid';
import { ObituaryFuneralHomeInfo } from './ObituaryFuneralHomeInfo';
import { ObituaryMemberGrid } from './ObituaryMemberGrid';

interface ObituaryBodyProps {
  enteranceDate: FeventDate;
  coffinInDate: FeventDate;
  coffinOutDate: FeventDate;
  cemetery: string;
  funeralHomeInfoEmbed?: FuneralHomeInfoEmbed;
  funeralHomeInfo: FuneralHomeInfo;
  roomInfo?: RoomInfo;
  roomInfoEmbed?: { name: string };
  mournerText: string;
  memberOrderList: Member[];
}

const ObituaryBody: FC<ObituaryBodyProps> = ({
  enteranceDate,
  coffinInDate,
  coffinOutDate,
  cemetery,
  funeralHomeInfo,
  funeralHomeInfoEmbed,
  roomInfoEmbed,
  memberOrderList,
  mournerText,
}) => {
  return (
    <div className="px-3">
      {/* 부고 정보 */}
      <div className="grid grid-cols-auto-1fr justify-items-start gap-y-2 gap-x-4 px-2 pt-4 pb-3 text-16 text-black">
        <div></div>
        <div></div>
        <ObituaryMemberGrid memberList={memberOrderList} />
        <div></div>
        <div></div>
        <ObituaryFeventGrid
          enteranceDate={enteranceDate}
          coffinInDate={coffinInDate}
          coffinOutDate={coffinOutDate}
          cemetery={cemetery}
          funeralHomeName={funeralHomeInfoEmbed?.name ?? funeralHomeInfo?.name}
          roomName={roomInfoEmbed?.name ?? ''}
          mournerText={mournerText}
        />
      </div>
      {/* 장례식장 정보 */}
      <ObituaryFuneralHomeInfo
        funeralHomeInfoEmbed={funeralHomeInfoEmbed}
        funeralHomeInfo={funeralHomeInfo}
      />
    </div>
  );
};

export { ObituaryBody };
