import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { DeliveryStatusHeader } from '@containers/FlowerStoreOrderContent/OrderDeliveryContent/components/DeliveryStatusHeader';
import { DeliveryStatusBody } from '@containers/FlowerStoreOrderContent/OrderDeliveryContent/components/DeliveryStatusBody';
import { DeliveryStatusFooter } from '@containers/FlowerStoreOrderContent/OrderDeliveryContent/components/DeliveryStatusFooter';
import { formatDate } from 'src/@utils/formatDate';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Button } from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { setDeliveryStatusText } from '@containers/FlowerStoreOrderContent/OrderPaymentContent/components/PaymentBody';
import { Loading } from '@components/Loading';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useFlowerStoreUserOrderListHook } from './state/FlowerStoreOrderList.query';
import { flowerStoreOrderListSelector } from './state/FlowerStoreOrderList.selector';
import { OrderList } from './state/FlowerStoreOrderList.interface';

const FlowerStoreOrderListContent = () => {
  const navigate = useNavigate();
  useFlowerStoreUserOrderListHook();
  useMobileNavTitleHook('배송조회', true, false);
  const { data, status } = useRecoilValue(flowerStoreOrderListSelector);

  const scrollSize = 5;
  const [page, setPage] = useState(0);
  const [chunks, setChunks] = useState<Array<OrderList>>([]);
  const [currentPageData, setCurrentPageData] = useState<OrderList | []>([]);

  const buttonHandler = () => {
    navigate(-1);
  };

  const moreView = () => {
    setPage((page) => page + 1);
  };

  useEffect(() => {
    if (data) {
      const chunks = _.chunk(data, scrollSize);
      setChunks(chunks);
      setCurrentPageData(chunks[0] ?? []);
    }
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (page > 0) {
      const newPageData = chunks[page];
      if (newPageData) {
        setCurrentPageData((prevData) => [...prevData, ...newPageData]);
      }
    }
  }, [chunks, page]);

  return (
    <div className="relative mx-auto max-w-[640px] break-keep max-sm:mx-[-2rem] max-sm:p-0">
      <InfiniteScroll
        dataLength={currentPageData.length}
        next={moreView}
        scrollThreshold={0.9}
        hasMore={page < chunks.length - 1}
        loader={<Loading />}
      >
        {status === 'loading' ? (
          <Loading />
        ) : status === 'success' && data && data.length > 0 ? (
          currentPageData.map((orderDetailData) => (
            <div className="relative mb-10 shadow-md" key={orderDetailData._id}>
              <DeliveryStatusHeader
                estimatedDeliveredAt={formatDate(
                  orderDetailData.deliveryDetail.estimatedDeliveredAt,

                  {
                    contains: {
                      year: false,
                      day: true,
                      hours: true,
                      minutes: true,
                    },
                  },
                )}
                deliveryStatus={orderDetailData.deliveryDetail.status}
              />

              <DeliveryStatusBody
                orderId={orderDetailData._id}
                itemImage={orderDetailData.orderDetail.shopItem.shopItemInfo.image.url}
                itemName={orderDetailData.orderDetail.shopItemEmbed.name}
                itemPrice={orderDetailData.orderDetail.shopItemEmbed.priceRetail}
                deliveryState={setDeliveryStatusText(
                  orderDetailData?.deliveryDetail.status,
                )}
                paymentMethod={orderDetailData.paymentDetail.method}
                receiptUrl={orderDetailData.paymentDetail.receiptUrl}
                estimatedDeliveredAt={formatDate(
                  orderDetailData?.deliveryDetail.estimatedDeliveredAt,
                  {
                    contains: {
                      day: true,
                      hours: true,
                      minutes: true,
                      year: false,
                    },
                  },
                )}
                requestedAt={formatDate(orderDetailData.paymentDetail.requestedAt, {
                  contains: {
                    day: true,
                    hours: true,
                    minutes: true,
                  },
                })}
              />
              <DeliveryStatusFooter
                mournerName={orderDetailData.deliveryDetail.receiverName}
                funeralAddress={orderDetailData.deliveryDetail.receiverAddress}
                senderPhrase={orderDetailData.bugoDetail.senderPhrase}
                condolencePhrase={orderDetailData.bugoDetail.condolencePhrase}
              />
            </div>
          ))
        ) : (
          <p className="text-gray-500">배송 내역이 없습니다.</p>
        )}
      </InfiniteScroll>

      <div className="left-0 bottom-0 mt-8 flex w-full justify-end max-sm:fixed max-sm:hidden">
        <Button
          onClick={buttonHandler}
          className="max-w-[187px] rounded border-none bg-primary stroke-none py-3 px-16 text-white shadow-none max-sm:w-full max-sm:max-w-full max-sm:rounded-none "
        >
          뒤로가기
        </Button>
      </div>
    </div>
  );
};

export { FlowerStoreOrderListContent };
