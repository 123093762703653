import { FC, useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import Modal from '@components/Modal';
import html2canvas from 'html2canvas';
import { useAsync } from 'react-use';

import { courtesyAssetSrcAtom, courtesyContentStringAtom } from '../state/courtesy.atom';

interface MemberCourtesyPreviewModalProps {
  openId: string;
  position?: 'start' | 'center' | 'end';
}

const saveImageToLocal = async (imageUrl: string) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  const file = new File([blob], 'image.jpeg', { type: 'image/jpeg' });
  const dataURL = URL.createObjectURL(file);

  return dataURL;
};

const MemberCourtesyPreviewModal: FC<MemberCourtesyPreviewModalProps> = ({
  openId,
  position,
}) => {
  //   const { Download } = Icon;
  // const courtesyAsset = useRecoilValue(courtesyAssetAtom);
  const imageUrl = useRecoilValue(courtesyAssetSrcAtom);
  const courtesyContentString = useRecoilValue(courtesyContentStringAtom);
  const divRef = useRef(null);
  const [localImageUrl, setLocalImageUrl] = useState<string>();

  useAsync(async () => {
    if (!imageUrl) return;

    const corsImageModified = new Image();
    corsImageModified.crossOrigin = 'Anonymous';

    corsImageModified.src = imageUrl + '?not-from-cache-please';

    const localUrl = await saveImageToLocal(corsImageModified.src);

    setLocalImageUrl(localUrl);
  }, [imageUrl]);

  function handleDownloadClick() {
    const div_ = divRef.current;
    if (div_)
      html2canvas(div_, { useCORS: true, allowTaint: true }).then((canvas) => {
        const dataUrl = canvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        const fileName = '답례인사.jpg';
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      });
  }

  return (
    <Modal openId={openId} className="bg-white" position={position}>
      <div className="relative" ref={divRef}>
        <img src={localImageUrl} />
        <div
          className={`noto-serif-kr absolute z-10 w-full whitespace-pre-wrap break-keep font-medium`}
          style={{
            fontSize: `12px`,
            top: `30px`,
            paddingLeft: `24px`,
            paddingRight: `24px`,
          }}
        >
          <p>{courtesyContentString}</p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          onClick={handleDownloadClick}
          className="button center-box mt-2 w-full gap-2 font-medium text-black"
        >
          {/* <Download className="wh-6 fill-gray-700" /> */}
          <p>다운로드</p>
        </Button>
      </div>
    </Modal>
  );
};

export { MemberCourtesyPreviewModal };
