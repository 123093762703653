import { atom } from 'recoil';

import { QueryStatus } from 'react-query';
import { ShopItemForStoreData } from '@api/mongo/interfaces';

export const storeItemStatusAtom = atom<QueryStatus>({
  key: 'storeItemStatusAtomFamily',
  default: 'idle',
});

export const storeItemDataAtom = atom<ShopItemForStoreData | undefined>({
  key: 'storeItemDataAtomFamily',
  default: undefined,
});
