import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Link, useLocation } from 'react-router-dom';

import { CustomerInfoBox } from './components/CustomerInfoBox';
import { FlowerStoreAccountGuide } from './components/FlowerStoreAccountGuide';
import { FlowerStoreItem } from './components/FlowerStoreItem';
import { flowerStoreItemListStateSelector } from './state/flowerStoreMainContent.selector';

const FlowerStoreMainContent: FC = () => {
  const { data } = useRecoilValue(flowerStoreItemListStateSelector);
  const location = useLocation();

  return (
    <div className="max-w-[768px] border bg-white py-3 max-sm:mx-[-1rem] max-sm:border-none">
      <CustomerInfoBox />
      {data?.map((item) => {
        return (
          <Link to={`/flower-store/item/${item._id}/${location.search}`} key={item._id}>
            <FlowerStoreItem
              name={item.shopItemInfo.name}
              priceRetail={item.priceRetail}
              priceRetailShow={item.priceRetailShow}
              detailInfo={item.shopItemInfo.detailInfo}
              tags={item.shopItemInfo.tags}
              url={item.shopItemInfo.image.url}
            />
          </Link>
        );
      })}
      <FlowerStoreAccountGuide />
    </div>
  );
};

export { FlowerStoreMainContent };
