import { FC } from 'react';

import { StoreItemTagEnum } from 'src/interfaces';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

import { FlowerStoreItemInfo } from './FlowerStoreItemInfo';

interface FlowerStoreItemProps {
  name: string;
  detailInfo: string;
  priceRetailShow: number;
  priceRetail: number;
  url: string;
  tags: StoreItemTagEnum[];
}

const FlowerStoreItem: FC<FlowerStoreItemProps> = ({
  name,
  detailInfo,
  priceRetailShow,
  priceRetail,
  tags,
  url,
}) => {
  const isMobileMedia = useMobileMediaQuery();

  return (
    <div className="grid h-[178px] grid-cols-3 border-t py-3 px-3 max-sm:grid-cols-2 max-sm:px-0">
      <div className="center-box  relative h-full w-full">
        <img
          src={`${process.env.REACT_APP_BUCKET_URL}/w200/${url}`}
          className="absolute top-0 left-0 h-full w-full object-contain"
          alt="flower"
        ></img>
      </div>

      <FlowerStoreItemInfo
        name={name}
        priceRetailShow={priceRetailShow}
        priceRetail={priceRetail}
        tags={tags}
      ></FlowerStoreItemInfo>

      {!isMobileMedia && (
        <p className="break-keep text-left text-12 text-black">{detailInfo}</p>
      )}
    </div>
  );
};

export { FlowerStoreItem };
