import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { CurrentUserFevent } from './userBugoList.interface';

const currentUserFeventListDataAtom = atom<CurrentUserFevent[]>({
  key: 'currentUserFeventList',
  default: undefined,
});

const currentUserFeventListStatusAtom = atom<QueryStatus>({
  key: 'currentUserFeventListStatusAtom',
  default: 'idle',
});

const searchResultFeventListDataAtom = atom<CurrentUserFevent[] | null>({
  key: 'feventListCurrentPageAtom',
  default: null,
});

export {
  currentUserFeventListDataAtom,
  currentUserFeventListStatusAtom,
  searchResultFeventListDataAtom,
};
