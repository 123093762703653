import { selectorFamily } from 'recoil';

import { QueryDataState } from 'src/interfaces';

import {
  feventMemberOrderBugoDataAtomFamily,
  feventMemberOrderBugoStatusAtomFamily,
} from './bugoPreview.atom';
import { FeventMemberOrderBugo } from './bugoPreview.interface';

export const feventMemberOrderBugoStateSelectorFamily = selectorFamily<
  QueryDataState<FeventMemberOrderBugo>,
  string
>({
  key: 'feventMemberOrderBugoStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        data: get(feventMemberOrderBugoDataAtomFamily(id)),
        status: get(feventMemberOrderBugoStatusAtomFamily(id)),
      };
    },
});
