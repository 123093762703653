import { FC } from 'react';

import {
  bugoFAQContents,
  courtesyFAQContents,
  flowerFAQContents,
} from 'src/@utils/FAQContents';

import { FAQTabContentItem } from './FAQTabContentItem';

interface FAQTabContentListProps {
  selectedTab: number;
}

const FAQTabContentList: FC<FAQTabContentListProps> = ({ selectedTab }) => {
  switch (selectedTab) {
    case 0:
      return (
        // 부고장 탭
        <div>
          {bugoFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
    case 1:
      return (
        // 답례인사 탭
        <div>
          {courtesyFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
    case 2:
      return (
        // 화환 주문 탭
        <div>
          {flowerFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
    default:
      return (
        // 부고장 탭
        <div>
          {bugoFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
  }
};

export { FAQTabContentList };
