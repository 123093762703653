import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { FuneralHomeInfo, FuneralHomeInfoEmbed } from '@api/mongo/interfaces';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Map } from '@components/Map';
import NavigatorsModal from '@components/NavigatorsModal';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useId } from 'react-id-generator';
import { addressCopyHandler } from 'src/@utils/addressCopyHandler';

interface ObituaryFuneralHomeInfoProps {
  funeralHomeInfoEmbed?: FuneralHomeInfoEmbed;
  funeralHomeInfo: FuneralHomeInfo;
}

const ObituaryFuneralHomeInfo: FC<ObituaryFuneralHomeInfoProps> = ({
  funeralHomeInfo,
  funeralHomeInfoEmbed,
}) => {
  const funeralHomeInfoData = funeralHomeInfoEmbed ?? funeralHomeInfo;
  const [id] = useId(1, 'navigatorsModal');
  const [publicTransportId] = useId(1, 'publicNavigatorsModal');
  const setOpen = useSetRecoilState(modalOpenAtomFamily(id));
  const setPublicOpen = useSetRecoilState(modalOpenAtomFamily(publicTransportId));
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  return (
    <>
      <div className="pl-2 pb-1 text-left font-bold">오시는길</div>
      {funeralHomeInfo && (
        <div className="aspect-w-13 aspect-h-7 mb-3 w-full">
          <div className="h-full w-full border">
            <Map coord={funeralHomeInfo?.coord} name={funeralHomeInfo?.name} />
          </div>
        </div>
      )}
      {funeralHomeInfo && (
        <div className="pb-3 text-16">
          <div className="flex items-start justify-between px-2 ">
            <div className="break-keep text-left font-medium text-black">
              {funeralHomeInfo.name}
              <p className="break-keep text-gray-500">
                {funeralHomeInfo.address}
                <span
                  className="cursor-pointer break-keep pl-2 font-bold text-apricot-600 underline"
                  onClick={() => {
                    addressCopyHandler(
                      funeralHomeInfoData.address,
                      notifiacationInstance,
                      '주소 복사 완료',
                    );
                  }}
                >
                  주소복사
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 gap-2 pb-3">
        <a href={`tel:${funeralHomeInfo.phoneNumber}`} className="w-full">
          <Button className="center-box w-full gap-2 rounded bg-white text-gray-700 shadow-sm">
            <div className="center-box -mr-1 h-6 w-6">
              <Icon.Phone className="h-5 w-5 fill-gray-500 stroke-none" />
            </div>
            <p>장례식장</p>
          </Button>
        </a>
        <Button
          onClick={() => setOpen(true)}
          className="center-box gap-2 rounded bg-white text-gray-700 shadow-sm"
        >
          <Icon.MapLocation className="h-6 w-6 fill-gray-500 stroke-none" />
          <p>길찾기</p>
        </Button>
        {id && (
          <NavigatorsModal
            openId={id}
            coords={funeralHomeInfo.coord}
            destinationName={funeralHomeInfo.name}
          />
        )}
        <Button
          onClick={() => setPublicOpen(true)}
          className="center-box gap-2 rounded bg-white text-gray-700 shadow-sm"
        >
          <Icon.TaxiBus className="h-6 w-6 stroke-none" />
          <p>대중교통</p>
        </Button>
        {publicTransportId && (
          <NavigatorsModal
            openId={publicTransportId}
            coords={funeralHomeInfo.coord}
            destinationName={funeralHomeInfo.name}
            publicTransport={true}
          />
        )}
      </div>
      {funeralHomeInfo?.parkingAddress && (
        <div className="pb-3 text-left text-16">
          <div className=" px-2">
            <p className="font-bold leading-5 text-gray-700">주차 안내</p>
            <p className=" break-keep text-black">
              {funeralHomeInfo?.parkingAddress ?? ''}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export { ObituaryFuneralHomeInfo };
