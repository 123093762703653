import { FC, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { Bugo, Member } from '@api/mongo/interfaces';
import { Button } from '@components/Button';
import { nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { ConfirmModal } from '@components/ConfirmModal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { TopLayerModal } from '@components/TopLayerModal';

import { MournerListItem } from './PreviewMournerListItem';
import { PreviewSentSuccessModal } from './PreviewSentSuccessModal';

type MournerListProps = {
  listData: Member<string, Bugo>[];
  feventId: string;
  isMobileMedia: boolean;
  currentBugoId: string;
};

export type SentMemberListType = {
  name: string;
  phoneNumber: string;
};

const MournerList: FC<MournerListProps> = ({
  listData,
  feventId,
  isMobileMedia,
  currentBugoId,
}) => {
  const allSendConfirmModalOpenId = 'allSendConfirmModal';
  const sendingModalOpenId = 'sendingModal';
  const sentSuccessModalOpenId = 'sendSuccessModal';
  const emptyPhoneNumberText = '연락처 미입력';

  const [sentMemberList, setSentMemberList] = useState<[] | SentMemberListType[]>([]);
  const setAllSendConfirmModal = useSetRecoilState(
    modalOpenAtomFamily(allSendConfirmModalOpenId),
  );
  const setSendingModalOpen = useSetRecoilState(modalOpenAtomFamily(sendingModalOpenId));
  const setSuccessModal = useSetRecoilState(modalOpenAtomFamily(sentSuccessModalOpenId));

  const allSendHandler = async () => {
    const updatedMemberList = listData
      .filter((member) => member.fullName)
      .map((member) => ({
        name: member.fullName,
        phoneNumber:
          member.phoneNumber !== '' ? member.phoneNumber : emptyPhoneNumberText,
      }));
    //해당 상태를 list에 전달하여 전송완료 여부 표시
    setSentMemberList(updatedMemberList);
    setSendingModalOpen(true);
    //부고장 전송
    await Promise.all(
      listData.map(async (member) => {
        await nestAlertApi().bugoSend({
          bugos: [member.bugo?._id ?? ''],
          method: undefined,
        });
      }),
    );
    //
    setSendingModalOpen(false);
    setSuccessModal(true);
  };

  return (
    <>
      {!isMobileMedia && (
        <div className="mb-3 break-keep bg-white p-1">
          ※ 상주님별로 부고장이 개별 생성됩니다. 미리보실 부고장의 상주님을 선택해주세요
        </div>
      )}
      <div className="flex flex-col gap-y-3">
        {listData.map((member) => {
          return (
            <MournerListItem
              key={member._id}
              bugoId={member.bugo?._id ?? ''}
              memberName={member.fullName}
              feventId={feventId}
              currentBugoId={currentBugoId}
            />
          );
        })}
        <Button
          className={`center-box relative min-h-[44px] w-full rounded border border-transparent bg-apricot-600 font-medium text-white shadow-sm`}
          onClick={() => {
            setAllSendConfirmModal(true);
          }}
        >
          전체 상주 부고 전송
        </Button>
      </div>

      <ConfirmModal
        title="전체 상주님께 부고를 전송하시겠습니까?"
        buttonTitle="전송"
        openId={allSendConfirmModalOpenId}
        onClick={() => allSendHandler()}
      ></ConfirmModal>

      <PreviewSentSuccessModal feventId={feventId} sentMemberList={sentMemberList} />
      <TopLayerModal text="전송 중" openId={sendingModalOpenId} />
    </>
  );
};

export { MournerList };
