import { forwardRef, InputHTMLAttributes } from 'react';

import { useId } from 'react-id-generator';
import { CustomCallback } from 'src/interfaces';

import { Icon } from './Icon';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  //! onCheckedChange에 checked state를 변경하는 코드가 필수로 있어야함!!
  onCheckedChange: CustomCallback<boolean, any>;
}

export const CheckBox = forwardRef<HTMLInputElement, IProps>(
  ({ checked, onCheckedChange, className = '', ...props }, ref) => {
    const [id] = useId(1, 'checkbox');

    return (
      <label
        className={`center-box relative h-5 w-5 shrink-0 cursor-pointer rounded  transition-colors ${className} ${
          checked ? ' bg-primary' : 'border-2'
        }`}
      >
        <input
          ref={ref}
          id={id}
          className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0 "
          type="checkbox"
          {...props}
          onChange={(event) => onCheckedChange(event.currentTarget.checked)}
          checked={checked}
        />
        {checked && <Icon.CheckBox className=" h-3  w-3 stroke-none " />}
      </label>
    );
  },
);
