import { useRecoilValue, useSetRecoilState } from 'recoil';

import { impUidAtom } from '@containers/FindIdPassword/state/imp.atom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateError } from 'src/@utils/noti.utils';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    IMP: any;
  }
}

export const useImp = () => {
  const setImpUid = useSetRecoilState(impUidAtom);
  const notificationInstance = useRecoilValue(notificationInstanceAtom);

  const navigate = useNavigate();

  const IMP = window.IMP;
  // 가맹점 식별 코드로 생성
  IMP.init(process.env.REACT_APP_IMP_CODE);
  // console.log(redirectUrl);
  //imp certification 호출
  const phoneCertification = (redirectUrl: string, name: string, phone: string) => {
    console.log(IMP.certification);
    IMP.certification(
      {
        //params
        m_redirect_url: process.env.REACT_APP_BASE_URL + redirectUrl, // 다시 돌아갈 원래 url
        popup: true,
        name, // 이름 자동완성을 위한 param
        phone, // 휴대폰번호 자동완성을 위한 param
      },
      (res: { success: boolean; imp_uid: string; error_msg: string }) => {
        //callback
        if (res.success) {
          // 인증성공
          // console.log(rsp);
          // console.log(redirectUrl);
          setImpUid(res.imp_uid);
          navigate(redirectUrl);
        } else {
          //인증 실패
          notificateError(
            notificationInstance,
            `인증에 실패하였습니다.\n에러:${res.error_msg}`,
          );
        }
      },
    );
  };

  return { phoneCertification };
};
