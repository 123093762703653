import { FC, useCallback, useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { nestAuthApi } from '@api/mongo/controllers';
import { User } from '@api/mongo/interfaces';
import { BasicInput } from '@components/BasicInput';
import { impUidAtom } from '@containers/FindIdPassword/state/imp.atom';
import { useImp } from '@plugin/imp/imp.certification';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ciUserAtom, ciUserListAtom, selectUserIdAtom } from '../state/ciUser.atom';

export type UserResponse = Pick<User, '_id' | 'username' | 'info'> & {
  createdAt: string;
};

type FormValues = {
  name: string;
  phoneNumber: string;
};

interface ConfirmByPhoneNumberFormProps {
  selectedTab: number;
}
const { findByCi } = nestAuthApi();

const ConfirmByPhoneNumberForm: FC<ConfirmByPhoneNumberFormProps> = ({ selectedTab }) => {
  const { handleSubmit, register } = useForm<FormValues>();
  const setCiUser = useSetRecoilState(ciUserAtom);
  const [ciUserList, setCiUserList] = useRecoilState(ciUserListAtom);
  const selectUserId = useRecoilValue(selectUserIdAtom);
  const impUid = useRecoilValue(impUidAtom);

  const { phoneCertification } = useImp();

  const getCiUserList = useCallback(
    async (impUid: string) => {
      const { data } = await findByCi({ imp_uid: impUid });
      setCiUserList(data);
    },
    [setCiUserList],
  );

  useEffect(() => {
    // 해당 uid의 phoneNumber와 일치하는 계정을 찾는 api
    if (!impUid) return;
    if (ciUserList) return;
    getCiUserList(impUid);
  }, [impUid, ciUserList, getCiUserList]);

  useEffect(() => {
    if (selectUserId) {
      setCiUser(ciUserList?.find((ciUser) => ciUser?.username === selectUserId));
    }
  }, [selectUserId, ciUserList, setCiUser]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    phoneCertification('find-id-password', data.name, data.phoneNumber);
  };

  return (
    <form className="mt-6 text-left" onSubmit={handleSubmit(onSubmit)}>
      <p className="text-12 font-medium text-black">
        {selectedTab === 0 ? '휴대폰으로 찾기' : '휴대폰으로 재설정하기'}
      </p>
      <BasicInput
        placeholder="이름"
        className="mt-2 px-4 text-16"
        {...register('name', { required: true })}
      />
      <BasicInput
        placeholder="휴대전화번호"
        className="mt-3 px-4 text-16"
        {...register('phoneNumber', { required: true })}
      />
      <button
        type="submit"
        className="mt-4 w-full rounded bg-primary px-4 py-[10px] text-14 text-white"
      >
        휴대폰 인증하기
      </button>
    </form>
  );
};

export { ConfirmByPhoneNumberForm };
