import { MainSection } from '@components/MainSection';
import { EnquiryForm } from '@containers/EnquiryForm';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';

const FlowerStoreEnquiryPage = () => {
  useMobileNavTitleHook('1:1문의하기', true);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>

      <Body footer header>
        <MainSection>
          <EnquiryForm />
        </MainSection>
      </Body>

      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { FlowerStoreEnquiryPage };
