import { FC } from 'react';

import { useNavigate } from 'react-router';

const StrangerLoginBtnSet: FC = () => {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate('/fevent/add');
  };

  return (
    <div className="mt-8">
      <p className="font-['Noto_Sans'] text-12 text-gray-500">
        비회원으로도 부고 작성이 가능합니다
      </p>
      <button
        onClick={onButtonClick}
        className="mt-2 w-full rounded border border-apricot-600 bg-white px-8 py-3 text-18 text-apricot-600"
      >
        비회원 부고 작성하기
      </button>
    </div>
  );
};

export { StrangerLoginBtnSet };
