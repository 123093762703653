import { FlowerStoreMyPage } from '@pages/FlowerStore/FlowerStoreMyPage';
import { StoreConfirmPasswordPage } from '@pages/FlowerStore/StoreUser/StoreConfirmPasswordPage';
import { StoreUpgradePage } from '@pages/FlowerStore/StoreUser/StoreUpgradePage';
import { Route, Routes } from 'react-router-dom';

const StoreUserRouter = () => {
  return (
    <Routes>
      <Route path="mypage/*" element={<FlowerStoreMyPage />}></Route>
      <Route path="/upgrade/select-type" element={<StoreUpgradePage />} />
      <Route path="/confirm-password" element={<StoreConfirmPasswordPage />} />
    </Routes>
  );
};

export { StoreUserRouter };
