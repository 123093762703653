export enum UserRole {
  Admin = 'Admin',
  BugoAgencyAdmin = 'BugoAgencyAdmin',
  BugoFuneralHome = 'BugoFuneralHome',
  BugoAgencyWorker = 'BugoAgencyWorker',
  BugoCustomer = 'BugoCustomer',
  BugoGuest = 'BugoGuest',
  StoreSeller = 'StoreSeller',
  StoreCustomer = 'StoreCustomer',
  StoreGuest = 'StoreGuest',
}

export enum DeliveryState {
  BEFORE_PREPARING = 'BeforePreparing',
  PREPARING = 'Preparing',
  DELIVERYING = 'Deliverying',
  DELIVERED = 'Delivered',
}

export enum PaymentState {
  NOT_PAID = 'NotPaid',
  WAITING = 'Waiting',
  PAID = 'Paid',
  CANCEL_REQUESTED = 'CancelRequested',
  CANCELED = 'Canceled',
  CONFIRMED = 'Confirmed',
}

export enum Gender {
  Male = '남',
  Femail = '여',
}

export type CustomCallback<A, T> = (e: A) => Promise<T> | T;

export type FeventDate = {
  date: Date | null;
  timeDefined: boolean;
};

export enum LowerRole {
  Admin = 'admin',
  NonAdmin = 'non-admin',
  BugoAgencyAdmin = 'bugo-agency-admin',
  BugoFuneralHome = 'bugo-funeralHome',
  BugoAgencyWorker = 'bugo-agency-worker',
  BugoCustomer = 'bugo-customer',
  BugoGuest = 'bugo-guest',
  StoreSeller = 'store-seller',
  StoreCustomer = 'store-customer',
  StoreGuest = 'store-guest',
  Public = 'public',
}

export enum CamelRole {
  Admin = 'admin',
  BugoAgencyAdmin = 'bugoAgencyAdmin',
  BugoFuneralHome = 'bugoFuneralHome',
  BugoAgencyWorker = 'bugoAgencyWorker',
  BugoCustomer = 'bugoCustomer',
  BugoGuest = 'bugoGuest',
  StoreSeller = 'storeSeller',
  StoreCustomer = 'storeCustomer',
  StoreGuest = 'storeGuest',
  Public = 'public',
}

export enum UserCustomTheme {
  Default = 'Default',
  Preed = 'Preed',
  Dark = 'Dark',
  DaeMyung = 'DaeMyung',
}

export enum AlertMethod {
  MESSAGE = 'Message',
  KAKAO = 'Kakao',
}

export enum UpsertMode {
  Update = 'Update',
  Insert = 'Insert',
}

export enum StoreItemTagEnum {
  Best = 'BEST',
  Primium = '프리미엄',
  Popular = '인기',
  SpecialPrice = '특가',
  FreeDelivery = '무료배송',
  Sale = 'SALE',
  Hit = 'HIT',
  Reward = '적립',
}

//* 실제 입력으로 사용하는 것
export enum MemberFormInputsEnum {
  Name = 'name',
  RelationType = 'relationType',
  PhoneNumber = 'phoneNumber',
  CashAccountId = 'cashAccountId',
  CashAccount = 'cashAccount',
  AccountHolder = 'accountHolder',
  Bank = 'bank',
  AccountHolderSame = 'accountHolderSame',
}

export interface MemberIFormInput {
  _id: string; // member._id
  [MemberFormInputsEnum.Name]: string; // member.fullname
  [MemberFormInputsEnum.RelationType]: string; // member.relationType
  [MemberFormInputsEnum.PhoneNumber]: string; // member.phoneNumber
  [MemberFormInputsEnum.CashAccountId]?: string; // member.cashAccount._id
  [MemberFormInputsEnum.CashAccount]: string; // member.cashAccount.account
  [MemberFormInputsEnum.AccountHolder]: string; // 예금주
  [MemberFormInputsEnum.Bank]: string; // member.cashAccount.bank
  [MemberFormInputsEnum.AccountHolderSame]: boolean; // 예금주 동일
  isVerified: boolean; // member.cashAccount.isVerified
  cashAccountModalConfirmed: boolean;
  isNew: boolean;
}

export interface MemberIFormInputs {
  member: MemberIFormInput[];
}

export const vacantMemberInputForm = [
  {
    _id: '',
    [MemberFormInputsEnum.Name]: '',
    [MemberFormInputsEnum.RelationType]: '',
    [MemberFormInputsEnum.PhoneNumber]: '',
    [MemberFormInputsEnum.CashAccount]: '',
    [MemberFormInputsEnum.AccountHolder]: '',
    [MemberFormInputsEnum.Bank]: '',
    [MemberFormInputsEnum.AccountHolderSame]: true,
    isVerified: false,
    cashAccountModalConfirmed: false,
    isNew: false,
  },
];
