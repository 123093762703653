import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { FeventStatus } from '@api/mongo/interfaces';
import { Chip } from '@components/Chip';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import dayjs from 'dayjs';
import { FeventDate } from 'src/interfaces';

interface UserBugoItemProps {
  _id: string;
  feventStatus?: FeventStatus;
  coffinOut?: FeventDate;
  funeralHomeName: string;
  roomName?: string;
  deceasedName: string;
  memberName?: string;
}

const UserBugoItem: FC<UserBugoItemProps> = ({
  _id,
  feventStatus,
  coffinOut,
  funeralHomeName,
  roomName,
  deceasedName,
  memberName,
}) => {
  const setOpen = useSetRecoilState(modalOpenAtomFamily(`confirm-remove-${_id}`));

  const setColorByFeventStatus = () => {
    switch (feventStatus) {
      case FeventStatus.Doing:
        return 'border-apricot-600 bg-apricot-600 text-white';
      case FeventStatus.Done:
        return 'border-gray-500 bg-gray-500 text-white';
      case FeventStatus.Todo:
        return 'border-apricot-600 bg-white text-apricot-600';
      default:
        return 'border-apricot-600 bg-white text-apricot-600';
    }
  };

  const setStatusText = () => {
    switch (feventStatus) {
      case FeventStatus.Doing:
        return '진행중';
      case FeventStatus.Done:
        return '발인완료';
      case FeventStatus.Todo:
        return '입실예정';
      default:
        return '입실예정';
    }
  };

  const setCoffinOutDate = () => {
    if (!coffinOut) {
      return '미정';
    }

    if (!coffinOut.date) {
      return '미정';
    } else if (!coffinOut.timeDefined) {
      return `${dayjs(coffinOut.date).format('YYYY년 MM월 DD일')} 시간 미정`;
    } else {
      return `${dayjs(coffinOut.date).format('YYYY년 MM월 DD일 시간 HH:mm')}`;
    }
  };

  return (
    <div className="w-full cursor-pointer border border-transparent bg-white p-2 px-4 text-14 font-medium shadow-md transition-all hover:bg-gray-100">
      <div className="flex justify-between">
        <div className="flex gap-3">
          <Chip color="gray">故 {deceasedName}님</Chip>
          <Chip color="orange">상주 {memberName}</Chip>
        </div>
        <button
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setOpen(true);
          }}
          className="rounded border border-red-500 bg-white px-4 py-1 text-red-500 hover:bg-red-500 hover:font-bold hover:text-white"
        >
          삭제
        </button>
      </div>
      <div className="mt-2 flex justify-between text-left ">
        <div className="mt-1 flex flex-col gap-1 break-keep text-16">
          <p>{funeralHomeName}</p>
          <p className="text-14">발인 {setCoffinOutDate()}</p>
        </div>
        <div className="flex flex-col gap-1">
          <p>{roomName}</p>
          <div
            className={`ml-auto w-[100px] rounded border py-1 px-4 text-center ${setColorByFeventStatus()}`}
          >
            {setStatusText()}
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserBugoItem };
