import { useRecoilValue } from 'recoil';

import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { Link } from 'react-router-dom';
import footerLogo from 'src/assets/image/footer-logo.webp';
import { v4 } from 'uuid';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

const FooterContent = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);
  const isMobileMedia = useMobileMediaQuery();

  const footerMenuArr = [
    {
      title: '마이페이지',
      href: '/user/mypage',
    },
    { title: '고객센터', href: '/faq' },
    { title: '이용약관', href: '/terms?title=use' },
    { title: '개인정보처리방침', href: '/terms?title=privacy' },
    { title: '배송 및 환불 규정', href: '/terms?title=delivery' },
  ];

  const StorefooterMenuArr = [
    {
      title: '마이페이지',
      href: !isMobileMedia
        ? '/flower-store/user/mypage?state=enquiry'
        : '/flower-store/user/mypage',
    },
    { title: '고객센터', href: '/flower-store/faq' },
    { title: '이용약관', href: '/flower-store/terms?title=use' },
    { title: '개인정보처리방침', href: '/flower-store/terms?title=privacy' },
    { title: '배송 및 환불 규정', href: '/flower-store/terms?title=delivery' },
  ];

  return (
    <div className="flex w-[1120px] flex-col items-center justify-center">
      {/* logo */}
      <div className="mb-12 mt-2 flex h-[53px] max-w-[360px] items-center justify-center ">
        <img src={footerLogo} alt="footerLogo"></img>
      </div>
      {/* footerMenu */}
      <div className="mb-12 w-full text-14">
        <ul className="flex  justify-evenly  max-sm:flex-col max-sm:gap-4 max-sm:text-left">
          {(isStorePage ? StorefooterMenuArr : footerMenuArr).map((item) => (
            <li key={v4()}>
              <Link to={item.href}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      {/* Info */}
      <div className="mb-12 w-full text-14 max-sm:text-left max-sm:text-12">
        사업자 등록번호 : 363-86-02183 | 대표 : 김기훈, 정호산 <br />
        서울특별시 금천구 가산디지털2로 101, 한라원앤원타워 제B동 14층 1402호
        <br /> CS :<a href="tel:1577-1954">1577-1954</a> | 메일 : contact@gaonprime.com
        <br />
        통신판매업신고 : 제 2023-서울금천-0445호
      </div>

      {/* copyright */}
      <div className="w-full text-16 max-sm:text-left max-sm:text-12">
        © 2021 GAON prime, Corp. All rights reserved.
      </div>
    </div>
  );
};

export { FooterContent };
