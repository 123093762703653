import gql from 'graphql-tag';
import { Count } from 'src/graphql/interface';

const COUNT_BY_PK = gql`
  query COUNT_BY_PK($id: uuid = "") {
    Count_by_pk(id: $id) {
      curr
    }
  }
`;

type COUNT_BY_PK_VARIABLES = {
  id: string;
};

type COUNT_BY_PK_RES = {
  Count_by_pk: Omit<Count, 'id' | '__typename'>;
};

export { COUNT_BY_PK, COUNT_BY_PK_VARIABLES, COUNT_BY_PK_RES };
