import { MemberHaveBugoAccount } from '../state/bugoSendForm.interface';

const setDefaultValues = (memberOrderList: MemberHaveBugoAccount[]) => {
  return memberOrderList.map((member) => {
    return {
      _id: member._id,
      bugoId: member.bugo?._id,
      phoneNumber: member.phoneNumber,
      mournerText: member.bugo?.mournerText ?? '',
    };
  });
};

export { setDefaultValues };
