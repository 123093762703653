import { useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { useAlertNotSupportedHook } from '@hooks/useAlertNotSupportedHook';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Link } from 'react-router-dom';
import { mobileSidePopupModalId } from '@containers/MobileSidePopup';

const MenuListItem = ({ name, href }: { name: string; href: string | undefined }) => {
  const { alertNotSupported, alertPartnership } = useAlertNotSupportedHook();
  const setMobileSidePopupOpen = useSetRecoilState(
    modalOpenAtomFamily(mobileSidePopupModalId),
  );

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const buttonText = event.currentTarget.firstElementChild?.textContent;
    if (buttonText === '제휴신청 문의') {
      event.preventDefault();
      alertPartnership();
      return;
    }
    if (!href) {
      event.preventDefault();
      alertNotSupported();
      return;
    }
    setMobileSidePopupOpen(false);
  };

  return (
    <Link onClick={onClickHandler} to={href ?? ''}>
      <li className="flex items-center justify-between  bg-white  px-4 py-[10px] text-14 font-medium text-[#000000]">
        {name}
        <Icon.ArrowRight className="h-6 w-6 stroke-none" />
      </li>
    </Link>
  );
};

export { MenuListItem };
