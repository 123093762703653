import { useRecoilValue } from 'recoil';

import { useBugoHook } from '@containers/BugoPageContent/state/bugo.hook';
import { bugoStateSelectorFamily } from '@containers/BugoPageContent/state/bugo.selector';
import _ from 'lodash';
import { BugoPageContent } from '@containers/BugoPageContent';

const BugoPreview = ({ bugoId }: { bugoId: string }) => {
  const bugoState = useRecoilValue(bugoStateSelectorFamily(`${bugoId}`));
  useBugoHook(`${bugoId}`, _.isUndefined(bugoId));

  return (
    <div className="max-sm:m-[-1rem]">
      {!_.isNil(bugoState.data) && (
        <BugoPageContent bugo={bugoState.data}></BugoPageContent>
      )}
    </div>
  );
};

export { BugoPreview };
