import { useRecoilValue } from 'recoil';

import { Loading } from '@components/Loading';
import { BugoPageContent } from '@containers/BugoPageContent';
import { useMemberBugoDataHook } from '@containers/BugoPageContent/state/bugo.hook';
import { memberBugoDataStateSelectorFamily } from '@containers/BugoPageContent/state/bugo.selector';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { MemberBugoPageHeader } from './components/MemberBugoPageHeader';

const MemberBugoPage = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();

  const rightButtonHandler = () => {
    navigate(-1);
  };

  useMemberBugoDataHook(memberId ?? '');
  const { data, status } = useRecoilValue(
    memberBugoDataStateSelectorFamily(memberId ?? ''),
  );

  if (!data || !data.bugo) {
    return (
      <>
        <MemberBugoPageHeader rightButtonHandler={rightButtonHandler} />
        <Loading />
      </>
    );
  }

  switch (status) {
    case 'success':
      return (
        <>
          <MemberBugoPageHeader rightButtonHandler={rightButtonHandler} />
          <div className="mt-[56px]">
            <BugoPageContent bugo={data.bugo}>
              <Outlet />
            </BugoPageContent>
          </div>
        </>
      );
    default:
      return (
        <>
          <MemberBugoPageHeader rightButtonHandler={rightButtonHandler} />
          <Loading />
        </>
      );
  }
};

export { MemberBugoPage };
