import { MainSection } from '@components/MainSection';
import { FooterContent } from '@containers/FooterContent';
import { SignUpForm } from '@containers/SignUpForm';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';

const FlowerStoreSignUpPage = () => {
  useMobileNavTitleHook('회원가입', true, true);
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection>
          <SignUpForm />
        </MainSection>
      </Body>

      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { FlowerStoreSignUpPage };
