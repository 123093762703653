import { FC } from 'react';

import { Bugo, CashAccount, Member } from '@api/mongo/interfaces';
import { Icon } from '@components/Icon';
import { Link } from 'react-router-dom';
import { nestAlertApi } from '@api/mongo/controllers/alert.controller';

interface MemberListProps {
  bugoId?: string;
  members: Member<string, Bugo, CashAccount>[];
  sendToCurMemberModalOpen: () => void;
}

const MemberList: FC<MemberListProps> = ({
  bugoId,
  members,
  sendToCurMemberModalOpen,
}) => {
  const { courtesySend } = nestAlertApi();
  const onClickSendCurrentMember = async (bugoId: string) => {
    await courtesySend({
      bugos: [bugoId],
    });
    sendToCurMemberModalOpen();
  };
  return (
    <>
      <div className={`my-4 flex flex-col gap-4 ${bugoId && 'hidden'}`}>
        {members.map((member) => (
          <Link to={`?bugoId=${member.bugo?._id}`} key={member._id}>
            <div
              className={`relative cursor-pointer rounded border py-3 px-4 text-center drop-shadow ${
                bugoId === member.bugo?._id
                  ? 'border-transparent bg-apricot-600 text-white'
                  : 'bg-white'
              }`}
            >
              {bugoId === member.bugo?._id && (
                <Icon.CircleCheckWhite className=" absolute left-2 top-2 h-[27px] w-[27px] stroke-none" />
              )}
              상주 {member.fullName}
            </div>
          </Link>
        ))}
      </div>
      <div className={`my-4 flex flex-col gap-4 max-sm:hidden`}>
        {members.map((member, index) => (
          <div className="flex gap-x-2" key={member._id}>
            <div
              className={`flex w-full items-center justify-start py-3 px-4 ${
                index === 0 ? 'font-bold' : ''
              }`}
            >
              상주 {member.fullName}
            </div>
            <button
              className="w-full rounded border bg-white px-4 py-3 shadow-sm"
              onClick={() => onClickSendCurrentMember(member.bugo?._id ?? '')}
            >
              개별 전송
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export { MemberList };
