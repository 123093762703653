import { FC } from 'react';

import { BugoKakaoShareContent } from '@components/Kakao/utils/bugoMessage.util';
import { UserBugoBrand } from '@api/mongo/interfaces';
import { BottomPopup } from '@components/BottomPopup';
import { Icon } from '@components/Icon';
import { checkIOS } from 'src/@utils/checkIOS';
import { RWebShare } from 'react-web-share';

import KakaoShareButton from './KakaoShareButton';

interface Props {
  openId: string;
  content: string;
  title: string;
  kakaoContent: BugoKakaoShareContent;
  linkUrl: string;
  bugoBrand?: UserBugoBrand;
}

export const WebShareModal: FC<Props> = function WebShareModal({
  openId,
  content,
  title,
  kakaoContent,
  linkUrl,
  bugoBrand,
}: Props) {
  const { ShareMessage, OtherApps } = Icon;
  const smsContent = content.replace(/\n/g, '%0D%0A');

  return (
    <BottomPopup openId={openId}>
      <div className="w-screen rounded-t-2xl bg-white px-9 pt-6 pb-12">
        <h2 className="whitespace-pre-line break-keep text-center text-base font-bold leading-5">
          공유하기
        </h2>
        <div className="center-box flex gap-6 pt-6">
          <a href={checkIOS() ? `sms:&body=${smsContent}` : `sms:?body=${smsContent}`}>
            <div className="center-box h-[70px] w-[80px]">
              <div className="flex flex-col items-center justify-center gap-2">
                <ShareMessage className="h-full w-full stroke-none" />
                <p className="text-sm font-bold leading-5">문자</p>
              </div>
            </div>
          </a>
          <div className="center-box h-[70px] w-[80px]">
            <div className="flex flex-col items-center justify-center gap-2">
              <KakaoShareButton
                kakaoContent={kakaoContent}
                kakaoButtonId={bugoBrand?.bugoAgency?.kakaoTemplateBugoButtonId}
                kakaoJavascriptKey={bugoBrand?.bugoAgency?.kakaoAppJavascriptKey}
              />
              <p className="text-sm font-bold">카카오톡</p>
            </div>
          </div>
          <RWebShare
            data={{
              text: content,
              url: linkUrl,
              title: title,
            }}
            onClick={() => console.log('shared successfully!')}
          >
            <div className="center-box h-[70px] w-[80px]">
              <div className=" flex flex-col items-center justify-center gap-2">
                <OtherApps className="stroke-none" />
                <p className="text-sm font-bold leading-5">다른 앱</p>
              </div>
            </div>
          </RWebShare>
        </div>
      </div>
    </BottomPopup>
  );
};
