import { FC } from 'react';

import { CourtesyAssetSelect } from './components/CourtesyAssetSelect';
import { CourtesyBtnSet } from './components/CourtesyBtnSet';
import { CourtesyContentSelect } from './components/CourtesyContentSelect';

interface CourtesyProps {
  memberName: string;
  deceasedName: string;
}

const Courtesy: FC<CourtesyProps> = ({ memberName, deceasedName }) => {
  return (
    <div className="mx-auto max-w-[640px] bg-gray-50 text-12">
      <div className="p-4">
        <div className="bg-white p-1 text-left">
          <p className="text-gray-800">
            ※ 지인분들께 답례인사를 보내드리실 수 있도록 도와드립니다
          </p>
          <ul className="ml-5 list-disc break-keep text-left leading-5">
            <li>원하시는 답례이미지와 답례글 유형을 선택해주세요.</li>
            <li>
              답례글 내용 수정은 아래 답례글 선택 후 글 상자를 클릭하셔서 변경하실 수
              있습니다
            </li>
            <li>
              답례인사(이미지) 버튼 클릭 시 원하시는 답례글과 이미지가 합쳐저 편지지같은
              답례인사이미지가 완성됩니다
            </li>
            <li>답례글 공유 클릭 시 이미지 없이 글만 전송됩니다</li>
            <li className=" whitespace-pre-line">
              {`각 상주 별로 자신의 성함이 답례 인사 마지막에 기재됩니다. 
              ex) 홍길동 상주 → 홍길동 배상`}
            </li>
          </ul>
        </div>
      </div>
      <CourtesyAssetSelect />
      <CourtesyContentSelect memberName={memberName} deceasedName={deceasedName} />
      <CourtesyBtnSet />
    </div>
  );
};

export { Courtesy };
