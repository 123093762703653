import React, { useEffect, useState, useRef, FC } from 'react';

import { useTimePickerDrag } from '../state/timePicker.hook';
import { HourWheelProps } from '../state/timePicker.interface';
import { initialNumbersValue, returnSelectedValue } from '../utils/createValues';

const HourWheel: FC<HourWheelProps> = ({ height, value, setValue, use12Hours }) => {
  const mainListRef = useRef<HTMLDivElement>(null);
  const hourLength = use12Hours ? 13 : 24;
  const [hours, setHours] = useState(
    initialNumbersValue(height, hourLength, parseInt(value.slice(0, 2))),
  );
  const initialHourValue = initialNumbersValue(
    height,
    hourLength,
    parseInt(value.slice(0, 2)),
  ).filter((item) => item.number === value.slice(0, 2) && item.selected === true);

  const initialTranslatedValue =
    initialHourValue.length > 0
      ? parseInt(initialHourValue[0]?.translatedValue ?? '')
      : 0;

  const [currentTranslatedValue, setCurrentTranslatedValue] =
    useState(initialTranslatedValue);

  const [mouseWheelType, setMouseWheelType] = useState<string | null>(null);

  const {
    handleMouseDown,
    handleMouseUpAndMouseLeave,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleClickToSelect,
    dragDirection,
    startCapture,
    cursorPosition,
    setCursorPosition,
    dragEndTime,
    dragStartTime,
    showFinalTranslate,
    isFastCondition,
    isSlowCondition,
  } = useTimePickerDrag({ setCurrentTranslatedValue });

  // preview translation
  useEffect(() => {
    if (startCapture && mainListRef.current) {
      mainListRef.current.style.transform = `translateY(${
        currentTranslatedValue + cursorPosition
      }px)`;
    }
  }, [currentTranslatedValue, cursorPosition, startCapture]);

  // final translation here
  useEffect(() => {
    if (showFinalTranslate && mainListRef.current) {
      if (dragEndTime - dragStartTime <= 100 && cursorPosition !== 0) {
        let currentValue = 0;
        if (dragDirection === 'down') {
          currentValue =
            currentTranslatedValue - (120 / (dragEndTime - dragStartTime)) * 100;
        } else if (dragDirection === 'up') {
          currentValue =
            currentTranslatedValue + (120 / (dragEndTime - dragStartTime)) * 100;
        }
        let finalValue = Math.round(currentValue ?? 0 / height) * height;
        if (use12Hours) {
          if (finalValue < height * -34) finalValue = height * -34;
          if (finalValue > height) finalValue = height;
        } else {
          if (finalValue < height * -69) finalValue = height * -69;
          if (finalValue > height * 2) finalValue = height * 2;
        }

        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      if (dragEndTime - dragStartTime > 100 && cursorPosition !== 0) {
        let finalValue = Math.round(currentTranslatedValue / height) * height;
        if (use12Hours) {
          if (finalValue < height * -34) finalValue = height * -34;
          if (finalValue > height) finalValue = height;
        } else {
          if (finalValue < height * -69) finalValue = height * -69;
          if (finalValue > height * 2) finalValue = height * 2;
        }
        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      setCursorPosition(0);
    }
  }, [
    currentTranslatedValue,
    cursorPosition,
    dragDirection,
    dragEndTime,
    dragStartTime,
    height,
    setCursorPosition,
    showFinalTranslate,
    use12Hours,
  ]);

  // return to default position after drag end (handleTransitionEnd)
  const handleTransitionEnd = () => {
    const selectedTranslatedValue = currentTranslatedValue.toString();
    setHours((prevHours) => {
      return prevHours.map((hour) => {
        if (hour.translatedValue === selectedTranslatedValue) {
          return {
            ...hour,
            selected: true,
          };
        } else {
          return {
            ...hour,
            selected: false,
          };
        }
      });
    });
    returnSelectedValue(height, hourLength).forEach((item) => {
      if (parseInt(item.translatedValue) === currentTranslatedValue) {
        setValue((prev) => prev && `${item.number}:${prev.slice(3, 6)}`);
      }
    });
  };

  /** ***************************   handle wheel scroll ************************* */
  const handleWheelScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (Math.abs(e.deltaY) > 50) {
      setMouseWheelType('fast');
    } else {
      setMouseWheelType('slow');
    }
    let accumulatedDelta = e.deltaY;
    const scrollStep = () => {
      if (Math.abs(accumulatedDelta) >= height) {
        const scrollAmount = Math.floor(accumulatedDelta / height) * height;
        if (use12Hours) {
          if (scrollAmount > 0) {
            if (currentTranslatedValue < height) {
              setCurrentTranslatedValue((prev) => prev + height);
            }
          } else if (currentTranslatedValue > height * -34) {
            setCurrentTranslatedValue((prev) => prev - height);
          }
        } else {
          if (scrollAmount > 0) {
            if (currentTranslatedValue < height * 2) {
              setCurrentTranslatedValue((prev) => prev + height);
            }
          } else if (currentTranslatedValue > height * -69) {
            setCurrentTranslatedValue((prev) => prev - height);
          }
        }
        accumulatedDelta -= scrollAmount;
      }
      if (Math.abs(accumulatedDelta) >= height) {
        requestAnimationFrame(scrollStep);
      } else {
        setMouseWheelType(null);
      }
    };
    requestAnimationFrame(scrollStep);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (mouseWheelType) {
      timeoutId = setTimeout(() => setMouseWheelType(null), 300);
    }
    // Clean-up 함수
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [mouseWheelType]);

  return (
    <div
      className={`react-ios-time-picker-hour ${
        use12Hours && 'react-ios-time-picker-hour-12hour-format'
      }`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUpAndMouseLeave}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUpAndMouseLeave}
      style={{ height: height * 5 }}
      onWheel={handleWheelScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUpAndMouseLeave}
    >
      <div
        ref={mainListRef}
        className={`${
          (isFastCondition === true || mouseWheelType === 'fast') &&
          'react-ios-time-picker-fast'
        } ${
          (isSlowCondition === true || mouseWheelType === 'slow') &&
          'react-ios-time-picker-slow'
        }`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateY(${currentTranslatedValue}px)` }}
      >
        {hours.map((hourObj, index: number) => (
          <div
            key={index}
            className="react-ios-time-picker-cell-hour"
            style={{ height: `${height}px` }}
          >
            <div
              className={`react-ios-time-picker-cell-inner-hour${
                hourObj.selected ? ' react-ios-time-picker-cell-inner-selected' : ''
              }${hourObj?.hidden ? ' react-ios-time-picker-cell-inner-hidden' : ''}`}
              onClick={handleClickToSelect}
              data-translated-value={hourObj.translatedValue}
            >
              {hourObj.number}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HourWheel;
