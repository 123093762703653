import { Icon } from '@components/Icon';

const MemberBugoPageHeader = ({
  rightButtonHandler,
}: {
  rightButtonHandler: () => void;
}) => {
  return (
    <div className="fixed inset-0 mx-auto flex h-[56px] max-w-[640px] items-center justify-between bg-white px-4 shadow">
      <div className="flex min-w-0 items-center gap-2 text-16 font-bold text-black ">
        <Icon.Symbol className="shrink-0 stroke-none " />
        <p className="truncate">부고 공유하기(상주님 전용페이지)</p>
      </div>
      <div className=" shrink-0 cursor-pointer" onClick={rightButtonHandler}>
        <Icon.X />
      </div>
    </div>
  );
};
export { MemberBugoPageHeader };
