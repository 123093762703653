import { FC, useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Button } from '@components/Button';
import Modal from '@components/Modal';

import HourFormat from './HourFormat';
import MinuteWheel from './MinuteWheel';
import HourWheel from './HourWheel';
import { TimePickerSelectionProps, HourFormatType } from '../state/timePicker.interface';

const TimePickerSelection: FC<TimePickerSelectionProps> = ({
  openId,
  label,
  pickerDefaultValue,
  initialValue,
  onChange,
  cellHeight,
  saveButtonText,
  setInputValue,
  use12Hours,
  onAmPmChange,
}) => {
  const initialTimeValue =
    initialValue && use12Hours ? initialValue.slice(0, 5) : initialValue;

  const [value, setValue] = useState(initialTimeValue ?? pickerDefaultValue);
  const [hourFormat, setHourFormat] = useState<HourFormatType>({
    mount: false,
    hourFormat: initialValue && initialValue.slice(6, 8),
  });
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const handleSave = () => {
    const finalSelectedValue = use12Hours ? `${value} ${hourFormat.hourFormat}` : value;
    setInputValue(finalSelectedValue ?? '');
    onChange(finalSelectedValue ?? '');
    setOpen(false);
  };

  useEffect(() => {
    if (hourFormat.mount) {
      onAmPmChange(hourFormat.hourFormat ?? '');
    }
  }, [hourFormat, onAmPmChange]);

  return (
    <Modal openId={openId} className="select-none bg-white">
      <div className="w-full">
        {label && <h1 className="text-center">{label}</h1>}
        <div className="py-4 text-center text-[32px]">{value}</div>
        <div
          className="relative flex min-w-[230px] justify-center bg-white py-5 max-sm:min-w-full"
          style={{ height: `${cellHeight * 5 + 40}px` }}
        >
          <div
            className=" pointer-events-none absolute left-0 right-0 z-10 mx-auto w-[50%] rounded bg-black"
            style={{
              top: `${cellHeight * 2 + 20}px`,
              height: `${cellHeight}px`,
            }}
          />
          <HourWheel
            height={cellHeight}
            setValue={setValue}
            use12Hours={use12Hours}
            value={value ?? ''}
          />
          <div className="react-ios-time-picker-colon">:</div>
          <MinuteWheel height={cellHeight} setValue={setValue} value={value ?? ''} />
          {use12Hours && (
            <HourFormat
              height={cellHeight}
              hourFormat={hourFormat}
              setHourFormat={setHourFormat}
            />
          )}
        </div>
        <Button
          className="w-full rounded border-none bg-primary text-white"
          onClick={handleSave}
        >
          {saveButtonText}
        </Button>
      </div>
    </Modal>
  );
};

export default TimePickerSelection;
