import { Link } from 'react-router-dom';

const GuestLoginBtnSet = () => {
  return (
    <div className="mt-8">
      <p className="font-['Noto_Sans'] text-12 text-gray-500">비회원으로 주문하셨나요?</p>
      <Link to="/flower-store/guest-login">
        <button className="mt-2 w-full rounded border border-primary bg-white px-8 py-3 text-18 text-primary">
          비회원 주문조회하기
        </button>
      </Link>
    </div>
  );
};

export { GuestLoginBtnSet };
