import { atom, atomFamily } from 'recoil';

import { CurrentFevent } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import { QueryStatus } from 'react-query';

const currentFeventIdAtom = atom<string | undefined>({
  key: 'currentFeventIdAtom',
  default: undefined,
});

const currentFeventDataAtomFamily = atomFamily<CurrentFevent, string>({
  key: 'currentFevent',
  default: undefined,
});

const currentFeventStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'currentFeventStatusAtom',
  default: 'idle',
});

const feventDataSnapshotAtom = atom<CurrentFevent | undefined>({
  key: 'feventDataSnapshotAtom',
  default: undefined,
});

const dpImageFileAtom = atom<File | undefined>({
  key: 'dpImageFileAtom',
  default: undefined,
});

export {
  currentFeventIdAtom,
  currentFeventDataAtomFamily,
  currentFeventStatusAtomFamily,
  feventDataSnapshotAtom,
  dpImageFileAtom,
};
