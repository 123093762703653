import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Loading } from '@components/Loading';
import { Pagination, PaginationProps } from 'antd';

import { BugoHistoryItem } from './components/BugoHistoryItem';
import { MyPageTempItem } from './components/MyPageTempItem';
import { useUserBugoHistroyHook } from './state/userBugoHistroryContent.query';
import { userBugoHistroryContentStateSelector } from './state/userBugoHistroryContent.selector';

const UserBugoHistoryContent = () => {
  useUserBugoHistroyHook(false);
  const { data, status } = useRecoilValue(userBugoHistroryContentStateSelector);
  const [totalFeventCount, setTotalFeventCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const onClickPage: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (data) setTotalFeventCount(data.length);
  }, [data]);

  useEffect(() => {
    if (totalFeventCount > 0 && totalFeventCount / 5 < currentPage) {
      setCurrentPage(Math.ceil(totalFeventCount / 5));
    }
  }, [totalFeventCount, setCurrentPage, currentPage]);

  const pageStartIndex = currentPage * 5 - 5;
  const pageEndIndex = currentPage * 5;

  switch (status) {
    case 'loading':
      return <Loading />;
    case 'success':
      return (
        <>
          {status === 'success' && data && data.length > 0 ? (
            data
              .slice(pageStartIndex, pageEndIndex)
              .map((item) => <BugoHistoryItem key={item._id} {...item} />)
          ) : (
            <MyPageTempItem text="작성한 부고가 존재하지 않습니다" />
          )}

          {data && data?.length > 0 && (
            <Pagination
              current={currentPage}
              defaultPageSize={5}
              total={totalFeventCount}
              showSizeChanger={false}
              onChange={onClickPage}
              rootClassName="mt-8"
            />
          )}
        </>
      );
    default:
      return <MyPageTempItem text="작성한 부고가 존재하지 않습니다" />;
  }
};

export { UserBugoHistoryContent };
