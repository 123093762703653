import { FC } from 'react';

import { Icon } from '@components/Icon';

interface IDeliveryStatusIndicator {
  state: string;
  isSuccess: boolean;
}

const DeliveryStatusIndicator: FC<IDeliveryStatusIndicator> = ({
  state,
  isSuccess = false,
}) => {
  const IconFill = isSuccess ? 'stroke-none fill-white' : 'stroke-none fill-gray-300';

  return (
    <div className="flex items-center justify-center gap-2 pb-8 ">
      {state === '상품준비중' && <Ellipse isSuccess={isSuccess} />}
      {state === '배송중' && <Ellipse isSuccess={isSuccess} />}
      {state === '배송완료' && <Ellipse isSuccess={isSuccess} />}
      <div className="center-box relative flex flex-col ">
        <div
          className={`center-box h-12 w-12  rounded-[20px]   ${
            isSuccess ? 'bg-primary' : ' bg-white'
          }`}
        >
          {state === '결제완료' && <Icon.Check className={IconFill} />}
          {state === '상품준비중' && <Icon.Loading className={`${IconFill} w-7`} />}
          {state === '배송중' && <Icon.DeliveryNew className={`${IconFill} w-7`} />}
          {state === '배송완료' && <Icon.Check className={IconFill} />}
        </div>
        <p className="absolute -bottom-7 text-14 font-medium text-white ">{state}</p>
      </div>
    </div>
  );
};

export { DeliveryStatusIndicator };

const Ellipse = ({ isSuccess = false }: { isSuccess?: boolean }) => {
  const EllipseClass = isSuccess
    ? 'w-1 h-1 bg-primary rounded-full'
    : 'w-1 h-1 bg-white rounded-full';
  return (
    <div className="flex gap-[2px] ">
      <div className={`${EllipseClass}`}></div>
      <div className={`${EllipseClass}`}></div>
      <div className={`${EllipseClass}`}></div>
      <div className={`${EllipseClass}`}></div>
      <div className={`${EllipseClass}`}></div>
      <div className={`${EllipseClass}`}></div>
    </div>
  );
};
