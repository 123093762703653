import { FC } from 'react';

interface RefundAmountBoxProps {
  priceRetail: number;
  paymentByPoint: number;
}

const RefundAmountBox: FC<RefundAmountBoxProps> = ({ priceRetail, paymentByPoint }) => {
  return (
    <>
      <div className="flex flex-col justify-end bg-white p-4 text-14 font-medium  text-black">
        <div className="flex justify-between  text-16">
          <p>환불예상금액 </p>
          <p>{priceRetail.toLocaleString()}원</p>
        </div>

        <div className="my-4 border-t" />
        <div className="flex flex-col gap-3">
          <div className="flex justify-between ">
            <p>결제금액 </p>
            <p>{priceRetail.toLocaleString()}원</p>
          </div>
          <div className="flex justify-between  ">
            <p>포인트 결제금액 </p>
            <p className="text-apricot-600">{paymentByPoint.toLocaleString()}P</p>
          </div>
          <div className="flex justify-between  ">
            <p>환불예상금액 </p>
            <p>
              <span className="text-apricot-600">{paymentByPoint.toLocaleString()}P</span>
              + {priceRetail.toLocaleString()}원
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export { RefundAmountBox };
