import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Loading } from '@components/Loading';
import { BugoPageContent } from '@containers/BugoPageContent';
import { useBugoHook } from '@containers/BugoPageContent/state/bugo.hook';
import { bugoStateSelectorFamily } from '@containers/BugoPageContent/state/bugo.selector';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

const BugoPage: FC = () => {
  const { bugoId } = useParams();
  const bugoState = useRecoilValue(bugoStateSelectorFamily(bugoId ?? ''));
  useBugoHook(`${bugoId}`, _.isUndefined(bugoId));

  if (!bugoState.data) {
    return <Loading />;
  }

  switch (bugoState.status) {
    case 'success':
      return <BugoPageContent bugo={bugoState.data} />;
    default:
      return <Loading />;
  }
};

export { BugoPage };
