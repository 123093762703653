import { FAQPage } from '@pages/FAQ/FAQPage';
import { Route, Routes } from 'react-router-dom';

const FAQRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<FAQPage />} />
    </Routes>
  );
};

export { FAQRouter };
