import { FC, ReactNode, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { FeventFormData } from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { useFeventSubmitContentHooks } from '@containers/FeventSubmitContent/state/feventSubmitContent.hook';
import {
  CurrentFevent,
  StepNameEnum,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import { useAuth } from '@hooks/useAuth';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import _ from 'lodash';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { Gender } from 'src/interfaces';

import {
  DeceasedBasicInfoForm,
  DeceasedDateInfoForm,
  DeceasedInfoFormBtnSet,
  FuneralInfoForm,
  MobileDescriptionSection,
  WriterInfoForm,
} from './components';
import { FeventStepper } from '../FeventStepper';

interface DeceasedInfoFormProps {
  data?: CurrentFevent | undefined;
  children?: ReactNode;
}

const DeceasedInfoForm: FC<DeceasedInfoFormProps> = ({ data }) => {
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const currentFeventId = useRecoilValue(currentFeventIdAtom);

  const { isAuthenticated } = useAuth();
  const { createFevent, updateFevent } = useFeventSubmitContentHooks();

  const method = useForm<FeventFormData>({
    mode: 'onBlur',
    defaultValues: {
      ...data,
      members: undefined,
      shopOrders: undefined,
      memberOrderList: _.map(data?.memberOrderList, (item) => item._id),
      cashAccounts: undefined,
      deceasedInfo: {
        ...(data?.deceasedInfo ?? {
          name: '',
          bugoTerm: '',
          cemetery: '',
          sex: Gender.Male,
        }),
        dpImage: data?.deceasedInfo.dpImage?._id,
      },
      mournerText: data?.mournerText ?? '',
      roomInfoEmbed: data?.roomInfoEmbed ?? { name: '' },
      funeralHomeInfo: data?.funeralHomeInfo?._id,
    },
  });
  const equalResult = useCallback((): boolean => {
    let isDiff;
    if (method.formState.defaultValues?.deceasedInfo?.age) {
      isDiff = _.isEqual(method.formState.defaultValues, method.getValues());
    } else {
      const DefaultValuesForEqual = { ...method.formState.defaultValues };
      const GetValuesForEqual = { ...method.getValues() };
      DefaultValuesForEqual.deceasedInfo!.age = 0;
      GetValuesForEqual.deceasedInfo!.age = GetValuesForEqual.deceasedInfo!.age || 0;
      isDiff = _.isEqual(DefaultValuesForEqual, GetValuesForEqual);
    }
    return isDiff;
  }, [method]);

  const onSubmit = useCallback(
    async (data: FeventFormData, isStepper: boolean) => {
      if (!data.funeralHomeInfo && !data.funeralHomeInfoEmbed?.name) {
        notificateError(notifiacationInstance, '빨간 테두리 영역은 필수 정보입니다.');
        return;
      }
      console.log('onSubmit');
      console.log(data);
      if (location.pathname.indexOf('add') > 0 && !currentFeventId) {
        const res = await createFevent(data);

        console.log('create Response: ', res);
      } else {
        //수정하기
        await updateFevent(data);
      }
      if (!equalResult()) {
        notificateSuccess(notifiacationInstance, '저장되었습니다.');
      }
      if (!isStepper) navigate(`../${StepNameEnum.MournerInfo}`);
    },
    [
      createFevent,
      currentFeventId,
      equalResult,
      location.pathname,
      navigate,
      notifiacationInstance,
      updateFevent,
    ],
  );

  const onSubmitError: SubmitErrorHandler<FeventFormData> = () => {
    notificateError(notifiacationInstance, '빨간 테두리 영역은 필수 정보입니다.');
  };

  return (
    <FormProvider {...method}>
      <FeventStepper
        checkEqual={equalResult}
        onSubmit={method.handleSubmit((data) => onSubmit(data, true), onSubmitError)}
        trigger={method.trigger}
      />
      <form
        onSubmit={method.handleSubmit((data) => onSubmit(data, false), onSubmitError)}
      >
        {/* 모바일 only */}
        <MobileDescriptionSection />
        {/* TODO: [ ] 로그인 시에만 보여주도록 수정 필요 */}
        {!isAuthenticated && <WriterInfoForm />}

        <DeceasedBasicInfoForm />
        <DeceasedDateInfoForm />
        <FuneralInfoForm funeralHomeInfo={data?.funeralHomeInfo} />
        <DeceasedInfoFormBtnSet />
      </form>
    </FormProvider>
  );
};

export { DeceasedInfoForm };
