import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { QueryStatus } from 'react-query';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { Icon } from '@components/Icon';
import { Button } from '@components/Button';
import { addressCopyHandler } from 'src/@utils/addressCopyHandler';

//기존 CashAccount.interface.ts
export type CashAccount = {
  _id: string;
  name: string;
  account: string;
  bank: string;
  fevent?: string;
  isVerified?: boolean; //계좌 인증여부
};

export type CashAccountState = {
  status: QueryStatus;
  data: CashAccount | null;
};

export type CashAccountCreate = Omit<CashAccount, '_id'>;

export interface CashAccountVerify extends CashAccountCreate {
  _id?: string;
}

export type CashAccountUpdate = {
  _id: string;
  name?: string;
  account?: string;
  bank?: string;
  fevent?: string;
  isVerified?: boolean; //계좌 인증여부
};

export type CashAccountVerifyResponse = {
  isVerified: boolean;
  message: string;
};

interface Props {
  cashAccount?: CashAccount;
  forMember?: boolean;
  cashAccountEditOpenId?: string;
}

const MemberMoneyCard: FC<Props> = ({
  cashAccount,
  forMember,
  cashAccountEditOpenId,
}) => {
  const [fold, setFold] = useState(true);
  const setCashAccountEditOpen = useSetRecoilState(
    modalOpenAtomFamily(cashAccountEditOpenId),
  );
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const cashAccountContent = useMemo(() => {
    if (cashAccount && cashAccount.isVerified) {
      return (
        <div className="flex flex-col gap-1 px-4 text-14 font-medium text-black">
          <div className="flex gap-1">
            <p>예금주</p>
            <p>:</p>
            <p>{cashAccount.name}</p>
          </div>
          <div className="flex gap-3">
            <p>{cashAccount.bank}</p>
            <p>|</p>
            <p>{cashAccount.account}</p>
            <p
              className="cursor-pointer break-keep text-14 font-bold text-apricot-600 underline"
              onClick={() =>
                addressCopyHandler(
                  `${cashAccount.bank} ${cashAccount.account}`,
                  notifiacationInstance,
                  '계좌복사 완료',
                )
              }
            >
              계좌복사
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col gap-1 px-4 text-14 font-medium text-black">
          인증된 계좌정보가 존재하지 않습니다.
        </div>
      );
    }
  }, [cashAccount, notifiacationInstance]);

  const memberCashAccountContent = useMemo(() => {
    if (forMember) {
      return (
        <Button
          className="theme-text-3 border-none bg-primary p-2"
          onClick={() => {
            setCashAccountEditOpen(true);
          }}
        >
          부의금정보수정
        </Button>
      );
    }
  }, [forMember, setCashAccountEditOpen]);

  return (
    <div className="mx-3 flex min-h-[40px] flex-col gap-3 bg-white p-1 py-1 text-left">
      <div className="flex items-center justify-between  ">
        <div className="flex items-center">
          <Icon.MoneyBag className="h-10 w-10 stroke-none" />
          <span className="text-14 font-bold">부의금 안내</span>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setFold(!fold);
          }}
        >
          {fold ? (
            <Icon.ChevronDown className=" stroke-none" />
          ) : (
            <Icon.ChevronUp className=" stroke-none" />
          )}
        </div>
      </div>
      {!fold && (
        <>
          <div className="">
            {cashAccountContent}
            <div className="center-box pt-3">{memberCashAccountContent}</div>
          </div>
        </>
      )}
    </div>
  );
};

export { MemberMoneyCard };
