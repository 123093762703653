import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useNavigate } from 'react-router-dom';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { mobileSidePopupModalId } from '@containers/MobileSidePopup';

import { Icon } from './Icon';

type ProfileIconButtonProps = {
  isLogin: boolean;
  isGuest: boolean;
  name: string;
  svgClassName?: string;
};

const ProfileIconButton: FC<ProfileIconButtonProps> = ({
  isLogin,
  isGuest,
  name,
  svgClassName,
}) => {
  const isStorePage = useRecoilValue(isStorePageAtom);
  const setMobileSidePopupOpen = useSetRecoilState(
    modalOpenAtomFamily(mobileSidePopupModalId),
  );
  const navigate = useNavigate();
  const profileButtonHandler = () => {
    navigate(isStorePage ? '/flower-store/user/mypage' : '/user/mypage');
    setMobileSidePopupOpen(false);
  };

  const loginIcon = useMemo(() => {
    if (!isLogin) return <></>;
    if (isGuest)
      return (
        <>
          <Icon.EmptyUser className={`h-[32px] w-[32px] stroke-none ${svgClassName} `} />
          <span className="break-keep max-sm:font-bold max-sm:text-black ">
            (비회원) {name}님
          </span>
        </>
      );
    return (
      <>
        <Icon.User className={`h-[32px] w-[32px] stroke-none ${svgClassName} `} />
        <span className="break-keep max-sm:font-bold max-sm:text-black ">{name}님</span>
      </>
    );
  }, [isGuest, isLogin, name, svgClassName]);

  return (
    <div
      className="mx-2 flex cursor-pointer items-center px-2 max-sm:p-0"
      onClick={profileButtonHandler}
    >
      {loginIcon}
    </div>
  );
};

export { ProfileIconButton };
