import { FC, ReactNode } from 'react';

interface BugoPreviewSectionProps {
  children: ReactNode;
  title: string;
  bugoId: boolean;
}

const BugoPreviewSection: FC<BugoPreviewSectionProps> = ({ children, title, bugoId }) => {
  return (
    <div className="bg-gray-50 ">
      <div className="max-md:px-0; mx-auto min-h-[calc(100vh-529px)] max-w-[1120px] max-sm:min-h-[calc(100vh-569px)]">
        <div className={`p-8 max-md:p-4 ${bugoId && 'max-md:p-0'} `}>
          <h2 className="mb-8 text-left text-24 font-bold text-black max-sm:hidden">
            {title}
          </h2>
          {children}
        </div>
      </div>
    </div>
  );
};

export { BugoPreviewSection };
