import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  currentUserFeventListDataAtom,
  currentUserFeventListStatusAtom,
} from './userBugoList.atom';

const populate = [
  { path: 'funeralHomeInfo', select: ['name'] },
  { path: 'memberOrderList', select: ['_id', 'fullName'] },
  { path: 'roomInfo', select: ['name'] },
];

const feventListByCurrentUserUrl = (role: string) =>
  `${role}/fevent/?${queryString({
    populate: populate,
    options: { sort: { createdAt: -1 } },
    select: ['roomInfoEmbed', 'deceasedInfo', 'status', 'funeralHomeInfoEmbed'],
  })}`;

const useFeventListByCurrentUser = (disabled?: boolean) => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    currentUserFeventListStatusAtom,
    currentUserFeventListDataAtom,
    feventListByCurrentUserUrl(bugoRole()),
    fetcher,
    disabled,
  );
};

export { feventListByCurrentUserUrl, useFeventListByCurrentUser };
