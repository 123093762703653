import { useRecoilValue } from 'recoil';

import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';

const useNotificateSendHook = () => {
  const notificationInstance = useRecoilValue(notificationInstanceAtom);

  const notiBySendStatus = (status: boolean) => {
    if (status) {
      notificateSuccess(
        notificationInstance,
        '답례글 링크 전송에 성공하였습니다. 핸드폰을 확인해주세요',
      );
    } else {
      notificateError(notificationInstance, '답례글 링크 전송에 실패하였습니다.');
    }
  };

  return { notiBySendStatus };
};

export { useNotificateSendHook };
