import { FC, useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { MobileNavigation } from '@components/MobileNavigation';
import { FooterContent } from '@containers/FooterContent';
import { MainBanner } from '@containers/MainBanner';
import { MainContent } from '@containers/MainContent';
import {
  CustomerGrid,
  MainButtonSet,
  MainMiddleBanner,
  MobileMainButtonSet,
  PromotionInfoGrid,
} from '@containers/MainContent/components';
import { CustomerGridLeftBox } from '@containers/MainContent/components/CustomerGrid/components/CustomerGridLeftBox';
import { CustomerGridRightBox } from '@containers/MainContent/components/CustomerGrid/components/CustomerGridRightBox';
import { PromotionCouponCard } from '@containers/MainContent/components/PromotionInfoGrid/components/PromotionCouponCard';
import { PromotionInfoBox } from '@containers/MainContent/components/PromotionInfoGrid/components/PromotionInfoBox';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { Body, Footer, Header } from '@layout';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';

const MainPage: FC = () => {
  const setCurrentFeventId = useSetRecoilState(currentFeventIdAtom);
  useEffect(() => {
    setCurrentFeventId('');
  }, [setCurrentFeventId]);
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainBanner />
        <MainContent>
          <PromotionInfoGrid>
            <PromotionInfoBox />
            <PromotionCouponCard />
          </PromotionInfoGrid>
          <MainButtonSet />
          <MobileMainButtonSet />
          <MainMiddleBanner />
          <CustomerGrid>
            <CustomerGridLeftBox />
            <CustomerGridRightBox />
          </CustomerGrid>
        </MainContent>
      </Body>

      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { MainPage };
