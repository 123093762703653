import { atom } from 'recoil';

const localStorageEffect =
  (key: string) =>
  ({
    setSelf,
    onSet,
  }: {
    setSelf: any;
    onSet: (fn: (newValue: any, previousValue: any, isReset: boolean) => any) => void;
  }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, previousValue: any, isReset: boolean) => {
      if (isReset) {
        localStorage.removeItem(key);
        return previousValue;
      }

      localStorage.setItem(key, JSON.stringify(newValue));
      return newValue;
    });
  };

export const persistUsernameAtom = atom<string>({
  key: 'persistUsernameAtom',
  default: '',
  effects: [localStorageEffect('persist_username_atom')],
});

export const persistUserPasswordAtom = atom<string>({
  key: 'persistUserPasswordAtom',
  default: '',
  effects: [localStorageEffect('persist_password_atom')],
});
