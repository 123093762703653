import { FC } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { Image } from '@api/mongo/interfaces';
import { Icon } from '@components/Icon';

import {
  courtesyAssetIdAtom,
  courtesyAssetNameAtom,
  courtesyAssetSrcAtom,
} from '../state/courtesy.atom';

interface ImageItemProps {
  image: Omit<Image, 'createdAt' | 'updatedAt'>;
}

const CourtesyAssetItem: FC<ImageItemProps> = ({ image }) => {
  const [courtesyAssetId, setCourtesyAssetId] = useRecoilState(courtesyAssetIdAtom);
  const setCourtesyAssetName = useSetRecoilState(courtesyAssetNameAtom);
  const setCourtesyAssetSrc = useSetRecoilState(courtesyAssetSrcAtom);

  const onClick = () => {
    setCourtesyAssetId(image._id);
    setCourtesyAssetName(image.name);
    setCourtesyAssetSrc(image.url);
  };

  return (
    <div
      className="flex basis-[146px] cursor-pointer flex-col justify-between gap-4"
      onClick={onClick}
    >
      <div className={`relative h-full`}>
        <img src={image.url} className="h-full w-full" />
        {courtesyAssetId === image._id && (
          <div className="absolute top-0 left-0 z-10 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-60">
            <Icon.BigCheck className="h-10 w-10 fill-apricot-600 stroke-none" />
            <p className="text-14 font-medium text-black">선택됨</p>
          </div>
        )}
      </div>
      <p className="font-medium text-black">{image.name}</p>
    </div>
  );
};

export { CourtesyAssetItem };
