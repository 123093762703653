import gql from 'graphql-tag';
import { Count } from 'src/graphql/interface';

const SUBSCRIPTION_COUNT_BY_PK = gql`
  subscription SubsCountById($id: uuid = "") {
    Count_by_pk(id: $id) {
      curr
    }
  }
`;

type SUBSCRIPTION_COUNT_BY_PK_VARIABLES = {
  id: string;
};

type SUBSCRIPTION_COUNT_BY_PK_RES = {
  Count_by_pk: Omit<Count, 'id' | '__typename'>;
};

export {
  SUBSCRIPTION_COUNT_BY_PK,
  SUBSCRIPTION_COUNT_BY_PK_RES,
  SUBSCRIPTION_COUNT_BY_PK_VARIABLES,
};
