import { FC, forwardRef, useState } from 'react';

import { BasicInput, BasicInputProps } from './BasicInput';
import { Icon } from './Icon';

const VIEW_SVG_CLASSNAME = 'w-5 fill-gray-400 cursor-pointer px-1 box-content';

const PasswordInput: FC<BasicInputProps> = forwardRef(({ ...props }, ref) => {
  const [viewPW, setViewPW] = useState(false);

  const onClickViewPW = () => {
    setViewPW(!viewPW);
  };

  return (
    <BasicInput
      type={viewPW ? 'text' : 'password'}
      autoComplete="off"
      ref={ref}
      autoCapitalize="off"
      {...props}
      SuffixEl={
        viewPW ? (
          <Icon.EyeWithSlash className={VIEW_SVG_CLASSNAME} onClick={onClickViewPW} />
        ) : (
          <Icon.Eye className={VIEW_SVG_CLASSNAME} onClick={onClickViewPW} />
        )
      }
    />
  );
});

export { PasswordInput };
