import { useState } from 'react';

import { Icon } from '@components/Icon';

const FAQTabContentItem = ({ content }: { content: Record<string, string> }) => {
  const [isFold, setIsFold] = useState(true);

  return (
    <div className="w-full border-b px-2">
      <div
        className="flex cursor-pointer items-center  justify-between py-2 px-8 max-sm:px-0"
        onClick={() => {
          setIsFold(!isFold);
        }}
      >
        <div className="flex items-center gap-8">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-700 text-18 text-white max-sm:hidden">
            Q
          </div>
          <p className="py-3 px-4 text-14 text-gray-500 max-sm:text-12">
            {content.question}
          </p>
        </div>
        <Icon.ChevronUp
          className={`cursor-pointer stroke-none transition-all ${
            isFold ? 'rotate-180' : ''
          }`}
        />
      </div>
      <div
        className={`overflow-y-auto transition-[max-height] ${
          isFold ? 'max-h-0' : `max-h-[300px] max-sm:max-h-[300px] max-sm:px-0`
        }`}
      >
        <div className="flex items-start gap-8 bg-white px-8 py-4">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary text-18 text-white max-sm:hidden">
            A
          </div>
          <p className="whitespace-pre-line break-keep px-4 text-left text-14 text-gray-500 max-sm:text-12">
            {content.answer}
          </p>
        </div>
      </div>
    </div>
  );
};

export { FAQTabContentItem };
