import { CashAccount } from '@api/mongo/interfaces';

const findDuplicateCashAccount = (data: CashAccount[], name: string, account: string) => {
  const matches: CashAccount[] = [];
  data.forEach((i) => {
    if (i.name === name && i.account === account && i.isVerified === true)
      matches.push(i);
  });
  if (matches.length === 0) {
    return false;
  }

  return matches[0];
};

export { findDuplicateCashAccount };
