import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { FeventManageModal } from '@components/FeventManageModal';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateSuccess } from 'src/@utils/noti.utils';
import DismissModal from '@components/DismissModal';
import { useQueryClient } from 'react-query';
import { useAuth } from '@hooks/useAuth';
import { UserBugoItem } from '@components/UserBugoItem';

import { useBugoHistoryDeleteHook } from '../state/userBugoHistoryContent.hook';
import { UserBugoHistroyItem } from '../state/userBugoHistroryContent.interface';
import { userBugoHistoryHookUrl } from '../state/userBugoHistroryContent.query';

const BugoHistoryItem: FC<UserBugoHistroyItem> = (props) => {
  const {
    deceasedInfo,
    _id,
    funeralHomeInfo,
    funeralHomeInfoEmbed,
    memberOrderList,
    status,
    roomInfo,
    roomInfoEmbed,
  } = props;
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();
  const removeModalOpenId = `confirm-remove-${_id}`;
  const manageModalOpenId = `fevent-manage-modal-${_id}`;
  const setManageModal = useSetRecoilState(modalOpenAtomFamily(manageModalOpenId));
  const notificationInstance = useRecoilValue(notificationInstanceAtom);

  const { bugoDelete } = useBugoHistoryDeleteHook();

  return (
    <>
      <div className="mb-2" onClick={() => setManageModal(true)}>
        <UserBugoItem
          _id={_id}
          funeralHomeName={funeralHomeInfo.name ?? funeralHomeInfoEmbed.name}
          roomName={roomInfoEmbed?.name ?? roomInfo.name}
          deceasedName={deceasedInfo.name}
          memberName={memberOrderList[0]?.fullName}
          coffinOut={deceasedInfo.coffinOut}
          feventStatus={status}
        />
      </div>
      <DismissModal
        openId={removeModalOpenId}
        onClick={async () => {
          await bugoDelete(_id);
          await queryClient.invalidateQueries(userBugoHistoryHookUrl(bugoRole()));
          notificateSuccess(notificationInstance, '삭제 되었습니다.');
          console.log('삭제');
        }}
        buttonTitle={'삭제'}
        title={'장례(부고) 삭제'}
        subtitle="해당 장례를 삭제합니다. 삭제된 장례는 복원이 불가능합니다."
      ></DismissModal>

      <FeventManageModal
        title={`故 ${deceasedInfo.name}님 - ${funeralHomeInfo?.name ?? ''}`}
        feventId={_id}
        openId={manageModalOpenId}
      />
    </>
  );
};

export { BugoHistoryItem };
