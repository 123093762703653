import { FC } from 'react';

import { v4 } from 'uuid';

import { MenuListItem } from './MenuLinkItem';

type MenuLinkButtonProps = {
  title: string;
  subMenuList: SubMenuListType[];
};

type SubMenuListType = {
  name: string;
  href: string | undefined;
};

type SideBarProps = MenuLinkButtonProps[];

const MenuLinkButton: FC<MenuLinkButtonProps> = ({ title, subMenuList }) => {
  return (
    <>
      <div>
        <div className="bg-gray-50 px-4 text-12 font-medium leading-5 text-gray-700">
          {title}
        </div>
        <ul className="flex flex-col gap-[1px]">
          {subMenuList.map((item) => {
            return <MenuListItem key={v4()} {...item} />;
          })}
        </ul>
      </div>
    </>
  );
};

export { MenuLinkButton, SideBarProps };
