import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';

import { useRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Controller } from 'react-hook-form';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { Label } from '@components/Label';
import { Icon } from '@components/Icon';

import { MobileSelect } from './components/MobileSelect';
import { PcSelect } from './components/PcSelect';
import { AutoSelectProps } from './state/autoSelect.interface';

/**
 *
 * @param formControl useForm control
 * @param formFieldName useForm register name
 * @param optionList option list
 * @param [labelText] input label - optional
 * @param [isDisabled] input 비활성화 여부
 * @param [inputPlaceholder] input placeholder - optional
 * @param [allowsDirectInput] 직접입력 사용 여부 - optional
 * @param [modalStyleClass] mobile modal style - optional
 * @param [inputStyleClass] input style - optional
 * @param [validationRules] useForm register rules - optional
 * @param [onOptionClick] 옵션 선택 시 콜백함수 - optional
 */
const AutoSelect: FC<AutoSelectProps> = ({
  formControl,
  formFieldName,
  optionList,
  isDisabled,
  labelText,
  validationRules,
  inputPlaceholder = '선택해주세요.',
  allowsDirectInput = false,
  modalStyleClass,
  inputStyleClass,
  onOptionClick,
}) => {
  const openId = `auto-select-${formFieldName}`;
  const isMobileMedia = useMobileMediaQuery();
  const selectNodeRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));
  const [inputDisabled, setInputDisabled] = useState(true);

  const directInputHandler = () => {
    setInputDisabled(false);
    setOpen(false);
    if (!inputDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  };

  //select 외부 영역 클릭 시 셀렉트 close
  useEffect(() => {
    const handleClickOutside: MouseEventHandler = (e) => {
      if (selectNodeRef.current && !selectNodeRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener(
      'mousedown',
      handleClickOutside as unknown as EventListener,
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener,
      );
    };
  }, [setOpen]);

  //직접 입력 클릭 시 포커싱
  useEffect(() => {
    if (!inputDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputDisabled]);

  return (
    <div className="relative h-full w-full">
      {labelText && <Label text={labelText} />}
      <Controller
        name={formFieldName}
        defaultValue={''}
        rules={validationRules}
        control={formControl}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const selectHandler = (item: string) => {
            onChange(item);
            setInputDisabled(true);
            setOpen(false);
            //! 모바일일때는 Modal로 인해서 history.back 호출해야 뒤로가기 정상 작동함
            isMobileMedia && history.back();
          };
          return (
            <div ref={selectNodeRef}>
              <div
                className={`flex cursor-pointer items-center rounded border bg-white py-2 px-4 ${
                  open ? ' rounded-b-none' : ''
                } ${error && 'border-red-500'}
                `}
                onClick={() => setOpen((prev) => !prev)}
              >
                <input
                  ref={inputRef}
                  className={`w-full placeholder-shown:truncate disabled:cursor-pointer disabled:bg-transparent ${inputStyleClass}`}
                  value={value}
                  placeholder={inputPlaceholder}
                  disabled={isDisabled || inputDisabled}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                ></input>
                <Icon.ChevronDownFit className="h-3 w-3" />
              </div>
              {isMobileMedia ? (
                //! mobile
                <MobileSelect
                  value={value}
                  openId={openId}
                  labelText={labelText ?? ''}
                  modalStyleClass={modalStyleClass ?? ''}
                  allowsDirectInput={allowsDirectInput}
                  optionList={optionList}
                  selectHandler={selectHandler}
                  directInputHandler={directInputHandler}
                  onOptionClick={onOptionClick}
                />
              ) : (
                //! pc
                open && (
                  <PcSelect
                    value={value}
                    allowsDirectInput={allowsDirectInput}
                    optionList={optionList}
                    selectHandler={selectHandler}
                    directInputHandler={directInputHandler}
                    onOptionClick={onOptionClick}
                  />
                )
              )}
            </div>
          );
        }}
      ></Controller>
    </div>
  );
};

export { AutoSelect };
