//! 05.10 쿠폰 콘텐츠 비활성화

export type MyPageTabButtonType = {
  id: number;
  name: string;
  path: string;
};

export const myPageTabMenuList = (isStorePage: boolean): MyPageTabButtonType[] => {
  const basicTab = [
    { id: 1, name: '부고장 내역', path: '?state=bugo' },
    // { id: 2, name: '포인트 관리', path: '?state=point' },
    // { id: 3, name: '선물함 (쿠폰함)', path: '?state=coupon' },
    { id: 3, name: '화환 주문하기', path: '/flower-store' },
    { id: 4, name: '답례품 전하기', path: '?state=gift' },
    { id: 5, name: '문의 내역', path: '?state=enquiry' },
    { id: 6, name: '내 정보', path: '?state=modify' },
  ];

  const storeTab = [
    { id: 1, name: '주문배송내역', path: '/flower-store/order-list' },
    // { id: 2, name: '포인트 관리', path: '?state=point' },
    // { id: 3, name: '선물함 (쿠폰함)', path: '?state=coupon' },
    { id: 3, name: '화환 주문하기', path: '/flower-store' },
    { id: 4, name: '답례품 전하기', path: '?state=gift' },
    { id: 5, name: '부고 서비스', path: '/' },
    { id: 6, name: '문의 내역', path: '?state=enquiry' },
    { id: 7, name: '내 정보', path: '?state=modify' },
  ];

  return isStorePage ? storeTab : basicTab;
};
