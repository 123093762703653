import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  userBugoHistroryContentDataAtom,
  userBugoHistroryContentStatusAtom,
} from './userBugoHistroryContent.atom';

const userBugoHistorySelect = [
  '_id',
  'deceasedInfo.name',
  'deceasedInfo.coffinIn',
  'deceasedInfo.coffinOut',
  'memberOrderList',
  'funeralHomeInfo',
  'funeralHomeInfoEmbed',
  'roomInfo',
  'roomInfoEmbed',
  'status',
];

const userBugoHistoryPopulate = [
  { path: 'funeralHomeInfo', select: ['name'] },
  { path: 'funeralHomeInfoEmbed', select: ['name'] },
  { path: 'memberOrderList', select: ['fullName'] },
  { path: 'roomInfo', select: ['name'] },
];

const userBugoHistoryHookUrl = (role: string) =>
  `${role}/fevent/?${queryString({
    select: userBugoHistorySelect,
    populate: userBugoHistoryPopulate,
    options: { sort: { createdAt: -1 } },
  })}`;

const useUserBugoHistroyHook = (disabled?: boolean) => {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    userBugoHistroryContentStatusAtom,
    userBugoHistroryContentDataAtom,
    userBugoHistoryHookUrl(bugoRole()),
    fetcher,
    disabled,
  );
};

export { userBugoHistoryHookUrl, useUserBugoHistroyHook };
