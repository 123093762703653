import { FC } from 'react';

import { CsCard } from './components/CsCard';
import { AppDownloadCard } from './components/AppDownloadCard';

const CustomerGridRightBox: FC = () => {
  return (
    <>
      <div className="h-[356px] w-full max-sm:w-full ">
        <div className="mb-2 text-left text-24 font-bold max-lg:px-2 ">고객센터</div>
        <div className="flex flex-col gap-4">
          <CsCard />
          <AppDownloadCard />
        </div>
      </div>
    </>
  );
};

export { CustomerGridRightBox };
