import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { useNavigate } from 'react-router-dom';
import { fileBucketUrl } from 'src/@utils/fileUtils';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { notificateInfo } from 'src/@utils/noti.utils';
import { notificationInstanceAtom } from '@state/atom/notification.atom';

import { DeliveryStatusText } from './DeliveryStatusText';

type DeliveryStatusBodyProps = {
  orderId: string;
  deliveryState?: string;
  estimatedDeliveredAt?: string;
  paymentMethod: string;
  receiptUrl: string;
  requestedAt?: string;
  itemName?: string;
  itemPrice?: number;
  itemImage?: string;
};

const DeliveryStatusBody: FC<DeliveryStatusBodyProps> = ({
  orderId,
  deliveryState = '상품준비중',
  estimatedDeliveredAt,
  paymentMethod,
  receiptUrl,
  requestedAt,
  itemName,
  itemPrice,
  itemImage,
}) => {
  const navigate = useNavigate();
  const isMobileMedia = useMobileMediaQuery();
  const notificationInstance = useRecoilValue(notificationInstanceAtom);

  const ButtonFill =
    'bg-white text-12 font-medium rounded px-2 text-gray-700 shadow-sm py-2 max-sm:py-1 w-full';

  const cancelOrderButtonHandler = () => {
    console.log('주문취소');
    navigate(`/flower-store/order/${orderId}/cancel`);
  };

  const sellerEnquiryButtonHandler = () => {
    console.log('판매자문의');
    navigate(`/flower-store/enquiry`);
  };

  const detailViewButtonHandler = () => {
    console.log('주문 상세보기');
    if (paymentMethod === null) {
      notificateInfo(notificationInstance, '결제가 완료되지않은 상품입니다.');
      return;
    }
    if (paymentMethod === '계좌 입금') {
      navigate(`/flower-store/order/${orderId}`);
    } else {
      window.open(receiptUrl, '_blank');
    }
  };

  return (
    <div className="max-sm:px-2">
      <div className="flex justify-between py-3">
        <p className="text-14 font-bold text-gray-700">{requestedAt} 주문</p>
        <div
          className="flex  cursor-pointer items-center"
          onClick={detailViewButtonHandler}
        >
          <Button className="border-none p-0 text-14 font-bold text-primary shadow-none">
            주문 상세보기
          </Button>
          <Icon.ChevronRight className=" fill-primary  stroke-none" />
        </div>
      </div>

      <div className="grid grid-cols-9 rounded border bg-white p-2 ">
        <div className="col-span-7 border-r ">
          <DeliveryStatusText
            status={deliveryState}
            estimatedDeliveredAt={estimatedDeliveredAt ?? ''}
          />
          <div className="grid grid-cols-2 gap-4 pt-2">
            <div className="max-h-[322px]">
              <img
                src={fileBucketUrl(itemImage ?? '', 'w400')}
                className="h-full w-full"
                alt={'flowerImage'}
              ></img>
            </div>
            <div className="flex flex-col justify-center text-left font-medium  text-black">
              <p className="pb-2 text-18">{itemName}</p>
              <p className="text-16 ">{(itemPrice ?? 0).toLocaleString()}원</p>
            </div>
          </div>
        </div>

        <div className="col-span-2 flex flex-col justify-center gap-8 pl-4 pr-2 max-sm:pl-2 max-sm:pr-0">
          <Button className={ButtonFill} onClick={cancelOrderButtonHandler}>
            주문취소
          </Button>
          {isMobileMedia ? (
            <a href="tel:1577-1954">
              <span className="center-box flex gap-1 rounded border py-2 shadow-sm max-sm:py-1">
                <span className="text-12 font-medium">판매자문의</span>
              </span>
            </a>
          ) : (
            <div className="w-full">
              <Button className={ButtonFill} onClick={sellerEnquiryButtonHandler}>
                판매자문의
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DeliveryStatusBody };
