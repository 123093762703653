import { RequestPaymentModel } from '@bootpay/client-js/models/bootpay-interface';

import { IParams } from '../state/flowerStoreItemCheckout.interface';
import { dateFormatBootPay } from './dateFormatBootpay';

export const getBootpayRequestPaymentDto = ({
  formData,
  shopOrder,
  shopItem,
  userProfile,
}: IParams): RequestPaymentModel => ({
  price: shopOrder.paymentDetail.paymentByCash, // 총 결제할 금액
  application_id: process.env.REACT_APP_BOOTPAY_APPLICATION_ID, //
  order_name: shopItem.shopItemInfo.name,
  pg: '나이스페이',
  items: [
    {
      id: shopItem._id,
      name: shopItem.shopItemInfo.name,
      // price: shopOrder.paymentDetail.paymentByCash / formData.itemCount,
      price: shopOrder.paymentDetail.paymentByCash,
      qty: shopOrder.orderDetail.quantity,
    },
  ],
  user: {
    username: userProfile.info.name ?? '',
    email: '',
    phone: userProfile.info.phoneNumber ?? '',
    addr: '',
  },
  tax_free: shopOrder.paymentDetail.paymentByCash,
  order_id: shopOrder._id,
  extra: {
    open_type: window.opener ? 'popup' : 'iframe',
    browser_open_type: [
      {
        browser: 'instagram',
        open_type: 'redirect',
      },
      {
        browser: 'facebook',
        open_type: 'redirect',
      },
      {
        browser: 'kakaotalk',
        open_type: 'popup',
      },
      {
        browser: 'naver',
        open_type: 'popup',
      },
      {
        browser: 'mobile_safari',
        open_type: 'popup',
      },
      {
        browser: 'mobile_chrome',
        open_type: 'iframe',
      },
    ],
    display_success_result: true,
    display_error_result: true,
    deposit_expiration: dateFormatBootPay(new Date(Date.now() + 1 * 24 * 3600 * 1000)),
    // start_at: "2019-05-10", // 정기 결제 시작일 - 시작일을 지정하지 않으면 그 날 당일로부터 결제가 가능한 Billing key 지급
    // end_at: "2022-05-10", // 정기결제 만료일 -  기간 없음 - 무제한
    // vbank_result: 1, // 가상계좌 사용시 사용, 가상계좌 결과창을 볼지(1), 말지(0), 미설정시 봄(1)
    card_quota: '0', // 결제금액이 5만원 이상시 할부개월 허용범위를 설정할 수 있음, [0(일시불), 2개월, 3개월] 허용, 미설정시 12개월까지 허용,
    // theme: 'purple', // [ red, purple(기본), custom ]
    // custom_background: '#06C7C3', // [ theme가 custom 일 때 background 색상 지정 가능 ]
    // custom_font_color: '#ffffff', // [ theme가 custom 일 때 font color 색상 지정 가능 ]
  },
});
