import { FC, PropsWithChildren } from 'react';

import { BugoData } from '@api/mongo/interfaces';

import { ObituaryFooter, ObituaryHeader } from './components';
import { ObituaryBody } from './components/ObituaryBody';
import { FlowerPromotionBox } from './components/ObituaryBody/FlowerPromotionBox';
import { MemberMoneyCard } from './components/ObituaryBody/MemberMoneyCard';

interface BugoPageContentProps extends PropsWithChildren {
  bugo: BugoData;
}

const BugoPageContent: FC<BugoPageContentProps> = (props) => {
  const { bugo } = props;

  const {
    deceasedInfo,
    funeralHomeInfo,
    funeralHomeInfoEmbed,
    roomInfo,
    roomInfoEmbed,
    memberOrderList,
    mournerText,
  } = bugo.fevent;

  return (
    <div className=" mx-auto max-w-[640px] bg-gray-100">
      <ObituaryHeader
        deceasedName={deceasedInfo.name}
        deceasedAge={deceasedInfo.age}
        deceasedSex={deceasedInfo.sex}
        deceasedNameDetail={deceasedInfo.nameDetail}
        deathDate={deceasedInfo.death}
        bugoTerm={deceasedInfo.bugoTerm}
        imageUrl={deceasedInfo.dpImage?.url}
      />
      <ObituaryBody
        enteranceDate={deceasedInfo.enterance}
        coffinInDate={deceasedInfo.coffinIn}
        coffinOutDate={deceasedInfo.coffinOut}
        cemetery={deceasedInfo.cemetery}
        funeralHomeInfo={funeralHomeInfo}
        funeralHomeInfoEmbed={funeralHomeInfoEmbed}
        roomInfo={roomInfo}
        roomInfoEmbed={roomInfoEmbed}
        memberOrderList={memberOrderList}
        mournerText={!bugo.mournerText ? mournerText : bugo.mournerText}
      />
      {/* 부의금 안내 */}
      <MemberMoneyCard
        cashAccount={bugo.member.cashAccount}
        forMember={undefined}
        cashAccountEditOpenId={`memberObituary-cashAccountOpen-${bugo._id}`}
      />
      {/* 화환 정보 */}
      <FlowerPromotionBox bugoId={bugo._id} shopOrders={bugo.fevent.shopOrders} />
      <ObituaryFooter bugo={bugo} />
    </div>
  );
};

export { BugoPageContent };
