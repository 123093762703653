import { MongoApiParams } from '@api/mongo/controllers/common.controller';
import { api_ } from '@plugin/axios';
import { AxiosResponse } from 'axios';

const endpoint = '/public/shop-item';

export type NestShopItemApi = <Entity>() => {
  findMany: (
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => Promise<AxiosResponse<Entity[]>>;
  findById: (
    id: string,
    params?: Omit<MongoApiParams<Entity>, 'filter'>,
  ) => Promise<AxiosResponse<Entity>>;
  findByBugo: (
    bugo: string,
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => Promise<AxiosResponse<Entity[]>>;
  findByFevent: (
    fevent: string,
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => Promise<AxiosResponse<Entity[]>>;
  findDefault: (
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => Promise<AxiosResponse<Entity[]>>;
};

const nestShopItemApi: NestShopItemApi = <Entity>() => {
  const findMany = async (params?: Omit<MongoApiParams<Entity>, 'id'>) => {
    return await api_.get<Entity[]>(endpoint, { params });
  };
  const findById = async (
    id: string,
    params?: Omit<MongoApiParams<Entity>, 'filter'>,
  ) => {
    return await api_.get<Entity>(`${endpoint}/${id}`, { params });
  };
  const findByBugo = async (
    bugo: string,
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => {
    return await api_.get<Entity[]>(`${endpoint}/by-bugo`, {
      params: { bugo, ...params },
    });
  };
  const findByFevent = async (
    fevent: string,
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => {
    return await api_.get<Entity[]>(`${endpoint}/by-fevent`, {
      params: { fevent, ...params },
    });
  };
  const findDefault = async (params?: Omit<MongoApiParams<Entity>, 'id'>) => {
    return await api_.get<Entity[]>(`${endpoint}/default`, {
      params,
    });
  };

  return {
    findMany,
    findById,
    findByBugo,
    findByFevent,
    findDefault,
  };
};

export { nestShopItemApi };
