import { FC, ReactNode, useEffect } from 'react';

import { BasicInput } from '@components/BasicInput';
import { GenderInput } from '@components/GenderInput';
import { ImageUploadInput } from '@components/ImageUploadInput';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { FeventFormData } from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import { AutoSelect } from '@components/AutoSelect';
import { useFormContext } from 'react-hook-form';

interface DeceasedBasicInfoFormProps {
  children?: ReactNode;
}

const bugoTermsList = ['별세', '소천', '영면', '선종', '운명', '열반', '타계', '서거'];

const DeceasedBasicInfoForm: FC<DeceasedBasicInfoFormProps> = () => {
  const {
    register,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    control,
  } = useFormContext<FeventFormData>();

  // 필수 입력이 필요한 input을 border-red 로 보여주기 위해 첫 렌더링에서 input 검증을 한다
  useEffect(() => {
    trigger();
  }, [trigger]);

  const { deceasedInfo } = getValues();

  const setImage = (id: string) => {
    setValue('deceasedInfo.dpImage', id);
  };

  return (
    <BasicInfoForm
      title="고인 기본 정보"
      desc="부고장에 필요한 고인의 대한 기본적인 정보를 작성해주세요. 
          빨간 테두리 영역은 필수 정보입니다. 그 외의 정보는 작성하지
          않은  경우 부고장에 선택적으로 표기되지 않습니다."
    >
      {/* 640px 모바일 레이아웃 전환 */}
      <div className="mb-3 grid grid-cols-4 gap-4 max-sm:grid-cols-2 max-sm:gap-x-1 max-sm:gap-y-2">
        <BasicInput
          label="고인 성함"
          PrefixEl="故"
          placeholder="고인 성함"
          className={`pl-8 pr-4 ${errors.deceasedInfo?.name ? 'border-red-500' : ''}`}
          {...register('deceasedInfo.name', { required: true })}
        />
        <GenderInput label="성별" value={deceasedInfo?.sex} setValue={setValue} />
        <BasicInput
          label="고인 나이"
          type="number"
          SuffixEl="세"
          placeholder="고인 나이"
          className="pl-4 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          {...register('deceasedInfo.age')}
        />
        <AutoSelect
          formControl={control}
          formFieldName="deceasedInfo.bugoTerm"
          optionList={bugoTermsList}
          labelText="부고 용어"
        />
        <ImageUploadInput label="영정 사진" setImage={setImage} />
      </div>
    </BasicInfoForm>
  );
};

export { DeceasedBasicInfoForm };
