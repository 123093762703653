import { FC } from 'react';

import { Body, Footer, Header } from '@layout';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { FooterContent } from '@containers/FooterContent';
import { MainSection } from '@components/MainSection';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { TermsPageContent } from '@containers/TermsPageContent';

const FlowerStoreTermsPage: FC = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection>
          <TermsPageContent />
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { FlowerStoreTermsPage };
