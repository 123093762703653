import { ShopOrderCreateDto, ShopOrderType } from '@api/mongo/interfaces';
import { getEstimateDeliveredAt } from '@containers/FlowerStoreItemDetail/utils/getEstimateDeliveredAt';

import { ShopOrderParams } from '../state/flowerStoreItemCheckout.interface';

export const getShopOrderDto = ({
  formData,
  shopItem,
  bugo,
  userId,
}: ShopOrderParams): ShopOrderCreateDto => ({
  user: userId,
  shop: shopItem.shop,
  bugo: bugo?._id ?? null,
  type: ShopOrderType.Bugo,
  bugoDetail: {
    condolencePhrase: formData.condolences,
    senderPhrase: formData.sender,
  },
  deliveryDetail: {
    estimatedDeliveredAt: getEstimateDeliveredAt(),
    senderAddress: '', // sender address 정보 입력된것이 없음
    senderName: formData.orderName,
    senderPhoneNumber: formData.phoneNumber,
    receiverAddress: formData.address,
    receiverName: formData.mournerName,
    receiverPhoneNumber: bugo?.member.phoneNumber ?? '',
    receiverPostalCode: bugo?.fevent.funeralHomeInfo.postalCode ?? '',
    // deliveredAt 서버에서 생성
    // deliveredImage 서버에서 생성
  },
  orderDetail: {
    shopItem: shopItem._id,
    // quantity: formData.itemCount,
    quantity: 1,
  },
  paymentDetail: {
    paymentByCash: shopItem.priceRetail,
    paymentByPoint: 0,
  },
});
