import { Icon } from '@components/Icon';
import { useCountHook } from '@containers/MainContent/state/mainContent.hook';

import { PromotionInfoBoxItem } from './PromotionInfoBoxItem';

const PromotionInfoBox = () => {
  const { userCount, userTotalCount } = useCountHook();

  return (
    <div className="flex w-full justify-around text-[#000000] max-sm:flex-col max-sm:gap-3 max-sm:px-2 max-sm:pt-3">
      <PromotionInfoBoxItem
        IconSVG={() => (
          <Icon.People className={'h-12 w-12 stroke-none max-sm:h-8 max-sm:w-8'} />
        )}
        count={userTotalCount?.toLocaleString() ?? '_'}
        unit="명"
        title="누적 이용자 수"
      />
      <PromotionInfoBoxItem
        IconSVG={() => (
          <Icon.CalendarSolid
            className={'h-10 w-10 fill-apricot-600 stroke-none  max-sm:h-8 max-sm:w-8'}
          />
        )}
        count={new Date().getFullYear().toString()}
        unit="년"
        title="연도"
      />
      <PromotionInfoBoxItem
        IconSVG={() => (
          <Icon.UserMain
            className={'h-10 w-10 fill-apricot-600 stroke-none max-sm:h-8 max-sm:w-8'}
          />
        )}
        count={userCount?.toLocaleString() ?? '_'}
        unit="명"
        title="이용자 수"
      />
    </div>
  );
};

export { PromotionInfoBox };
