import { FC, PropsWithChildren } from 'react';

interface MyPageWrapperProps extends PropsWithChildren {
  className?: string;
  children?: React.ReactNode;
}

const MyPageWrapper: FC<MyPageWrapperProps> = (props) => {
  const { className, children } = props;

  return <div className={`flex w-full flex-wrap ${className}`}>{children}</div>;
};

export { MyPageWrapper };
