import { atom } from 'recoil';

import { User } from '@api/mongo/interfaces';

export const ciUserAtom = atom<User | undefined>({
  key: 'ciUserAtom',
  default: undefined,
});

export const ciUserListAtom = atom<User[] | undefined>({
  key: 'ciUserListAtom',
  default: undefined,
});

export const selectUserIdAtom = atom<string | undefined>({
  key: 'selectUserIdAtom',
  default: undefined,
});
