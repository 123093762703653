import { FC, MouseEventHandler } from 'react';

import { CheckBox } from '@components/CheckBox';
import { Icon } from '@components/Icon';

type AgreementItemProps = {
  checked: boolean;
  label: string;
  onCheckedChange: (checked: boolean) => void;
  onAgreementView: MouseEventHandler<HTMLDivElement>;
};

const AgreementItem: FC<AgreementItemProps> = ({
  checked,
  label,
  onCheckedChange,
  onAgreementView,
}) => (
  <div className="flex justify-between px-4 py-2 text-gray-500  ">
    <div
      className="flex cursor-pointer items-center gap-4"
      onClick={() => onCheckedChange(!checked)}
    >
      <CheckBox checked={checked} onCheckedChange={onCheckedChange} />
      <label className="cursor-pointer break-keep">{label}</label>
    </div>
    <div
      className="flex cursor-pointer items-center"
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onAgreementView(event);
      }}
    >
      보기 <Icon.ArrowRight className="h-4 w-4 stroke-none" />
    </div>
  </div>
);

export { AgreementItem };
