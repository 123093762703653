import { FC } from 'react';

import { v4 } from 'uuid';

import { MyEnquiryItem } from './MyEnquiryItem';
import { UserEnquiryList } from '../state/userEnquiry.interface';

interface MyEnquiryListProps {
  enquiryData: UserEnquiryList;
}

const MyEnquiryList: FC<MyEnquiryListProps> = ({ enquiryData }) => {
  return (
    <div className="flex flex-col gap-2 max-sm:mx-[-1rem]">
      {enquiryData.map((item) => {
        return <MyEnquiryItem key={v4()} {...item} />;
      })}
    </div>
  );
};

export { MyEnquiryList };
