import { atom } from 'recoil';

export const serverHasuraCountAtom = atom<number>({
  key: 'serverHasuraCountAtom',
  default: 0,
});

export const serverHasuraCountIdAtom = atom<string>({
  key: 'serverHasuraCountIdAtom',
  default: process.env.REACT_APP_DEFAULT_COUNT_ID,
});
