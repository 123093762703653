import { FC, InputHTMLAttributes, ReactNode, Ref, forwardRef } from 'react';

import { Label } from './Label';

export interface BasicInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  PrefixEl?: string | ReactNode;
  SuffixEl?: string | ReactNode;
  label?: string;
  ref?: Ref<HTMLInputElement>;
  isError?: boolean;
  errorMessage?: string;
}

const BasicInput: FC<BasicInputProps> = forwardRef(
  ({ className, PrefixEl, SuffixEl, label, isError, errorMessage, ...props }, ref) => {
    return (
      <div>
        {label && <Label text={label} />}
        <div className="relative">
          {PrefixEl && (
            <div className="absolute top-0 left-3 flex h-full items-center font-bold">
              {PrefixEl}
            </div>
          )}
          <input
            {...props}
            ref={ref}
            className={`w-full rounded border py-2 ${className} ${
              isError && 'border-red-500'
            }  `}
          />
          {SuffixEl && (
            <div className="absolute top-0 right-3 flex h-full items-center font-bold">
              {SuffixEl}
            </div>
          )}
        </div>
        {isError && errorMessage && (
          <p className="pt-1 text-left text-red-500">{errorMessage}</p>
        )}
      </div>
    );
  },
);

export { BasicInput };
