const MobileDescriptionSection = () => {
  return (
    // 640px 모바일 레이아웃 전환
    <div className="my-4 hidden bg-white p-1 text-left text-12 text-gray-500 max-sm:block">
      <p>※ 부고장을 만들기 위한 정보를 작성해주세요.</p>
      <p>고인정보, 상주정보 순으로 작성합니다.</p>
      <p>
        <span className="text-red-500">빨간색</span> 테두리 영역은{' '}
        <span className="text-red-500">필수 정보</span>입니다. 그외 정보는 입력하지 않을시{' '}
        <span className="text-red-500">미표기</span> 됩니다.
      </p>
    </div>
  );
};

export { MobileDescriptionSection };
