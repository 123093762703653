import { useRecoilValue, useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';

import { courtesyContentStringAtom } from '../state/courtesy.atom';
import { MemberCourtesyPreviewModal } from './MemberCourtesyPreviewModal';
import { WebCourtesyShareModal } from './WebCourtesyShareModal';

const CourtesyBtnSet = () => {
  const previewOpenId = 'courtesy-preview-modal';
  const shareOpenId = 'courtesy-share-modal';
  const setOpenPreview = useSetRecoilState(modalOpenAtomFamily(previewOpenId));
  const setOpenShare = useSetRecoilState(modalOpenAtomFamily(shareOpenId));
  const courtesyContentString = useRecoilValue(courtesyContentStringAtom);

  return (
    <div className="mt-3 flex">
      <button
        className="w-full bg-apricot-600 p-4 text-white"
        onClick={() => setOpenPreview(true)}
      >
        답례인사(이미지)다운로드
      </button>
      <button
        className="w-full bg-gray-700 p-4 text-white"
        onClick={() => setOpenShare(true)}
      >
        답례글 공유
      </button>
      <MemberCourtesyPreviewModal openId={previewOpenId} />
      <WebCourtesyShareModal
        position="end"
        openId={shareOpenId}
        content={courtesyContentString ?? ''}
        title={''}
      />
    </div>
  );
};

export { CourtesyBtnSet };
