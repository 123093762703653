import { FC } from 'react';

import { FuneralHomeInfo } from '@api/mongo/interfaces';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Map } from '@components/Map';
import { CustomCallback } from 'src/interfaces';

interface IProps {
  selectedFuneralHome: FuneralHomeInfo<undefined, string>;
  onConfirm: CustomCallback<void, any>;
  onCancle: CustomCallback<void, any>;
  // goToFormStep: CustomCallback<void, any>;
}

const FuneralHomeInfoSearchDetail: FC<IProps> = ({
  selectedFuneralHome,
  onConfirm,
  onCancle,
  // goToFormStep,
}: IProps) => {
  const { ChevronUp } = Icon;
  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        <p className="font-bold">{selectedFuneralHome.name}</p>
        <ChevronUp className="cursor-pointer stroke-none" onClick={() => onCancle()} />
      </div>
      <p className=" text-sm text-gray-500">{selectedFuneralHome.address}</p>
      <div className="aspect-w-13 aspect-h-7 mt-4 w-full">
        <div className="h-full w-full">
          <Map coord={selectedFuneralHome.coord} name={selectedFuneralHome.name} />
        </div>
      </div>
      <p className="mt-4 font-bold">전화번호</p>
      <p className="text-sm">{selectedFuneralHome.phoneNumber}</p>
      <p className="mt-4 font-bold">주차안내</p>
      <p className="text-sm text-gray-500">{selectedFuneralHome.parkingAddress}</p>
      {/* <p className="mt-4 font-bold">
        위 정보가 틀릴 경우{' '}
        <span className="cursor-pointer text-apricot-600" onClick={() => goToFormStep()}>
          장례식장 직접입력
        </span>
        을 눌러주세요
      </p> */}
      <Button
        onClick={() => onConfirm()}
        className="button mt-4 h-[52px] w-full rounded-none border-0 bg-apricot-600 text-white shadow-none"
      >
        확인
      </Button>
    </div>
  );
};

export default FuneralHomeInfoSearchDetail;
