import { FC } from 'react';

interface CancelItemBoxProps {
  itemName: string;
  imageUrl: string;
  priceRetail: number;
  priceRetailShow: number;
}

const CancelItemBox: FC<CancelItemBoxProps> = ({
  itemName,
  imageUrl,
  priceRetail,
  priceRetailShow,
}) => {
  return (
    <div className="col-span-2 flex w-full gap-4 bg-white pt-2">
      <div className="h-[141px] max-w-full">
        <img
          src={`${process.env.REACT_APP_BUCKET_URL}/w400/${imageUrl}`}
          className="h-full w-full object-cover"
          alt="flowerImage"
        ></img>
      </div>
      <div className=" flex flex-col justify-center text-left  font-medium text-black">
        <p className="pb-2 text-18">{itemName}</p>
        <p className="text-16 ">
          <span className="  text-gray-500 line-through">
            {priceRetailShow.toLocaleString()}원
          </span>
          <span> {priceRetail.toLocaleString()}원</span>
        </p>
      </div>
    </div>
  );
};

export { CancelItemBox };
