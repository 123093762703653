import { useRecoilQuery, fetcher } from '@hooks';
import { FuneralHomeInfoList } from '@api/mongo/interfaces';

import {
  funeralHomeInfoListDataAtom,
  funeralHomeInfoListStatusAtom,
} from './funeralHomeInfo.atom';

export const funeralHomeInfoListHookUrl = `public/funeral-home-info`;

export const useFuneralHomeInfoListHook = () => {
  useRecoilQuery<FuneralHomeInfoList | undefined>(
    funeralHomeInfoListStatusAtom,
    funeralHomeInfoListDataAtom,
    funeralHomeInfoListHookUrl,
    fetcher,
  );
};
