import { FC, HTMLAttributes } from 'react';

interface MyPageTitleProps extends HTMLAttributes<HTMLParagraphElement> {
  title?: string;
  className?: string;
}

const MyPageTitle: FC<MyPageTitleProps> = ({ title, className, ...props }) => {
  return (
    <>
      {title && (
        <p
          className={`py-1 pb-5 text-left text-18 font-medium text-black  max-sm:text-16 max-sm:text-gray-700 ${className}`}
          {...props}
        >
          {title}
        </p>
      )}
    </>
  );
};

export { MyPageTitle };
