import { useFlowerStoreOrderByIdHook } from '@containers/FlowerStoreOrderContent/state/flowerStoreOrderContent.query';
import { OrderDeatilPage } from '@pages/FlowerStore/StoreOrder';
import { OrderCancelRequestPage } from '@pages/FlowerStore/StoreOrder/OrderCancelRequestPage';
import { OrderDeliveryPage } from '@pages/FlowerStore/StoreOrder/OrderDeliveryPage';
import { OrderPaymentPage } from '@pages/FlowerStore/StoreOrder/OrderPaymentPage';
import { Route, Routes, useParams } from 'react-router-dom';

const StoreOrderRouter = () => {
  const { orderId } = useParams();
  useFlowerStoreOrderByIdHook(orderId ?? '');

  return (
    <Routes>
      <Route path="/" element={<OrderDeatilPage />} />
      <Route path="/delivery" element={<OrderDeliveryPage />} />
      <Route path="/payment" element={<OrderPaymentPage />} />
      <Route path="/cancel" element={<OrderCancelRequestPage />} />
    </Routes>
  );
};

export { StoreOrderRouter };
