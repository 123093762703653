import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@components/Button';
import { CheckBox } from '@components/CheckBox';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import Modal from '@components/Modal';
import { standardTermsText, privacyPolicyText } from 'src/@utils/textDats';

import { AgreementItem } from './components/AgreementItem';
import { AgreementTextWrapper } from './components/AgreementTextWrapper';

type TermsAgreementFormRegisterType = {
  agreementAll: boolean;
  agreementTermsUse: boolean;
  agreementPrivacyPolicy: boolean;
};

const TermsAgreementForm = () => {
  const { handleSubmit, getValues, setValue, watch } =
    useForm<TermsAgreementFormRegisterType>({
      defaultValues: {
        agreementAll: false,
        agreementTermsUse: false,
        agreementPrivacyPolicy: false,
      },
    });

  const agreementTermsUseId = 'agreementTermsUse';
  const agreementPrivacyPolicyId = 'agreementPrivacyPolicy';

  const navigate = useNavigate();
  const isStorePage = useRecoilValue(isStorePageAtom);
  const agreementAll = watch('agreementAll');
  const agreementTermsUse = watch('agreementTermsUse');
  const agreementPrivacyPolicy = watch('agreementPrivacyPolicy');
  const isAllAgreed = agreementTermsUse && agreementPrivacyPolicy;

  const setAgreementTermsUseModal = useSetRecoilState(
    modalOpenAtomFamily(agreementTermsUseId),
  );
  const setAgreementPrivacyPolicyModal = useSetRecoilState(
    modalOpenAtomFamily(agreementPrivacyPolicyId),
  );

  const allAgreementCheckHandler = () => {
    const values = getValues([
      'agreementAll',
      'agreementTermsUse',
      'agreementPrivacyPolicy',
    ]).every(Boolean);

    setValue('agreementAll', !values);
    setValue('agreementTermsUse', !values);
    setValue('agreementPrivacyPolicy', !values);
  };

  const onSubmit = (data: TermsAgreementFormRegisterType) => {
    if (isAllAgreed) {
      console.log(data);
      navigate(isStorePage ? '/flower-store/signup' : '/signup');
    }
  };

  const agreementTermsUseHandler = () => {
    setAgreementTermsUseModal(true);
  };

  const agreementPrivacyPolicyHandler = () => {
    setAgreementPrivacyPolicyModal(true);
  };

  useEffect(() => {
    if (!agreementTermsUse || !agreementPrivacyPolicy) {
      setValue('agreementAll', false);
    }
  }, [agreementTermsUse, agreementPrivacyPolicy, setValue]);

  useMobileNavTitleHook('회원가입(약관동의)', true);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto flex max-w-[608px] flex-col gap-3 pt-26 max-sm:pt-12"
    >
      <h1 className="break-keep pb-5 text-24 font-medium">
        <span className=" text-primary">이용약관</span>에 먼저 동의해주세요.
      </h1>
      <div
        className="flex cursor-pointer  justify-between bg-gray-100 p-4 text-16 font-medium text-black"
        onClick={allAgreementCheckHandler}
      >
        <div className="flex items-center gap-4">
          <CheckBox
            checked={agreementAll}
            onCheckedChange={() => allAgreementCheckHandler}
          />
          <label htmlFor="agreementAll">전체동의</label>
        </div>
      </div>

      <AgreementItem
        checked={agreementTermsUse}
        label={`${
          isStorePage
            ? '가온플라워 이용약관 동의 (필수)'
            : '가온부고 이용약관 동의 (필수)'
        }`}
        onCheckedChange={(checked) => setValue('agreementTermsUse', checked)}
        onAgreementView={agreementTermsUseHandler}
      />
      <AgreementItem
        checked={agreementPrivacyPolicy}
        label="개인정보 처리방침 동의 (필수)"
        onCheckedChange={(checked) => setValue('agreementPrivacyPolicy', checked)}
        onAgreementView={agreementPrivacyPolicyHandler}
      />

      <div className="border" />
      <p className="break-keep text-left text-10 text-gray-400">
        고객은 동의를 거부할 권리가 있으며 동의를 거부할 경우, 사이트 가입 또는 일부
        서비스 이용이 제한됩니다.
        <br /> 가입은 만 14세 이상 고객만 가능합니다.
      </p>

      <Button
        type="submit"
        disabled={!isAllAgreed}
        className="w-full rounded border-none bg-primary text-14 font-medium text-white shadow-none disabled:bg-gray-100 disabled:text-gray-400"
      >
        다음
      </Button>

      <Modal openId={agreementTermsUseId} className="bg-white">
        <AgreementTextWrapper title={'이용약관'} text={standardTermsText} />
        <Button
          className="w-full rounded border-none bg-primary text-white"
          onClick={() => {
            setValue('agreementTermsUse', true);
            setAgreementTermsUseModal(false);
          }}
        >
          동의
        </Button>
      </Modal>

      <Modal openId={agreementPrivacyPolicyId} className="bg-white">
        <AgreementTextWrapper title={'개인정보 처리방침'} text={privacyPolicyText} />
        <Button
          className="w-full rounded border-none bg-primary text-white"
          onClick={() => {
            setValue('agreementPrivacyPolicy', true);
            setAgreementPrivacyPolicyModal(false);
          }}
        >
          동의
        </Button>
      </Modal>
    </form>
  );
};

export { TermsAgreementForm };
