import { api_ } from '@plugin/axios';
import { Image } from '@api/mongo/interfaces';
import { LowerRole } from 'src/interfaces';

const postImageWithRole = async (imageFile: File, role: LowerRole) => {
  try {
    const form = new FormData();
    form.append('image', imageFile);
    form.append('body', JSON.stringify({ name: imageFile.name }));

    const { data: image } = await api_.post<Image>(`${role}/image/file`, form);
    return image;
  } catch (error) {
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
    throw new Error('upload fail');
  }
};

const postImage = async (imageFile: File) => {
  try {
    const form = new FormData();
    form.append('image', imageFile);
    form.append('body', JSON.stringify({ name: imageFile.name }));

    const { data: image } = await api_.post<Image>(`admin/image/file`, form);
    return image;
  } catch (error) {
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
    throw new Error('upload fail');
  }
};

const postImages = async (imageFileList: FileList) => {
  const imageIdList: string[] = [];
  try {
    for (const [key, value] of Object.entries(imageFileList)) {
      const image = await postImage(value);
      imageIdList.push(image._id);
    }
  } catch (error) {
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
    throw new Error('upload fail');
  }

  return imageIdList;
};

export { postImage, postImages, postImageWithRole };
