import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation, MainNavigation } from '@containers/TopNavigation/components';
import { Body, Header, Footer } from '@layout';
import { TermsPageContent } from '@containers/TermsPageContent';

const TermsPage = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection title="이용약관 및 정보처리 방침">
          <TermsPageContent />
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { TermsPage };
