import { MainSection } from '@components/MainSection';
import { FAQLeftContent } from '@containers/FAQContent/components/FAQLeftContent';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { FlowerStoreFAQContent } from '@containers/FlowerStoreFAQContent';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';

const FlowerStoreFAQPage = () => {
  useMobileNavTitleHook('자주묻는질문(FAQ)', true);

  return (
    <div>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <MainSection title="자주묻는질문(FAQ)">
          <BasicInfoForm leftSectionTopComponent={<FAQLeftContent />}>
            <FlowerStoreFAQContent />
          </BasicInfoForm>
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </div>
  );
};

export { FlowerStoreFAQPage };
