import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

const useLoginBtnHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');
  const isLoginPage = location.pathname.indexOf('/login') > 0;

  const loginBtnClickHandler = () => {
    if (isLoginPage) return;
    navigate(`/login?redirect=${location.pathname ?? '/'}`);
  };

  return { loginBtnClickHandler, redirectUrl };
};

export { useLoginBtnHandler };
