//* 장례식장 검색용 모달
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { nestMongoApi } from '@api/mongo/controllers';
import {
  FuneralHomeInfo,
  FuneralHomeInfoEmbed,
  FuneralHomeInfoRequest,
  FuneralHomeInfoRequestStatus,
} from '@api/mongo/interfaces';
import { BasicInput } from '@components/BasicInput';
import Modal from '@components/Modal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import FuzzySearch from 'fuzzy-search';
import { SubmitHandler } from 'react-hook-form';
import { CustomCallback } from 'src/interfaces';

import { useFuneralHomeInfoListHook } from '../state/funeralHomeInfo.hook';
import { FuneralHomeInfoListStateSelector } from '../state/funeralHomeInfo.selector';
import FuneralHomeInfoSearchDetail from './FuneralHomeInfoSearchDetail';
import FuneralHomeInfoSearchForm, {
  IFuneralHomeInfoForm,
} from './FuneralHomeInfoSearchForm';
import FuneralHomeInfoSearchList from './FuneralHomeInfoSearchList';

export enum FuneralHomeInfoModalStepEnum {
  List = 'list',
  Detail = 'detail',
  Form = 'form',
}

interface Props {
  openId: string;
  onValueChange: CustomCallback<string, void>;
  onEmbedSelect: (
    funeralHomeInfoEmbed: FuneralHomeInfoEmbed,
    funeralHomeInfoId: string,
  ) => void;
  setFuneralHomeInfoName: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * @param onValueChange: 장례식장 선택시 실제 입력 하는 곳에서 변경되도록 받는 callBack function
 * @returns
 */

const FuneralHomeInfoSearchModal: FC<Props> = function FuneralHomeInfoSearchModal({
  openId,
  onValueChange,
  onEmbedSelect,
  setFuneralHomeInfoName,
}: Props) {
  //장례식장 전체 들고오기
  useFuneralHomeInfoListHook();
  const funeralHomeInfoListState = useRecoilValue(FuneralHomeInfoListStateSelector);

  const { create } = nestMongoApi<FuneralHomeInfo, FuneralHomeInfoRequest>(
    '/public/funeral-home-info-request',
  );

  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));

  const [step, setStep] = useState<FuneralHomeInfoModalStepEnum>(
    FuneralHomeInfoModalStepEnum.List,
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedFuneralHome, setSelectedFuneralHome] = useState<
    FuneralHomeInfo<undefined, string> | undefined
  >(undefined);

  // before button render
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!open) timeout = setTimeout(() => setSelectedPage(1), 200);
    return () => clearTimeout(timeout);
  }, [open]);

  const searchedFuneralHomeInfoList = useMemo(() => {
    if (funeralHomeInfoListState.status === 'success' && funeralHomeInfoListState.data) {
      const funeralHomeInfoList = funeralHomeInfoListState.data;
      const searcher = new FuzzySearch(funeralHomeInfoList, ['name', 'address']);

      return searcher.search(searchValue);
    }
    return [] as FuneralHomeInfo<undefined, string>[];
  }, [funeralHomeInfoListState, searchValue]);

  const goToListStep = () => setStep(FuneralHomeInfoModalStepEnum.List);
  const goToDetailStep = () => setStep(FuneralHomeInfoModalStepEnum.Detail);
  // const goToFormStep = () => setStep(FuneralHomeInfoModalStepEnum.Form);

  const renderContents = useMemo(() => {
    const onItemClickInListStep = (item: FuneralHomeInfo<undefined, string>) => {
      setSelectedFuneralHome(item);
      goToDetailStep();
    };

    const onConfirmInDetailStep = () => {
      if (selectedFuneralHome) {
        setFuneralHomeInfoName(selectedFuneralHome.name);
        onValueChange(selectedFuneralHome._id);
        setOpen(false);
        goToListStep();
      }
    };

    const onCancleInDetailStep = () => {
      setSelectedFuneralHome(undefined);
      goToListStep();
    };

    const onSubmitInFormStep: SubmitHandler<IFuneralHomeInfoForm> = async (data) => {
      // 1. funeralHomeInfo _id를 가져와야함
      // 2. form으로 받은 내용으로 FuneralHomeInfoRequest 내용 create
      // 3. funeralHomeInfoRequest 생성
      // 4. 최종 form에 안착
      const funeralHomeInfoId = selectedFuneralHome?._id;
      const funeralHomeInfoRequest = {
        ...data,
        funeralHomeInfo: funeralHomeInfoId,
        postalCode: selectedFuneralHome?.postalCode,
        status: FuneralHomeInfoRequestStatus.Todo,
      } as FuneralHomeInfoRequest;
      const result = await create(funeralHomeInfoRequest);
      console.log(result);
      setFuneralHomeInfoName(funeralHomeInfoRequest.name);
      onEmbedSelect(
        { ...data, postalCode: selectedFuneralHome?.postalCode },
        selectedFuneralHome?._id ?? '',
      );
      setOpen(false);
      goToListStep();
    };

    switch (step) {
      case FuneralHomeInfoModalStepEnum.List:
        return (
          <FuneralHomeInfoSearchList
            searchedFuneralHomeInfoList={searchedFuneralHomeInfoList}
            onItemClick={onItemClickInListStep}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        );
      case FuneralHomeInfoModalStepEnum.Detail:
        return (
          <>
            {selectedFuneralHome && (
              <FuneralHomeInfoSearchDetail
                selectedFuneralHome={selectedFuneralHome}
                onConfirm={onConfirmInDetailStep}
                onCancle={onCancleInDetailStep}
                // goToFormStep={goToFormStep}
              />
            )}
          </>
        );
      case FuneralHomeInfoModalStepEnum.Form:
        return (
          <FuneralHomeInfoSearchForm
            onSubmit={onSubmitInFormStep}
            funeralHomeInfo={selectedFuneralHome}
          />
        );
      default:
        return;
    }
  }, [
    create,
    onEmbedSelect,
    onValueChange,
    searchedFuneralHomeInfoList,
    selectedFuneralHome,
    selectedPage,
    setFuneralHomeInfoName,
    setOpen,
    step,
  ]);

  return (
    <Modal
      openId={openId}
      className="w-full max-w-lg space-y-4 bg-white max-sm:p-4"
      position="center"
    >
      <h3 className="whitespace-pre-line break-keep text-base font-bold leading-5">
        {step === FuneralHomeInfoModalStepEnum.Form
          ? '장례식장 직접입력'
          : '장례식장 검색'}
      </h3>
      {step !== FuneralHomeInfoModalStepEnum.Form && (
        <BasicInput
          className="h-11 w-full rounded-none px-4 transition duration-300 hover:border-apricot-600 focus:border-apricot-600"
          placeholder="장례식장 이름 및 지역을 검색하세요."
          onChange={(e) => {
            setSelectedPage(1);
            setSearchValue(e.target.value);
            goToListStep();
          }}
        />
      )}
      {renderContents}
    </Modal>
  );
};

export default FuneralHomeInfoSearchModal;
