import { FC } from 'react';

import { Button } from '@components/Button';
import _ from 'lodash';
import { FormProvider, SubmitErrorHandler, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { MemberIFormInputs } from '../../interface/mournerInfoForm.interface';
import { MournerListEditor } from './MournerListEditor';

interface MournerInfoListWrapperProps {
  useformMethod: UseFormReturn<MemberIFormInputs, any>;
  onSubmit: (data: MemberIFormInputs, isStepper: boolean) => Promise<void>;
  onSubmitError: SubmitErrorHandler<MemberIFormInputs>;
}

const MournerInfoListWrapper: FC<MournerInfoListWrapperProps> = ({
  useformMethod,
  onSubmit,
  onSubmitError,
}) => {
  const navigate = useNavigate();

  const memberLength = useformMethod.watch().member.length;

  return (
    <FormProvider {...useformMethod}>
      <form
        onSubmit={useformMethod.handleSubmit(
          (data) => onSubmit(data, false),
          onSubmitError,
        )}
      >
        <MournerListEditor />
        <div className="flex justify-end gap-4 font-bold max-sm:flex-col-reverse ">
          <Button
            className="mt-3 h-12 w-full max-w-[158px] rounded bg-gray-300 text-16 text-white shadow-none max-sm:mt-0 max-sm:max-w-full"
            onClick={() => navigate('../deceased-info')}
          >
            이전
          </Button>
          <Button
            className={`mt-3 h-12 w-full max-w-[158px] rounded border-none text-16 shadow-none max-sm:max-w-full ${
              useformMethod.formState.isValid && memberLength > 0
                ? 'bg-primary text-white'
                : 'bg-gray-100 text-gray-400 '
            }`}
            type="submit"
          >
            다음
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { MournerInfoListWrapper };
