import { useSearchParams } from 'react-router-dom';

const MobileDescriptionSection = () => {
  const [searchParams] = useSearchParams();
  const bugoId = searchParams.get('bugoId');

  return (
    // 640px 모바일 레이아웃 전환
    <div
      className={`mb-4 hidden bg-white p-1 text-left text-12 text-gray-500  ${
        bugoId ? 'max-sm:hidden' : 'max-sm:block'
      }`}
    >
      <p>
        ※ 상주님별로 답례인사가 개별 생성됩니다. 미리보실 답례인사의 상주님을 선택해주세요{' '}
      </p>
    </div>
  );
};

export { MobileDescriptionSection };
