import { useState } from 'react';

import Tabs from '@components/Tabs';

import { FindIdForm } from './components/FindIdForm';
import { FindPasswordForm } from './components/FindPasswordForm';

const FindIdPassword = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="mx-auto max-w-[415px] px-8 pt-24 pb-8 max-sm:px-0 max-sm:pt-16 ">
      <h2 className="text-24 font-medium text-black max-sm:hidden">계정정보 찾기</h2>
      <div className="mt-8">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          items={['아이디 찾기', '비밀번호 재설정']}
        />
        {selectedTab === 0 ? (
          <FindIdForm setSelectedTab={setSelectedTab} />
        ) : (
          <FindPasswordForm />
        )}
      </div>
    </div>
  );
};

export { FindIdPassword };
