import {
  Bugo,
  CashAccount,
  Fevent,
  FuneralHomeInfo,
  Image,
  Member,
  ShopOrderInBugo,
} from '@api/mongo/interfaces';
import { QueryDataState } from 'src/interfaces';

export type CurrentFevent = Fevent<
  string,
  FuneralHomeInfo,
  string,
  Member[],
  ShopOrderInBugo[],
  Member<string, Bugo, CashAccount>[],
  Image,
  CashAccount[]
>;

export type CurrentFeventState = QueryDataState<CurrentFevent>;

export enum StepNameEnum {
  DeceasedInfo = 'deceased-info',
  MournerInfo = 'mourner-info',
  BugoSend = 'bugo-send',
}

export interface stepperProps {
  title: string;
  step: StepNameEnum;
}

export const stepper: stepperProps[] = [
  {
    title: '고인정보',
    step: StepNameEnum.DeceasedInfo,
  },
  {
    title: '상주정보',
    step: StepNameEnum.MournerInfo,
  },
  { title: '부고전송', step: StepNameEnum.BugoSend },
];
