const MobileDescriptionSection = ({ autoSendDate }: { autoSendDate: string }) => {
  return (
    // 640px 모바일 레이아웃 전환
    <div className="my-4 hidden flex-col gap-4 bg-white p-1 text-left text-12 text-gray-500 max-sm:flex">
      <p className="text-gray-800">※ 상주님께 답례글 링크를 전송합니다. </p>
      <p>
        장례식장을 찾아와주신 조문객분들께 답례 인사를 하실 수 있도록 답례글 링크를
        보내드립니다.
      </p>
      <p>
        답례글 링크는 발인 다음 날 오전 9시에 자동으로 보내지며, 원하시면 이 페이지에서
        직접 보내실 수 있습니다.
      </p>
      <p>
        자동발송시각:{' '}
        <span className="font-bold text-gray-800">{autoSendDate} 09:00</span> [발인 익일
        오전 9시]
      </p>
    </div>
  );
};

export { MobileDescriptionSection };
