import { FC, useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { useStoreTheme } from '@hooks/useStoreTheme';
import { ApolloWrapper } from '@plugin/apollo';
import { RootRouter } from '@route';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notification } from 'antd';
import './App.css';
import { useScrollToTopHook } from '@hooks/useScrollToTop';
import { useLocation, useParams } from 'react-router-dom';

const App: FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const setNotificationInstance = useSetRecoilState(notificationInstanceAtom);
  useStoreTheme();
  useScrollToTopHook();
  const location = useLocation();

  useEffect(() => {
    setNotificationInstance(api);
  }, [api, setNotificationInstance]);

  useEffect(() => {
    const myDynamicManifest = {
      short_name: '가온부고',
      name: '가온부고',
      icons: [
        {
          src: 'favicon.ico',
          sizes: '64x64 32x32 24x24 16x16',
          type: 'image/x-icon',
        },
        {
          src: 'favicon.png',
          type: 'image/png',
          sizes: '256x256',
        },
      ],
      start_url: `./${window.location.pathname}${window.location.search}`,
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    };

    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    const link = document.createElement('link');
    link.setAttribute('rel', 'manifest');
    link.setAttribute('href', manifestURL);

    document.head.appendChild(link);

    return () => {
      // 컴포넌트가 언마운트되면 manifest URL 객체를 해제합니다.
      URL.revokeObjectURL(manifestURL);
      // link 태그를 제거합니다.
      document.head.removeChild(link);
    };
  }, [location]);

  return (
    <>
      {contextHolder}
      <div className="App">
        <ApolloWrapper>
          <RootRouter />
        </ApolloWrapper>
      </div>
    </>
  );
};

export default App;
