import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'src/@utils/formatDate';

import { DeliveryStatusBody } from './components/DeliveryStatusBody';
import { DeliveryStatusFooter } from './components/DeliveryStatusFooter';
import { flowerStoreOrderDetailStateSelectorFamily } from '../state/flowerStoreOrderContent.selector';
import { DeliveryStatusHeader } from './components/DeliveryStatusHeader';

const OrderDeliveryContent: FC = () => {
  useMobileNavTitleHook('배송조회', true, false);

  const navigate = useNavigate();

  const buttonHandler = () => {
    navigate(-1);
  };

  const { orderId } = useParams();
  const { data: orderDetailData } = useRecoilValue(
    flowerStoreOrderDetailStateSelectorFamily(orderId ?? ''),
  );

  return (
    <div className="relative mx-auto max-w-[640px] break-keep max-sm:mx-[-1rem] max-sm:bg-transparent max-sm:p-0">
      <DeliveryStatusHeader
        estimatedDeliveredAt={formatDate(
          orderDetailData?.deliveryDetail.estimatedDeliveredAt,
          {
            contains: {
              year: false,
              day: true,
              hours: true,
              minutes: true,
            },
          },
        )}
        deliveryStatus={orderDetailData?.deliveryDetail.status}
      />
      <DeliveryStatusBody
        orderId={orderId ?? ''}
        itemImage={orderDetailData?.orderDetail.shopItem.shopItemInfo.image.url}
        itemName={orderDetailData?.orderDetail.shopItemEmbed.name}
        itemPrice={orderDetailData?.orderDetail.shopItemEmbed.priceRetail}
        deliveryState={orderDetailData?.deliveryDetail.status}
        paymentMethod={orderDetailData?.paymentDetail.method ?? ''}
        receiptUrl={orderDetailData?.paymentDetail.receiptUrl ?? ''}
        estimatedDeliveredAt={formatDate(
          orderDetailData?.deliveryDetail.estimatedDeliveredAt,
          {
            contains: {
              day: true,
              hours: true,
              minutes: true,
            },
          },
        )}
        requestedAt={formatDate(orderDetailData?.paymentDetail.requestedAt, {
          contains: {
            day: true,
            hours: true,
            minutes: true,
          },
        })}
      />
      <DeliveryStatusFooter
        mournerName={orderDetailData?.deliveryDetail.receiverName ?? ''}
        funeralAddress={orderDetailData?.deliveryDetail.receiverAddress}
        senderPhrase={orderDetailData?.bugoDetail.senderPhrase}
        condolencePhrase={orderDetailData?.bugoDetail.condolencePhrase}
      />

      <div className="left-0 bottom-0 mt-8 flex w-full justify-end max-sm:fixed max-sm:hidden">
        <Button
          onClick={buttonHandler}
          className="max-w-[187px] rounded border-none bg-primary stroke-none py-3 px-16 text-white shadow-none max-sm:w-full max-sm:max-w-full max-sm:rounded-none "
        >
          뒤로가기
        </Button>
      </div>
    </div>
  );
};

export { OrderDeliveryContent };
