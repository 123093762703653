import { FC } from 'react';

import { DeliveryStatusText } from '@containers/FlowerStoreOrderContent/OrderDeliveryContent/components/DeliveryStatusText';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { DeliveryState } from 'src/interfaces';

dayjs.locale('ko');

export const setDeliveryStatusText = (deliveryStatus: DeliveryState) => {
  switch (deliveryStatus) {
    case DeliveryState.BEFORE_PREPARING:
      return '상품준비중';
    case DeliveryState.PREPARING:
      return '배송준비중';
    case DeliveryState.DELIVERYING:
      return '배송중';
    case DeliveryState.DELIVERED:
      return '배송완료';
    default:
      return 'null';
  }
};

interface PaymentBodyProps {
  deliveryStatus: DeliveryState;
  estimatedDeliveredAt: Date;
  createdAt: Date;
  itemName: string;
  priceRetail: number;
  priceRetailShow: number;
  paymentByPoint?: number;
  imageUrl: string;
}

const PaymentBody: FC<PaymentBodyProps> = ({
  deliveryStatus,
  estimatedDeliveredAt,
  createdAt,
  itemName,
  priceRetail,
  priceRetailShow,
  paymentByPoint,
  imageUrl,
}) => {
  return (
    <div>
      <div className="flex justify-between py-3">
        <p className="text-14 font-bold text-gray-700">
          {dayjs(createdAt).format('MM.DD(dd) hh:mm')} 주문
        </p>
      </div>

      <div className=" rounded border bg-white p-4 ">
        <div className="">
          <DeliveryStatusText
            status={setDeliveryStatusText(deliveryStatus)}
            estimatedDeliveredAt={`${dayjs(estimatedDeliveredAt).format(
              'MM.DD(dd)',
            )} 도착 예정`}
          />
          <div className="grid grid-cols-10 gap-4 pt-2">
            <div className="col-span-2 max-h-[102px]">
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}/w400/${imageUrl}`}
                className="h-full"
                alt={'flowerImage'}
              ></img>
            </div>
            <div className=" col-span-8 flex flex-col justify-center  text-left font-medium text-black">
              <p className="pb-2 text-18">{itemName}</p>
              <p className="text-16 ">
                <span className="  text-gray-500 line-through">
                  {priceRetailShow.toLocaleString()}원
                </span>
                <span> {priceRetail.toLocaleString()}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end  text-left text-black">
          <p className="text-left">
            포인트사용 : {paymentByPoint?.toLocaleString() ?? 0}원 <br />
            결제금액 : {priceRetail.toLocaleString()}원
          </p>
        </div>
      </div>
    </div>
  );
};

export { PaymentBody };
