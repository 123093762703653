import { useCallback } from 'react';

import { useSetRecoilState, useRecoilValue } from 'recoil';

import { useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { nestMongoApi } from '@api/mongo/controllers';
import { CashAccount, CashAccountCreate } from '@api/mongo/interfaces';
import { useAuth } from '@hooks/useAuth';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { nestCashAccountApi } from '@api/mongo/controllers/cashAccount.controller';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';

import { MemberFormInputsEnum } from './mournerInfoForm.interface';
import { findDuplicateCashAccount } from '../utils/findDuplicateCashAccount';

type VerifyCashAccountHandlerProps = {
  index: number;
  accountHolder: string;
  cashAccount: string;
  cashAccountId: string | undefined;
  isVerified: boolean;
  bank: string;
};

export const useVerifyCashAccountHandler = ({
  index,
  accountHolder,
  cashAccount,
  cashAccountId,
  isVerified,
  bank,
}: VerifyCashAccountHandlerProps) => {
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();
  const notificationInstance = useRecoilValue(notificationInstanceAtom);
  const currentFeventId = useRecoilValue(currentFeventIdAtom);
  const { data: currentFeventData } = useRecoilValue(
    currentFeventStateSelectorFamily(currentFeventId ?? ''),
  );
  const { create: createCashAccount } = nestMongoApi<CashAccount, CashAccountCreate>(
    `${bugoRole()}/cash-account`,
  );
  const { verifyById: verifyByCashAccountId } = nestCashAccountApi(
    `${bugoRole()}/cash-account`,
  );
  const modalOpenId = `verifyLoadingModal-${index}`;

  const setAccountVerifyLoadingModal = useSetRecoilState(
    modalOpenAtomFamily(modalOpenId),
  );
  const { setValue } = useFormContext();

  const checkDuplicateAccount = useCallback(async () => {
    const duplicateAccount = findDuplicateCashAccount(
      currentFeventData?.cashAccounts ?? [],
      accountHolder,
      cashAccount,
    );
    if (duplicateAccount) {
      setValue(
        `member.${index}.${MemberFormInputsEnum.AccountHolder}`,
        duplicateAccount.name,
      );
      setValue(
        `member.${index}.${MemberFormInputsEnum.CashAccount}`,
        duplicateAccount.account,
      );
      setValue(
        `member.${index}.${MemberFormInputsEnum.CashAccountId}`,
        duplicateAccount._id,
      );
      setValue(`member.${index}.${MemberFormInputsEnum.Bank}`, duplicateAccount.bank);
      setValue(`member.${index}.${MemberFormInputsEnum.AccountHolderSame}`, false);
      setValue(`member.${index}.isVerified`, true);
      notificateSuccess(notificationInstance, '이미 인증된 계좌입니다.');
      return true;
    }

    return false;
  }, [
    accountHolder,
    cashAccount,
    currentFeventData?.cashAccounts,
    index,
    notificationInstance,
    setValue,
  ]);

  const onVerifyCashAccountHandler = useCallback(async () => {
    setAccountVerifyLoadingModal(true);
    let tempCashAccountId = cashAccountId ?? '';
    try {
      //0. 인증 요청한 계좌와 기존 인증계좌 중복 확인
      const isDuplicate = await checkDuplicateAccount();
      if (isDuplicate) return;
      if (currentFeventData?.cashAccounts)
        if (!cashAccountId) {
          // 1. cash account의 존재 여부 확인 (MemberFormInputsEnum.CashAccountId)
          // 2. 만약 없다면 cash account 생성 및 set MemberFormInputsEnum.CashAccountId
          const cashAccountDto: CashAccountCreate = {
            account: cashAccount,
            bank: bank,
            name: accountHolder,
            fevent: currentFeventId,
            isVerified: isVerified,
          };
          const { data: newCashAccount } = await createCashAccount(cashAccountDto);
          tempCashAccountId = newCashAccount._id;
          setValue(
            `member.${index}.${MemberFormInputsEnum.CashAccountId}`,
            newCashAccount._id,
          );
        }
      // 3. 생성된 cash account verify 요청
      const { data: verifyResult } = await verifyByCashAccountId(tempCashAccountId);
      setValue(`member.${index}.isVerified`, verifyResult.isVerified);
      if (verifyResult.message !== '정상처리') {
        notificateError(notificationInstance, verifyResult.message);
        setValue(`member.${index}.${MemberFormInputsEnum.CashAccountId}`, undefined);
      } else {
        //4. fevent cashAccount 갱신
        queryClient.invalidateQueries(
          feventByIdHookUrl(currentFeventId ?? '', bugoRole()),
        );
        setValue(`member.${index}.${MemberFormInputsEnum.AccountHolderSame}`, false);
        notificateSuccess(notificationInstance, '인증 완료');
      }
    } catch (err) {
      notificateError(notificationInstance, JSON.stringify(err));
    } finally {
      setAccountVerifyLoadingModal(false);
    }
  }, [
    setAccountVerifyLoadingModal,
    cashAccountId,
    checkDuplicateAccount,
    currentFeventData?.cashAccounts,
    verifyByCashAccountId,
    setValue,
    index,
    cashAccount,
    bank,
    accountHolder,
    currentFeventId,
    isVerified,
    createCashAccount,
    notificationInstance,
    queryClient,
    bugoRole,
  ]);

  return { onVerifyCashAccountHandler, checkDuplicateAccount };
};
