import { useRecoilValue } from 'recoil';

import { nestShopOrderApi } from '@api/mongo/controllers/shopOrder.guest.controller';
import { Button } from '@components/Button';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { flowerStoreOrderDetailStateSelectorFamily } from '../state/flowerStoreOrderContent.selector';
import { CancelItemBox } from './components/CancelItemBox';
import { RefundAmountBox } from './components/RefundAmountBox';
import { SelectCancelReasonBox } from './components/SelectCancelReasonBox';

const { cancel: shopOrderCancel } = nestShopOrderApi();

const OrderCancelRequestContent = () => {
  useMobileNavTitleHook('취소신청', true, false);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const { data: orderDetailData, status: orderDetailStatus } = useRecoilValue(
    flowerStoreOrderDetailStateSelectorFamily(orderId ?? ''),
  );

  const { setValue, handleSubmit } = useForm({
    defaultValues: {
      cancelReason: '',
    },
  });

  const onSubmit: SubmitHandler<{ cancelReason: string }> = (data) => {
    shopOrderCancel(orderId ?? orderDetailData?._id ?? '', data);
  };

  if (orderDetailStatus === 'loading') return <></>;
  if (!orderDetailData) return <></>;

  const { orderDetail, paymentDetail } = orderDetailData;
  const imageUrl = orderDetail.shopItem.shopItemInfo.image.url;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto grid max-w-[640px] grid-cols-2 gap-4 max-sm:mx-[-1rem] max-sm:grid-cols-1 max-sm:gap-0 max-sm:gap-y-4"
    >
      <CancelItemBox
        itemName={orderDetail.shopItemEmbed.name}
        imageUrl={imageUrl}
        priceRetail={orderDetail.shopItemEmbed.priceRetail}
        priceRetailShow={orderDetail.shopItemEmbed.priceRetailShow}
      />
      <div className="col-span-2 grid grid-cols-2 gap-4 max-sm:grid-cols-1">
        <SelectCancelReasonBox setValue={setValue} />
        <RefundAmountBox
          priceRetail={orderDetail.shopItemEmbed.priceRetail}
          paymentByPoint={paymentDetail.paymentByPoint}
        />
      </div>

      <div className="col-start-2 col-end-2 grid grid-cols-2 gap-4 text-14 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:gap-0">
        <Button
          className=" block border-none bg-gray-300 py-4 text-white shadow-none"
          onClick={() => navigate(-1)}
        >
          뒤로가기
        </Button>
        <Button
          type="submit"
          className=" border-none bg-primary py-4 text-white shadow-none"
        >
          신청하기
        </Button>
      </div>
    </form>
  );
};

export { OrderCancelRequestContent };
