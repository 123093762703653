import { FC } from 'react';

import { Icon } from '@components/Icon';
import Modal from '@components/Modal';
import { checkIOS } from 'src/@utils/checkIOS';
import { BottomPopup } from '@components/BottomPopup';

interface WebCourtesyShareModalProps {
  openId: string;
  content: string;
  title?: string;
  position?: 'start' | 'center' | 'end';
  //   kakaoContent: CourtesyKakaoShareContent;
}

const WebCourtesyShareModal: FC<WebCourtesyShareModalProps> = ({
  openId,
  content,
  title,
  position,
}) => {
  const { ShareMessage, OtherApps } = Icon;
  const smsContent = content.replace(/\n/g, '%0D%0A');
  return (
    <BottomPopup openId={openId} className="bg-white">
      <div className="w-screen rounded-t-2xl bg-white px-9 pt-6 pb-12">
        <h2 className="whitespace-pre-line break-keep text-center text-base font-bold leading-5">
          공유하기
        </h2>

        <div className="flex items-center justify-center gap-6 pt-6">
          {/* <div className="center-box h-[70px] w-[80px]">
            <div className=" flex flex-col items-center justify-center gap-2">
              <div className="center-box wh-12 rounded-lg bg-gray-100">
                <Download className="wh-7 fill-gray-500" />
              </div>
              <p className="text-sm font-bold leading-5 theme-text-1">다운로드</p>
            </div>
          </div> */}
          <a href={checkIOS() ? `sms:&body=${smsContent}` : `sms:?body=${smsContent}`}>
            <div className="flex h-[70px] w-[80px] items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-2">
                <ShareMessage className="h-full w-full stroke-none" />
                <p className="text-sm font-bold leading-5 text-black">문자</p>
              </div>
            </div>
          </a>
          {/* <div className="center-box h-[70px] w-[80px]">
            <div className="flex flex-col items-center justify-center gap-2">
              <KakaoCourtesyShareButton context={kakaoContent.courtesyContent} />
              <p className="text-sm font-bold theme-text-1">카카오톡</p>
            </div>
          </div> */}
          <button
            onClick={() => {
              let navigatorVar: any;
              // eslint-disable-next-line prefer-const
              navigatorVar = window.navigator;
              if (navigatorVar && navigatorVar.share) {
                navigatorVar
                  .share({
                    title: '답례글\n',
                    text: content,
                  })
                  .then(() => console.log('success'))
                  .catch((error: any) => console.log('error', error));
              } else {
                alert('공유 기능을 지원하지 않는 환경입니다.');
              }
            }}
          >
            <div className="flex h-[70px] w-[80px] items-center justify-center">
              <div className=" flex flex-col items-center justify-center gap-2">
                <OtherApps className="stroke-none" />
                <p className="text-sm font-bold leading-5 text-black">다른 앱</p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </BottomPopup>
  );
};

export { WebCourtesyShareModal };
