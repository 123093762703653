import { FC, useMemo } from 'react';

import { Icon } from '@components/Icon';
import { Link } from 'react-router-dom';
import wreathImg from 'src/assets/image/Wreath.webp';

interface Props {
  bugoId: string;
}

export const FlowerBottomBanner: FC<Props> = ({ bugoId }) => {
  const shopItemImage = useMemo(() => {
    return <img src={wreathImg} alt="상품이미지" className="object-cover" />;
  }, []);

  return (
    <Link
      to={`/flower-store/?bugoId=${bugoId}`}
      className="mx-3 grid items-center rounded-xl bg-[#e9cebe]"
      style={{
        gridTemplateColumns: '0.6fr 0.4fr',
      }}
    >
      <div className="space-y-4 break-keep py-6 pl-6 text-left font-medium text-black ">
        <div className="text-14">
          <p>쉽고 간편하게</p>
          <p> 위로의 마음을 전해주세요</p>
        </div>
        <div className="flex items-center gap-1 text-20 font-bold ">
          <p>근조화환 보내기</p>
          <Icon.CaretDown className=" h-7 w-7 -rotate-90 fill-black" />
        </div>
        <div className="text-14 font-medium">
          <p>번거로운 주소 입력 없이</p>
          <p>해당 장례식장으로</p>
          <p>당일배송 해드립니다.</p>
          <p>{`(19시이전 주문 시)`}</p>
        </div>
      </div>
      <div className="aspect-w-10 aspect-h-14 w-full">{shopItemImage}</div>
    </Link>
  );
};
