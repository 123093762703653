import { Gender } from 'src/interfaces';

export const deceasedNameString = (
  name: string,
  sex?: Gender,
  age?: number,
  nameDetail?: string,
) => {
  const stringElement = [
    '',
    name,
    nameDetail && `(${nameDetail})`,
    (sex || age) && `(${sex ? sex : ''}${sex && age ? '/' : ''}${age ? `${age}세` : ''})`,
  ];

  return stringElement.join(' ');
};
