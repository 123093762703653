import { FC } from 'react';

import { ShopOrderInBugo } from '@api/mongo/interfaces';
import { PaymentState } from 'src/interfaces';

import { FlowerBottomBanner } from './FlowerBottomBanner';
import { FlowerEasyBuyModalButton } from './FlowerEasyBuyModalButton';
import { FlowerOrderListTable } from './FlowerOrderListTable';

interface Props {
  shopOrders?: ShopOrderInBugo[];
  bugoId: string;
}

export const FlowerPromotionBox: FC<Props> = ({ shopOrders, bugoId }) => {
  //* bugo에 이미 shopOrders가 있음
  const shopOrderListInFevent = shopOrders?.filter((order) => {
    return [PaymentState.PAID, PaymentState.CONFIRMED].includes(
      order.paymentDetail.status,
    );
  });

  const emptyEasyBuyCard = (
    <div className="w-full break-keep text-14">
      <div className="my-2 flex items-center justify-between bg-white py-1 px-2">
        <div className="flex-1 text-center leading-5">
          <div className="px-2 font-normal text-gray-700">
            <span className="font-bold  text-apricot-600">첫번째로</span>
            &nbsp; 고인과 유가족 분들께 <p>힘이 되어주세요</p>
          </div>
        </div>
        <FlowerEasyBuyModalButton bugoId={bugoId} />
      </div>
    </div>
  );

  const easyBuyCard = (
    <div className="w-full break-keep text-14">
      <div className="my-2 flex items-center justify-between bg-white py-1 px-2">
        <div className="flex-1 text-center font-normal leading-5">
          <p>고인과 유가족 분들께</p>
          <p>힘이 되어주세요</p>
        </div>
        <FlowerEasyBuyModalButton bugoId={bugoId} />
      </div>
    </div>
  );

  return (
    <div className="space-y-3 break-keep pb-3 text-16">
      <div className="px-5">
        <p className="pt-3 text-left font-bold text-gray-700 ">
          꽃으로 마음을 보내신 분들
        </p>
      </div>
      {shopOrderListInFevent ? (
        <div className="px-3">
          {shopOrderListInFevent.length === 0 && emptyEasyBuyCard}
          {shopOrderListInFevent.length !== 0 && easyBuyCard}
          {shopOrderListInFevent.length > 0 && (
            <FlowerOrderListTable shopOrders={shopOrderListInFevent} bugoId={bugoId} />
          )}
        </div>
      ) : (
        <></>
      )}

      <FlowerBottomBanner bugoId={bugoId} />
    </div>
  );
};
