import { FC } from 'react';

import { Button } from '@components/Button';
import { BasicInput } from '@components/BasicInput';

import { UserModifyButton } from './UserModifyButton';
import { UserModifyFormItemProps } from '../state/userModifyContent.interface';

const UserModifyFormItem: FC<UserModifyFormItemProps> = ({
  label,
  register,
  placeholder,
  defaultValue,
  isOpenModifyForm,
  notUseModify,
  className,
  inputClassName,
  inputValue,
  openHandler,
  submitHandler,
  cancelHandler,
  minLength,
  maxLength,
  type,
}) => {
  return (
    <div
      className={`relative flex w-full flex-col border-b pb-1 pt-2 text-left ${className}`}
    >
      <div className="grid grid-cols-8">
        <div className="col-span-7 flex flex-col ">
          <label className="text-14 text-gray-500 max-sm:text-10">{label}</label>
          {defaultValue && <div className="text-primary">{defaultValue}</div>}
        </div>
        {!notUseModify && !isOpenModifyForm && (
          <UserModifyButton isValue={defaultValue || ''} onClick={openHandler} />
        )}
      </div>
      {isOpenModifyForm && (
        <div className="pt-2 pb-4">
          <BasicInput
            {...register}
            placeholder={placeholder}
            className={`px-4 ${inputClassName}`}
            minLength={minLength}
            maxLength={maxLength}
            value={inputValue}
            type={type}
          />
          <div className="mt-2 grid grid-cols-2 gap-2">
            <Button
              className="rounded border-none bg-primary text-white shadow-none"
              onClick={submitHandler}
            >
              확인
            </Button>
            <Button
              className="rounded border-none bg-gray-300 text-white shadow-none"
              onClick={cancelHandler}
            >
              취소
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { UserModifyFormItem };
