import { FC, PropsWithChildren } from 'react';

const Header: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <div className="fixed z-50 flex w-full justify-center bg-white shadow-sm">
      {children}
    </div>
  );
};

export { Header };
