import { selector } from 'recoil';

import { QueryDataState } from 'src/interfaces';

import { StoreItem } from './FlowerStoreItem.interface';
import {
  flowerStoreItemListDataAtom,
  flowerStoreItemListStatusAtom,
} from './flowerStoreMainContent.atom';

const flowerStoreItemListStateSelector = selector<QueryDataState<StoreItem[]>>({
  key: 'flowerStoreItemListStateSelector',
  get: ({ get }) => {
    return {
      status: get(flowerStoreItemListStatusAtom),
      data: get(flowerStoreItemListDataAtom),
    };
  },
});

export { flowerStoreItemListStateSelector };
