import { FC } from 'react';

import { formatFeventDateString } from 'src/@utils/formatDate';
import { FeventDate } from 'src/interfaces';

interface ObituaryFeventGridProps {
  enteranceDate?: FeventDate;
  coffinInDate?: FeventDate;
  coffinOutDate?: FeventDate;
  cemetery?: string;
  funeralHomeName: string;
  roomName: string;
  mournerText: string;
}

export const ObituaryFeventGrid: FC<ObituaryFeventGridProps> = ({
  enteranceDate,
  coffinInDate,
  coffinOutDate,
  cemetery,
  funeralHomeName,
  roomName,
  mournerText,
}) => {
  return (
    <>
      {enteranceDate?.date && (
        <>
          <p className="font-bold text-gray-700">입실</p>
          <p className="font-normal">{formatFeventDateString(enteranceDate)}</p>
        </>
      )}
      {coffinInDate?.date && (
        <>
          <p className="font-bold text-gray-700">입관</p>
          <p className="font-normal">{formatFeventDateString(coffinInDate)}</p>
        </>
      )}
      {coffinOutDate?.date && (
        <>
          <p className="font-bold text-gray-700">발인</p>
          <p className="font-normal">{formatFeventDateString(coffinOutDate)}</p>
        </>
      )}
      {cemetery && (
        <>
          <p className="font-bold text-gray-700">장지</p>
          <p className="font-normal">{cemetery}</p>
        </>
      )}

      <p className="font-bold text-gray-700">빈소</p>
      <p className="font-normal">{funeralHomeName}</p>

      <p className="font-bold text-gray-700">호실</p>
      <p className="font-normal">{roomName}</p>

      {mournerText && (
        <>
          <p className="font-bold leading-5 text-gray-700">
            상주
            <br />
            말씀
          </p>
          <p className="font-normal leading-5">{mournerText}</p>
        </>
      )}
    </>
  );
};
