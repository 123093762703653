import { v4 } from 'uuid';

const bugoFAQContents = [
  {
    _id: v4(),
    question: '여러가지 결제수단으로 결제할 수 있나요?',
    answer: `결제방법에는 무통장 입금,실시간 계좌이체,카드결제, 휴대폰결제, 에스크로결제가 있으며 모든 결제 방법은 적립금과 함께 결제 가능합니다.\n\n
        1. 간편결제(카카오페이) : 카카오페이를 통해 결제 하실 수 있습니다.\n\n
        2.간편결제(네이버페이) - 네이버페이를 통해 결제 하실 수 있습니다.\n\n
        3. 카드결제 : 카드결제 프로그램에 따라 결제를 진행하시면됩니다.\n\n
        4. 휴대폰결제 : 주민번호와 휴대폰번호를 이용하여 승인된 인증번호로 결제하는 방법입니다.\n\n
        이용요금은 추후 휴대폰요금 청구서에 합산됩니다.\n\n
        5. 에스크로(실시간계좌이체)결제 : 무통장입금처럼 주문결제가 이루어지지만 실제 구매대금은 제 3자(에스크로PG사)에게 맡긴 뒤 물품 배송을 확인하고 판매자에게 지불하는 제도로써, 소비자보호를 위한 안전결제 수단 입니다.`,
  },
];
const courtesyFAQContents = [
  {
    _id: v4(),
    question: '답례인사 샘플',
    answer: `결제asdasdasd제가 있으며 모든 결제 방법은 적립금과 함께 결제 가능합니다.\n\n
        1. 간편결제(카카오페이) : 카카오페이를 통해 결제 하실 수 있습니다.\n\n
        2.간편결제(네이버페이) - 네이버페이를 통해 결제 하실 수 있습니다.\n\n
        3. 카드결제 : 카드결제 프로그램에 따라 결제를 진행하시면됩니다.\n\n
        4. 휴대폰결제 : 주민번호와 휴대폰번호를 이용하여 승인된 인증번호로 결제하는 방법입니다.\n\n
        이용요금은 추후 휴대폰요금 청구서에 합산됩니다.\n\n
        5. 에스크로(실시간계좌이체)결제 : 무통장입금처럼 주문결제가 이루어지지만 실제 구매대금은 제 3자(에스크로PG사)에게 맡긴 뒤 물품 배송을 확인하고 판매자에게 지불하는 제도로써, 소비자보호를 위한 안전결제 수단 입니다.`,
  },
];
const flowerFAQContents = [
  {
    _id: v4(),
    question: '여러가지 결제수단으로 결제할 수 있나요?',
    answer: `결제방법에는 무통장 입금,실시간 계좌이체,카드결제, 휴대폰결제, 에스크로결제가 있으며 모든 결제 방법은 적립금과 함께 결제 가능합니다.
        
          1. 간편결제(카카오페이) : 카카오페이를 통해 결제 하실 수 있습니다.
          
          2.간편결제(네이버페이) - 네이버페이를 통해 결제 하실 수 있습니다.
          
          3. 카드결제 : 카드결제 프로그램에 따라 결제를 진행하시면됩니다.
          
          4. 휴대폰결제 : 주민번호와 휴대폰번호를 이용하여 승인된 인증번호로 결제하는 방법입니다.
          
          이용요금은 추후 휴대폰요금 청구서에 합산됩니다.
          
          5. 에스크로(실시간계좌이체)결제 : 무통장입금처럼 주문결제가 이루어지지만 실제 구매대금은 제 3자(에스크로PG사)에게 맡긴 뒤 물품 배송을 확인하고 판매자에게 지불하는 제도로써, 소비자보호를 위한 안전결제 수단 입니다.`,
  },
  {
    _id: v4(),
    question: '입금으로 결제할 수 있나요?',
    answer: `네 가능합니다.
        
      저희 판매페이지 하단에 입금계좌로 입금주시면 입금 결제가 가능합니다.
          
      관련 추가적인 문의는 상단에 있는 전화링크를 통해 문의주시면 친절하게 답변드리겠습니다.`,
  },
];

export { bugoFAQContents, courtesyFAQContents, flowerFAQContents };
