import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopItemInBugo } from '@api/mongo/interfaces';
import { Button } from '@components/Button';
import Modal from '@components/Modal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Link } from 'react-router-dom';

interface Props {
  shopItem?: ShopItemInBugo;
  bugoId: string;
  openId: string;
}

export const FlowerSameItemBuyModal: FC<Props> = function SameItemBuyModal({
  shopItem,
  bugoId,
  openId,
}: Props) {
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));
  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };
  if (shopItem) {
    const shopItemImageUrl = shopItem.image?.url ?? shopItem.shopItemInfo?.image?.url;

    return (
      <Modal openId={openId} position="center" className="bg-white">
        <div className="">
          <div className="w-full">
            <img
              src={`${process.env.REACT_APP_BUCKET_URL}/w400/${shopItemImageUrl}`}
              alt="상품이미지"
              className="object-cover"
            />
          </div>
          <div className="flex h-full flex-col justify-around gap-y-3">
            <div>
              <p className="whitespace-pre-line break-keep text-18 font-bold">
                {shopItem.shopItemInfo.name}
              </p>
            </div>
            <p className="whitespace-pre-line break-keep text-17 font-medium">
              동일한 상품으로 마음을 전하시겠습니까?
            </p>
            <div className="flex justify-center gap-4">
              <Link
                to={`/flower-store/item/${shopItem._id}/checkout/?bugoId=${bugoId}`}
                className="flex h-10 w-full items-center justify-center rounded-none bg-apricot-600 px-4 text-17 text-white sm:px-6 sm:text-19"
              >
                구매
              </Link>
              <Button
                className="filled-gray-300 h-10 w-full rounded-none px-4 text-17 sm:px-6 sm:text-19"
                onClick={onCloseModal}
              >
                취소
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};
