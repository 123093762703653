import { FC, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopItemInBugo, ShopOrderInBugo } from '@api/mongo/interfaces';
import { Button } from '@components/Button';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { fileBucketUrl } from 'src/@utils/fileUtils';
import flowerIcon from 'src/assets/image/Wreath.webp';

import { FlowerSameItemBuyModal } from './FlowerSameItmeBuyModal';

interface Props {
  shopOrders: ShopOrderInBugo[];
  bugoId: string;
}

export const FlowerOrderListTable: FC<Props> = function FlowerOrderListTable({
  shopOrders,
  bugoId,
}: Props) {
  const [sameShopItem, setSameShopItem] = useState<ShopItemInBugo | undefined>(undefined);
  const [viewAllList, setViewAllList] = useState(false);
  const sameShopItemOpenId = `flowerOrderListTable-${bugoId}`;
  const setSameShopItemModalOpen = useSetRecoilState(
    modalOpenAtomFamily(sameShopItemOpenId),
  );

  const phraseBox = (shopOrder: ShopOrderInBugo) => {
    return (
      <div className="text-left text-12 text-black">
        <p className=" font-medium">{shopOrder?.bugoDetail?.senderPhrase}</p>
        <p className="">{shopOrder?.bugoDetail?.condolencePhrase}</p>
      </div>
    );
  };

  const shopOrderLimit10 = shopOrders.slice(0, 5);

  return (
    <div className="space-y-2.5">
      <div className="w-full break-keep border border-gray-300 bg-white">
        {(viewAllList ? shopOrders : shopOrderLimit10).map((order, index) => {
          const shopItem = order.orderDetail.shopItem;
          const shopItemImageUrl =
            shopItem?.image?.url ?? shopItem?.shopItemInfo?.image?.url;
          return (
            <div
              key={index}
              onClick={() => {
                setSameShopItem(shopItem);
                setSameShopItemModalOpen(true);
              }}
              className="flex cursor-pointer items-center gap-3 py-1 pl-1"
            >
              {shopItemImageUrl ? (
                <img
                  className="w-11"
                  src={fileBucketUrl(shopItemImageUrl ?? '', 'w200')}
                ></img>
              ) : (
                <img className="py-1.5 pl-3 pr-4" src={flowerIcon}></img>
              )}
              {phraseBox(order)}
            </div>
          );
        })}
      </div>
      {shopOrders?.length > 5 && (
        <div className="center-box">
          <Button onClick={() => setViewAllList((curr) => !curr)} className="">
            <p>{viewAllList ? '접기' : '전체보기'}</p>
          </Button>
        </div>
      )}
      <FlowerSameItemBuyModal
        shopItem={sameShopItem}
        bugoId={bugoId}
        openId={sameShopItemOpenId}
      />
    </div>
  );
};
