import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { CourtesyPreview } from '@containers/CourtesyPreview';
import { MobileDescriptionSection } from '@containers/CourtesyPreview/components/MobileDescription';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { Body, Footer, Header } from '@layout';

const CourtesyPreviewPage = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <MainSection title="답례 인사 미리보기">
          <MobileDescriptionSection />
          <CourtesyPreview />
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { CourtesyPreviewPage };
