import { FlowerStoreEnquiryPage } from '@pages/FlowerStore/FlowerStoreEnquiryPage';
import { FlowerStoreFAQPage } from '@pages/FlowerStore/FlowerStoreFAQPage';
import { FlowerStoreFindIdPasswordPage } from '@pages/FlowerStore/FlowerStoreFindIdPasswordPage';
import { FlowerStoreGuestLoginPage } from '@pages/FlowerStore/FlowerStoreGuestLoginPage';
import { FlowerStoreLoginPage } from '@pages/FlowerStore/FlowerStoreLoginPage';
import { FlowerStoreMainPage } from '@pages/FlowerStore/FlowerStoreMainPage';
import { FlowerStoreSignUpPage } from '@pages/FlowerStore/FlowerStoreSignUpPage';
import { ProtectedRoute } from '@route/ProtectedRoute';
import { Route, Routes, useLocation } from 'react-router-dom';
import { FlowerStoreTermsAgreementPage } from '@pages/FlowerStore/FlowerStoreTermsAgreementPage';
import { FlowerStoreOrderListPage } from '@pages/FlowerStore/FlowerStoreOrderListPage';
import { FlowerStoreTermsPage } from '@pages/FlowerStore/FlowerStoreTermsPage';

import { StoreItemRouter } from './StoreItemRoute';
import { StoreOrderRouter } from './StoreOrderRoute';
import { StoreUserRouter } from './StoreUserRoute';

const FlowerStoreRouter = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/*" element={<FlowerStoreMainPage />} />
      <Route path="/login" element={<FlowerStoreLoginPage />} />
      <Route path="/guest-login" element={<FlowerStoreGuestLoginPage />} />
      <Route path="/signup" element={<FlowerStoreSignUpPage />} />
      <Route path="/signup/terms-agreement" element={<FlowerStoreTermsAgreementPage />} />
      <Route path="/find-id-password" element={<FlowerStoreFindIdPasswordPage />} />
      <Route
        path="/enquiry"
        element={
          <ProtectedRoute
            loginUrl={`/login?redirect=${location.pathname}`}
            children={<FlowerStoreEnquiryPage />}
          />
        }
      />
      <Route path="/faq" element={<FlowerStoreFAQPage />} />
      <Route path="/terms/*" element={<FlowerStoreTermsPage />} />
      <Route
        path="/user/*"
        element={
          <ProtectedRoute
            loginUrl={`/login?redirect=${location.pathname}`}
            children={<StoreUserRouter />}
          />
        }
      />
      <Route path="/Item/*" element={<StoreItemRouter />} />
      <Route path="/order/:orderId/*" element={<StoreOrderRouter />} />
      <Route
        path="/order-list/*"
        element={
          <ProtectedRoute
            loginUrl={`/flower-store/guest-login?redirect=${location.pathname}`}
            children={<FlowerStoreOrderListPage />}
          />
        }
      />
    </Routes>
  );
};

export { FlowerStoreRouter };
