import { Fragment } from 'react';

import { useRecoilState } from 'recoil';

import { MobileMenuButton } from '@components/MobileMenuButton';
import { Transition, Dialog } from '@headlessui/react';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useModalHook } from '@hooks';

import { MenuLinkButton, SideBarProps } from './components/MenuLinkButton';
import { UserRow } from './components/UserRow';

export const mobileSidePopupModalId = 'mobileSidePopup';
const MobileSidePopup = ({ navigation }: { navigation: SideBarProps }) => {
  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(mobileSidePopupModalId));

  useModalHook(mobileSidePopupModalId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 flex justify-end sm:hidden"
        onClose={setOpen}
      >
        {/* 모달 뒷 배경 */}
        <Transition.Child
          as={Fragment}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-70" />
        </Transition.Child>
        {/* 사이드메뉴 */}
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="relative w-full max-w-[256px] flex-col overflow-y-auto bg-gray-50">
            <div className=" flex h-[56px] items-center justify-end bg-white p-4">
              <MobileMenuButton />
            </div>
            <UserRow />
            <nav className="flex-shrink-0" aria-label="Sidebar">
              {navigation.map((item, index) => {
                return (
                  <MenuLinkButton
                    key={index}
                    title={item.title}
                    subMenuList={item.subMenuList}
                  />
                );
              })}
            </nav>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export { MobileSidePopup };
