import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Button } from '@components/Button';
import Modal from '@components/Modal';
import { StepNameEnum } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import { SentMemberListType } from './PreviewMournerList';

type PreviewSentSuccessModalProps = {
  feventId: string;
  sentMemberList: SentMemberListType[];
};

const emptyPhoneNumberText = '연락처 미입력';

const PreviewSentSuccessModal: FC<PreviewSentSuccessModalProps> = ({
  feventId,
  sentMemberList,
}) => {
  const navigate = useNavigate();
  const sentSuccessModalOpenId = 'sendSuccessModal';
  const setSuccessModal = useSetRecoilState(modalOpenAtomFamily(sentSuccessModalOpenId));

  const isEmptyPhoneNumberMember = sentMemberList.some(
    (item) => item.phoneNumber === emptyPhoneNumberText,
  );

  return (
    <Modal openId={sentSuccessModalOpenId} className="bg-white">
      <div className="py-3 text-left">
        <h1 className="py-1 text-20  text-black">부고장 전송이 완료되었습니다.</h1>

        {isEmptyPhoneNumberMember && (
          <>
            <p className="break-keep text-14 text-red-500">
              연락처를 입력하지 않은 상주님들께는 전송되지 않습니다.
            </p>
            <p
              className="cursor-pointer text-right text-14 text-gray-500 underline hover:font-bold"
              onClick={() => {
                navigate(`/fevent/edit/${feventId}/${StepNameEnum.MournerInfo}`);
                setSuccessModal(false);
              }}
            >
              연락처 입력하기
            </p>
          </>
        )}
      </div>
      <div className="grid grid-cols-2 border-t border-b py-2 text-center font-bold">
        <span>성함</span>
        <span>연락처</span>
      </div>
      <ul className="max-h-[300px] overflow-y-auto pb-2">
        {sentMemberList.map((item) => {
          return (
            <li
              key={v4()}
              className="grid grid-cols-2 border-t py-2 text-center first:border-t-0"
            >
              <span className="border-r">{item.name}</span>
              <span
                className={`${
                  item.phoneNumber === emptyPhoneNumberText && 'text-red-500'
                }`}
              >
                {item.phoneNumber}
              </span>
            </li>
          );
        })}
      </ul>

      <Button
        className="w-full rounded border-none bg-primary text-white shadow-none"
        onClick={() => {
          setSuccessModal(false);
        }}
      >
        확인
      </Button>
    </Modal>
  );
};

export { PreviewSentSuccessModal };
