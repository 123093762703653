import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useNavigate } from 'react-router-dom';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { StepNameEnum } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';

import Modal from './Modal';
import { Icon } from './Icon';

interface Props {
  title: string;
  feventId: string;
  openId: string;
}

const FeventManageModal: FC<Props> = ({ title, feventId, openId }) => {
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));
  const isMobileMedia = useMobileMediaQuery();
  const navigate = useNavigate();
  const linkHandler = (to: string) => {
    setOpen(false);
    navigate(to);
  };
  return (
    <>
      <Modal
        openId={openId}
        className="bg-white pb-10"
        position={isMobileMedia ? 'end' : 'center'}
      >
        <h1 className="break-keep py-2 text-16 font-bold">{title}</h1>
        <div className="flex flex-col gap-3">
          <LinkButton
            text="부고장 수정"
            onClick={() =>
              linkHandler(`/fevent/edit/${feventId}/${StepNameEnum.DeceasedInfo}`)
            }
          >
            <Icon.Pencil className="stroke-none" />
          </LinkButton>
          <LinkButton
            text="부고장 미리보기"
            onClick={() => linkHandler(`/fevent/${feventId}/bugo/preview`)}
          >
            <Icon.View className="h-6 w-6 stroke-none" />
          </LinkButton>
          <LinkButton
            text="답례글 보내기"
            onClick={() => linkHandler(`/fevent/${feventId}/courtesy-send`)}
          >
            <Icon.Send className="stroke-none" />
          </LinkButton>
        </div>
      </Modal>
    </>
  );
};

export { FeventManageModal };

interface LinkButtonProps extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  text: string;
}

const LinkButton: FC<LinkButtonProps> = ({ text, children, ...props }) => {
  return (
    <div
      className="flex cursor-pointer items-center rounded-md border p-2 px-4 font-medium shadow-sm hover:border-primary hover:bg-primary hover:text-white"
      {...props}
    >
      <div className="center-box h-full pl-1">
        {children}
        <p className="bugo-h3 ml-2 text-sm">{text}</p>
      </div>
    </div>
  );
};
