import { FC, InputHTMLAttributes, Ref, forwardRef, useCallback, useState } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  currentFeventIdAtom,
  dpImageFileAtom,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useParams } from 'react-router-dom';
import { fileBucketUrl } from 'src/@utils/fileUtils';

import { Icon } from './Icon';
import { Label } from './Label';
import Modal from './Modal';

interface ImageUploadInputProps extends InputHTMLAttributes<HTMLInputElement> {
  setImage: (id: string) => void;
  label?: string;
  ref?: Ref<HTMLInputElement>;
}

const ImageUploadInput: FC<ImageUploadInputProps> = forwardRef(
  ({ setImage, label, ...props }, ref) => {
    const { feventId } = useParams();
    const currentFeventId = useRecoilValue(currentFeventIdAtom);
    const [dpImageFile, setDpImageFile] = useRecoilState(dpImageFileAtom);

    const currentFevent = useRecoilValue(
      currentFeventStateSelectorFamily(feventId ?? currentFeventId ?? ''),
    );

    const setOpen = useSetRecoilState(modalOpenAtomFamily('deceaseBasicForm-preview'));
    const setAlert = useSetRecoilState(modalOpenAtomFamily('fileSizeLimitAlert'));
    const [fileName, setFileName] = useState(
      currentFevent.data?.deceasedInfo.dpImage?.name ?? '',
    ); // 파일명 상태 변수
    const [previewUrl, setPreviewUrl] = useState(
      currentFevent.data?.deceasedInfo.dpImage?.url
        ? fileBucketUrl(currentFevent.data?.deceasedInfo.dpImage?.url, 'raw')
        : '',
    );

    const handleFileChange = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
          const fileSize = file.size / 1024 / 1024;
          if (fileSize > 2.5) {
            setAlert(true);
            return;
          }
          setDpImageFile(file);
          setFileName(file.name); // 파일명을 업데이트합니다.
          const url = URL.createObjectURL(file);
          setPreviewUrl(url);
          // const image = await postImage(file);
          // setImage(image._id);
          // console.log('image: ', image);
        }
      },
      [setAlert, setDpImageFile],
    );

    const handleClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      setFileName(''); // 파일명을 초기화합니다.
      setPreviewUrl('');
      setDpImageFile(undefined);
      setImage('');
      const fileUploader = document.getElementById('fileUploader') as HTMLInputElement;
      if (fileUploader) {
        fileUploader.value = ''; // input 요소도 초기화합니다.
      }
    };

    const onClickPreview = () => {
      setOpen(true);
    };
    return (
      <div className="col-span-2">
        {label && <Label text={label} />}
        {previewUrl ? (
          <div className="flex gap-3 max-sm:gap-1">
            {/* PC only */}
            <img
              src={previewUrl}
              alt="preview"
              className="h-[124px] w-[98px] max-sm:hidden"
            />
            {/* 모바일 only */}
            <p className="hidden w-[50%] overflow-hidden text-ellipsis rounded border border-apricot-600 bg-white px-4 py-2 text-center text-apricot-600 max-sm:block">
              {fileName}
            </p>
            <button
              type="button"
              className="hidden h-10 w-20 rounded border text-12 max-sm:block max-sm:w-[25%]"
              onClick={onClickPreview}
            >
              미리보기
            </button>
            {/* 모바일 & PC */}
            <button
              type="button"
              onClick={handleClear}
              className="h-10 w-20 rounded border border-red-500 bg-white text-12 text-red-500 max-sm:w-[25%]"
            >
              삭제
            </button>
          </div>
        ) : (
          <label
            htmlFor="fileUploader"
            className="relative flex h-[124px] w-[230px] cursor-pointer items-center justify-center rounded border bg-white p-4 max-sm:h-10 max-sm:w-[50%] max-sm:py-2"
          >
            <input
              id="fileUploader"
              type="file"
              className="absolute left-0 h-full w-full cursor-pointer opacity-0"
              onChange={handleFileChange}
              accept="image/*"
              ref={ref}
              {...props}
            />
            {/* PC only */}
            <div className="text-left text-12 text-gray-500 max-sm:hidden">
              <Icon.UploadImage className="mb-1" />
              <p className="mb-1">
                <span className="text-apricot-600">클릭 </span>
                또는 사진 파일을 드래그하세요
              </p>
              <p>PNG, JPG up to 2.5MB</p>
            </div>
            {/* 모바일 only */}
            <p className="hidden text-12 max-sm:block">이미지 업로드</p>
          </label>
        )}
        {/* 모바일 이미지 미리보기 모달 */}
        <Modal className="bg-white" openId="deceaseBasicForm-preview">
          <div>
            <img src={previewUrl} alt="preview" />
          </div>
        </Modal>
        {/* 이미지 파일 2.5MB */}
        <Modal className="bg-white" openId="fileSizeLimitAlert" position="center">
          <div>
            <p className="my-4 text-16">이미지 파일의 크기는 2.5MB 이하여야 합니다.</p>
            <button
              className="w-full rounded bg-red-500 py-4 text-white"
              onClick={() => setAlert(false)}
            >
              확인
            </button>
          </div>
        </Modal>
      </div>
    );
  },
);

export { ImageUploadInput };
