import { api_ } from '@plugin/axios';

import { EnquiryCreate, Enquiry, EnquiryUpdate } from '../interfaces';

export const createEnquiry = async (enquiryCreateDto: EnquiryCreate) => {
  const { data: enquiry } = await api_.post<Enquiry>('/public/enquiry', enquiryCreateDto);
  return enquiry;
};

export const updateEnquiry = async (enquiryUpdate: EnquiryUpdate, role: string) => {
  const { data: enquiry } = await api_.patch(
    `/${role}/enquiry/${enquiryUpdate._id}`,
    enquiryUpdate,
  );
  return enquiry;
};

export const deleteEnquiry = async (_id: string, role: string) => {
  const { data: enquiry } = await api_.delete(`/${role}/enquiry/${_id}`);
  return enquiry;
};
