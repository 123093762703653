import kakao from '@assets/image/kakao.png';

const KaKaoLoginBtnSet = () => {
  return (
    <div className="mt-4 text-12">
      <p className="text-gray-500">간편하게 카카오 계정으로 로그인 할 수 있어요</p>
      <button className="mt-1 flex h-12 w-full items-center justify-center gap-4 rounded bg-[#FEE500] py-2 px-8 text-12">
        <p className="relative">
          <img src={kakao} className=" absolute -left-11 -top-2" />
          카카오 로그인
        </p>
      </button>
    </div>
  );
};

export { KaKaoLoginBtnSet };
