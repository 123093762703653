import { useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import BugoSendFormList from '@containers/FeventSubmitContent/components/BugoSendForm/components/BugoSendFormList';
import {
  FormValue,
  FormValues,
} from '@containers/FeventSubmitContent/components/BugoSendForm/state/bugoSendForm.interface';
import { setDefaultValues } from '@containers/FeventSubmitContent/components/BugoSendForm/utils/setDefaultFormValues';
import {
  currentFeventIdAtom,
  feventDataSnapshotAtom,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { useBugoSendHook } from '@containers/FeventSubmitContent/state/feventSubmitContent.hook';
import {
  feventByIdHookUrl,
  useFeventByIdHook,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { useAuth } from '@hooks/useAuth';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

const BugoSendPage = () => {
  const { feventId } = useParams();
  const queryClient = useQueryClient();

  const setIsEditPage = useSetRecoilState(isEditPageAtom);
  const [currentFeventId, setCurrentFeventId] = useRecoilState(currentFeventIdAtom);
  const [feventDataSnapshot, setFeventDataSnapshot] =
    useRecoilState(feventDataSnapshotAtom);

  useFeventByIdHook(feventId ?? currentFeventId ?? '', !feventId && !currentFeventId);

  useMobileNavTitleHook('부고 전송하기', true);

  const { data } = useRecoilValue(
    currentFeventStateSelectorFamily(feventId ?? currentFeventId ?? ''),
  );

  useEffect(() => {
    if (feventId) setCurrentFeventId(feventId);
    return () => {
      setCurrentFeventId('');
    };
  }, [feventId, setCurrentFeventId]);

  useEffect(() => {
    if (feventId) {
      setIsEditPage(true);
    }
  }, [feventId, setIsEditPage]);

  useEffect(() => {
    if (data && !feventDataSnapshot) {
      setFeventDataSnapshot(data);
    }
  }, [data, feventDataSnapshot, setFeventDataSnapshot]);

  const { bugoRole } = useAuth();
  const { patchUpdateMembersData, notificationSendSuccess } = useBugoSendHook();
  const { data: fevent } = useRecoilValue(
    currentFeventStateSelectorFamily(currentFeventId ?? ''),
  );
  const { bugoSend } = nestAlertApi();

  const method = useForm<FormValues>({
    defaultValues: {
      memberData: fevent ? setDefaultValues(fevent.memberOrderList) : undefined,
    },
  });
  const { control, getValues } = method;

  const { fields } = useFieldArray({
    control,
    name: 'memberData',
  });

  const checkIsEqual = () => {
    return !fields.reduce((found, field, index) => {
      if (found) {
        return true; // 이미 찾은 경우, 중복된 호출 방지를 위해 바로 true 반환
      }
      return (
        !!getValues(`memberData.${index}.isPhoneNumberUpdated`) ||
        !!getValues(`memberData.${index}.isMournerTextUpdated`)
      ); // 해당 요소의 조건을 확인하여 true 또는 false 반환
    }, false);
  };

  const updateMemberData = (memberData: FormValue, index: number) => {
    if (!memberData.mournerText && fevent?.mournerText) {
      memberData.mournerText = fevent.mournerText;
    }
    memberData.bugoId = fevent?.memberOrderList[index]?.bugo?._id ?? '';
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    data.memberData.forEach(updateMemberData);
    await patchUpdateMembersData(data);
    await queryClient.invalidateQueries(
      feventByIdHookUrl(feventId ?? currentFeventId ?? '', bugoRole()),
    );
    const memberBugoIdList = data.memberData.map((data) => data.bugoId);

    if (feventId) {
      const updatedMemberBugoIdList = data.memberData
        .filter(
          (memberData) => memberData.isPhoneNumberUpdated || memberData.isAddedMember,
        )
        .map((member) => member.bugoId);

      if (updatedMemberBugoIdList.length === 0) return;
      await bugoSend({ bugos: updatedMemberBugoIdList });
      notificationSendSuccess();
      return;
    }
    await bugoSend({ bugos: memberBugoIdList });
    notificationSendSuccess();
  };

  useEffect(() => {
    if (fevent?.memberOrderList.length !== fields.length)
      control._reset({
        memberData: fevent ? setDefaultValues(fevent.memberOrderList) : undefined,
      });
  }, [fevent, control, fields.length]);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <MainSection title="부고 전송하기">
          <BasicInfoForm
            title="부고전송"
            desc={`상주님께 부고를 보내드립니다. 각 상주님들은 받으신 부고문자 페이지에서 공유를 통하여 지인분들께 부고를 전하실 수 있습니다.\n\n 부고장 미리보기를 클릭하셔서 보내실 부고장을 미리 확인하실 수 있습니다.\n\n 수정 · 추가된 부고 전송 클릭시 휴대전화번호가 변경되었거나, 새로 추가된 상주님들에게만 부고가 전송됩니다.\n\n 추가적으로 한번더 전송이 필요한 경우 재전송 버튼을 통해 진행해주세요.\n\n 부고장 내용이 수정이 필요하신 경우 부고장 수정 버튼을 클릭해주세요`}
          >
            <FormProvider {...method}>
              <form onSubmit={method.handleSubmit(onSubmit)}>
                <BugoSendFormList
                  fevent={fevent}
                  onSubmit={onSubmit}
                  fields={fields}
                  checkUpdated={checkIsEqual}
                />
              </form>
            </FormProvider>
          </BasicInfoForm>
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { BugoSendPage };
