import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import dayjs from 'dayjs';
import { CustomCallback } from 'src/interfaces';

import { DatePickerModal } from './DatePickerModal';
import { Icon } from './Icon';
import { Label } from './Label';

type DateInputProps = {
  openId: string;
  onDateChange: CustomCallback<Date | null, void>;
  onClear?: () => void;
  value?: Date | null;
  label?: string;
  placeholder?: string;
};
const DateInput: FC<DateInputProps> = ({
  value,
  onDateChange,
  label,
  openId,
  placeholder,
  onClear,
}) => {
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const onClickClear = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    if (onClear) {
      onClear();
    }
  };

  return (
    <div>
      {label && <Label text={label} />}
      <div className="relative w-full" onClick={() => setOpen(true)}>
        <Icon.Calendar className="absolute top-1 left-2 z-20 stroke-none" />
        <div className="flex h-10 w-full cursor-pointer items-center rounded border bg-white pl-11">
          <p className="">
            {value ? (
              dayjs(value).format('MM.DD (dd)')
            ) : (
              <span className="text-gray-400">{placeholder}</span>
            )}
          </p>
        </div>
        {value && (
          <Icon.X
            className="absolute top-1 right-2 z-20 cursor-pointer stroke-none"
            onClick={onClickClear}
          />
        )}
      </div>
      <DatePickerModal openId={openId} onDateChange={onDateChange} value={value} />
    </div>
  );
};

export { DateInput };
