import React, { FC } from 'react';

import { Member } from '@api/mongo/interfaces';

interface Props {
  memberList: Member[];
}

export const ObituaryMemberGrid: FC<Props> = ({ memberList }) => {
  const memberListByRelationType = Array.from(
    new Set(memberList.map((item) => item.relationType)),
  ).map((relation) => ({
    relationType: relation,
    memberList: memberList.filter((item) => item.relationType === relation),
  }));

  return (
    <>
      {memberListByRelationType.map((item, index) => (
        <React.Fragment key={index}>
          <p className="font-bold text-gray-700">{item.relationType}</p>
          <p className="font-normal">
            {item.memberList?.reduce((accu, prev) => {
              return accu ? `${accu}, ${prev.shortName}` : prev.shortName;
            }, '')}
          </p>
        </React.Fragment>
      ))}
    </>
  );
};
