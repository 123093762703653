import { FC, useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { EnquiryPage } from '@pages/EnquiryPage';
import { FindIdPasswordPage } from '@pages/FindIdPasswordPage';
import { GuestLoginPage } from '@pages/Login/GuestLoginPage';
import { LoginPage } from '@pages/Login/LoginPage';
import { MainPage } from '@pages/MainPage';
import { SignUpPage } from '@pages/SignUpPage';
import { TermsAgreementPage } from '@pages/TermsAgreementPage';
import { BugoRouter } from '@route/BugoRoute';
import { FeventRouter } from '@route/FeventRoute';
import { MemberRouter } from '@route/MemberRoute';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TermsPage } from '@pages/TermsPage';

import { FAQRouter } from './FAQRoute';
import { FlowerStoreRouter } from './FlowerStoreRoute';
import { UserRouter } from './UserRoute';
import { ProtectedRoute } from './ProtectedRoute';

const RootRouter: FC = () => {
  const setIsStorePage = useSetRecoilState(isStorePageAtom);
  const location = useLocation();

  useEffect(() => {
    location.pathname.indexOf('flower-store') > 0
      ? setIsStorePage(true)
      : setIsStorePage(false);
  }, [location, setIsStorePage]);

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="login/*" element={<LoginPage />} />
      <Route path="signup/*" element={<SignUpPage />} />
      <Route path="guest-login/*" element={<GuestLoginPage />} />
      <Route
        path="user/*"
        element={
          <ProtectedRoute
            loginUrl={`/login?redirect=${location.pathname}`}
            children={<UserRouter />}
          />
        }
      />
      <Route path="find-id-password/*" element={<FindIdPasswordPage />} />
      <Route path="signup/terms-agreement/*" element={<TermsAgreementPage />} />
      <Route path="fevent/*" element={<FeventRouter />} />
      <Route path="bugo/*" element={<BugoRouter />} />
      <Route path="member/*" element={<MemberRouter />} />
      <Route
        path="enquiry/*"
        element={
          <ProtectedRoute
            loginUrl={`/login?redirect=${location.pathname}`}
            children={<EnquiryPage />}
          />
        }
      />

      <Route path="faq/*" element={<FAQRouter />} />
      <Route path="flower-store/*" element={<FlowerStoreRouter />} />
      <Route path="terms/*" element={<TermsPage />} />
    </Routes>
  );
};

export { RootRouter };
