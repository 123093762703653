import { FC, useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';

import { FuneralHomeInfoEmbed } from '@api/mongo/interfaces';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useId } from 'react-id-generator';
import { CustomCallback } from 'src/interfaces';

import FuneralHomeInfoSearchModal from './components/FuneralHomeInfoSearchModal';

interface IProps {
  value: string;
  onValueChange: CustomCallback<string, void>;
  onEmbedSelect: (
    funeralHomeInfoEmbed: FuneralHomeInfoEmbed,
    funeralHomeInfoId: string,
  ) => void;
}

const FuneralHomeInfoSearcher: FC<IProps> = ({
  value: defaultValue,
  onValueChange,
  onEmbedSelect,
}: IProps) => {
  const { MagnifyingGlassRegular } = Icon;
  const [id] = useId(1, 'searchFuneralHome');
  const openId = `modal-${id}`;
  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));
  const [funeralHomeInfoName, setFuneralHomeInfoName] = useState<string>(defaultValue);

  useEffect(() => {
    setFuneralHomeInfoName(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Button
        type="button"
        onClick={() => setOpen(true)}
        className={`button relative block h-10 w-full rounded bg-white px-4 text-left text-sm font-bold shadow-none disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:border-gray-300 ${
          funeralHomeInfoName ? 'border' : 'border-red-500'
        }`}
      >
        <p className={`${funeralHomeInfoName ? '' : 'text-gray-300'}`}>
          {funeralHomeInfoName ? funeralHomeInfoName : '장례식장 검색'}
        </p>
        <MagnifyingGlassRegular className="absolute top-0 right-0 h-full p-[11px]" />
      </Button>
      {open && (
        <FuneralHomeInfoSearchModal
          openId={openId}
          onValueChange={onValueChange}
          onEmbedSelect={onEmbedSelect}
          setFuneralHomeInfoName={setFuneralHomeInfoName}
        />
      )}
    </>
  );
};

export default FuneralHomeInfoSearcher;
