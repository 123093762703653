import { useRecoilState, useRecoilValue } from 'recoil';

import { nestAuthApi } from '@api/mongo/controllers';
import { BasicInput } from '@components/BasicInput';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { notificateError } from 'src/@utils/noti.utils';

import { ciUserAtom, selectUserIdAtom } from '../state/ciUser.atom';
import { ConfirmByPhoneNumberForm } from './ConfirmByPhoneNumberForm';
import { ResetPasswordForm } from './ResetPasswordForm';

type FormValues = {
  username: string;
};

const { findByUsername } = nestAuthApi();

const FindPasswordForm = () => {
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const [selectUserId, setSelectUserId] = useRecoilState(selectUserIdAtom);

  const ciUser = useRecoilValue(ciUserAtom);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const res = await findByUsername({ username: data.username });

    if (!res.data) {
      notificateError(
        notifiacationInstance,
        '입력하신 아이디와 일치하는 정보가 없습니다.',
      );
      return;
    }
    setSelectUserId(data.username);
  };

  return (
    <div>
      {!ciUser ? (
        selectUserId ? (
          <ConfirmByPhoneNumberForm selectedTab={1} />
        ) : (
          <form className="mt-6 text-12" onSubmit={handleSubmit(onSubmit)}>
            <p>아이디를 먼저 확인하신 후</p>
            <p className="mt-1">비밀번호를 재설정 할 수 있습니다.</p>
            <BasicInput
              className="mt-6 px-4 text-16"
              placeholder="아이디"
              {...register('username', { required: true })}
            />
            {errors.username && (
              <p className="text-left text-10 text-red-500">아이디를 입력해주세요.</p>
            )}
            <button
              type="submit"
              className="mt-6 w-full rounded bg-primary px-4 py-3 text-14 font-bold text-white"
            >
              아이디 확인
            </button>
          </form>
        )
      ) : (
        <ResetPasswordForm />
      )}
    </div>
  );
};

export { FindPasswordForm };
