import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Icon } from '@components/Icon';
import { ProfileIconButton } from '@components/ProfileIconButton';
import { useAuth } from '@hooks/useAuth';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useNavigate } from 'react-router-dom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateInfo } from 'src/@utils/noti.utils';
import { useAlertNotSupportedHook } from '@hooks/useAlertNotSupportedHook';
import { useLoginBtnHandler } from '@hooks/useLoginBtnHandler';

import { SubNavigationItem } from './SubNavigationItem';

const SubNavigation: FC = () => {
  const navigate = useNavigate();
  const notificationInstance = useRecoilValue(notificationInstanceAtom);
  const { alertPartnership } = useAlertNotSupportedHook();
  const { loginBtnClickHandler } = useLoginBtnHandler();
  const handleBookmark = () => {
    notificateInfo(
      notificationInstance,
      '"Ctrl + D" 버튼을 눌러주세요.',
      '즐겨찾기에 추가됩니다.',
      undefined,
      4,
    );
  };
  const partnershipHandler = () => {
    alertPartnership();
  };

  const { isAuthenticated, isGuest, userProfile, logout } = useAuth();
  const isStorePage = useRecoilValue(isStorePageAtom);

  const logoutBtnClickHandler = () => {
    logout();
  };

  const myPageHandler = () => {
    navigate(isStorePage ? '/flower-store/user/mypage' : '/user/mypage');
  };
  const registerHandler = () => {
    navigate(
      isStorePage ? '/flower-store/signup/terms-agreement' : '/signup/terms-agreement',
    );
  };
  const enquiryHandler = () => {
    navigate(isStorePage ? '/flower-store/enquiry' : '/enquiry');
  };

  return (
    <nav className="flex h-[44px] items-center justify-between p-2 text-12 max-sm:hidden">
      <ul className="flex">
        <div onClick={handleBookmark} className="mr-4 flex cursor-pointer">
          <Icon.Star className="mr-1 w-[16px]" />
          <li>즐겨찾기</li>
        </div>
        <a href="tel:1577-1954">
          <div className="flex">
            <Icon.Phone className=" mr-1 w-[16px] fill-gray-500" />
            <li>1577-1954</li>
          </div>
        </a>
      </ul>
      <ul className="flex items-center ">
        <SubNavigationItem
          text="주문확인"
          className="font-bold"
          onClick={() =>
            navigate(
              isStorePage ? '/flower-store/order-list' : '/flower-store/order-list',
            )
          }
        />
        {isAuthenticated ? (
          <>
            <SubNavigationItem text="로그아웃" onClick={logoutBtnClickHandler} />
            <SubNavigationItem text="마이페이지" onClick={myPageHandler} />
          </>
        ) : (
          <>
            <SubNavigationItem text="로그인" onClick={loginBtnClickHandler} />
            <SubNavigationItem text="회원가입" onClick={registerHandler} />
          </>
        )}
        <SubNavigationItem text="1:1 문의" onClick={enquiryHandler} />
        <SubNavigationItem text="제휴신청" onClick={partnershipHandler} />
        {isAuthenticated && (
          <ProfileIconButton
            isLogin={isAuthenticated}
            isGuest={isGuest}
            name={
              userProfile ? (userProfile.info ? userProfile.info.name : '회원') : '회원'
            }
          />
        )}
      </ul>
    </nav>
  );
};

export { SubNavigation };
