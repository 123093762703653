import { selector } from 'recoil';

import { FuneralHomeInfoListState } from '@api/mongo/interfaces';

import {
  funeralHomeInfoListDataAtom,
  funeralHomeInfoListStatusAtom,
} from './funeralHomeInfo.atom';

export const FuneralHomeInfoListStateSelector = selector<FuneralHomeInfoListState>({
  key: 'funeralHomeInfoListStateSelector',
  get: ({ get }) => {
    return {
      status: get(funeralHomeInfoListStatusAtom),
      data: get(funeralHomeInfoListDataAtom),
    };
  },
});
