import { FC, useCallback, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import {
  Bugo,
  BugoCreate,
  CashAccount,
  CashAccountCreate,
  Fevent,
  FeventCreate,
  Member,
  MemberCreate,
} from '@api/mongo/interfaces';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { getReverseType } from 'src/@utils/getReverseType';
import { useQueryClient } from 'react-query';
import { nestMongoApi } from '@api/mongo/controllers';
import { Gender } from 'src/interfaces';
import { useAuth } from '@hooks/useAuth';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { StepNameEnum } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';

import { defaultMemberInputForm } from './utils/createDefaultMemberInputForm';
import {
  MemberFormInputsEnum,
  MemberIFormInputs,
} from './interface/mournerInfoForm.interface';
import { MournerInfoListWrapper } from './components/MournerInfoList';
import { BasicInfoForm } from '../BasicInfoForm';
import { MobileDescriptionSection } from './components/MobileDescriptionSection';
import { FeventStepper } from '../FeventStepper';

const MournerInfoForm: FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const currentFeventId = useRecoilValue(currentFeventIdAtom);

  const { feventId } = useParams();
  const { bugoRole, userProfile } = useAuth();
  const { data: currentFeventData, status } = useRecoilValue(
    currentFeventStateSelectorFamily(feventId ?? currentFeventId ?? ''),
  );

  const useformMethod = useForm<MemberIFormInputs>({
    defaultValues: { member: defaultMemberInputForm(currentFeventData?.memberOrderList) },
  });

  const { create: createCashAccount, updateById: updateCashAccountById } = nestMongoApi<
    CashAccount,
    CashAccountCreate
  >(`${bugoRole()}/cash-account`);
  const { create: createMember, updateById: updateMemberById } = nestMongoApi<
    Member,
    MemberCreate
  >(`${bugoRole()}/member`);
  const { updateById: updateFeventById } = nestMongoApi<Fevent, FeventCreate>(
    `${bugoRole()}/fevent`,
  );
  const { create: createBugo } = nestMongoApi<Bugo, BugoCreate>(`${bugoRole()}/bugo`);

  const deceasedSex = useMemo(() => {
    return currentFeventData?.deceasedInfo.sex;
  }, [currentFeventData?.deceasedInfo.sex]);

  const equalResult = useCallback(() => {
    return _.isEqual(
      useformMethod.formState.defaultValues?.member,
      useformMethod.getValues().member,
    );
  }, [useformMethod]);

  useEffect(() => {
    if (currentFeventData && status === 'success')
      useformMethod.control._reset({
        member: defaultMemberInputForm(currentFeventData.memberOrderList),
      });
  }, [currentFeventData, status, useformMethod.control]);

  const onSubmit = useCallback(
    async (data: MemberIFormInputs, isStepper?: boolean) => {
      //멤버가 없으면 noti
      if (data.member.length === 0) {
        notificateError(notifiacationInstance, '상주정보가 입력되지않았습니다.');
        return;
      }
      if (!currentFeventId) {
        return;
      }
      const memberOrderList = await Promise.all(
        _.map(data.member, async (item) => {
          const cashAccountDto: CashAccountCreate = {
            account: item[MemberFormInputsEnum.CashAccount],
            bank: item[MemberFormInputsEnum.Bank],
            name: item.accountHolder,
            fevent: currentFeventId,
            isVerified: item.isVerified,
          };
          let cashAccount: CashAccount | null;
          if (item.cashAccountId) {
            cashAccount = (
              await updateCashAccountById(item.cashAccountId, cashAccountDto)
            ).data;
          } else {
            cashAccount = (await createCashAccount(cashAccountDto)).data;
          }

          if (_.isNil(cashAccount)) return;

          const memberDto: MemberCreate = {
            cashAccount: cashAccount._id,
            cashAccountModalConfirmed: false, //! 이게뭐지?
            fevent: currentFeventId,
            fullName: item[MemberFormInputsEnum.Name],
            phoneNumber: item[MemberFormInputsEnum.PhoneNumber],
            privacyAgreement: false,
            relationType: item[MemberFormInputsEnum.RelationType],
            reverseType: getReverseType(
              item[MemberFormInputsEnum.RelationType],
              deceasedSex ?? Gender.Male,
            ),
            shortName: item[MemberFormInputsEnum.Name],
            bugo: undefined,
          };
          let member: Member | null;
          if (item._id) {
            member = (await updateMemberById(item._id, memberDto)).data;
          } else {
            member = (await createMember(memberDto)).data;
            //TODO bugo create
            if (_.isNil(member) || _.isNil(userProfile)) return;
            const bugoDto: BugoCreate = {
              user: userProfile._id,
              fevent: currentFeventId,
              member: member._id,
              bugoBrand: userProfile.bugoBrand,
              schedule: {
                courtesySend: false,
              },
              config: {
                isEnable: true,
              },
            };
            await createBugo(bugoDto);
          }

          if (_.isNil(member)) return;

          return member._id;
        }),
      );
      function isDefined<T>(argument: T | undefined | null): argument is T {
        return argument !== undefined && argument !== null;
      }

      const { data: fevent } = await updateFeventById(currentFeventId, {
        memberOrderList: _.filter(memberOrderList, isDefined<string>),
      });
      console.log('fevent: ', fevent);
      // ! 변경사항 있으면 noti
      if (!equalResult()) {
        notificateSuccess(notifiacationInstance, '저장되었습니다.');
      }
      queryClient.invalidateQueries(feventByIdHookUrl(currentFeventId, bugoRole()));
      if (!isStepper) navigate(`../${StepNameEnum.BugoSend}`);
    },
    [
      bugoRole,
      createBugo,
      createCashAccount,
      createMember,
      currentFeventId,
      deceasedSex,
      equalResult,
      navigate,
      notifiacationInstance,
      queryClient,
      updateCashAccountById,
      updateFeventById,
      updateMemberById,
      userProfile,
    ],
  );

  const onSubmitError: SubmitErrorHandler<MemberIFormInputs> = useCallback(() => {
    notificateError(notifiacationInstance, '빨간 테두리 영역은 필수 정보입니다.');
  }, [notifiacationInstance]);

  const mournerInfoListWrapper = useMemo(() => {
    if (_.isNil(currentFeventData)) return <></>;
    return (
      <MournerInfoListWrapper
        useformMethod={useformMethod}
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
      />
    );
  }, [currentFeventData, onSubmit, onSubmitError, useformMethod]);

  return (
    <>
      <FeventStepper
        checkEqual={equalResult}
        onSubmit={useformMethod.handleSubmit((data) => {
          onSubmit(data, true);
        }, onSubmitError)}
        trigger={useformMethod.trigger}
      />
      <MobileDescriptionSection />
      <BasicInfoForm
        title="상주 정보"
        desc="상주님 정보를 작성해주세요.  접기 기능을 통해 등록된
      상주님들을 쉽게 확인하실 수 있습니다.
      또한 드래그하여 순서를 쉽게 변경 가능합니다. 
      계좌정보 작성 후 계좌확인 버튼을 클릭하셔서 계좌번호를 
      인증해주세요.
      등록한 계좌번호를 계좌선택을 통해 인증 없이 재사용 
      가능합니다. "
      >
        {mournerInfoListWrapper}
      </BasicInfoForm>
    </>
  );
};

export { MournerInfoForm };
