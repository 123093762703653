import { useEffect } from 'react';

import { MobileNavigation } from '@components/MobileNavigation';
import { LoginForm } from '@containers/LoginForm';
import { KaKaoLoginBtnSet } from '@containers/LoginForm/components/KaKaoLoginBtnSet';
import { StrangerLoginBtnSet } from '@containers/LoginForm/components/StrangerLoginBtnSet';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { Body, Header } from '@layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoginFormTopLogo } from '@containers/LoginForm/components/LoginFormTopLogo';
import { useLoginBtnHandler } from '@hooks/useLoginBtnHandler';
import { useAuth } from '@hooks/useAuth';

const LoginPage = () => {
  const { redirectUrl } = useLoginBtnHandler();
  const [searchParams] = useSearchParams();
  const purpose = searchParams.get('purpose');

  const navigate = useNavigate();
  const { isAuthenticated, redirectAfterLogin } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectUrl ?? redirectAfterLogin ?? '/');
    }
  }, [isAuthenticated, navigate, redirectAfterLogin, redirectUrl]);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header>
        <div className="before:content-'' mx-auto mt-[135px] h-[437px] w-[352px] text-gray-700 before:absolute before:inset-0 before:-z-10 before:bg-gray-50 max-sm:mt-[112px] max-sm:h-[549px] max-sm:w-full max-sm:px-2">
          <LoginFormTopLogo />
          <LoginForm />
          <KaKaoLoginBtnSet />
          {purpose === 'bugo' && <StrangerLoginBtnSet />}
        </div>
      </Body>
    </>
  );
};

export { LoginPage };
