import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { MyPageContent } from '@containers/BugoMyPageContent/components/MyPageContent';
import { MyPageWrapper } from '@containers/BugoMyPageContent/components/MyPageWrapper';
import { UserEnquiryContent } from '@containers/BugoMyPageContent/components/UserEnquiryContent';
import _ from 'lodash';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useUserEnquiryListHook } from '@containers/BugoMyPageContent/components/UserEnquiryContent/state/userEnquiry.query';
import { userEnquiryListStateSelector } from '@containers/BugoMyPageContent/components/UserEnquiryContent/state/userEnquiry.selector';
import { Loading } from '@components/Loading';
import { Icon } from '@components/Icon';
import { CustomerBox } from '@containers/BugoMyPageContent/components/UserEnquiryContent/components/CustomerBox';
import { Button } from '@components/Button';
import { useNavigate } from 'react-router-dom';

const UserEnquiryPage: FC = () => {
  const navigate = useNavigate();
  useUserEnquiryListHook();
  const { data, status } = useRecoilValue(userEnquiryListStateSelector);

  const isStorePage = useRecoilValue(isStorePageAtom);

  useMobileNavTitleHook(
    '문의내역',
    true,
    false,
    isStorePage ? '/flower-store/user/mypage' : '/user/mypage',
  );

  return (
    <MyPageWrapper className="h-full">
      <MyPageContent>
        <Button
          onClick={() => {
            navigate(isStorePage ? '/flower-store/enquiry' : '/enquiry');
          }}
          className="mb-3 w-full rounded border-none bg-primary py-3 text-white shadow-none"
        >
          문의하기
          <Icon.ModifyWhite className="ml-1 h-6 w-6 stroke-none" />
        </Button>
        {status == 'success' && data ? (
          <UserEnquiryContent enquriyData={data} />
        ) : (
          <Loading />
        )}
      </MyPageContent>
      <CustomerBox />
    </MyPageWrapper>
  );
};

export { UserEnquiryPage };
