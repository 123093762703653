import { FC, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import TablePagination from '@components/TablePagination';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import _ from 'lodash';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

import { MyEnquiryList } from './components/MyEnquiryList';
import { MyPageContent } from '../MyPageContent';
import { WelcomeMessage } from '../MyPageHeaderSection/WelcomeMessage';
import { MyEnquiryInfoBox } from './components/MyEnquriyInfoBox';
import { MyPageTempItem } from '../UserBugoHistroryContent/components/MyPageTempItem';
import { UserEnquiryList } from './state/userEnquiry.interface';
import { isAnyItemOpenSelector } from './state/userEnquiry.selector';

type UserEnquiryContentProps = {
  enquriyData: UserEnquiryList;
};

const UserEnquiryContent: FC<UserEnquiryContentProps> = ({ enquriyData }) => {
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const chunkedData = _.chunk(enquriyData, itemsPerPage);
  const currentPageData = chunkedData[currentPage - 1] || [];
  const adjustedPageLength = Math.ceil(enquriyData.length / itemsPerPage);

  const isStorePage = useRecoilValue(isStorePageAtom);
  const isAnyItemOpen = useRecoilValue(isAnyItemOpenSelector(enquriyData));

  //접수, 답변 갯수
  const reciveCount = enquriyData.filter((item) => item.answer !== 'Done').length;
  const answerCount = enquriyData.filter((item) => item.answer === 'Done').length;
  const isMobileMedia = useMobileMediaQuery();

  //문의글이 pc에선 9개, 모바일에선 6개까지 보이도록
  useEffect(() => {
    if (isMobileMedia) {
      setItemsPerPage(6);
    } else {
      setItemsPerPage(9);
    }
  }, [isMobileMedia]);

  useMobileNavTitleHook(
    '문의내역',
    true,
    false,
    isStorePage ? '/flower-store/user/mypage' : '/user/mypage',
  );

  return (
    <>
      <div className="flex w-full flex-col gap-3 pb-4">
        <WelcomeMessage className="hidden max-sm:flex max-sm:items-center max-sm:justify-center max-sm:gap-2 " />
        <MyEnquiryInfoBox
          answerCount={answerCount}
          receiveCount={reciveCount}
          className="max-w-full  justify-around"
        />
      </div>

      <MyPageContent>
        <div className="flex flex-col ">
          <div className="mb-8 flex flex-col gap-2">
            {currentPageData.length > 0 ? (
              <MyEnquiryList enquiryData={currentPageData} />
            ) : (
              <MyPageTempItem text="문의 내역이 없습니다." />
            )}
          </div>
          <TablePagination
            pageLength={adjustedPageLength}
            selectedPage={currentPage}
            setSelectedPage={setCurrentPage}
          />
          {!isAnyItemOpen && (
            <p className="font-mediu4 pt-8">최근 1년간 문의내역만 조회 가능합니다.</p>
          )}
        </div>
      </MyPageContent>
    </>
  );
};

export { UserEnquiryContent };
