import { selectorFamily } from 'recoil';

import { QueryDataState } from 'src/interfaces';

import {
  flowerStoreOrderDetailDataAtomFamily,
  flowerStoreOrderDetailStatusAtomFamily,
} from './flowerStoreOrderContent.atom';
import { OrderDetail } from './flowerStoreOrderContent.inteface';

const flowerStoreOrderDetailStateSelectorFamily = selectorFamily<
  QueryDataState<OrderDetail>,
  string
>({
  key: 'flowerStoreOrderDetailStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(flowerStoreOrderDetailStatusAtomFamily(id)),
        data: get(flowerStoreOrderDetailDataAtomFamily(id)),
      };
    },
});

export { flowerStoreOrderDetailStateSelectorFamily };
