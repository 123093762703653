const modalTextContents = {
  isNotUpdated: `변경사항이 존재하지 않습니다.`,

  isAddPhoneUpdated: `변경된 번호 및 추가된 상주님께 부고장이 전송되었습니다.
          
    지인분들께 공유해주세요. 감사합니다.`,

  isPhoneNumberUpdated: `변경된 번호로 부고장이 전송되었습니다. 
    
    지인분들께 공유해주세요. 감사합니다.`,

  isAdded: `추가된 상주님께 부고장이 전송되었습니다.
    
    지인분들께 공유해주세요. 감사합니다.`,

  isTextUpdated: `변경된 정보 저장에 성공하였습니다.
  
    기존 상주님의 부고장에는 수정사항이 자동 반영되었습니다. 다시 재공유하실 필요 없습니다. 
  
    감사합니다.`,

  isAddedTextUpdated: `추가된 상주님께 부고장이 전송되었습니다.
  
  지인분들께 공유해주세요.

  기존 상주님의 부고장에는 수정사항이 자동 반영되었습니다.
  
  다시 재공유하실 필요 없습니다. 
  
  감사합니다.`,

  isPhoneTextUpdated: `변경된 번호로 부고장이 전송되었습니다. 
    
  지인분들께 공유해주세요.
    
  다른 상주님의 부고장에는 수정사항이 자동 반영되었습니다.
  
  다시 재공유하실 필요 없습니다. 
  
  감사합니다.`,

  addPageOnly: `부고장이 성공적으로 전송되었습니다. 
  
  받으신 부고장을 지인분들께 공유해주세요. 
  
  감사합니다.`,
};

export { modalTextContents };
