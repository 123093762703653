import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { FeventDataHaveMemberOrderList } from './courtesySendForm.interface';

const isSubmitAtom = atom({
  key: 'isSubmitAtom',
  default: false,
});

const feventDataForCourtesySendPageAtomFamily = atomFamily<
  FeventDataHaveMemberOrderList,
  string
>({
  key: 'feventDataForCourtesySendPageAtomFamily',
  default: undefined,
});
const feventDataStatusForCourtesySendPageAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventDataStatusForCourtesySendPageAtomFamily',
  default: 'idle',
});

export {
  isSubmitAtom,
  feventDataForCourtesySendPageAtomFamily,
  feventDataStatusForCourtesySendPageAtomFamily,
};
