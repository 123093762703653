import { selectorFamily } from 'recoil';

import { QueryDataState } from 'src/interfaces';

import { BugoData, BugoState, Member } from '../../../api/mongo/interfaces';
import {
  bugoDataAtomFamily,
  bugoStatusAtomFamily,
  memberBugoDataAtomFamily,
  memberBugoStatusAtomFamily,
} from './bugo.atom';

export const bugoStateSelectorFamily = selectorFamily<BugoState, string>({
  key: 'bugoStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(bugoStatusAtomFamily(id)),
        data: get(bugoDataAtomFamily(id)),
      };
    },
});

export const memberBugoDataStateSelectorFamily = selectorFamily<
  QueryDataState<Member<string, BugoData>>,
  string
>({
  key: 'memberBugoDataStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(memberBugoStatusAtomFamily(id)),
        data: get(memberBugoDataAtomFamily(id)),
      };
    },
});
