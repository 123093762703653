import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';

const TempContent = ({ navTitle }: { navTitle: string }) => {
  useMobileNavTitleHook(navTitle, true);

  return (
    <div className=" center-box w-full text-20 text-gray-500 max-sm:h-[200px]">
      준비 중입니다
    </div>
  );
};

export { TempContent };
