import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  currentMemberDataAtomFamily,
  currentMemberStatusAtomFamily,
} from './courtesy.atom';

const populateBaseByMemberId = [{ path: 'fevent' }];

const memberByIdHookUrl = (id: string, role: string) =>
  `${role}/member/${id}/?${queryString({ populate: populateBaseByMemberId })}`;

const useMemberByIdHook = (id: string, disabled?: boolean) => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    currentMemberStatusAtomFamily(id),
    currentMemberDataAtomFamily(id),
    memberByIdHookUrl(id, bugoRole()),
    fetcher,
    disabled,
  );
};

export { memberByIdHookUrl, useMemberByIdHook };
