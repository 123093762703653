import { CheckBox } from '@components/CheckBox';
import { Label } from '@components/Label';
import { CustomCallback } from 'src/interfaces';

const AccountHolderSameButton = ({
  checked,
  onCheckedChange,
}: {
  checked: boolean;
  onCheckedChange: CustomCallback<boolean, any>;
}) => {
  return (
    <div>
      <Label>예금주 동일</Label>
      <label className="flex gap-2 rounded border py-2 px-4">
        <CheckBox checked={checked} onCheckedChange={onCheckedChange} />
        <span className={` shrink-0 ${checked ? '' : 'text-gray-400'} `}>
          예금주 동일
        </span>
      </label>
    </div>
  );
};

export { AccountHolderSameButton };
