import { CashAccount } from '@api/mongo/interfaces';
import { api_ } from '@plugin/axios';
import { AxiosResponse } from 'axios';
import { FilterQuery, QueryOptions } from 'mongoose';

export type PopbillVerifyResponse = {
  isVerified: boolean;
  message: string;
};

export type CashAccountApiParams = {
  id: string;
  filter?: FilterQuery<CashAccount>;
  options?: QueryOptions;
};

export type NestCashAccountApi = (endpoint: string) => {
  verify: (
    params?: Omit<CashAccountApiParams, 'id'>,
  ) => Promise<AxiosResponse<PopbillVerifyResponse[]>>;

  verifyById: (id: string) => Promise<AxiosResponse<PopbillVerifyResponse>>;
};

const nestCashAccountApi: NestCashAccountApi = (endpoint: string) => {
  const verify = async (params?: Omit<CashAccountApiParams, 'id'>) => {
    return await api_.patch(`${endpoint}verify`, { params });
  };

  const verifyById = async (id: string) => {
    return await api_.patch(`${endpoint}/${id}/verify`);
  };

  return {
    verify,
    verifyById,
  };
};

export { nestCashAccountApi };
