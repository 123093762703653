import { FC } from 'react';

import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { useNavigate } from 'react-router-dom';

type MournerListItemProps = {
  feventId: string;
  bugoId: string;
  currentBugoId: string;
  memberName: string;
};

const MournerListItem: FC<MournerListItemProps> = ({
  feventId,
  bugoId,
  currentBugoId,
  memberName,
}) => {
  const navigate = useNavigate();
  const bugoPreviewTo = () => {
    navigate(`/fevent/${feventId}/bugo/preview?bugoId=${bugoId}`, { replace: true });
  };

  return (
    <>
      <Button
        className={`center-box relative min-h-[44px] w-full rounded border shadow-sm ${
          currentBugoId === bugoId
            ? 'border-transparent bg-apricot-600 text-white'
            : 'bg-white'
        }`}
        onClick={bugoPreviewTo}
      >
        <div className="">
          {currentBugoId === bugoId && (
            <Icon.CircleCheckWhite className=" absolute left-2 top-2 h-[27px] w-[27px] stroke-none" />
          )}
          <p>{`상주 ${memberName}`}</p>
        </div>
      </Button>
    </>
  );
};

export { MournerListItem, MournerListItemProps };
