const AgreementTextWrapper = ({ text, title }: { text: string; title: string }) => {
  return (
    <div className="whitespace-pre-line p-4 pt-0 ">
      <p className="pb-4 text-center text-16 font-bold text-black">{title}</p>
      <div className="max-h-[40vh] max-w-[640px] overflow-y-scroll">{text}</div>
    </div>
  );
};

export { AgreementTextWrapper };
