const MainMiddleBanner = () => {
  return (
    <div className="pb-[72px] max-sm:pb-10">
      <div
        className="flex h-fit w-full justify-between break-keep py-4 px-9 text-white max-sm:h-fit max-sm:flex-wrap max-sm:py-1"
        style={{
          background:
            'linear-gradient(92.63deg, rgba(55, 65, 81, 0.78) 0%, #374151 10.75%, #374151 25.84%, rgba(55, 65, 81, 0.9) 49.46%, #374151 83.15%, #374151 84.41%, rgba(55, 65, 81, 0.78) 100%)',
        }}
      >
        <div className="pr-5 text-left ">
          <div className=" mb-8 mt-7 text-24">
            <p className="font-bold">쉽고 간편하게</p>
            <p>부고장을 만들 수 있습니다.</p>
          </div>
          <div className="mb-7 text-16 font-medium">
            <p className="mb-2">
              가온부고는 정중하고 기품있는 부고 알림 서비스를 무료로 제공합니다.
            </p>
            <p>
              사랑하는 사람과의 이별에 누가 되지 않도록, 가족처럼 마음을 담아 정성을
              다하겠습니다.
            </p>
          </div>
        </div>

        <div className="py-6 text-left text-16 font-medium">
          <p className="mb-2">
            ※ 부고장 작성만 해도
            <span className="font-bold text-[#FB923C]">할인쿠폰 </span>
            증정
          </p>
          <p>
            ※ 부고장에서 근조화환이 판매되면
            <span className="font-bold text-[#FB923C]"> 포인트</span>로 돌려드립니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export { MainMiddleBanner };
