import { useState } from 'react';

import Tabs from '@components/Tabs';
import { Link } from 'react-router-dom';

import { FlowerStoreFAQTabContentList } from './components/FlowerStoreFAQTabContentList';

const FlowerStoreFAQContent = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="max-sm:m-[-1rem]">
      <div className="max-sm:bg-white">
        <Tabs
          items={['주문/결제', '취소/환불', '반품/교환']}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <FlowerStoreFAQTabContentList selectedTab={selectedTab} />
      <div className="hidden max-sm:block">
        <p className=" mt-8 mb-3 text-12 font-medium">
          그 외 궁금하신 점은 1:1 문의를 이용해 주세요
        </p>
        <Link to="/flower-store/enquiry">
          <button className="w-[160px] rounded bg-gray-800 px-4 py-3 text-white">
            1:1문의
          </button>
        </Link>
      </div>
    </div>
  );
};

export { FlowerStoreFAQContent };
