import { useRecoilValue } from 'recoil';

import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateInfo, notificateWarning } from 'src/@utils/noti.utils';

const useAlertNotSupportedHook = () => {
  const NotificationInstance = useRecoilValue(notificationInstanceAtom);
  const alertNotSupported = () =>
    notificateWarning(NotificationInstance, '준비중인 서비스 입니다.');

  const alertPartnership = () => {
    notificateInfo(
      NotificationInstance,
      '1577-1954',
      '제휴신청은 연락 부탁드립니다.',
      undefined,
      4,
    );
  };

  return { alertNotSupported, alertPartnership };
};

export { useAlertNotSupportedHook };
