import { FC } from 'react';

interface GuestLoginFormTitleProps {
  purpose: string | null;
}

const GuestLoginFormTitle: FC<GuestLoginFormTitleProps> = ({ purpose }) => {
  const setPageTitle = () => {
    switch (purpose) {
      case 'bugo':
        return '부고 수정하기';
      case 'courtesy':
        return '답례글 보내기';
      default:
        return '';
    }
  };
  return (
    <>
      <h2 className="text-36 font-bold text-black">{setPageTitle()}</h2>
      <h3 className="mt-6 text-24 font-medium text-black">작성자 정보로 검색</h3>
    </>
  );
};

export { GuestLoginFormTitle };
