import gql from 'graphql-tag';
import { Count } from 'src/graphql/interface';

const UPDATE_COUNT_BY_PK = gql`
  mutation UpdateCountByPk($id: uuid = "", $inc: Int = 0) {
    update_Count_by_pk(pk_columns: { id: $id }, _inc: { curr: $inc }) {
      curr
    }
  }
`;

type UPDATE_COUNT_BY_PK_VARIABLES = {
  id: string;
  inc: number;
};

type UPDATE_COUNT_BY_PK_RES = {
  update_Count_by_pk: Omit<Count, 'id' | '__typename'>;
};

export { UPDATE_COUNT_BY_PK, UPDATE_COUNT_BY_PK_VARIABLES, UPDATE_COUNT_BY_PK_RES };
