import { atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { OrderDetail } from './flowerStoreOrderContent.inteface';

const flowerStoreOrderDetailDataAtomFamily = atomFamily<OrderDetail, string>({
  key: 'flowerStoreOrderDetailDataAtomFamily',
  default: undefined,
});
const flowerStoreOrderDetailStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'flowerStoreOrderDetailStatusAtomFamily',
  default: 'idle',
});

export { flowerStoreOrderDetailDataAtomFamily, flowerStoreOrderDetailStatusAtomFamily };
