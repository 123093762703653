import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  flowerStoreOrderDetailDataAtomFamily,
  flowerStoreOrderDetailStatusAtomFamily,
} from './flowerStoreOrderContent.atom';

const orderPopulate = [
  {
    path: 'user',
  },
  {
    path: 'funeralHomeInfo',
  },
  {
    path: 'orderDetail.shopItem',
    populate: [
      {
        path: 'shopItemInfo',
        populate: [
          {
            path: 'image',
          },
        ],
      },
    ],
  },
];

const orderByIdUrl = (id: string, role: string) =>
  `${role}/shop-order/${id}?${queryString({
    populate: orderPopulate,
  })}`;

const useFlowerStoreOrderByIdHook = (id: string, disabled?: boolean) => {
  const { storeRole } = useAuth();
  useRecoilQuery(
    flowerStoreOrderDetailStatusAtomFamily(id),
    flowerStoreOrderDetailDataAtomFamily(id),
    orderByIdUrl(id, storeRole()),
    fetcher,
    disabled,
  );
};

export { useFlowerStoreOrderByIdHook, orderByIdUrl };
