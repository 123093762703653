import { FC, HTMLAttributes } from 'react';

interface SubNavigationItemProps extends HTMLAttributes<HTMLLIElement> {
  text: string;
  className?: string;
}

const SubNavigationItem: FC<SubNavigationItemProps> = ({ text, className, ...props }) => {
  return (
    <li
      className={`cursor-pointer break-keep py-1 px-4 max-md:px-2 ${className}`}
      {...props}
    >
      {text && text}
    </li>
  );
};

export { SubNavigationItem };
