import React, { FC } from 'react';

import { Icon } from '@components/Icon';
import _ from 'lodash';

interface IProps {
  pageLength: number;
  selectedPage: number;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
}

const TablePagination: FC<IProps> = ({
  pageLength,
  selectedPage,
  setSelectedPage,
}: IProps) => {
  const { ChevronRight } = Icon;

  const pageArray = Array.from({ length: pageLength }, (_, i) => i + 1);
  const chunkedPage = _.chunk(pageArray, 5);
  const pageCursor = Math.floor((selectedPage - 1) / 5);
  const result = chunkedPage[pageCursor];

  return (
    <div className="flex items-center justify-center self-center">
      {/* Before Button */}
      {pageCursor > 0 ? (
        <button
          key="pageBefore"
          onClick={() => {
            setSelectedPage(pageCursor * 5);
          }}
        >
          <ChevronRight className="rotate-180 stroke-none" />
        </button>
      ) : (
        <div className="h-6 w-6"></div>
      )}
      {/* Page List */}
      {_.map(result, (item) => {
        if (item === selectedPage) {
          return (
            <button
              key={`page-button-${item}`}
              className="center-box h-7 w-7 rounded-full bg-primary  font-medium text-white"
              onClick={() => setSelectedPage(item)}
            >
              <p>{item}</p>
            </button>
          );
        }
        return (
          <button
            key={`page-button-${item}`}
            className="center-box h-7 w-7 "
            onClick={() => setSelectedPage(item)}
          >
            {item}
          </button>
        );
      })}
      {/* After Button */}
      {pageCursor + 1 < chunkedPage.length ? (
        <button
          key="pageAfter"
          onClick={() => {
            setSelectedPage(pageCursor * 5 + 6);
          }}
        >
          <ChevronRight className=" stroke-none" />
        </button>
      ) : (
        <div className="h-6 w-6"></div>
      )}
    </div>
  );
};

export default React.memo(TablePagination);
