import { ShopItemForStoreData, BugoData, ShopOrder, User } from '@api/mongo/interfaces';

export type FormContextType = {
  condolences: string;
  mournerName: string;
  orderName: string;
  phoneNumber: string;
  sender: string;
  address: string;
  point: number;
};

export enum CheckOutState {
  IDLE = 'IDLE',
  DOING = 'DOING',
  DONE = 'DONE',
}

export interface ShopOrderParams {
  formData: FormContextType;
  shopItem: ShopItemForStoreData;
  bugo?: BugoData;
  userId: string;
}

export interface IParams {
  formData: FormContextType;
  shopOrder: ShopOrder;
  shopItem: ShopItemForStoreData;
  userProfile: User<string>;
}
