import { selector } from 'recoil';

import {
  roomListDataAtom,
  roomListStatusAtom,
  roomDataAtom,
  roomStatusAtom,
} from './room.atom';
import { RoomListState, RoomState } from './room.interface';

export const roomStateSelector = selector<RoomState>({
  key: 'roomStateSelector',
  get: ({ get }) => {
    return {
      status: get(roomStatusAtom),
      data: get(roomDataAtom),
    };
  },
});

export const roomListStateSelector = selector<RoomListState>({
  key: 'roomListStateSelector',
  get: ({ get }) => {
    return {
      status: get(roomListStatusAtom),
      data: get(roomListDataAtom),
    };
  },
});
