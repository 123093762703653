import { useCallback, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { nestAuthApi } from '@api/mongo/controllers';
import { tokenSelector } from '@state/atom/auth.atom';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { PasswordInput } from '@components/PasswordInput';

import { ciUserAtom } from '../state/ciUser.atom';
import { impUidAtom } from '../state/imp.atom';

type FormValues = {
  password: string;
  confirmPassword: string;
};
const { loginByCi } = nestAuthApi();

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const ciUser = useRecoilValue(ciUserAtom);
  const impUid = useRecoilValue(impUidAtom);
  const isStorePage = useRecoilValue(isStorePageAtom);
  const setToken = useSetRecoilState(tokenSelector);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const {
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
  });

  const passwordWatch = watch('password', '');
  const confirmPasswordWatch = watch('confirmPassword', '');
  const passwordMatch = passwordWatch === confirmPasswordWatch;

  const setCiToken = useCallback(
    async (ciUserId: string, impUid: string) => {
      try {
        const { data } = await loginByCi({
          _id: ciUserId,
          imp_uid: impUid,
        });
        setToken(data.token);
      } catch (error) {
        console.log(error);
      }
    },
    [setToken],
  );

  useEffect(() => {
    trigger('confirmPassword');
  }, [passwordWatch, confirmPasswordWatch, trigger]);

  useEffect(() => {
    if (!ciUser) navigate(0);
  }, [ciUser, navigate]);

  useEffect(() => {
    if (!impUid) return;
    setCiToken(ciUser?._id ?? '', impUid);
  }, [ciUser, impUid, setCiToken]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await nestAuthApi().patchPassword({ password: data.password });
      navigate(isStorePage ? 'flower-store/login' : '/login');
      notificateSuccess(notifiacationInstance, '비밀번호 변경에 성공하였습니다.');
    } catch (error) {
      notificateError(notifiacationInstance, '비밀번호 변경에 실패하였습니다.');
    }
  };

  return (
    <form className="mt-6 text-left text-12" onSubmit={handleSubmit(onSubmit)}>
      <p className=" ">
        회원님의 아이디는 <span className="font-bold text-black">{ciUser?.username}</span>{' '}
        입니다.
      </p>
      <p className="mt-6 mb-2">새 비밀번호를 입력해주세요</p>
      <PasswordInput
        placeholder="새 비밀번호"
        className="px-4 text-16"
        isError={!!errors.password}
        errorMessage={errors.password?.message}
        {...register('password', {
          required: { value: true, message: '비밀번호를 입력해주세요.' },
          maxLength: {
            value: 20,
            message: '비밀번호는 최소 5자, 최대 20자이어야 합니다.',
          },
          minLength: {
            value: 5,
            message: '비밀번호는 최소 5자, 최대 20자이어야 합니다.',
          },
        })}
      />
      <PasswordInput
        placeholder="새 비밀번호 확인"
        className="mt-3 px-4 text-16"
        isError={!!errors.confirmPassword}
        {...register('confirmPassword', {
          validate: (value) => value === passwordWatch || '비밀번호가 일치하지 않습니다.',
        })}
      />
      {!passwordMatch && (
        <span className="text-10 text-red-500">비밀번호가 일치하지 않습니다.</span>
      )}
      <button
        className="mt-6 w-full rounded bg-primary px-4 py-3 text-14 font-bold text-white disabled:cursor-not-allowed disabled:bg-gray-300"
        disabled={passwordMatch ? false : true}
      >
        확인
      </button>
    </form>
  );
};

export { ResetPasswordForm };
