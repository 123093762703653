import { FC, Fragment, ReactNode } from 'react';

import { BasicInput } from '@components/BasicInput';
import { DateInput } from '@components/DateInput';
import { TextAreaInput } from '@components/TextAreaInput';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import {
  DateInfo,
  DateTimeInputConfig,
  FeventFormData,
} from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import { useFormContext, useWatch } from 'react-hook-form';
import TimePicker from '@components/TimePicker';
import dayjs from 'dayjs';

import {
  dateChangeHandler,
  timeChanegeHandler,
  translateTime,
} from './utils/DeaceasedDateInfoForm.utils';

interface DeceasedDateInfoFormProps {
  children?: ReactNode;
}

const DeceasedDateInfoForm: FC<DeceasedDateInfoFormProps> = () => {
  const { register, setValue } = useFormContext<FeventFormData>();
  // watch 함수를 이용해 date와 time 필드 값을 감시함
  const deathInfo: DateInfo = useWatch({ name: 'deceasedInfo.death' });
  const coffinInInfo: DateInfo = useWatch({ name: 'deceasedInfo.coffinIn' });
  const coffinOutInfo: DateInfo = useWatch({ name: 'deceasedInfo.coffinOut' });
  const enteranceInfo: DateInfo = useWatch({ name: 'deceasedInfo.enterance' });

  const dateTimeInputConfig: DateTimeInputConfig[] = [
    {
      info: deathInfo,
      date: 'deceasedInfo.death.date',
      timeDefined: 'deceasedInfo.death.timeDefined',
      label: '별세',
    },
    {
      info: enteranceInfo,
      date: 'deceasedInfo.enterance.date',
      timeDefined: 'deceasedInfo.enterance.timeDefined',
      label: '입실',
    },
    {
      info: coffinInInfo,
      date: 'deceasedInfo.coffinIn.date',
      timeDefined: 'deceasedInfo.coffinIn.timeDefined',
      label: '입관',
    },
    {
      info: coffinOutInfo,
      date: 'deceasedInfo.coffinOut.date',
      timeDefined: 'deceasedInfo.coffinOut.timeDefined',
      label: '발인',
    },
  ];

  return (
    <>
      <BasicInfoForm
        title="장례 상세 정보"
        desc="부고장에 표시될 장례 일정, 장지, 상주님 말씀 등을 작성해주세요. 작성하지 않으신 정보는 미표시됩니다."
      >
        {/* 640px 모바일 레이아웃 전환 */}
        <div className="grid grid-cols-4 gap-4 max-sm:grid-cols-2 max-sm:gap-x-1 max-sm:gap-y-2">
          {dateTimeInputConfig.map((dateTimeInputConfig) => {
            const { date, info, label, timeDefined } = dateTimeInputConfig;
            return (
              <Fragment key={date}>
                <DateInput
                  openId={date}
                  value={info?.date}
                  label={`${label}일`}
                  placeholder={`${label}일`}
                  onClear={() => {
                    setValue(date, null);
                    setValue(timeDefined, false);
                  }}
                  onDateChange={(value) => {
                    dateChangeHandler(info, date, value, setValue);
                  }}
                />

                {/* 
                기존 TimeInput
                <TimeInput
                  value={info ? (info.timeDefined ? info.date : null) : null}
                  openId={timeDefined}
                  onTimeChange={(value) => {
                    console.log('value:', value);
                    timeChanegeHandler(info, timeDefined, date, value, setValue);
                  }}
                  onClear={() => {
                    setValue(timeDefined, false);
                  }}
                  label={`${label}시간`}
                  placeholder={`${label}시간`}
                /> */}
                <TimePicker
                  label={`${label}시간`}
                  openId={`${label}-timePicker`}
                  placeHolder={`${label}시간`}
                  value={
                    info
                      ? info.timeDefined
                        ? dayjs(info.date).format('HH:mm')
                        : null
                      : null
                  }
                  onClear={() => {
                    setValue(timeDefined, false);
                  }}
                  onChange={(timeValue) => {
                    console.log('timeValue:', timeValue);
                    if (timeValue) {
                      timeChanegeHandler(
                        info,
                        timeDefined,
                        date,
                        translateTime(timeValue),
                        setValue,
                      );
                    }
                  }}
                />
              </Fragment>
            );
          })}
        </div>
        <div className="mt-3 flex flex-col gap-3">
          <BasicInput
            label="장지"
            placeholder="장지 (ex 서울추모공원)"
            className="px-4"
            {...register('deceasedInfo.cemetery')}
          />
          <TextAreaInput
            label="상주말씀"
            placeholder="상주 말씀"
            {...register('mournerText')}
          />
        </div>
      </BasicInfoForm>
    </>
  );
};

export { DeceasedDateInfoForm };
