import { FC, ReactNode, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Chip } from '@components/Chip';
import { useParams } from 'react-router-dom';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';

interface FoldableBoxProps {
  children: ReactNode;
  openText?: string;
  name: string;
  lastSentPhoneNumber?: string;
  isUpdated?: boolean;
  defaultFold?: boolean;
}

const FoldableBox: FC<FoldableBoxProps> = ({
  children,
  openText,
  name,
  isUpdated,
  lastSentPhoneNumber,
  defaultFold = false,
}) => {
  const [isFold, setIsFold] = useState(defaultFold);
  const { feventId } = useParams();
  const isEditPage = useRecoilValue(isEditPageAtom);

  const onFoldHandler = () => {
    setIsFold(!isFold);
  };

  return (
    <div
      className={`min-h-[44px] rounded border bg-white py-2 px-4  font-medium ${
        isUpdated && feventId ? 'border-apricot-600' : ''
      }`}
    >
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={() => onFoldHandler()}
      >
        <div className="flex items-center gap-2 text-12 max-sm:gap-1">
          <div className="text-[#000000] ">상주</div>
          <Chip color="orange">{name}</Chip>
          {/*  */}
          {!isEditPage && lastSentPhoneNumber && <Chip color="apricot">전송완료</Chip>}
          {isEditPage ? (
            isUpdated ? (
              <Chip color="red">전송 필요</Chip>
            ) : (
              <Chip>전송 불필요</Chip>
            )
          ) : (
            <></>
          )}
        </div>
        <div className={`text-12 ${!isFold ? 'text-gray-400' : 'text-apricot-500'}`}>
          {openText ? (isFold ? openText : '접기') : '접기'}
        </div>
      </div>
      <div
        className={`overflow-y-hidden transition-[max-height] ${
          isFold ? 'max-h-0' : 'max-h-96'
        } `}
      >
        <div className="my-2 border" />
        {children}
      </div>
    </div>
  );
};

export { FoldableBox };
