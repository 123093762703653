import { PropsWithChildren } from 'react';

import { useSetRecoilState } from 'recoil';

import { useAuth } from '@hooks/useAuth';
import { redirectAfterLoginAtom } from '@state/atom/auth.atom';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface Props extends PropsWithChildren {
  loginUrl?: string;
  children?: React.ReactNode;
}

const ProtectedRoute = ({ loginUrl, children }: Props) => {
  const setRedirectAfterLogin = useSetRecoilState(redirectAfterLoginAtom);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    if (location.pathname.indexOf('login') < 0) {
      setRedirectAfterLogin(`${location.pathname}${location.search}`);
    }
  }

  if (!isAuthenticated) return <Navigate to={loginUrl ?? '/login'} replace />;
  return children ? <>{children}</> : <Outlet />;
};

export { ProtectedRoute };
