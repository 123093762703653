import { selectorFamily } from 'recoil';

import { QueryDataState } from 'src/interfaces';

import { FeventDataHaveMemberOrderList } from './courtesySendForm.interface';
import {
  feventDataForCourtesySendPageAtomFamily,
  feventDataStatusForCourtesySendPageAtomFamily,
} from './courtesySendForm.atom';

const feventStateForCourtesySendPageSelectorFamily = selectorFamily<
  QueryDataState<FeventDataHaveMemberOrderList>,
  string
>({
  key: 'feventDataForCourtesySendPageSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        data: get(feventDataForCourtesySendPageAtomFamily(id)),
        status: get(feventDataStatusForCourtesySendPageAtomFamily(id)),
      };
    },
});

export { feventStateForCourtesySendPageSelectorFamily };
