import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Button, ButtonProps } from '@components/Button';
import { useFormContext } from 'react-hook-form';
import { MemberFormInputsEnum } from 'src/interfaces';
import { modalOpenAtomFamily } from '@state/atom/openAtom';

interface ICashAccountButton extends ButtonProps {
  isVerified: boolean;
  index: number;
}

const CashAccountButton: FC<ICashAccountButton> = ({ isVerified, index, ...props }) => {
  const { setValue } = useFormContext();
  const cashAccountSelectModalId = `cashAccountSelectModal-${index}`;
  const setCashAccountSelectModal = useSetRecoilState(
    modalOpenAtomFamily(cashAccountSelectModalId),
  );

  const accountInfoChoiceHandler = () => {
    setCashAccountSelectModal(true);
  };

  const accountInfoRemoveHandler = () => {
    setValue(`member.${index}.${MemberFormInputsEnum.CashAccount}`, '');
    setValue(`member.${index}.${MemberFormInputsEnum.CashAccountId}`, '');
    setValue(`member.${index}.${MemberFormInputsEnum.Bank}`, '');
    setValue(`member.${index}.isVerified`, false);
  };

  const buttonStyle =
    'bg-white font-bold w-full max-lg:basis-1/2 h-10 mt-3 rounded border shadow-sm text-12 disabled:bg-gray-100 disabled:shadow-none disabled:text-gray-400 disabled:border-none';
  const buttonText = isVerified ? '계좌삭제' : '인증계좌 불러오기';

  return (
    <Button
      className={`${buttonStyle} ${
        isVerified
          ? 'border-red-500 text-red-500 hover:bg-red-500 hover:text-white'
          : 'border leading-[16px]'
      }`}
      {...props}
      onClick={isVerified ? accountInfoRemoveHandler : accountInfoChoiceHandler}
    >
      {buttonText}
    </Button>
  );
};

export { CashAccountButton };
