import {
  currentFeventDataAtomFamily,
  currentFeventStatusAtomFamily,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

const feventByIdPopulate = [
  { path: 'funeralHomeInfo' },
  { path: 'members' },
  {
    path: 'deceasedInfo.dpImage',
  },
  {
    path: 'shopOrders',
    populate: [
      {
        path: 'orderDetail.shopItem',
        populate: [{ path: 'shopItemInfo', populate: [{ path: 'image' }] }],
      },
    ],
  },
  { path: 'cashAccounts' },
  { path: 'memberOrderList', populate: [{ path: 'cashAccount' }, { path: 'bugo' }] },
];

const feventByIdHookUrl = (id: string, role: string) =>
  `${role}/fevent/${id}/?${queryString({ populate: feventByIdPopulate })}`;

const useFeventByIdHook = (id: string, disabled?: boolean) => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    currentFeventStatusAtomFamily(id),
    currentFeventDataAtomFamily(id),
    feventByIdHookUrl(id, bugoRole()),
    fetcher,
    disabled,
  );
};

export { feventByIdHookUrl, useFeventByIdHook };
