import { FC, useEffect, useState } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import paryImg from '@assets/image/courtesy-asset-pray.png';
import skyImg from '@assets/image/courtesy-asset-sky.png';
import thanksImg from '@assets/image/courtesy-asset-thanks.png';
import waveImg from '@assets/image/courtesy-asset-wave.png';
import { Icon } from '@components/Icon';
import { v4 } from 'uuid';

import {
  courtesyAssetIdAtom,
  courtesyAssetNameAtom,
  courtesyAssetSrcAtom,
} from '../state/courtesy.atom';
import { CourtesyAssetItem } from './CourtesyAssetItem';
const assetsImages = [
  {
    _id: v4(),
    url: waveImg,
    name: '파도',
  },
  {
    _id: v4(),
    url: paryImg,
    name: '기도',
  },
  {
    _id: v4(),
    url: skyImg,
    name: '하늘',
  },
  {
    _id: v4(),
    url: thanksImg,
    name: '감사',
  },
];

const CourtesyAssetSelect: FC = () => {
  const [isFold, setIsFold] = useState(false);
  const [courtesyAssetName, setCourtesyAssetName] = useRecoilState(courtesyAssetNameAtom);
  const setCourtesyAssetId = useSetRecoilState(courtesyAssetIdAtom);
  const setCourtesyAssetSrc = useSetRecoilState(courtesyAssetSrcAtom);

  useEffect(() => {
    setCourtesyAssetId(assetsImages[0]?._id as string);
    setCourtesyAssetName(assetsImages[0]?.name as string);
    setCourtesyAssetSrc(assetsImages[0]?.url as string);
  }, [setCourtesyAssetId, setCourtesyAssetName, setCourtesyAssetSrc]);

  return (
    <div
      className={`transition-height flex flex-col gap-4 overflow-y-hidden bg-white px-4 py-2 duration-300 ${
        isFold
          ? 'h-[48px] max-md:h-auto max-md:max-h-[48px]'
          : 'h-[280px] max-md:h-auto max-md:max-h-[280px]'
      }`}
    >
      <div className="flex items-center justify-between">
        <h2 className="text-left text-16 font-bold leading-6 text-black">
          답례이미지 선택
        </h2>
        <div className="flex gap-1">
          <div className="flex w-24 items-center justify-center rounded border bg-white px-4 py-1 shadow-sm">
            {courtesyAssetName}
          </div>
          <Icon.ChevronUp
            className={`cursor-pointer stroke-none transition-all duration-300 ${
              isFold ? 'rotate-180' : ''
            }`}
            onClick={() => {
              setIsFold(!isFold);
            }}
          />
        </div>
      </div>
      <div className={`flex h-full gap-2`}>
        {assetsImages.map((image) => (
          <CourtesyAssetItem image={image} key={image._id} />
        ))}
      </div>
    </div>
  );
};

export { CourtesyAssetSelect };
