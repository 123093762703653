import { MongoApiParams } from '@api/mongo/controllers/common.controller';
import { ShopOrderCreateDto } from '@api/mongo/interfaces';
import { api_ } from '@plugin/axios';
import { AxiosResponse } from 'axios';

export type ShopOrderUpdateDetailStoreCustomerDto = {
  bugoDetail?: {
    condolencePhrase?: string;
    senderPhrase?: string;
  };
  deliveryDetail?: {
    senderAddress?: string;
    senderName?: string;
    senderPhoneNumber?: string;
    receiverAddress?: string;
    receiverPostalCode?: string;
    receiverName?: string;
    receiverPhoneNumber?: string;
  };
};

export type ShopOrderCancelStoreCustomerDto = {
  cancelReason: string;
};

const endpoint = '/store-guest/shop-order';

export type NestShopOrderApi = <Entity>() => {
  findMany: (
    params?: Omit<MongoApiParams<Entity>, 'id'>,
  ) => Promise<AxiosResponse<Entity[]>>;
  findById: (
    id: string,
    params?: Omit<MongoApiParams<Entity>, 'filter'>,
  ) => Promise<AxiosResponse<Entity>>;
  deleteById: (
    id: string,
    params?: Pick<MongoApiParams<Entity>, 'options'>,
  ) => Promise<AxiosResponse<Entity>>;
  create: (body: ShopOrderCreateDto) => Promise<AxiosResponse<Entity>>;
  patchDetail: (
    id: string,
    dto: ShopOrderUpdateDetailStoreCustomerDto,
  ) => Promise<AxiosResponse<Entity>>;
  isSuccesslyPaid: (id: string) => Promise<AxiosResponse<boolean>>;
  confirm: (id: string) => Promise<AxiosResponse<Entity>>;
  cancel: (
    id: string,
    dto: ShopOrderCancelStoreCustomerDto,
  ) => Promise<AxiosResponse<void>>;
};

const nestShopOrderApi: NestShopOrderApi = <Entity>() => {
  const findMany = async (params?: Omit<MongoApiParams<Entity>, 'id'>) => {
    return await api_.get<Entity[]>(endpoint, { params });
  };
  const findById = async (
    id: string,
    params?: Omit<MongoApiParams<Entity>, 'filter'>,
  ) => {
    return await api_.get<Entity>(`${endpoint}/${id}`, { params });
  };
  const deleteById = async (
    id: string,
    params?: Pick<MongoApiParams<Entity>, 'options'>,
  ) => {
    return await api_.delete<Entity>(`${endpoint}/delete/${id}`, { params });
  };
  const create = async (body: ShopOrderCreateDto) => {
    return await api_.post<Entity>(`${endpoint}/create`, body);
  };
  const patchDetail = async (id: string, dto: ShopOrderUpdateDetailStoreCustomerDto) => {
    return await api_.patch<Entity>(`${endpoint}/patch-detail/${id}`, dto);
  };
  const isSuccesslyPaid = async (id: string) => {
    return await api_.get<boolean>(`${endpoint}/success/${id}`);
  };
  const confirm = async (id: string) => {
    return await api_.patch<Entity>(`${endpoint}/${id}`);
  };
  const cancel = async (id: string, dto: ShopOrderCancelStoreCustomerDto) => {
    return await api_.patch<void>(`${endpoint}/cancel/${id}`, { dto });
  };

  return {
    findMany,
    findById,
    deleteById,
    create,
    patchDetail,
    isSuccesslyPaid,
    confirm,
    cancel,
  };
};

export { nestShopOrderApi };
