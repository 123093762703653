import { selector } from 'recoil';

import {
  currentUserFeventListDataAtom,
  currentUserFeventListStatusAtom,
} from './userBugoList.atom';
import { CurrentUserFeventState } from './userBugoList.interface';

const currentUserFeventListStateSelector = selector<CurrentUserFeventState>({
  key: 'currentUserFeventListStateSelector',
  get: ({ get }) => {
    return {
      status: get(currentUserFeventListStatusAtom),
      data: get(currentUserFeventListDataAtom),
    };
  },
});

export { currentUserFeventListStateSelector };
