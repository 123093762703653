import { nestMongoApi } from '@api/mongo/controllers';
import { Fevent } from '@api/mongo/interfaces';
import { useAuth } from '@hooks/useAuth';
import { useQueryClient } from 'react-query';

import { userBugoHistoryHookUrl } from './userBugoHistroryContent.query';

type FeventDelete = {
  _id: string;
};

const useBugoHistoryDeleteHook = () => {
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();
  const { deleteById } = nestMongoApi<Fevent, FeventDelete>(`${bugoRole()}/fevent`);

  const bugoDelete = async (bugoId: string) => {
    await deleteById(bugoId);
    queryClient.invalidateQueries(userBugoHistoryHookUrl(bugoRole()));
  };

  return { bugoDelete };
};

export { useBugoHistoryDeleteHook };
