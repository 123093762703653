import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { FlowerStoreIfnoForm } from '@components/FlowerStoreInfoForm';
import { FlowerStoreSection } from '@components/FlowerStoreSection';
import { StoreItemAccountNotice } from '@containers/FlowerStoreItemDetail/components/StoreItemAccountNotice';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { Body, Header, Footer } from '@layout';
import { FlowerStoreItemCheckout } from '@containers/FlowerStoreItemCheckout';
import { useStoreItemHook } from '@containers/FlowerStoreItemDetail/state/storeItemDetail.hook';
import { storeItemStateSelector } from '@containers/FlowerStoreItemDetail/state/storeItemDetail.selector';
import { useParams } from 'react-router-dom';
import { Loading } from '@components/Loading';
import { useScrollToTopHook } from '@hooks/useScrollToTop';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { privacyPolicyText } from 'src/@utils/textDats';

const ShopItemCheckoutPage = () => {
  const { itemId } = useParams();
  useStoreItemHook(itemId ?? '');
  useScrollToTopHook();
  useMobileNavTitleHook('화환보내기', true);

  const { data, status } = useRecoilValue(storeItemStateSelector);
  const isMobileMedia = useMobileMediaQuery();

  const Notice = useCallback(() => {
    return (
      <>
        <StoreItemAccountNotice
          title="개인정보 취급방침에 대한 동의"
          desc={privacyPolicyText}
        />
        <div className="break-keep py-4 text-left text-12 text-gray-500">
          (주)가온프라임은 통신판매중개자로서, 입점판매자가 등록한 상품정보 및 거래에 대해
          (주)가온프라임은 일체의 책임을 지지 않습니다.
        </div>
      </>
    );
  }, []);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <FlowerStoreSection title="화환보내기">
          <FlowerStoreIfnoForm
            title="화환보내기"
            leftSectionTopComponent={isMobileMedia ? null : <Notice />}
          >
            {status === 'success' && data ? <FlowerStoreItemCheckout /> : <Loading />}
            {isMobileMedia && <Notice />}
          </FlowerStoreIfnoForm>
        </FlowerStoreSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { ShopItemCheckoutPage };
