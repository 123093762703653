import { useEffect, useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { useParams } from 'react-router-dom';
import { formatDate } from 'src/@utils/formatDate';
import { useReactToPrint } from 'react-to-print';

import { OrderItem } from './components/OrderItem';
import { OrderTableTitle } from './components/OrderTableTitle';
import { OrderTableWrapper } from './components/OrderTableWrapper';
import { flowerStoreOrderDetailStateSelectorFamily } from '../state/flowerStoreOrderContent.selector';
import { PaymentStatusItem } from './components/PaymentStatusItem';
const OrderDetailContent = () => {
  useMobileNavTitleHook('주문상세정보', true);

  const [printingHiddenComponent, setPrintingHiddenComponent] = useState(false);
  const { orderId } = useParams();
  const { data } = useRecoilValue(
    flowerStoreOrderDetailStateSelectorFamily(orderId ?? ''),
  );
  const printComponentRef = useRef<HTMLDivElement>(null);
  const printPromiseResolveRef = useRef<null | ((value?: boolean) => void)>(null);

  const reactPrintHandler = useReactToPrint({
    content: () => printComponentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        printPromiseResolveRef.current = resolve;
        setPrintingHiddenComponent(true);
      });
    },
    onAfterPrint: () => {
      printPromiseResolveRef.current = null;
      setPrintingHiddenComponent(false);
    },
  });

  const printButtonHandler = async () => {
    reactPrintHandler();
    return;
  };

  useEffect(() => {
    if (printingHiddenComponent && printPromiseResolveRef.current) {
      printPromiseResolveRef.current();
    }
  }, [printingHiddenComponent]);

  return (
    <div
      className={`relative mx-auto max-w-[640px] break-keep bg-white p-4 max-sm:mx-[-1rem] max-sm:bg-transparent max-sm:p-0 ${
        printingHiddenComponent && 'max-sm:mx-[0] max-sm:bg-white max-sm:p-4'
      }`}
      ref={printComponentRef}
    >
      <h1 className="py-8 text-center text-24 font-bold text-apricot-600">{'영수증'}</h1>
      <div className=" flex flex-col gap-8">
        <div>
          <OrderTableTitle title="결제정보" />
          <OrderTableWrapper>
            <div className="grid grid-cols-2">
              <OrderItem title="구매자" value={data?.deliveryDetail.senderName} />
              <OrderItem title="연락처" value={data?.deliveryDetail.senderPhoneNumber} />
            </div>
            <OrderItem title="상품명" value={data?.orderDetail.shopItemEmbed.name} />
            <OrderItem title="이메일" />
            <PaymentStatusItem title="거래상태" value={data?.paymentDetail.status} />
            <OrderItem
              title="완료시각"
              value={formatDate(data?.paymentDetail.purchasedAt, {
                dateSeparater: '.',
                contains: {
                  year: true,
                  hours: true,
                  minutes: true,
                  seconds: true,
                },
              })}
            />
            <OrderItem title="주문번호" value={data?._id} />
            <OrderItem title="과세금액" value="0원" />
            <OrderItem
              title="비과세금액"
              value={`${data?.paymentDetail.paymentByCash.toLocaleString()}원`}
            />
            <OrderItem
              title="합계금액"
              value={`${data?.paymentDetail.paymentByCash.toLocaleString()}원`}
            />
          </OrderTableWrapper>
        </div>
        {/* {
          <div>
            <OrderTableTitle title="카드결제정보" />
            <OrderTableWrapper>
              <div className="grid grid-cols-2">
                <OrderItem title="카드사" value={data?.paymentDetail.method} />
                <OrderItem title="할부" value="일시불" />
              </div>
              <OrderItem title="카드번호" />
              <OrderItem title="승인번호" />
            </OrderTableWrapper>
          </div>
        } */}

        <div>
          <OrderTableTitle title="공급자정보" />
          <OrderTableWrapper>
            <div className="grid grid-cols-2">
              <OrderItem title="회사명" value="(주)가온프라임" />
              <OrderItem title="사업자번호" value="363-86-02183" />
            </div>
            <OrderItem title="대표자명" value="김기훈" />
            <OrderItem
              title="주소"
              value="서울 금천구 가산디지털2로 101 (가산동)
제 B동 14층 1402호"
            />
          </OrderTableWrapper>
        </div>
      </div>
      <div className="left-0 bottom-0 mt-8 flex w-full justify-end max-sm:fixed">
        {!printingHiddenComponent && (
          <Button
            onClick={printButtonHandler}
            className="max-w-[187px] rounded border-none bg-apricot-600 stroke-none py-3 px-16 text-white shadow-none max-sm:w-full max-sm:max-w-full max-sm:rounded-none "
          >
            출력하기
          </Button>
        )}
      </div>
    </div>
  );
};

export { OrderDetailContent };
