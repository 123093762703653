import React, { FC, Suspense } from 'react';

import { IMapProps } from 'src/interfaces';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '@components/ErrorFallback';

// import { Loading } from './Loading';

const MapSourceNaver = React.lazy(() => import('./MapSourceNaver'));

const Map: FC<IMapProps> = function ({ coord, name }: IMapProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={null}>
        <div className="relative z-0 h-full w-full">
          <MapSourceNaver coord={coord} name={name} />
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export { Map };
