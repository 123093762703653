import { useRecoilValue } from 'recoil';

import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { CourtesySendForm } from '@containers/FeventSubmitContent/components/CourtesySendForm';
import { MobileDescriptionSection } from '@containers/FeventSubmitContent/components/CourtesySendForm/components/MobileDescriptionSection';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useFeventForCourtesySendPageHook } from '@containers/FeventSubmitContent/components/CourtesySendForm/state/courtesySendForm.query';
import { feventStateForCourtesySendPageSelectorFamily } from '@containers/FeventSubmitContent/components/CourtesySendForm/state/courtesySendForm.selector';

const CourtesySendPage = () => {
  const { feventId } = useParams();
  useMobileNavTitleHook('답례글 전송', true);
  useFeventForCourtesySendPageHook(feventId ?? '');
  const { data } = useRecoilValue(
    feventStateForCourtesySendPageSelectorFamily(feventId ?? ''),
  );

  console.log(data);

  const autoSendDate = dayjs(data?.deceasedInfo?.coffinOut?.date)
    .add(1, 'day')
    .format('YYYY.MM.DD');

  return (
    <div>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <MainSection title="답례글 링크">
          <BasicInfoForm
            title="답례글 전송"
            desc={`※ 상주님께 답례글 링크를 전송합니다.\n\n 장례식장을 찾아와주신 조문객분들께 답례 인사를 하실 수 있도록 답례글 링크를 보내드립니다.\n\n 답례글 링크는 발인 다음 날 오전 9시에 자동으로 보내지며, 원하시면 이 페이지에서 직접 보내실 수 있습니다.`}
            leftSectionBottomComponent={
              <p className="mb-4 whitespace-pre-line break-keep text-12 text-gray-500 max-sm:hidden">
                자동발송시각:{' '}
                <span className="font-bold text-gray-800">{autoSendDate} 09:00</span>{' '}
                [발인 익일 오전 9시]
              </p>
            }
          >
            <MobileDescriptionSection autoSendDate={autoSendDate} />
            <CourtesySendForm />
          </BasicInfoForm>
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </div>
  );
};

export { CourtesySendPage };
