import { useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@components/Button';
import { bugoPreviewAtom } from '@containers/BugoPreviewPageContent/state/bugoPreview.atom';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Link } from 'react-router-dom';
import logo from 'src/assets/image/bugopage-header-logo.webp';
import { WebShareModal } from '@components/Kakao/WebShareModal';
import {
  bugoMessageContentString,
  bugoKakaoShareContent,
  bugoLinkUrl,
} from '@components/Kakao/utils/bugoMessage.util';
import { BugoData } from '@api/mongo/interfaces';

const ObituaryFooter = ({ bugo }: { bugo: BugoData }) => {
  const openId = `bugo-${bugo._id}`;
  const setShareModalOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  //PreviewPage에서 보고있을 시 footer 스타일 조정
  const bugoPreview = useRecoilValue(bugoPreviewAtom);

  const bottomButton = useMemo(() => {
    return (
      <div
        className={`fixed bottom-0 z-20 flex h-14 w-full max-w-inherit text-14 ${
          bugoPreview && 'relative'
        }`}
      >
        <Link
          className=" flex h-full w-full basis-[50%]  items-center justify-center bg-apricot-600 font-bold "
          to={`/flower-store/?bugo._id=${bugo._id}`}
        >
          <span className="text-white">근조화환</span>
        </Link>
        <Button
          className=" h-full basis-[50%] border-none bg-gray-700 text-white"
          onClick={() => {
            setShareModalOpen(true);
          }}
        >
          <span>공유</span>
        </Button>
        <WebShareModal
          openId={openId}
          content={bugoMessageContentString(bugo)}
          title={'- 부고'}
          kakaoContent={bugoKakaoShareContent(bugo)}
          linkUrl={bugoLinkUrl(bugo)}
        />
      </div>
    );
  }, [bugo, bugoPreview, openId, setShareModalOpen]);

  return (
    <>
      {/* 브랜드 로고 */}
      <div
        className={`center-box mb-[56px]  min-h-[106px]  w-full bg-gray-200 ${
          bugoPreview && 'mb-0'
        }`}
      >
        <Link to={`/flower-store/?bugo._id=${bugo._id}`}>
          <img src={logo} className=""></img>
        </Link>
      </div>
      {bottomButton}
    </>
  );
};

export { ObituaryFooter };
