import { FC, ReactNode, useState } from 'react';

import { Chip } from '@components/Chip';

interface FoldableBoxProps {
  children: ReactNode;
  openText?: string;
  memberName?: string;
  isUpdated?: boolean;
  isSend?: boolean;
}

const CourtesyFoldableBox: FC<FoldableBoxProps> = ({
  children,
  openText,
  memberName,
  isUpdated,
  isSend,
}) => {
  const [isFold, setIsFold] = useState(true);

  const onFoldHandler = () => {
    setIsFold((prev) => !prev);
  };

  return (
    <div
      className={`min-h-[44px] cursor-pointer rounded border bg-white py-2  px-4 font-medium ${
        isUpdated ? 'border-apricot-600' : ''
      }`}
    >
      <div className="flex justify-between " onClick={() => onFoldHandler()}>
        <div className="flex items-center gap-2 text-12 max-sm:gap-1">
          <div className="text-[#000000] ">상주</div>
          <Chip color="orange">{memberName}</Chip>

          {isSend && <Chip>답례글전송완료</Chip>}
        </div>
        <div className="flex items-center gap-2 py-1">
          <div
            className={`cursor-pointer text-12 ${
              !isFold ? 'text-apricot-500' : 'text-gray-400'
            }`}
          >
            {openText ? (isFold ? openText : '접기') : '접기'}
          </div>
        </div>
      </div>
      <div
        className={`overflow-y-hidden transition-[max-height] ${
          isFold ? 'max-h-0' : 'max-h-96'
        } `}
      >
        <div className="my-2 border"></div>
        {children}
      </div>
    </div>
  );
};

export { CourtesyFoldableBox };
