import { FC, HTMLAttributes } from 'react';

import { useAlertNotSupportedHook } from '@hooks/useAlertNotSupportedHook';
import { useNavigate } from 'react-router-dom';

interface MainButtonItemProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  IconSVG: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  to?: string | undefined;
}

const MainButtonItem: FC<MainButtonItemProps> = ({ title, IconSVG, to, ...props }) => {
  const navigate = useNavigate();
  const { alertPartnership } = useAlertNotSupportedHook();
  const onClickHandler = () => {
    if (!to) {
      alertPartnership();
      return;
    }
    if (to) {
      navigate(to);
    }
  };
  return (
    <div
      onClick={onClickHandler}
      className="flex cursor-pointer flex-col items-center justify-center p-4 max-sm:pt-0"
      {...props}
    >
      <div className="flex h-[120px] w-[120px] items-center justify-center rounded-lg border shadow-md max-sm:h-[80px] max-sm:w-[80px]">
        <IconSVG />
      </div>
      <p className="pt-2 text-center text-16 font-medium text-[#000000] max-sm:pb-0 max-sm:text-14">
        {title}
      </p>
    </div>
  );
};

export { MainButtonItem };
