import { FC, useMemo } from 'react';

import { MenuListItem } from '@components/MenuListItem';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Logo from 'src/assets/image/logo-gaon.png';

const menuArr = [
  { _id: '1', title: '부고 만들기', href: '/fevent/add' },
  { _id: '2', title: '부고 수정', href: '/fevent/list/?purpose=bugo' },
  { _id: '3', title: '답례 인사', href: '/fevent/list/?purpose=courtesy' },
  { _id: '4', title: '답례품 보내기', href: undefined },
  { _id: '5', title: '화환 주문', href: '/flower-store' },
];

const MainNavigation: FC = () => {
  const navigate = useNavigate();

  const menuList = useMemo(() => {
    return _.map(menuArr, (item) => (
      <MenuListItem key={item._id} {...item}></MenuListItem>
    ));
  }, []);

  const logoHandler = () => {
    console.log('home');
    navigate('/');
  };

  return (
    <div className=" flex h-[92px] items-center justify-between px-2 max-sm:hidden max-sm:h-[56px] max-sm:px-4">
      <div onClick={logoHandler} className=" flex shrink-0 cursor-pointer items-center">
        <div className="flex h-[64px] w-[64px] items-center justify-center">
          <img src={Logo}></img>
        </div>
        <h1 className=" text-24 font-bold">가온부고</h1>
      </div>
      <nav className="max-sm:hidden">
        <ul className="flex gap-2 break-keep text-18 text-gray-700 max-lg:gap-1 max-lg:text-16">
          {menuList}
        </ul>
      </nav>
    </div>
  );
};

export { MainNavigation };
