import { FC } from 'react';

import { useAuth } from '@hooks/useAuth';

interface CashPointInfoSetProps {
  coupon?: number;
}

const CashPointInfoSet: FC<CashPointInfoSetProps> = ({ coupon }) => {
  const { userProfile } = useAuth();

  const myPoint = userProfile?.storeCustomerDetail?.shopMembershipPoint ?? 0;

  return (
    <div className="flex w-full gap-2 rounded-lg bg-white">
      <div className="w-full py-2">
        <div className="text-12">
          <p>G포인트</p>
          <p className="mt-1 text-primary">
            <span className="text-14 font-bold">
              {`${Number(myPoint).toLocaleString()}`}
            </span>
            원
          </p>
        </div>
      </div>
      {/* <div className="w-full">
        <div className="text-12">
          <p>내 쿠폰</p>
          <p className="mt-1 text-primary">
            <span className="font-bold text-14">{coupon.toLocaleString()}</span>원
          </p>
        </div>
      </div> */}
    </div>
  );
};

export { CashPointInfoSet };
