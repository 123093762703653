import { FC } from 'react';

import { nestMongoApi } from '@api/mongo/controllers';
import { nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { BasicInput } from '@components/BasicInput';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { useAuth } from '@hooks/useAuth';
import { SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';

import { CourtesyFoldableBox } from './CourtesyFoldableBox';
import { useNotificateSendHook } from '../state/courtesySendForm.hook';
import {
  CourtesySendFormValue,
  CourtesySendFormValues,
} from '../state/courtesySendForm.interface';

interface CourtesySendFormItemProps {
  memberName: string;
  bugoId: string;
  phoneNumber: string;
  memberId?: string;
  index: number;
  isSended?: boolean;
}

const CourtesySendFormItem: FC<CourtesySendFormItemProps> = ({
  memberName,
  bugoId,
  memberId,
  index,
  isSended,
}) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<CourtesySendFormValues>();
  const { notiBySendStatus } = useNotificateSendHook();
  const { handleSubmit } = useForm<CourtesySendFormValue>();
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();
  const fieldArray = useWatch({ control, name: 'formData' });
  const { feventId } = useParams();
  const phoneNumberV = fieldArray[index]?.phoneNumber;
  const phoneNumberLength = phoneNumberV!.length;
  const validatePhoneNumber =
    phoneNumberLength === 0 || phoneNumberLength < 10 || phoneNumberLength > 11;

  const updateMemberPhoneNumber = async () => {
    if (memberId) {
      await nestMongoApi(`${bugoRole()}/member`).updateById(memberId, {
        phoneNumber: fieldArray[index]?.phoneNumber,
      });
      queryClient.invalidateQueries(feventByIdHookUrl(feventId ?? '', bugoRole()));
    }
  };

  const onSubmit: SubmitHandler<CourtesySendFormValue> = async (data) => {
    if (validatePhoneNumber) return;
    await updateMemberPhoneNumber();
    const sendResponse = await nestAlertApi().courtesySend({ bugos: [bugoId] });
    notiBySendStatus(sendResponse.data.success);
  };

  return (
    <CourtesyFoldableBox openText="개인별전송" memberName={memberName} isSend={isSended}>
      <div className="grid grid-cols-2 gap-2 max-sm:grid-cols-1 max-sm:gap-x-0">
        <BasicInput
          className={`w-full px-4 ${validatePhoneNumber && 'border-red-500'}`}
          label="휴대전화번호"
          value={phoneNumberStringFormator(phoneNumberV)}
          isError={errors.formData && !!errors.formData[index]?.phoneNumber}
          {...register(`formData.${index}.phoneNumber`, {
            required: { value: true, message: '휴대전화 번호를 입력하세요.' },
            maxLength: {
              value: 11,
              message: '휴대전화 번호는 최소 10자, 최대 11자이어야 합니다.',
            },
            minLength: {
              value: 10,
              message: '휴대전화 번호는 최소 10자, 최대 11자이어야 합니다.',
            },
            setValueAs: (phoneNumber) => phoneNumber.replace(/[^0-9]/gi, ''),
          })}
        />
        {/* <Link
          to={`/member/${memberId}/courtesy`}
          className=" flex h-10 items-center justify-center self-end rounded border bg-white font-bold max-sm:order-1"
        >
          답례글 미리보기
        </Link> */}
        <button
          type="button"
          className=" block h-10 self-end rounded bg-apricot-600 font-bold text-white max-sm:order-1"
          onClick={handleSubmit(onSubmit)}
        >
          전송
        </button>
      </div>
    </CourtesyFoldableBox>
  );
};

export { CourtesySendFormItem };
