import React, { FC } from 'react';
import { MouseEventHandler } from 'react';

import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

type CashAccountConfirmButtonProps = {
  onClick: MouseEventHandler;
  disabled: boolean;
  isVerified: boolean;
};

const CashAccountConfirmButton: FC<CashAccountConfirmButtonProps> = ({
  onClick,
  disabled,
  isVerified,
}) => {
  const isMobileMedia = useMobileMediaQuery();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={` mt-3 h-10 w-full rounded border-none text-12 font-medium text-gray-400 shadow-none  max-lg:basis-1/2 ${
        isVerified || !disabled ? ' bg-apricot-600  text-white' : 'bg-gray-100'
      }`}
    >
      {isVerified ? (
        <div className="center-box flex font-bold">
          <p>인증완료</p>
          {!isMobileMedia && <Icon.Check className="h-[16px] w-[16px]" />}
        </div>
      ) : (
        '계좌인증'
      )}
    </Button>
  );
};

export default React.memo(CashAccountConfirmButton);
