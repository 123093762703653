import { queryString, useRecoilQuery, fetcher } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import { userEnquiryListDataAtom, userEnquiryListStatusAtom } from './userEnquiry.atom';
const select = ['answer', 'content', 'createdAt', 'title', 'status', 'image'];
const populate = [
  {
    path: 'image',
    select: ['url'],
  },
];

export const userEnquiryListHookUrl = (role: string) => {
  return `/${role}/enquiry/?${queryString({
    select: select,
    populate: populate,
    options: {
      sort: { createdAt: -1 },
    },
  })}`;
};

export const useUserEnquiryListHook = () => {
  const { assetRole, isAuthenticated } = useAuth();
  useRecoilQuery(
    userEnquiryListStatusAtom,
    userEnquiryListDataAtom,
    userEnquiryListHookUrl(assetRole()),
    fetcher,
    !isAuthenticated,
  );
};
