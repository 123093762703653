import { FC, HTMLAttributes } from 'react';

import dayjs from 'dayjs';
import { StoreItemTagEnum } from 'src/interfaces';
import { v4 } from 'uuid';

import { getDiscountRate } from '../utils/getDiscountRate';
import { getReward } from '../utils/getReward';
import { FlowerStoreItemTags } from './FlowerStoreItemTags';

interface FlowerStoreItemInfoProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  priceRetailShow?: number;
  priceRetail?: number;
  tags?: StoreItemTagEnum[];
}

const FlowerStoreItemInfo: FC<FlowerStoreItemInfoProps> = ({
  name,
  priceRetailShow,
  priceRetail,
  tags,
  // freeDelivery,
  ...props
}) => {
  const isDiscount = priceRetail !== priceRetailShow;
  const freeDelivery = tags?.find((item) => item === StoreItemTagEnum.FreeDelivery);
  const showReward = tags?.find((tag) => tag === StoreItemTagEnum.Reward);
  return (
    <>
      <div className="text-left" {...props}>
        <p className="text-18 font-medium text-black">{name}</p>
        {/* 태그 */}
        <div className="flex flex-wrap gap-2">
          {tags &&
            tags.map((item) => {
              return <FlowerStoreItemTags key={v4()} tags={item} />;
            })}
        </div>
        {/* 가격 */}
        {isDiscount ? (
          <>
            <p className="text-14 text-gray-400 ">
              <del className="mr-2">{priceRetailShow?.toLocaleString()}원</del>
              <span className=" text-indigo-500">
                {getDiscountRate(priceRetailShow ?? 0, priceRetail ?? 0)}%
              </span>
            </p>
            <p className="text-16 font-medium text-black">
              {priceRetail?.toLocaleString()}원
            </p>
          </>
        ) : (
          <>
            <p className="text-16 font-medium text-black">
              {priceRetailShow?.toLocaleString()}원
            </p>
          </>
        )}
        {showReward && (
          <div className="flex gap-2 text-emerald-500">
            <FlowerStoreItemTags tags={StoreItemTagEnum.Reward} />
            <span>{getReward(priceRetail ?? priceRetailShow ?? 0)}원</span>
          </div>
        )}

        <p className=" text-12 text-emerald-500">
          {freeDelivery && <span className=" text-indigo-500">{'무료배송'}</span>}
          오늘 {dayjs(new Date()).format('M/DD')} 도착 보장
        </p>
      </div>
    </>
  );
};

export { FlowerStoreItemInfo };
