import { useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { Courtesy } from '@containers/Courtesy';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { useFeventByIdHook } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { useParams, useSearchParams } from 'react-router-dom';
import { nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { SuccessAlertModal } from '@components/SuccessAlertModal';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

import { MemberList } from './components/MemberList';

const openId = 'CourtesySendSuccess-modal';

const text = {
  toAllMember: `모든 상주분께 답례글 링크가 전송되었습니다.

  문자 또는 카카오톡을 확인해주세요.`,
  toCurrentMember: `선택하신 상주분께 답례글 링크가 전송되었습니다. 

  문자 또는 카카오톡을 확인해주세요.`,
};

const CourtesyPreview = () => {
  const [modalText, setModalText] = useState(text.toAllMember);
  const [searchParams, setSearchParams] = useSearchParams();
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));
  const { courtesySend } = nestAlertApi();
  const bugoId = searchParams.get('bugoId');
  const { feventId } = useParams();
  useFeventByIdHook(feventId ?? '');
  const isMobile = useMobileMediaQuery();

  const { data } = useRecoilValue(currentFeventStateSelectorFamily(feventId ?? ''));
  const selectedMember = data?.memberOrderList.find(
    (member) => member.bugo?._id === bugoId,
  );

  useMobileNavTitleHook(
    bugoId ? '답례인사 미리보기' : '답례인사 미리보기(상주님 선택)',
    true,
    true,
    '',
    bugoId ? `/fevent/${feventId}/courtesy/preview` : undefined,
  );

  const sendToCurMemberModalOpen = () => {
    setModalText(text.toCurrentMember);
    setOpen(true);
  };

  const onClickSendToCurrentMember = async () => {
    await courtesySend({ bugos: [bugoId ?? ''] });
    sendToCurMemberModalOpen();
  };

  const onClickSendToAllMember = async () => {
    setModalText(text.toAllMember);
    const memberBugoIdList = data?.memberOrderList.map((member) => member.bugo!._id);
    await courtesySend({
      bugos: memberBugoIdList ?? [],
    });
    setOpen(true);
  };

  useEffect(() => {
    if (!isMobile)
      setSearchParams(
        { bugoId: data?.memberOrderList[0]?.bugo?._id ?? '' },
        { replace: true },
      );
  }, [data?.memberOrderList, isMobile, setSearchParams]);

  return (
    <>
      <BasicInfoForm
        title="상주님 선택"
        desc={`상주님마다 각각 이미지와 인사를 자유롭게 선택하실 수 있도록 개별로 링크를 보내드립니다.`}
        leftSectionBottomComponent={
          <>
            {data && (
              <div>
                <MemberList
                  members={data?.memberOrderList}
                  bugoId={bugoId ?? ''}
                  sendToCurMemberModalOpen={sendToCurMemberModalOpen}
                />
                <div className="flex gap-x-2">
                  <button
                    type="button"
                    className={`hidden w-full rounded border bg-white px-4 py-3 shadow-sm ${
                      bugoId ? 'max-sm:block' : 'hidden'
                    }`}
                    onClick={onClickSendToCurrentMember}
                  >
                    개별 답례글 전송
                  </button>
                  <button
                    type="button"
                    className={`w-full rounded bg-apricot-600 py-3 px-4 text-white`}
                    onClick={onClickSendToAllMember}
                  >
                    전체 상주 답례글 전송
                  </button>
                </div>
              </div>
            )}
          </>
        }
      >
        {!bugoId ? (
          <div className="ml-auto flex h-[940px] w-full max-w-[584px] flex-col items-center gap-4 border border-gray-400 py-32 max-sm:hidden">
            <Icon.CourtesyThin className=" h-16 w-16 stroke-none" />
            <div className="flex min-h-[180px] flex-col justify-between p-[10px] text-24 font-medium leading-8 text-black">
              <p>답례인사 미리보기 생성 영역</p>
              <p>상주님을 선택해주세요</p>
              <p> 해당 상주님의 답례인사를 미리보실 수 있습니다</p>
            </div>
          </div>
        ) : (
          <div className="ml-auto max-w-[584px] border border-gray-400 max-sm:mx-[-1rem] max-sm:border-none">
            <Courtesy
              memberName={selectedMember?.fullName ?? ''}
              deceasedName={data?.deceasedInfo.name ?? ''}
            />
          </div>
        )}
      </BasicInfoForm>
      <SuccessAlertModal openId={openId} modalText={modalText} />
    </>
  );
};

export { CourtesyPreview };
