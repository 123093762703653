import { FC } from 'react';

import { DeliveryState } from 'src/interfaces';

import { DeliveryStatusIndicator } from './DeliveryStatusIndicator';

type DeliveryStatusHeaderProps = {
  estimatedDeliveredAt: string;
  deliveryStatus?: DeliveryState;
};

const DeliveryStatusHeader: FC<DeliveryStatusHeaderProps> = ({
  estimatedDeliveredAt,
  deliveryStatus = DeliveryState.BEFORE_PREPARING,
}) => {
  const deliveryStates = [
    { state: '결제완료', enum: DeliveryState.BEFORE_PREPARING },
    { state: '상품준비중', enum: DeliveryState.PREPARING },
    { state: '배송중', enum: DeliveryState.DELIVERYING },
    { state: '배송완료', enum: DeliveryState.DELIVERED },
  ];

  const deliveryStateObjs = deliveryStates.map((stateObj, index) => ({
    state: stateObj.state,
    // 현재 상태의 인덱스가 deliveryStatus의 인덱스보다 작거나 같으면 true를 반환
    isSuccess: index <= deliveryStates.findIndex((obj) => obj.enum === deliveryStatus),
  }));

  return (
    <div className="flex w-full flex-col gap-8 bg-gray-700 py-8">
      <h1 className="text-24 font-bold text-white">{estimatedDeliveredAt} 도착 예정</h1>
      <div className="p flex  justify-center gap-2">
        {deliveryStateObjs.map((obj) => (
          <DeliveryStatusIndicator
            key={obj.state}
            state={obj.state}
            isSuccess={obj.isSuccess}
          />
        ))}
      </div>
    </div>
  );
};

export { DeliveryStatusHeader };
