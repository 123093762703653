import { FC } from 'react';

import Modal from '@components/Modal';

import { MobileSelectProps } from '../state/autoSelect.interface';

const MobileSelect: FC<MobileSelectProps> = ({
  value,
  openId,
  labelText,
  modalStyleClass,
  allowsDirectInput,
  optionList,
  selectHandler,
  directInputHandler,
  onOptionClick,
}) => {
  return (
    <Modal position="end" openId={openId} className={`bg-white ${modalStyleClass}`}>
      <div className="rounded-lg bg-white  pb-6 text-left text-black max-sm:rounded-b-none">
        <h1 className="pb-2 text-center text-16 font-bold">{labelText}</h1>
        <div className="thin-scrollbar max-h-[500px] overflow-y-auto pb-4 max-sm:max-h-[20vh]">
          {allowsDirectInput && (
            <option
              className="cursor-pointer select-none border-b p-2 hover:bg-primary hover:text-white"
              onClick={directInputHandler}
            >
              직접 입력
            </option>
          )}

          {optionList.map((option, index) => {
            return (
              <option
                key={index}
                className={`cursor-pointer select-none border-b p-2 last:border-b-0 hover:bg-primary hover:text-white ${
                  value === option && 'bg-primary text-white'
                }`}
                onClick={(e) => {
                  selectHandler(option);
                  onOptionClick && onOptionClick(e);
                }}
              >
                {option}
              </option>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export { MobileSelect };
