import { FC } from 'react';

import { CashAccount } from '@api/mongo/interfaces';
import _ from 'lodash';

import { CashAccountItem } from './CashAccountItem';

type CashAccountListProps = {
  accounts: CashAccount[];
  index: number;
  cashAccountSelectModalId: string;
};

const CashAccountList: FC<CashAccountListProps> = ({
  accounts,
  index,
  cashAccountSelectModalId,
}) => {
  return (
    <div className="text-center">
      <div className="mb-2 flex items-center ">
        <h1 className="mr-2 text-18 font-bold text-black ">계좌 선택</h1>
        <span className="text-13 font-normal text-gray-700 ">
          인증된 계좌를 선택하실 수 있습니다.
        </span>
      </div>
      <div className="rounded border">
        <div className="grid grid-cols-4 border-b p-2 font-bold">
          <p>예금주</p>
          <p>은행</p>
          <p className="col-span-2">계좌번호</p>
        </div>
        {accounts.length > 0 &&
          _.map(accounts, (item) => {
            return (
              item.isVerified && (
                <CashAccountItem
                  key={item._id}
                  account={item}
                  cashAccountSelectModalId={cashAccountSelectModalId}
                  index={index}
                />
              )
            );
          })}
      </div>
    </div>
  );
};

export { CashAccountList };
