import { useEffect, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { BugoSendForm } from '@containers/FeventSubmitContent/components/BugoSendForm';
import { DeceasedInfoForm } from '@containers/FeventSubmitContent/components/DeceasedInfoForm';
import { MournerInfoForm } from '@containers/FeventSubmitContent/components/MournerInfoForm';
import {
  currentFeventIdAtom,
  feventDataSnapshotAtom,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { useFeventByIdHook } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';
import { useParams, useSearchParams } from 'react-router-dom';
import { StepNameEnum } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import { isSubmitOnBugoSendFormAtom } from '@containers/FeventSubmitContent/components/BugoSendForm/state/bugoSendForm.atom';
import { useUpdateFevnetSnapshotHook } from '@containers/FeventSubmitContent/state/feventSubmitContent.hook';

const DynamicStepPage = () => {
  const [test, setIsSubmitOnBugoSendForm] = useRecoilState(isSubmitOnBugoSendFormAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const { feventId, step } = useParams();
  const feventIdBySearchParam = searchParams.get('feventId');
  const [currentFeventId, setCurrentFeventId] = useRecoilState(currentFeventIdAtom);
  const { status, data } = useRecoilValue(
    currentFeventStateSelectorFamily(feventId ?? currentFeventId ?? ''),
  );
  const [feventDataSnapshot, setFeventDataSnapshot] =
    useRecoilState(feventDataSnapshotAtom);
  const isEditPage = useRecoilValue(isEditPageAtom);
  useUpdateFevnetSnapshotHook(feventId ?? '');

  useFeventByIdHook(
    feventId ?? feventIdBySearchParam ?? '',
    !feventId && !feventIdBySearchParam,
  );

  useEffect(() => {
    if (!isEditPage && currentFeventId)
      setSearchParams({ feventId: currentFeventId }, { replace: true });
  }, [currentFeventId, isEditPage, setSearchParams]);

  useEffect(() => {
    if (feventId || feventIdBySearchParam)
      setCurrentFeventId(feventId ?? feventIdBySearchParam ?? '');
    return () => {
      setCurrentFeventId('');
      setFeventDataSnapshot(undefined);
    };
  }, [
    feventId,
    setCurrentFeventId,
    isEditPage,
    setFeventDataSnapshot,
    feventIdBySearchParam,
  ]);

  useEffect(() => {
    if (isEditPage && data && !feventDataSnapshot) {
      setFeventDataSnapshot(data);
    }
  }, [
    data,
    feventDataSnapshot,
    isEditPage,
    setFeventDataSnapshot,
    setIsSubmitOnBugoSendForm,
    test,
  ]);

  const render = useMemo(() => {
    if (status === 'loading') {
      return <></>;
    }
    switch (step) {
      case StepNameEnum.DeceasedInfo:
        return <DeceasedInfoForm data={data} />;
      case StepNameEnum.MournerInfo:
        return <MournerInfoForm />;
      case StepNameEnum.BugoSend:
        return <BugoSendForm />;
      default:
        return <DeceasedInfoForm data={data} />;
    }
  }, [data, status, step]);

  return render;
};

export { DynamicStepPage };
