import { useRecoilValue } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';

const TopLayerModal = ({ text, openId }: { text: string; openId: string }) => {
  const open = useRecoilValue(modalOpenAtomFamily(openId));

  if (open) {
    return (
      <div className=" center-box fixed inset-0 z-[9999] h-full w-full bg-black bg-opacity-50">
        <div>
          <div className="sk-circle h-10 w-10">
            <div className="sk-circle1 sk-chil"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
          <div className="center-box py-3">
            <p className="text-16 font-medium text-white ">{text ?? 'loading'}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export { TopLayerModal };
