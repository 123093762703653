import React, { FC, useMemo } from 'react';

import { FuneralHomeInfo } from '@api/mongo/interfaces';
import _ from 'lodash';
import { CustomCallback } from 'src/interfaces';

import TablePagination from './PaginationTable/TablePagination';

interface IProps {
  searchedFuneralHomeInfoList: FuneralHomeInfo<undefined, string>[];
  selectedPage: number;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
  onItemClick: CustomCallback<FuneralHomeInfo<undefined, string>, any>;
  chunkSize?: number;
}

const FuneralHomeInfoSearchList: FC<IProps> = ({
  searchedFuneralHomeInfoList,
  selectedPage,
  setSelectedPage,
  onItemClick,
  chunkSize = 3,
}: IProps) => {
  // chunkedList
  const chunkedList = useMemo<FuneralHomeInfo<undefined, string>[][]>(() => {
    return _.chunk(searchedFuneralHomeInfoList, chunkSize);
  }, [chunkSize, searchedFuneralHomeInfoList]);

  // renderItemList
  const renderItemList = useMemo(() => {
    const result = chunkedList[selectedPage - 1];
    if (result) return result;
    return [] as FuneralHomeInfo<undefined, string>[];
  }, [chunkedList, selectedPage]);

  return (
    <React.Fragment>
      <p>검색결과 ({searchedFuneralHomeInfoList.length})</p>
      {renderItemList.map((item) => {
        return (
          <div
            key={`funeralHomeInfoSeacrh-${item._id}`}
            className="cursor-pointer"
            onClick={() => onItemClick(item)}
          >
            <p className="font-bold">{item.name}</p>
            <p className="min-h-[40px] text-sm text-gray-500">{item.address}</p>
          </div>
        );
      })}
      <TablePagination
        pageLength={chunkedList.length}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    </React.Fragment>
  );
};

export default FuneralHomeInfoSearchList;
