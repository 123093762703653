import { FC } from 'react';

import { Icon } from '@components/Icon';

const FlowerStoreAccountGuide: FC = () => {
  return (
    <div className="w-full border-t py-3">
      <div className="mx-auto grid w-fit grid-cols-2 items-center gap-3 text-14 font-medium max-sm:w-full">
        <div className="center-box flex flex-col ">
          <Icon.Account className="stroke-none" />
          <p>입금계좌</p>
          <p className="text-primary">(주)가온프라임</p>
        </div>
        <div>
          <p className="text-primary">농협은행</p>
          <p>301-4053-9530-21</p>
        </div>
        <div className=" col-span-2">
          <p>위 계좌는 화환구매를 위한 입금계좌입니다.</p>
          <p className="text-red-500">※부의금을 보내시면 안됩니다※</p>
        </div>
      </div>
    </div>
  );
};

export { FlowerStoreAccountGuide };
