import { forwardRef, InputHTMLAttributes } from 'react';

import { useId } from 'react-id-generator';

import { Icon } from './Icon';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  frameClassName?: string;
  useCheckIcon?: boolean;
  CheckIconClassName?: string;
}

const RadioInput = forwardRef<HTMLInputElement, IProps>(
  (
    {
      label,
      labelClassName,
      frameClassName,
      CheckIconClassName,
      className,
      useCheckIcon,
      children,
      ...props
    },
    ref,
  ) => {
    const [id] = useId(1, 'radioInput');

    return (
      <label
        htmlFor={id}
        className={`relative flex items-center space-x-3 px-2 py-3 ${frameClassName}`}
      >
        <div className="center-box relative">
          <input
            ref={ref}
            id={id}
            type="radio"
            className={`wh-4 appearance-none rounded-full border checked:border-[4px] ${className}`}
            {...props}
          />
          {useCheckIcon && (
            <div className={`absolute ${CheckIconClassName}`}>
              <Icon.Check className={`h-full w-full stroke-none `} />
            </div>
          )}
        </div>
        {label && (
          <p className={`break-keep ${labelClassName ?? 'theme-text-8'}`}>{label}</p>
        )}
        {children}
      </label>
    );
  },
);

export default RadioInput;
