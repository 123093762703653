import { FC, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BasicInput } from '@components/BasicInput';
import { Button } from '@components/Button';
import RadioInput from '@components/RadioInput';
import { useAuth } from '@hooks/useAuth';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { redirectAfterLoginAtom } from '@state/atom/auth.atom';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { notificateSuccess } from 'src/@utils/noti.utils';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';
import { UserRole } from 'src/interfaces';
import { PasswordInput } from '@components/PasswordInput';

type SignUpFormInputType = {
  username: string;
  password: string;
  confirmPassword: string;
  roles: UserRole[];
  info: { name: string; phoneNumber: string };
};

const SignUpForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    trigger,
    watch,
  } = useForm<SignUpFormInputType>({ mode: 'all' });
  useMobileNavTitleHook('회원가입', true, false);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const { signupByUsernamePasswordCheckPossible, signupByUsernamePassword } = useAuth();
  const isStorePage = useRecoilValue(isStorePageAtom);
  const navigate = useNavigate();
  const redirectAfterLogin = useRecoilValue(redirectAfterLoginAtom);

  const [formatPhoneNumber, setFormatPhoneNumber] = useState('');

  const passwordWatch = watch('password');
  const confirmPasswordWatch = watch('confirmPassword');

  useEffect(() => {
    trigger('confirmPassword');
  }, [passwordWatch, confirmPasswordWatch, trigger]);

  const signupSubmitHandler = async (data: SignUpFormInputType) => {
    //아이디 중복확인
    const CheckResult = await signupByUsernamePasswordCheckPossible(data);
    if (CheckResult) {
      setError('username', { type: 'error', message: '이미 사용중인 아이디입니다.' });
      return;
    }

    const set = new Set(
      _.concat(data.roles, [
        UserRole.BugoGuest,
        UserRole.BugoCustomer,
        UserRole.StoreGuest,
        UserRole.StoreCustomer,
      ]),
    );
    data.roles = _.compact(Array.from(set));

    await signupByUsernamePassword(data);
    notificateSuccess(notifiacationInstance, '회원가입 완료');
    navigate(redirectAfterLogin ?? isStorePage ? '/flower-store' : '/');
  };

  return (
    <div className="mx-auto max-w-[608px] px-8 pb-8 pt-20 max-sm:px-0">
      <h1 className="break-keep pb-8 text-24 font-medium">
        <span className="text-primary">회원정보</span>를 입력해주세요
      </h1>
      <form onSubmit={handleSubmit(signupSubmitHandler)}>
        <div className="flex flex-col gap-3">
          <BasicInput
            placeholder="ID"
            className={`px-4 text-16 `}
            autoCapitalize="off"
            isError={!!errors.username}
            errorMessage={errors.username?.message}
            {...register('username', {
              required: { value: true, message: '아이디를 입력해주세요.' },
              maxLength: { value: 30, message: '최대 30자까지만 가능합니다.' },
            })}
          ></BasicInput>
          <div>
            <PasswordInput
              placeholder="비밀번호"
              className={`rounded-br-none rounded-bl-none  px-4 text-16 ${
                errors.password ? ' border-b border-red-500' : 'border-b-0 '
              }`}
              {...register('password', {
                required: { value: true, message: '비밀번호를 입력해주세요.' },
                maxLength: {
                  value: 20,
                  message: '비밀번호는 최소 5자, 최대 20자이어야 합니다.',
                },
                minLength: {
                  value: 5,
                  message: '비밀번호는 최소 5자, 최대 20자이어야 합니다.',
                },
              })}
            />
            <PasswordInput
              placeholder="비밀번호 재입력"
              className={`rounded-tr-none rounded-tl-none px-4 text-16 ${
                errors.confirmPassword && ' border-red-500'
              }
              ${errors.confirmPassword && errors.password && 'border-t-0'}
    
              `}
              {...register('confirmPassword', {
                validate: (value) =>
                  value === passwordWatch || '비밀번호가 일치하지 않습니다.',
              })}
            />
            {errors.password && (
              <p className="pt-1 text-left text-red-500">{errors.password.message}</p>
            )}
            {!errors.password && errors.confirmPassword && (
              <p className="pt-1 text-left text-red-500">
                {errors.confirmPassword?.message}
              </p>
            )}
          </div>
          <BasicInput
            placeholder="이름"
            className={`px-4 text-16 `}
            isError={!!errors.info?.name}
            errorMessage={errors.info?.name?.message}
            {...register('info.name', {
              required: { value: true, message: '이름을 입력해주세요.' },
            })}
          />
          <BasicInput
            maxLength={13}
            placeholder="휴대전화번호"
            type="tel"
            value={formatPhoneNumber}
            isError={!!errors.info?.phoneNumber}
            errorMessage={errors.info?.phoneNumber?.message}
            className={`px-4 text-16`}
            {...register('info.phoneNumber', {
              required: { value: true, message: '휴대전화 번호를 확인해주세요.' },
              onChange(event) {
                setFormatPhoneNumber(phoneNumberStringFormator(event.target.value));
              },
              minLength: 10,
              maxLength: 11,
              setValueAs: (value) => value.replace(/[^0-9]/gi, ''),
            })}
          />

          {!isStorePage && (
            <>
              <div className="flex">
                <RadioInput
                  {...register('roles', { required: true })}
                  value={UserRole.BugoCustomer}
                  className=" h-4 w-4 rounded-sm bg-white checked:border-none  checked:bg-primary "
                  frameClassName="px-0 py-0 text-12 text-gary-700 cursor-pointer"
                  label="일반회원 (상주,모임,단체 등)"
                  useCheckIcon
                />
                <RadioInput
                  {...register('roles', { required: true })}
                  value={UserRole.BugoAgencyWorker}
                  className="h-4 w-4 rounded-sm bg-white checked:border-none  checked:bg-primary"
                  frameClassName="ml-4 py-0 text-12 text-gary-700 cursor-pointer"
                  label="장례 지도사 회원"
                  useCheckIcon
                />
              </div>
              {errors.roles && (
                <p
                  className={`break-keep text-left text-14
                text-red-500
                `}
                >
                  가입하시는 회원 유형을 선택해주세요.
                </p>
              )}
            </>
          )}
          <div className="grid grid-cols-2 gap-2 text-14 font-medium">
            <Button
              className="rounded bg-white py-3 px-4 shadow-sm"
              onClick={() => {
                navigate(isStorePage ? '/flower-store' : '/', { replace: true });
              }}
            >
              처음으로
            </Button>
            <Button
              className={`rounded bg-primary py-3 px-4 text-white shadow-none`}
              type="submit"
            >
              완료
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { SignUpForm };
