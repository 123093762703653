import { FC } from 'react';

import { Outlet } from 'react-router-dom';

const FeventSubmitContent: FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { FeventSubmitContent };
