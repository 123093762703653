import { FC } from 'react';

import { Button, ButtonProps } from '@components/Button';

interface UserModifyButtonProps extends ButtonProps {
  isValue: string;
  onClick: () => void;
}

const UserModifyButton: FC<UserModifyButtonProps> = ({ isValue, onClick }) => {
  return (
    <div className="  col-end-9">
      <Button
        className=" max-h-[28px] max-w-[50px] bg-white px-2 py-1 text-gray-700 shadow-sm "
        onClick={onClick}
      >
        {isValue ? '수정' : '등록'}
      </Button>
    </div>
  );
};

export { UserModifyButton };
