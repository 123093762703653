import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { UserEnquiryList } from './userEnquiry.interface';

export const userEnquiryListStatusAtom = atom<QueryStatus>({
  key: 'userEnquiryListStatusAtom',
  default: 'idle',
});

export const userEnquiryListDataAtom = atom<UserEnquiryList | undefined>({
  key: 'userEnquiryListDataAtom',
  default: undefined,
});

export const enquiryItemOpenStateFamily = atomFamily({
  key: 'itemOpenStateFamily',
  default: false, // 기본값은 false, 아이템이 닫힌 상태
});
