import { BasicInputProps } from '@components/BasicInput';
import { UseFormRegisterReturn } from 'react-hook-form';

export enum FormStateKeyType {
  Name = 'name',
  Id = '_id',
  PhoneNumber = 'phoneNumber',
  Password = 'password',
}

export type UserModifyFormType = Record<FormStateKeyType, string>;

export interface FormFields extends BasicInputProps {
  label: string;
  key: FormStateKeyType;
  placeholder?: string;
  defaultValue: string;
  cancelHandler?: (key: FormStateKeyType) => void;
  submitHandler?: (key: FormStateKeyType) => void;
  registerOptions?: Record<string, any>;
  notUseModify?: boolean;
  isValue?: string;
  className?: string;
  maxLength?: number;
  inputClassName?: string;
  inputValue?: string;
}

export interface UserModifyFormItemProps extends BasicInputProps {
  label: string;
  register: UseFormRegisterReturn<any>;
  placeholder?: string;
  defaultValue: string;
  isOpenModifyForm: boolean;
  notUseModify?: boolean;
  wrapperClassName?: string;
  maxLength?: number;
  inputClassName?: string;
  inputValue?: string;
  openHandler: () => void;
  submitHandler: () => void;
  cancelHandler?: () => void;
}
