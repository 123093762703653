const OrderItem = ({ title, value = '-' }: { title: string; value?: string }) => {
  return (
    <div className="grid  grid-cols-[74px_1fr] text-left text-12 font-medium">
      <div className="border-b border-r  border-l bg-orange-50 px-2 py-3 text-center text-apricot-600">
        {title}
      </div>
      <div className="border-b bg-white px-2 py-3 text-black">{value}</div>
    </div>
  );
};

export { OrderItem };
