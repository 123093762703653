import { FC } from 'react';

import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { BugoMyPageContent } from '@containers/BugoMyPageContent';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation, MainNavigation } from '@containers/TopNavigation/components';
import { Body, Header, Footer } from '@layout';

const BugoMyPage: FC = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <MainSection>
          <BugoMyPageContent />
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { BugoMyPage };
