import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { FeventMemberOrderBugo } from './bugoPreview.interface';
// 부고장 미리보기할 시 부고장 버튼 컴포넌트 스타일 변경을 위한 값
export const bugoPreviewAtom = atom({ key: 'bugoPreviewAtom', default: false });

export const feventMemberOrderBugoStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventMemberOrderBugoStatusAtomFamily',
  default: 'idle',
});

export const feventMemberOrderBugoDataAtomFamily = atomFamily<
  FeventMemberOrderBugo,
  string
>({
  key: 'feventMemberOrderBugoDataAtomFamily',
  default: undefined,
});
