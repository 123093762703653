import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { ConfirmModal } from '@components/ConfirmModal';
import { CurrentFevent } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import {
  FieldArrayWithId,
  SubmitHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';

import { FormValue, FormValues } from '../state/bugoSendForm.interface';
import { BugoSendBtnSet } from './BugoSendBtnSet';
import { BugoSendFormItem } from './BugoSendFormItem';
import { BugoSubmitModal } from './BugoSubmitModal';

interface BugoSendFormListProps {
  fevent: CurrentFevent | undefined;
  onSubmit: SubmitHandler<FormValues>;
  fields: FieldArrayWithId<FormValues, 'memberData', 'id'>[];
  checkUpdated: () => boolean;
}

const BugoSendFormList: FC<BugoSendFormListProps> = ({
  fevent,
  onSubmit,
  fields,
  checkUpdated,
}) => {
  const isEditPage = useRecoilValue(isEditPageAtom);
  const navigate = useNavigate();
  const [focusedIndex, setFocusedIndex] = useState<undefined | number>(undefined);
  const [focusedInput, setFocusedInput] = useState<
    'phoneNumber' | 'mournerText' | undefined
  >(undefined);
  const { handleSubmit, control, setFocus } = useFormContext<FormValues>();

  const fieldArray: FormValue[] | undefined = useWatch({ control, name: 'memberData' });

  const indexedFieldArray = _.map(fieldArray, (field, idx) => {
    return { idx, field };
  });

  const addedOrUpdatedMembers = indexedFieldArray.filter(
    (item) => item.field.isAddedMember || item.field.isPhoneNumberUpdated,
  );

  const notUpdatedOrAddedMembers = indexedFieldArray.filter(
    (item) => !item.field.isPhoneNumberUpdated && !item.field.isAddedMember,
  );

  useEffect(() => {
    if (focusedInput) setFocus(`memberData.${focusedIndex ?? 0}.${focusedInput}`);
  }, [
    setFocus,
    focusedIndex,
    addedOrUpdatedMembers,
    notUpdatedOrAddedMembers,
    focusedInput,
  ]);

  return (
    <>
      {fevent?.memberOrderList.length === 0 && (
        <div className="flex h-[163px] items-center justify-center text-16 text-gray-500">
          등록된 상주가 없습니다.
        </div>
      )}
      {!isEditPage && (
        <div className=" flex flex-col gap-2">
          {fields.map(
            (field, index) =>
              fevent &&
              fevent.memberOrderList.length > 0 && (
                <BugoSendFormItem
                  key={field.id}
                  index={index}
                  mournerText={fevent.mournerText}
                  setFocusedIndex={setFocusedIndex}
                  setFocusedInput={setFocusedInput}
                  memberName={fevent.memberOrderList[index]?.fullName ?? ''}
                  memberPhoneNumber={fevent?.memberOrderList[index]?.phoneNumber}
                  lastSentPhoneNumber={
                    fevent?.memberOrderList[index]?.bugo?.lastSentPhoneNumber
                  }
                />
              ),
          )}
        </div>
      )}

      <div className="flex flex-col gap-y-20">
        {isEditPage && fieldArray && addedOrUpdatedMembers.length > 0 && (
          <div className=" flex flex-col gap-2">
            <p className=" mb-2 text-left text-16 font-semibold">
              재전송 필요 상주 - 연락처를 수정하셨거나 새롭게 추가하신 상주님입니다.
            </p>
            {addedOrUpdatedMembers.map((item) => (
              <BugoSendFormItem
                key={item.field._id}
                index={item.idx}
                mournerText={fevent?.mournerText}
                setFocusedIndex={setFocusedIndex}
                setFocusedInput={setFocusedInput}
                memberName={fevent?.memberOrderList[item.idx]?.fullName ?? ''}
                memberPhoneNumber={fevent?.memberOrderList[item.idx]?.phoneNumber}
                lastSentPhoneNumber={
                  fevent?.memberOrderList[item.idx]?.bugo?.lastSentPhoneNumber
                }
              />
            ))}
          </div>
        )}
        {isEditPage && fieldArray && notUpdatedOrAddedMembers.length > 0 && (
          <div className="  flex flex-col gap-2">
            <p className=" mb-2 text-left text-16 font-semibold">
              재전송 불필요 상주 - 수정사항이 기존 부고장에 자동 반영됩니다.
            </p>
            {notUpdatedOrAddedMembers.map((item) => (
              <BugoSendFormItem
                key={item.field._id}
                index={item.idx}
                mournerText={fevent?.mournerText}
                setFocusedIndex={setFocusedIndex}
                setFocusedInput={setFocusedInput}
                defaultFold={focusedIndex === item.idx ? false : true}
                memberName={fevent?.memberOrderList[item.idx]?.fullName ?? ''}
                memberPhoneNumber={fevent?.memberOrderList[item.idx]?.phoneNumber}
                lastSentPhoneNumber={
                  fevent?.memberOrderList[item.idx]?.bugo?.lastSentPhoneNumber
                }
              />
            ))}
          </div>
        )}
      </div>
      <BugoSendBtnSet checkUpdated={checkUpdated} />
      <ConfirmModal
        openId="confirm-modal-BugoSendPage"
        title="메인페이지 이동"
        subtitle="변경사항이 존재합니다. 저장 후 전송 하시겠습니까?"
        buttonTitle="전송"
        onClick={async () => {
          await handleSubmit(onSubmit)();
          navigate('/');
        }}
      />
      <ConfirmModal
        openId="confirm-modal-BugoSendPage-onlyAddPage"
        title="메인페이지 이동"
        subtitle="부고문자가 전송되지 않은 상주님이 존재합니다. 전송 하시겠습니까?"
        buttonTitle="전송"
        onClick={async () => {
          await handleSubmit(onSubmit)();
          navigate('/');
        }}
      />
      {/* 부고 전체 전송버튼 클릭 시 나타나는 확인 모달 */}
      <BugoSubmitModal />
    </>
  );
};

export default BugoSendFormList;
