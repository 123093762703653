import { FC } from 'react';

import kakaoNaviLogo from 'src/assets/image/kakao-navi-logo.png';
import naverNaviLogo from 'src/assets/image/naver-navi-logo.png';
import tmapLogo from 'src/assets/image/tmap-logo.png';
import { Coord } from 'src/interfaces';

import Modal from './Modal';

interface Props {
  openId: string;
  coords: Coord;
  destinationName: string;
  publicTransport?: boolean;
}

const origin = window.location.origin;

const NavigatorsModal: FC<Props> = ({
  openId,
  coords,
  destinationName,
  publicTransport,
}: Props) => {
  const { x, y } = coords;

  if (publicTransport) {
    return (
      <Modal id={openId} openId={openId} className="bg-white" position="center">
        <div className="flex gap-14 px-10 py-3">
          <a
            // https://guide.ncloud-docs.com/docs/naveropenapiv3-maps-url-scheme-url-scheme
            href={`nmap://route/public/navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={naverNaviLogo} className="w-14" />
            <h4 className="text-sm font-bold">네이버</h4>
          </a>
          <a
            // https://apis.map.kakao.com/web/guide/#mapurl
            href={`https://map.kakao.com/link/to/${destinationName},${y},${x}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={kakaoNaviLogo} className="w-14" />
            <h4 className="text-sm font-bold">카카오</h4>
          </a>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal id={openId} openId={openId} className="bg-white" position="center">
        <div className="flex gap-14 px-10 py-3">
          <a
            // https://guide.ncloud-docs.com/docs/naveropenapiv3-maps-url-scheme-url-scheme
            href={`nmap://navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={naverNaviLogo} className="w-14" />
            <h4 className="text-sm font-bold">네이버</h4>
          </a>
          <a
            // https://apis.map.kakao.com/web/guide/#mapurl
            href={`https://map.kakao.com/link/to/${destinationName},${y},${x}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={kakaoNaviLogo} className="w-14" />
            <h4 className="text-sm font-bold">카카오</h4>
          </a>
          <a
            // https://okky.kr/articles/712480
            href={`tmap://route?goalname=${destinationName}&goaly=${y}&goalx=${x}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={tmapLogo} className="w-14" />
            <h4 className="text-sm font-bold">티맵</h4>
          </a>
        </div>
      </Modal>
    );
  }
};

export default NavigatorsModal;
