import { FC } from 'react';

import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';

interface PaymentHeaderProps {
  orderId: string;
  createdAt: Date;
}

const PaymentHeader: FC<PaymentHeaderProps> = ({ orderId, createdAt }) => {
  const FooterButtonSetStyle = 'text-12 rounded font-medium  py-1 shadow-sm min-w-[79px]';

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-3 pt-[90px]">
      <div className="center-box  h-14 w-14 rounded-full border-4 border-primary">
        <Icon.Check className=" h-full w-full fill-primary stroke-none" />
      </div>
      <p className="text-16 font-medium">
        고객님의 주문이 <span className="text-red-500">완료</span> 되었습니다.
      </p>
      <div className="flex">
        주문 내역 및 배송에 관한 안내는
        <Link
          className="border-none p-0 px-1 text-primary underline shadow-none"
          to={`/flower-store/order/${orderId}/delivery`}
        >
          주문배송
        </Link>
        에서 확인 하실 수 있습니다.
      </div>

      <p>
        주문번호 : <span>{orderId}</span>
      </p>

      <p>
        주문일자 : <span>{dayjs(createdAt).format('MM월 DD일')}</span>
      </p>

      <div className="flex gap-2">
        <Button
          className={`${FooterButtonSetStyle}`}
          onClick={() => navigate('/user/mypage?state=bugo')}
        >
          마이페이지
        </Button>
        <Button className={`${FooterButtonSetStyle}`}>주문배송</Button>
        <Button className={`${FooterButtonSetStyle}`}>고객센터</Button>
      </div>
    </div>
  );
};

export { PaymentHeader };
