import { FC } from 'react';

import { FlowerStoreItemInfo } from '@containers/FlowerStoreMainContent/components/FlowerStoreItemInfo';
import { Button } from '@components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { fileBucketUrl } from '@plugin/s3Image';
import { v4 } from 'uuid';
import { ShopItemForStoreData } from '@api/mongo/interfaces';

type FlowerStoreItemDetailProps = {
  itemData: ShopItemForStoreData;
};

const FlowerStoreItemDetail: FC<FlowerStoreItemDetailProps> = ({ itemData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderButtonHandler = () => {
    navigate(`checkout/${location.search}`);
  };

  return (
    <div className="flex flex-col items-end ">
      <div className="mb-8 flex flex-col gap-3 border  bg-white p-4 max-sm:mx-[-1rem] max-sm:mb-0 max-sm:border-none">
        <div className="center-box mx-[-1rem] border-b">
          <img src={fileBucketUrl(itemData.shopItemInfo.image.url, 'w1080')}></img>
        </div>
        <FlowerStoreItemInfo
          name={itemData.shopItemInfo.name}
          priceRetailShow={itemData.priceRetailShow}
          priceRetail={itemData.priceRetail}
          tags={itemData.shopItemInfo.tags}
        />
        <div className="mx-[-1rem] break-keep border-t border-b px-4 py-2 text-left">
          {itemData.shopItemInfo.detailInfo}
        </div>

        <div className="text-left text-16 font-bold text-black">실제 배송사진</div>
        <div className="flex flex-wrap gap-2">
          {itemData.deliveredImageList.length > 0 ? (
            <>
              {_.map(itemData.deliveredImageList, (item) => {
                return (
                  <div key={v4()} className="max-h-[138px] max-w-[105px] bg-gray-200 ">
                    <img src={fileBucketUrl(item.url, 'w200')}></img>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <p className="text-gray-500">등록된 사진이 없습니다.</p>
            </>
          )}
        </div>
      </div>
      <Button
        className="w-full max-w-[180px] rounded border-none bg-primary py-3 px-16 text-white shadow-none max-sm:fixed max-sm:bottom-0 max-sm:mx-[-1rem] max-sm:h-[52px] max-sm:max-w-full max-sm:rounded-none"
        onClick={orderButtonHandler}
      >
        주문하기
      </Button>
    </div>
  );
};

export { FlowerStoreItemDetail };
