import { FlowerStoreIfnoForm } from '@components/FlowerStoreInfoForm';
import { FlowerStoreSection } from '@components/FlowerStoreSection';
import { FlowerStoreMainContent } from '@containers/FlowerStoreMainContent';
import { useFlowerStoreItemList } from '@containers/FlowerStoreMainContent/state/flowerStoreMainContent.query';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { Body, Footer, Header } from '@layout';

const FlowerStoreMainPage = () => {
  useFlowerStoreItemList();

  const SideNotice = () => {
    return (
      <div className=" break-keep text-12 text-gray-500 max-sm:hidden">
        <span className="font-medium text-gray-700">구매 배송안내</span>
        <br /> * 구입금액과 지역 관계없이 무료배송 (단. 산간지역과 섬지역은 예외) (단,
        희망한 배송시간 3시간 전까지 연락주시면 주문취소가 가능합니다.) 배송시간 :
        09시부터 시작하여 오후 8시까지 배송소요시간 : 3시간 이내 배송가능
        <br />
        <br />
        <span className="font-medium text-gray-700">교환/환불 관련 안내</span>
        <br /> 교환과 환불은 주문한 상품에 대한 아래와 같은 요소가 발생시에는 100%
        가능합니다. - 교환은 다음과 같은 경우 요인 ① 배송된 상품이 파손 되었거나
        오염되었을 경우 ② 배송된 상품이 주문한 내용과 다를 경우 ③ 쇼핑몰이 제공한 정보와
        다를 경우(교환은 위과 같은 요인 발생시 3시간이내 재교환이 가능합니다.) - 환불은
        아래와 같은 요인 경우 ① 배송시간 3시간 전에 주문이 취소되었을 경우 ② 상품이 품절
        되었거나 기타 사유로 인해 배송이 불가능한 경우(환불처리는 위와같은 요인이 발생시
        12시간 이내에 전액 환불처리 해드립니다)
        <br />
        <br /> - 꽃 수급 상황과 지역 특색으로 인해 제품이미지와 배송이미지가 조금 상이할
        수 있습니다. 이러한 이유로는 교환과 환불이 불가능한 점 양해해주시면
        감사하겠습니다.
      </div>
    );
  };

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body header>
        <FlowerStoreSection title="화환보내기">
          <FlowerStoreIfnoForm
            title="화환보내기"
            leftSectionTopComponent={<SideNotice />}
          >
            <FlowerStoreMainContent />
          </FlowerStoreIfnoForm>
        </FlowerStoreSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { FlowerStoreMainPage };
