import React, { useEffect, useState, useRef, FC } from 'react';

import { useTimePickerDrag } from '../state/timePicker.hook';
import { MinuteWheelProps } from '../state/timePicker.interface';
import { initialNumbersValue, returnSelectedValue } from '../utils/createValues';

const MinuteWheel: FC<MinuteWheelProps> = ({ height, value, setValue }) => {
  const mainListRef = useRef<HTMLDivElement>(null);
  const [minute, setMinute] = useState(
    initialNumbersValue(height, 60, parseInt(value.slice(3, 6))),
  );
  const initialMinuteValue = initialNumbersValue(
    height,
    60,
    parseInt(value.slice(3, 6)),
  ).filter((item) => item.number === value.slice(3, 6) && item.selected === true);

  const initialTranslatedValue =
    initialMinuteValue.length > 0
      ? parseInt(initialMinuteValue[0]?.translatedValue ?? '')
      : 0;

  const [currentTranslatedValue, setCurrentTranslatedValue] =
    useState(initialTranslatedValue);

  const [mouseWheelType, setMouseWheelType] = useState<string | null>(null);

  const {
    handleMouseDown,
    handleMouseUpAndMouseLeave,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleClickToSelect,
    dragDirection,
    startCapture,
    cursorPosition,
    setCursorPosition,
    dragEndTime,
    dragStartTime,
    showFinalTranslate,
    isFastCondition,
    isSlowCondition,
  } = useTimePickerDrag({ setCurrentTranslatedValue });

  // preview translation
  useEffect(() => {
    if (startCapture && mainListRef.current) {
      mainListRef.current.style.transform = `translateY(${
        currentTranslatedValue + cursorPosition
      }px)`;
    }
  }, [currentTranslatedValue, cursorPosition, startCapture]);

  // final translation here
  useEffect(() => {
    if (showFinalTranslate && mainListRef.current) {
      if (dragEndTime - dragStartTime <= 100 && cursorPosition !== 0) {
        let currentValue = 0;
        if (dragDirection === 'down') {
          currentValue =
            currentTranslatedValue - (120 / (dragEndTime - dragStartTime)) * 100;
        } else if (dragDirection === 'up') {
          currentValue =
            currentTranslatedValue + (120 / (dragEndTime - dragStartTime)) * 100;
        }
        let finalValue = Math.round(currentValue / height) * height;
        if (finalValue < height * -177) finalValue = height * -177;
        if (finalValue > height * 2) finalValue = height * 2;

        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      if (dragEndTime - dragStartTime > 100 && cursorPosition !== 0) {
        let finalValue = Math.round(currentTranslatedValue / height) * height;
        if (finalValue < height * -177) finalValue = height * -177;
        if (finalValue > height * 2) finalValue = height * 2;
        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      setCursorPosition(0);
    }
  }, [
    currentTranslatedValue,
    cursorPosition,
    dragDirection,
    dragEndTime,
    dragStartTime,
    height,
    setCursorPosition,
    showFinalTranslate,
  ]);

  // return to default position after drag end (handleTransitionEnd)
  const handleTransitionEnd = () => {
    const selectedTranslatedValue = currentTranslatedValue.toString();
    setMinute((prevHours) => {
      return prevHours.map((hour) => {
        if (hour.translatedValue === selectedTranslatedValue) {
          return {
            ...hour,
            selected: true,
          };
        } else {
          return {
            ...hour,
            selected: false,
          };
        }
      });
    });

    returnSelectedValue(height, 60).forEach((item) => {
      if (parseInt(item.translatedValue) === currentTranslatedValue) {
        setValue((prev) => prev && `${prev.slice(0, 2)}:${item.number}`);
      }
    });
  };

  /* ***************************   handle wheel scroll ************************* */
  const handleWheelScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (Math.abs(e.deltaY) > 50) {
      setMouseWheelType('fast');
    } else {
      setMouseWheelType('slow');
    }

    let accumulatedDelta = e.deltaY;

    const scrollStep = () => {
      if (Math.abs(accumulatedDelta) >= height) {
        const scrollAmount = Math.floor(accumulatedDelta / height) * height;

        if (e.deltaY > 0) {
          if (currentTranslatedValue < height * 2) {
            setCurrentTranslatedValue((prev) => prev + height);
          }
        } else if (currentTranslatedValue > height * -177) {
          setCurrentTranslatedValue((prev) => prev - height);
        }

        accumulatedDelta -= scrollAmount;
      }

      if (Math.abs(accumulatedDelta) >= height) {
        requestAnimationFrame(scrollStep);
      } else {
        setTimeout(() => setMouseWheelType(null), 300); // adjust delay as necessary
      }
    };

    requestAnimationFrame(scrollStep);
  };
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (mouseWheelType) {
      timeoutId = setTimeout(() => setMouseWheelType(null), 300);
    }
    // Clean-up 함수
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [mouseWheelType]);

  return (
    <div
      className="react-ios-time-picker-minute"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUpAndMouseLeave}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUpAndMouseLeave}
      style={{ height: height * 5 }}
      onWheel={handleWheelScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUpAndMouseLeave}
    >
      <div
        ref={mainListRef}
        className={`${
          (isFastCondition === true || mouseWheelType === 'fast') &&
          'react-ios-time-picker-fast'
        } ${
          (isSlowCondition === true || mouseWheelType === 'slow') &&
          'react-ios-time-picker-slow'
        }`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateY(${currentTranslatedValue}px)` }}
      >
        {minute.map((minuteObj, index) => (
          <div
            key={index}
            className="react-ios-time-picker-cell-minute"
            style={{ height: `${height}px` }}
          >
            <div
              className={`react-ios-time-picker-cell-inner-minute${
                minuteObj.selected ? ' react-ios-time-picker-cell-inner-selected' : ''
              }`}
              onClick={handleClickToSelect}
              data-translated-value={minuteObj.translatedValue}
            >
              {minuteObj.number}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MinuteWheel;
