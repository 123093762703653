import { useRecoilValue, useSetRecoilState } from 'recoil';

import kakao from '@assets/image/kakao.png';
import { hasConfirmPasswordAtom } from '@containers/BugoMyPageContent/components/UserModifyContent/state/userModifyContent.atom';
import { useAuth } from '@hooks/useAuth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { PasswordInput } from '@components/PasswordInput';

type FormValues = {
  password: string;
};

const ConfirmPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  useMobileNavTitleHook('회원정보 수정', true);
  const { userProfile, confirmByUsernamePassword } = useAuth();
  const isStore = useRecoilValue(isStorePageAtom);

  const setHasConfirmPassword = useSetRecoilState(hasConfirmPasswordAtom);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (userProfile) {
      const dto = { ...data, username: userProfile?.username };
      const token = await confirmByUsernamePassword(dto);
      setHasConfirmPassword(true);
      if (token) {
        navigate(
          isStore
            ? '/flower-store/user/mypage?state=modify'
            : '/user/mypage?state=modify',
          { replace: true },
        );
      }
    }
  };

  return (
    <div className="mx-auto flex max-w-[484px] flex-col gap-8 px-4 py-32 text-14 max-sm:mx-[-1rem] max-sm:py-16">
      <div>
        <p>개인 정보를 안전하게 보호하기 위해</p>
        <p>비밀번호를 다시 한 번 입력해주세요.</p>
      </div>
      <form className="text-left max-sm:text-center" onSubmit={handleSubmit(onSubmit)}>
        <p className=" mb-2 font-medium text-black">비밀번호</p>
        <PasswordInput
          className="px-4 text-16"
          placeholder="비밀번호"
          {...register('password', { required: true })}
        />
        {errors.password && (
          <span className="text-10 text-red-500">비밀번호를 입력하세요</span>
        )}
        <button
          type="submit"
          className="mt-4 w-full rounded bg-primary px-4 py-3 font-medium text-white"
        >
          확인
        </button>
      </form>
      <div className="w-full border"></div>
      <button className="mt-1 flex h-12 w-full items-center justify-center gap-4 rounded bg-[#FEE500] py-2 px-8 text-12">
        <img src={kakao} />
        카카오로 확인
      </button>
    </div>
  );
};

export { ConfirmPasswordForm };
