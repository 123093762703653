import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { UpgradeContent } from '@containers/UpgradeContent';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';

const UpgradeBugoPage = () => {
  useMobileNavTitleHook('회원 전환');

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection>
          <UpgradeContent />
        </MainSection>
      </Body>

      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export default UpgradeBugoPage;
