import {
  NotificationInstance,
  NotificationPlacement,
} from 'antd/es/notification/interface';

export const notificateSuccess = (
  api: NotificationInstance | null,
  title: string,
  description?: string,
  placement = 'top' as NotificationPlacement,
  duration = 2.5,
) => {
  if (api)
    api.success({
      message: title,
      description: description,
      placement,
      duration,
    });
};

export const notificateInfo = (
  api: NotificationInstance | null,
  title: string,
  description?: string,
  placement = 'top' as NotificationPlacement,
  duration = 2.5,
) => {
  if (api)
    api.info({
      message: title,
      description: description,
      placement,
      duration,
    });
};

export const notificateWarning = (
  api: NotificationInstance | null,
  title: string,
  description?: string,
  placement = 'top' as NotificationPlacement,
  duration = 2.5,
) => {
  if (api)
    api.warning({
      message: title,
      description: description,
      placement,
      duration,
    });
};

export const notificateError = (
  api: NotificationInstance | null,
  title: string,
  description?: string,
  placement = 'top' as NotificationPlacement,
  duration = 2.5,
) => {
  if (api)
    api.error({
      message: title,
      description: description,
      placement,
      duration,
    });
};
