import { api_ } from '@plugin/axios';
import { PopulateOptions, QueryOptions } from 'mongoose';
import { UserCustomTheme, UserRole } from 'src/interfaces';
import { User } from '@api/mongo/interfaces';
import { AxiosResponse } from 'axios';

type AuthApiParams = {
  populate?: PopulateOptions;
  options?: QueryOptions;
  select?: string;
};

type AuthBodyBase = {
  username: string;
  password: string;
  name: string;
  phoneNumber: string;
  roles: UserRole[];
  info: {
    name: string;
    phoneNumber: string;
  };
  // for kakao social login user
  code: string;
  bugoBrand: string;
};

type AuthToken = {
  token: string;
};

type UserPatchDto = Pick<
  User,
  | 'info'
  | 'adminDetail'
  | 'bugoAgencyAdminDetail'
  | 'bugoAgencyWorkerDetail'
  | 'bugoFuneralHomeDetail'
  | 'bugoCustomerDetail'
  | 'bugoGuestDetail'
  | 'storeCustomerDetail'
>;

type UserPatchbody = UserPatchDto;

type NestAuthApi = () => {
  //* signup
  // username password user
  signupByUsernamePasswordCheckPossible: (
    body: Pick<AuthBodyBase, 'username'>,
  ) => Promise<AxiosResponse<boolean>>;
  signupByUsernamePassword: (
    body: Pick<AuthBodyBase, 'username' | 'password' | 'roles' | 'info'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  // kakao social login user
  signupByKakaoCheckPossible: (
    body: Pick<AuthBodyBase, 'code'>,
  ) => Promise<AxiosResponse<boolean>>;
  signupByKakao: (
    body: Pick<AuthBodyBase, 'code' | 'roles'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  //* login
  // username password user
  loginByUsernamePassword: (
    body: Pick<AuthBodyBase, 'username' | 'password'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  loginByUsernamePasswordBugoBrand: (
    body: Pick<AuthBodyBase, 'username' | 'password' | 'bugoBrand'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  // kakao social login user
  loginByKakao: (body: Pick<AuthBodyBase, 'code'>) => Promise<AxiosResponse<AuthToken>>;
  // guest user
  loginStoreGuest: (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  loginBugoGuest: (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  //* signup or login
  // kakao social login user
  signupOrLoginByKakao: (
    body: Pick<AuthBodyBase, 'code' | 'roles'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  // guest user
  signupOrLoginStoreGuest: (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  signupOrLoginBugoGuest: (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => Promise<AxiosResponse<AuthToken>>;
  //* user api
  // link kakao user
  linkByKakao: (body: Pick<AuthBodyBase, 'code'>) => Promise<AxiosResponse<User>>;
  // get current user(me) info
  getMe: (params?: AuthApiParams) => Promise<AxiosResponse<User>>;
  // patch user(me)
  patchMeDetail: (body: UserPatchbody) => Promise<AxiosResponse<User>>;
  patchPassword: (body: Pick<AuthBodyBase, 'password'>) => Promise<AxiosResponse<User>>;
  //* ci
  findByUsername: (body: { username: string }) => Promise<AxiosResponse<User[]>>;
  loginByCi: (body: {
    _id: string;
    imp_uid: string;
  }) => Promise<AxiosResponse<AuthToken>>;
  findByCi: (body: { imp_uid: string }) => Promise<AxiosResponse<User[]>>;
};

const nestAuthApi: NestAuthApi = () => {
  //* signup
  // username password user
  const signupByUsernamePasswordCheckPossible = async (
    body: Pick<AuthBodyBase, 'username'>,
  ) => {
    return await api_.post<boolean>('auth/signup/username-password/possible', body);
  };
  const signupByUsernamePassword = async (
    body: Pick<AuthBodyBase, 'username' | 'password' | 'roles'>,
  ) => {
    return await api_.post<AuthToken>('auth/signup/username-password', body);
  };
  // kakao social login user
  const signupByKakaoCheckPossible = async (body: Pick<AuthBodyBase, 'code'>) => {
    return await api_.post<boolean>('auth/signup/social/kakao/possible', body);
  };
  const signupByKakao = async (body: Pick<AuthBodyBase, 'code' | 'roles'>) => {
    return await api_.post<AuthToken>('auth/signup/kakao', body);
  };
  //* login
  // username password user
  const loginByUsernamePassword = async (
    body: Pick<AuthBodyBase, 'username' | 'password'>,
  ) => {
    return await api_.post<AuthToken>('auth/login/username-password', body);
  };
  const loginByUsernamePasswordBugoBrand = async (
    body: Pick<AuthBodyBase, 'username' | 'password' | 'bugoBrand'>,
  ) => {
    return await api_.post<AuthToken>('auth/login/username-password/bugo-brand', body);
  };
  // kakao social login user
  const loginByKakao = async (body: Pick<AuthBodyBase, 'code'>) => {
    return await api_.post<AuthToken>('auth/login/social/kakao', body);
  };
  // guest user
  const loginStoreGuest = async (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => {
    return await api_.post<AuthToken>('auth/login/guest/store', body);
  };
  const loginBugoGuest = async (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => {
    return await api_.post<AuthToken>('auth/login/guest/bugo', body);
  };
  //* signup or login
  // kakao social login user
  const signupOrLoginByKakao = async (body: Pick<AuthBodyBase, 'code' | 'roles'>) => {
    return await api_.post<AuthToken>('auth/signup-or-login/social/kakao', body);
  };
  // guest user
  const signupOrLoginStoreGuest = async (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => {
    return await api_.post<AuthToken>('auth/signup-or-login/guest/store', body);
  };
  const signupOrLoginBugoGuest = async (
    body: Pick<AuthBodyBase, 'name' | 'phoneNumber' | 'password'>,
  ) => {
    return await api_.post<AuthToken>('auth/signup-or-login/guest/bugo', body);
  };
  //* user api
  // link kakao user
  const linkByKakao = async (body: Pick<AuthBodyBase, 'code'>) => {
    return await api_.patch<User>('auth/link/social/kakao', body);
  };
  // get current user(me) info
  const getMe = async (params?: AuthApiParams) => {
    return await api_.get<User>('auth/me', { params });
  };
  // patch user(me)
  const patchMeDetail = async (body: UserPatchbody) => {
    return await api_.patch<User>('auth/me/detail', body);
  };
  const patchPassword = async (body: Pick<AuthBodyBase, 'password'>) => {
    return await api_.patch<User>('auth/me/password', body);
  };
  //* ci
  const findByUsername = async (body: { username: string }) => {
    return await api_.post<User[]>('auth/find/username', body);
  };
  const loginByCi = async (body: { _id: string; imp_uid: string }) => {
    return await api_.post<AuthToken>('auth/login/temp-ci', body);
  };
  const findByCi = async (body: { imp_uid: string }) => {
    return await api_.post<User[]>('auth/find/temp-ci', body);
  };

  return {
    signupByUsernamePasswordCheckPossible,
    signupByUsernamePassword,
    signupByKakaoCheckPossible,
    signupByKakao,
    loginByUsernamePassword,
    loginByUsernamePasswordBugoBrand,
    loginByKakao,
    loginStoreGuest,
    loginBugoGuest,
    signupOrLoginByKakao,
    signupOrLoginStoreGuest,
    signupOrLoginBugoGuest,
    linkByKakao,
    getMe,
    patchMeDetail,
    patchPassword,
    findByUsername,
    loginByCi,
    findByCi,
  };
};

export { nestAuthApi, AuthBodyBase };
