import { FC } from 'react';

import { Icon } from '@components/Icon';

import { MainButtonItem } from '../MainButtonSet/components/MainButtonItem';

const MobileMainButtonSet: FC = () => {
  return (
    <div className=" hidden flex-wrap gap-4 p-4 py-10 max-[792px]:grid max-[792px]:grid-cols-3 ">
      <MainButtonItem
        title="부고 만들기"
        IconSVG={() => <Icon.Register className={'h-[60px] w-[60px] '} />}
        to="/fevent/add"
      />
      <MainButtonItem
        title="부고 수정"
        IconSVG={() => <Icon.Modify className={'h-[60px] w-[60px]'} />}
        to="/fevent/list/?purpose=bugo"
      />
      <MainButtonItem
        title="답례글"
        IconSVG={() => <Icon.Courtesy className={'h-[60px] w-[60px]'} />}
        to="/fevent/list/?purpose=courtesy"
      />
      <MainButtonItem
        title="답례품"
        IconSVG={() => <Icon.Giftbox className={'h-[60px] w-[60px]'} />}
      />
      <MainButtonItem
        title="근조화환"
        IconSVG={() => <Icon.Wreath className={'h-[60px] w-[60px]'} />}
        to="/flower-store"
      />
      <MainButtonItem
        title="제휴 신청"
        IconSVG={() => <Icon.Partnership className={'h-[60px] w-[60px] stroke-none'} />}
      />
    </div>
  );
};

export { MobileMainButtonSet };
