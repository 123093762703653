import { StoreItemTagEnum } from 'src/interfaces';

export const getStoreItemTagColorStyle = (tagName: StoreItemTagEnum) => {
  switch (tagName) {
    case StoreItemTagEnum.Best:
      return ' bg-emerald-400 text-white';
    case StoreItemTagEnum.Popular:
      return ' bg-sky-400 text-white';
    case StoreItemTagEnum.SpecialPrice:
      return ' bg-gray-100 text-black';
    case StoreItemTagEnum.Primium:
      return ' bg-apricot-600 text-white';
    case StoreItemTagEnum.Reward:
      return ' bg-emerald-200 text-emerald-700';
    case StoreItemTagEnum.Hit:
      return ' bg-orange-500';
    case StoreItemTagEnum.FreeDelivery:
      return 'bg-indigo-500 text-white';
    case StoreItemTagEnum.Sale:
      return 'bg-teal-600 text-white';
    default:
      return '';
  }
};
