import { useState } from 'react';

import { useTimePickerDragProps, useTimePickerDragReturn } from './timePicker.interface';

const useTimePickerDrag = ({
  setCurrentTranslatedValue,
}: useTimePickerDragProps): useTimePickerDragReturn => {
  const [startCapture, setStartCapture] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [firstCursorPosition, setFirstCursorPosition] = useState<number>(0);
  const [dragStartTime, setDragStartTime] = useState<number>(0);
  const [dragEndTime, setDragEndTime] = useState<number>(0);
  const [dragDirection, setDragDirection] = useState<string | null>(null);
  const [showFinalTranslate, setShowFinalTranslate] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowFinalTranslate(false);
    setFirstCursorPosition(e.clientY);
    setStartCapture(true);
    setDragStartTime(performance.now());
  };

  const handleMouseUpAndMouseLeave = () => {
    setStartCapture(false);
    setCurrentTranslatedValue((prev) => prev + cursorPosition);
    setShowFinalTranslate(true);
    setDragEndTime(performance.now());
    if (cursorPosition < 0) {
      setDragDirection('down');
    } else {
      setDragDirection('up');
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (startCapture) {
      setCursorPosition(e.clientY - firstCursorPosition);
    } else {
      setCursorPosition(0);
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setShowFinalTranslate(false);
    if (e.targetTouches[0]) {
      setFirstCursorPosition(e.targetTouches[0].clientY);
    }
    setStartCapture(true);
    setDragStartTime(performance.now());
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startCapture && firstCursorPosition && e.targetTouches[0]) {
      setCursorPosition(e.targetTouches[0].clientY - firstCursorPosition);
    } else {
      setCursorPosition(0);
    }
  };

  // handle click to select number
  const handleClickToSelect = (
    e: React.MouseEvent<HTMLDivElement & { dataset: { [key: string]: string } }>,
  ) => {
    if (cursorPosition === 0) {
      const translatedValue = e.currentTarget.dataset.translatedValue;
      if (translatedValue !== undefined) {
        setCurrentTranslatedValue(parseInt(translatedValue));
      }
    }
  };

  const isFastDrag = () => dragEndTime - dragStartTime <= 100;
  const isSlowDrag = () => dragEndTime - dragStartTime > 100;

  return {
    handleMouseDown,
    handleMouseUpAndMouseLeave,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleClickToSelect,
    dragDirection,
    startCapture,
    cursorPosition,
    setCursorPosition,
    dragEndTime,
    dragStartTime,
    showFinalTranslate,
    isFastCondition: showFinalTranslate && isFastDrag(),
    isSlowCondition: showFinalTranslate && isSlowDrag(),
  };
};

export { useTimePickerDrag };
