import { MainSection } from '@components/MainSection';
import { FindIdPassword } from '@containers/FindIdPassword';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';

const FlowerStoreFindIdPasswordPage = () => {
  useMobileNavTitleHook('계정정보 찾기', true);

  return (
    <div>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection>
          <FindIdPassword />
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </div>
  );
};

export { FlowerStoreFindIdPasswordPage };
