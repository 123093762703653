import { selector } from 'recoil';

import { ShopItemForStoreState } from '@api/mongo/interfaces';

import { storeItemDataAtom, storeItemStatusAtom } from './storeItemDetail.atom';

export const storeItemStateSelector = selector<ShopItemForStoreState>({
  key: 'storeItemStateSelector',
  get: ({ get }) => {
    return {
      status: get(storeItemStatusAtom),
      data: get(storeItemDataAtom),
    };
  },
});
