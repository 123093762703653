import { FC, useEffect } from 'react';

import { BasicInput } from '@components/BasicInput';
import { useFormContext } from 'react-hook-form';
import { FeventFormData } from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';

import { BasicInfoForm } from '../../BasicInfoForm';

const WriterInfoForm: FC = () => {
  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext<FeventFormData>();
  const phoneNumber = watch('phoneNumber');

  // 필수 입력이 필요한 input을 border-red 로 보여주기 위해 첫 렌더링에서 input 검증을 한다
  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <BasicInfoForm
      title="작성자 정보"
      desc="부고장을 작성하시는 분의 정보를 작성해주세요. 추후 입력한
정보를 통해 부고를 수정하거나 답례인사를 보내실때 활용됩니다."
    >
      <div className="grid grid-cols-2 gap-4 max-sm:gap-1">
        <BasicInput
          label="작성자 성함"
          className={`px-4 ${errors.name ? 'border-red-500' : ''}`}
          placeholder="작성자 성함"
          {...register('name', { required: true })}
        />
        <BasicInput
          label="작성자 휴대폰 번호"
          className={`px-4 ${errors.phoneNumber ? 'border-red-500' : ''}`}
          placeholder="작성자 휴대폰 번호"
          value={phoneNumberStringFormator(phoneNumber)}
          {...register('phoneNumber', {
            required: true,
            maxLength: 11,
            minLength: 10,
            setValueAs: (phoneNumber) => phoneNumber.replace(/[^0-9]/gi, ''),
          })}
        />
      </div>
    </BasicInfoForm>
  );
};

export { WriterInfoForm };
