import { FC } from 'react';

import { Icon } from '@components/Icon';

import { MainButtonItem } from './components/MainButtonItem';

const MainButtonSet: FC = () => {
  return (
    <div className="flex flex-wrap justify-between  gap-2 py-[58px] px-4 max-[792px]:hidden max-sm:justify-around">
      <MainButtonItem
        title="부고 만들기"
        IconSVG={() => <Icon.Register className={'h-[60px] w-[60px] '} />}
        to="/fevent/add"
      />
      <MainButtonItem
        title="부고 수정"
        IconSVG={() => <Icon.Modify className={'h-[60px] w-[60px]'} />}
        to="/fevent/list/?purpose=bugo"
      />
      <MainButtonItem
        title="답례글"
        IconSVG={() => <Icon.Courtesy className={'h-[60px] w-[60px]'} />}
        to="/fevent/list/?purpose=courtesy"
      />
      <MainButtonItem
        title="답례품"
        IconSVG={() => <Icon.Giftbox className={'h-[60px] w-[60px]'} />}
      />
      <MainButtonItem
        title="근조화환"
        IconSVG={() => <Icon.Wreath className={'h-[60px] w-[60px]'} />}
        to="/flower-store"
      />
      <MainButtonItem
        title="제휴 신청"
        IconSVG={() => <Icon.Partnership className={'h-[60px] w-[60px] stroke-none'} />}
        to={undefined}
      />
    </div>
  );
};

export { MainButtonSet };
