import { useRecoilValue } from 'recoil';

import { BasicInput } from '@components/BasicInput';
import { Icon } from '@components/Icon';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { Link } from 'react-router-dom';

const FAQLeftContent = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);

  return (
    <div className="text-center max-sm:hidden">
      <BasicInput
        className="pl-4 pr-8"
        placeholder="검색어를 입력하세요."
        SuffixEl={<Icon.MagnifyingGlassRegular className="w-5 fill-gray-400" />}
      />
      <p className=" mt-8 mb-3 text-12 font-medium">
        그 외 궁금하신 점은 1:1 문의를 이용해 주세요
      </p>
      <Link to={isStorePage ? '/flower-store/enquiry' : '/enquiry'}>
        <button className="w-[160px] rounded bg-gray-800 px-4 py-3 text-white">
          1:1문의
        </button>
      </Link>
    </div>
  );
};

export { FAQLeftContent };
