import { FC, useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { CashAccount } from '@api/mongo/interfaces';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useFormContext } from 'react-hook-form';

import { MemberFormInputsEnum } from '../../interface/mournerInfoForm.interface';

type CashAccountItemProps = {
  account: CashAccount;
  index: number;
  cashAccountSelectModalId: string;
};

const CashAccountItem: FC<CashAccountItemProps> = ({
  account,
  index,
  cashAccountSelectModalId,
}) => {
  const setCashAccountSelectModal = useSetRecoilState(
    modalOpenAtomFamily(cashAccountSelectModalId),
  );

  const { setValue } = useFormContext();

  const setMemberAccountHandler = useCallback(() => {
    setValue(`member.${index}.${MemberFormInputsEnum.AccountHolder}`, account.name);
    // 인증 완료된 계좌의 예금주명을 변경할 수가 있어 무조건 false 처리
    // const isAccountHolderSame = getValues().member[index].name === account.name;
    // setValue(
    //   `member.${index}.${MemberFormInputsEnum.AccountHolderSame}`,
    //   isAccountHolderSame,
    // );
    setValue(`member.${index}.${MemberFormInputsEnum.AccountHolderSame}`, false);
    setValue(`member.${index}.${MemberFormInputsEnum.CashAccount}`, account.account);
    setValue(`member.${index}.${MemberFormInputsEnum.CashAccountId}`, account._id);
    setValue(`member.${index}.${MemberFormInputsEnum.Bank}`, account.bank);
    setValue(`member.${index}.isVerified`, account.isVerified);
    setCashAccountSelectModal(false);
  }, [
    account._id,
    account.account,
    account.bank,
    account.isVerified,
    account.name,
    index,
    setCashAccountSelectModal,
    setValue,
  ]);

  return (
    <div
      className="grid cursor-pointer grid-cols-4 border-b p-2 last:border-b-0 hover:bg-gray-100"
      onClick={setMemberAccountHandler}
    >
      <p>{account.name}</p>
      <p>{account.bank}</p>
      <p className="col-span-2">{account.account}</p>
    </div>
  );
};

export { CashAccountItem };
