import { FC } from 'react';
interface IDeliveryStatusFooter {
  mournerName?: string;
  funeralAddress?: string;
  senderPhrase?: string;
  condolencePhrase?: string;
}
const DeliveryStatusFooter: FC<IDeliveryStatusFooter> = ({
  mournerName,
  funeralAddress,
  senderPhrase,
  condolencePhrase,
}) => {
  return (
    <div className="max-sm:px-2">
      <div className="mt-2 w-full rounded border bg-white py-2 px-4 text-left">
        <div className="flex items-center justify-between">
          <p className="text-16 font-bold text-black">{mournerName} 상주님</p>
        </div>
        <p className="text-14 font-medium text-black">{funeralAddress}</p>
        <div className="my-2 border-t" />
        <div className="flex flex-col gap-3">
          <p className="text-16 font-bold text-black">문구정보</p>
          <div>
            <p className="text-12 font-medium">보내는분(왼쪽)</p>
            <p className="text-16 text-black">{senderPhrase}</p>
          </div>
          <div>
            <p className="text-12 font-medium">조의문구(오른쪽)</p>
            <p className="text-16 text-black">{condolencePhrase}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export { DeliveryStatusFooter };
