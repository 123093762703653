import { FC } from 'react';

import { BugoPreviewPage } from '@pages/Fevent/BugoPreviewPage';
import { BugoSendPage } from '@pages/Fevent/BugoSendPage';
import { CourtesyPreviewPage } from '@pages/Fevent/CourtesyPreviewPage';
import { CourtesySendPage } from '@pages/Fevent/CourtesySendPage';
import { DynamicStepPage } from '@pages/Fevent/DynamicStepPage';
import { FeventEditPage } from '@pages/Fevent/FeventEditPage';
import { FeventListPage } from '@pages/Fevent/FeventListPage';
import { ProtectedRoute } from '@route/ProtectedRoute';
import { Route, Routes, useLocation } from 'react-router-dom';

const FeventRouter: FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="add/" element={<FeventEditPage />}>
        <Route path=":step" element={<DynamicStepPage />} />
      </Route>
      <Route path="edit/:feventId/" element={<FeventEditPage />}>
        <Route path=":step" element={<DynamicStepPage />} />
      </Route>
      <Route
        path=":feventId/bugo-send"
        element={
          <ProtectedRoute
            loginUrl="/guest-login/?purpose=bugo"
            children={<BugoSendPage />}
          />
        }
      />
      <Route
        path=":feventId/courtesy/preview"
        element={
          <ProtectedRoute
            loginUrl="/guest-login/?purpose=courtesy"
            children={<CourtesyPreviewPage />}
          />
        }
      />
      <Route
        path=":feventId/bugo/preview"
        element={
          <ProtectedRoute
            loginUrl="/guest-login/?purpose=bugo"
            children={<BugoPreviewPage />}
          />
        }
      />
      <Route
        path=":feventId/courtesy-send"
        element={
          <ProtectedRoute
            loginUrl="/guest-login/?purpose=courtesy"
            children={<CourtesySendPage />}
          />
        }
      />
      <Route
        path="list/"
        element={
          <ProtectedRoute
            loginUrl={`/guest-login?redirect=${location.pathname}${location.search}`}
            children={<FeventListPage />}
          />
        }
      />
    </Routes>
  );
};

export { FeventRouter };
