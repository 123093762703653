import { FC, ReactNode } from 'react';

interface FlowerStoreIfnoFormProps {
  title?: string;
  desc?: string;
  children: ReactNode;
  leftSectionTopComponent?: ReactNode;
  leftSectionBottomComponent?: ReactNode;
  leftComponentClassName?: string;
}

const FlowerStoreIfnoForm: FC<FlowerStoreIfnoFormProps> = ({
  title,
  desc,
  children,
  leftSectionTopComponent,
  leftSectionBottomComponent,
  leftComponentClassName,
}) => {
  return (
    <div className="mt-8 flex gap-8 border-t border-gray-400 py-8 max-sm:mt-0 max-sm:block max-sm:border-none max-sm:py-0">
      <div
        className={`shrink-0 basis-[320px] text-left max-sm:shrink ${leftComponentClassName}`}
      >
        {title && (
          <p className="mb-4 text-16 font-medium text-black max-sm:hidden">{title}</p>
        )}
        {leftSectionTopComponent}
        {desc && (
          <p className="mb-4 whitespace-pre-line break-keep text-12 text-gray-500 max-sm:hidden">
            {desc}
          </p>
        )}
        {leftSectionBottomComponent}
      </div>
      <div className="shrink-0 grow basis-[300px] max-sm:shrink">{children}</div>
    </div>
  );
};

export { FlowerStoreIfnoForm };
