import { FC } from 'react';

import { useRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { mobileSidePopupModalId } from '@containers/MobileSidePopup';

type MobileMenuButtonProps = {
  rightMenu?: boolean;
  onClick?: () => void;
};

const MobileMenuButton: FC<MobileMenuButtonProps> = ({ rightMenu = true, onClick }) => {
  const [mobileSidePopupOpen, setMobileSidePopupOpen] = useRecoilState(
    modalOpenAtomFamily(mobileSidePopupModalId),
  );

  const sidePopupViewHandler = () => {
    setMobileSidePopupOpen((prev) => !prev);
  };

  return (
    <div className="hidden cursor-pointer max-sm:block">
      {rightMenu ? (
        <div onClick={sidePopupViewHandler}>
          {mobileSidePopupOpen ? <Icon.X /> : <Icon.Menu />}
        </div>
      ) : (
        <div onClick={onClick ? onClick : sidePopupViewHandler}>
          <Icon.X />
        </div>
      )}
    </div>
  );
};

export { MobileMenuButton };
