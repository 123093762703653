import { useEffect } from 'react';

import { MobileNavigation } from '@components/MobileNavigation';
import { GuestLoginForm } from '@containers/GuestLoginForm';
import { GuestLoginFormTitle } from '@containers/GuestLoginForm/components/GuestLoginFormTitle';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { useAuth } from '@hooks/useAuth';
import { Body, Header } from '@layout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginBtnHandler } from '@hooks/useLoginBtnHandler';

const GuestLoginPage = () => {
  const { redirectUrl } = useLoginBtnHandler();
  const [searchParams] = useSearchParams();
  const purpose = searchParams.get('purpose');
  const navigate = useNavigate();
  const { isAuthenticated, redirectAfterLogin } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectUrl ?? redirectAfterLogin ?? '/');
    }
  }, [isAuthenticated, navigate, redirectAfterLogin, redirectUrl]);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header>
        <div className="before:content-'' mx-auto mt-[135px] h-[437px] w-[352px] text-gray-700 before:absolute before:inset-0 before:-z-10 before:bg-gray-50 max-sm:mt-[112px] max-sm:h-[549px] max-sm:w-full max-sm:px-2">
          <GuestLoginFormTitle purpose={purpose} />
          <GuestLoginForm />
          <div className="mt-8">
            <p className="font-['Noto_Sans'] text-12 text-gray-500">회원이신가요?</p>
            <Link to={`/login${purpose ? `?purpose=${purpose}` : ''}`}>
              <button className="mt-2 w-full rounded border border-apricot-600 bg-white px-8 py-3 text-18 text-apricot-600">
                회원 로그인
              </button>
            </Link>
          </div>
        </div>
      </Body>
    </>
  );
};

export { GuestLoginPage };
