import React, { FC, useCallback, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmModal } from '@components/ConfirmModal';
import { UseFormTrigger } from 'react-hook-form';

import {
  StepNameEnum,
  stepper,
  stepperProps,
} from '../state/feventSubmitContent.interface';

type FeventStepperProps = {
  checkEqual: () => boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  trigger: UseFormTrigger<any>;
};

const FeventStepper: FC<FeventStepperProps> = ({ checkEqual, onSubmit, trigger }) => {
  const setOpen = useSetRecoilState(
    modalOpenAtomFamily('BugoSendFormSubmitSuccess-modal'),
  );
  const navigate = useNavigate();
  const { step: currentStep } = useParams();
  const confirmModalId = 'confirm-diffrent-modal';
  const diffModal = useSetRecoilState(modalOpenAtomFamily(confirmModalId));
  const [stepState, setStepState] = useState<StepNameEnum>(StepNameEnum.DeceasedInfo);

  const getEnumIndex = (enumObj: any, value: string) => {
    return Object.values(enumObj).indexOf(value);
  };

  const handleClick = useCallback(
    async (item: stepperProps) => {
      setStepState(item.step);

      const from = getEnumIndex(StepNameEnum, currentStep ?? '');
      const to = getEnumIndex(StepNameEnum, item.step);
      const ok = to < from;
      const isValid = await trigger(); // 필수항목 체크
      const isDiff = !checkEqual(); // 변경여부 체크

      console.log('isDiff : ', isDiff);

      const navigateOrSubmit = () => {
        if (isValid || ok) {
          navigate(`../${item.step}`);
        } else {
          onSubmit();
        }
      };

      if (isDiff) {
        ok || isValid ? diffModal(true) : onSubmit();
      } else {
        navigateOrSubmit();
      }
    },
    [currentStep, navigate, trigger, checkEqual, diffModal, onSubmit],
  );

  return (
    <div className="relative mb-4 flex w-auto items-center justify-between gap-[23px]">
      {stepper.map((item, index) => {
        return (
          <div
            key={item.step}
            onClick={() => handleClick(item)}
            className={`z-10 grow cursor-pointer border-t-2 text-left text-14 transition-all duration-300 ${
              item.step === currentStep ? 'border-apricot-600 ' : 'border-gray-300'
            }`}
          >
            <p
              className={`${
                item.step === currentStep
                  ? 'border-apricot-600 text-apricot-600'
                  : 'text-gray-400'
              }`}
            >
              Step {index + 1}
            </p>
            <span className="font-medium">{item.title}</span>
          </div>
        );
      })}
      <ConfirmModal
        openId={confirmModalId}
        title="변경사항이 존재합니다. 저장하시겠습니까?"
        buttonTitle="저장"
        onClick={async () => {
          await onSubmit();
          diffModal(false);
          setOpen(false);
          navigate(`../${stepState}`);
        }}
      />
    </div>
  );
};

export { FeventStepper };
