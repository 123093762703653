import React, { FC, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Label } from '@components/Label';
import { Icon } from '@components/Icon';

import { TimePickerProps } from './state/timePicker.interface';
import TimePickerSelection from './components/TimePickerSelection';

const TimePicker: FC<TimePickerProps> = ({
  openId,
  label,
  value = null,
  cellHeight = 32,
  placeHolder = '시간을 선택해주세요.',
  pickerDefaultValue = '10:00',
  saveButtonText = '확인',
  use12Hours = false,
  onChange = () => {
    return;
  },
  onClear,
  onAmPmChange = () => {
    return;
  },
}) => {
  const [inputValue, setInputValue] = useState(value);
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const onClickClear = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setInputValue(null);
    onClear && onClear();
  };

  return (
    <div>
      {label && <Label text={label} />}
      <div
        className="relative"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Icon.Time className="absolute top-1 left-2 z-20" />
        <div
          className={`flex h-10 w-full cursor-pointer items-center rounded border bg-white pl-11 ${
            inputValue === null && 'text-gray-400'
          }`}
          placeholder={placeHolder}
        >
          {inputValue === null ? placeHolder : inputValue}
        </div>
        {inputValue && (
          <Icon.X
            className="absolute top-1 right-2 z-20 cursor-pointer stroke-none"
            onClick={onClickClear}
          />
        )}
      </div>
      <TimePickerSelection
        openId={openId}
        label={label ?? ''}
        cellHeight={cellHeight}
        initialValue={inputValue}
        onAmPmChange={onAmPmChange}
        onChange={onChange}
        pickerDefaultValue={pickerDefaultValue}
        saveButtonText={saveButtonText}
        setInputValue={setInputValue}
        use12Hours={use12Hours}
      />
    </div>
  );
};

export default TimePicker;
