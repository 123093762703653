export interface NumberOption {
  number: string;
  translatedValue: string;
  selected?: boolean;
  hidden?: boolean;
}

const createNumberOption = (
  number: string,
  translatedValue: string,
  selected = false,
): NumberOption => {
  return {
    number,
    translatedValue,
    selected,
  };
};

const createNumberOptions = (
  heightValue: number,
  numbersLength: number,
  value: number,
  initialValue: NumberOption[],
): NumberOption[] => {
  const arrayOfSelectedValue: NumberOption[] = [...initialValue];

  let count = 0;
  for (let index = 0; index < 3; index++) {
    for (let j = 0; j < numbersLength; j++) {
      if ((index === 0 && j < 2) || (numbersLength === 13 && j === 0)) {
        continue;
      }
      const selected = index === 1 && j === value;

      const number = j.toString().length === 1 ? `0${j}` : j.toString();
      const translatedValue = count === 0 ? `${count}` : `-${count}`;
      const option = createNumberOption(number, translatedValue, selected);

      arrayOfSelectedValue.push(option);

      count += heightValue;
    }
  }

  return arrayOfSelectedValue;
};

export const initialNumbersValue = (
  heightValue = 54,
  numbersLength = 24,
  value = 0,
): NumberOption[] => {
  const initialValue24hourFormat: NumberOption[] = [
    createNumberOption('00', (heightValue * 2).toString(), false),
    createNumberOption('01', heightValue.toString(), false),
  ];

  const initialValue12hourFormat: NumberOption[] = [
    createNumberOption('00', heightValue.toString(), false),
    createNumberOption('01', heightValue.toString(), false),
  ];

  const arrayOfSelectedValue =
    numbersLength === 13 ? initialValue12hourFormat : initialValue24hourFormat;

  return createNumberOptions(heightValue, numbersLength, value, arrayOfSelectedValue);
};

export const returnSelectedValue = (
  heightValue = 54,
  numbersLength = 24,
): NumberOption[] => {
  const arrayOfSelectedValue: NumberOption[] = [
    createNumberOption('00', (heightValue * 2).toString()),
    createNumberOption('01', heightValue.toString()),
  ];

  return createNumberOptions(heightValue, numbersLength, 0, arrayOfSelectedValue);
};
