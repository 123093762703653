import { useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { Loading } from '@components/Loading';
import { MobileNavigation } from '@components/MobileNavigation';
import { BugoPreview } from '@containers/BugoPreviewPageContent';
import { BugoPreviewSection } from '@containers/BugoPreviewPageContent/components/BugoPreviewSection';
import { MournerList } from '@containers/BugoPreviewPageContent/components/PreviewMournerListSection/PreviewMournerList';
import { bugoPreviewAtom } from '@containers/BugoPreviewPageContent/state/bugoPreview.atom';
import { useFeventMemberOrderBugoByIdHook } from '@containers/BugoPreviewPageContent/state/bugoPreview.query';
import { feventMemberOrderBugoStateSelectorFamily } from '@containers/BugoPreviewPageContent/state/bugoPreview.selector';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';
import { useLocation, useParams } from 'react-router-dom';

function useSeacrchParamsTemp() {
  return new URLSearchParams(useLocation().search);
}

const BugoPreviewPage = () => {
  const setBugoPreviewState = useSetRecoilState(bugoPreviewAtom);
  const { feventId } = useParams();

  const bugoId = useSeacrchParamsTemp().get('bugoId');

  useFeventMemberOrderBugoByIdHook(feventId ?? '');
  const { data } = useRecoilValue(
    feventMemberOrderBugoStateSelectorFamily(feventId ?? ''),
  );

  const isMobileMedia = !useMobileMediaQuery();

  const MournerListContent = useMemo(() => {
    if (bugoId && !isMobileMedia) return null;

    if (!data) {
      return <Loading />;
    }

    return (
      <MournerList
        listData={data.memberOrderList}
        feventId={feventId ?? ''}
        isMobileMedia={isMobileMedia}
        currentBugoId={bugoId || ''}
      />
    );
  }, [bugoId, isMobileMedia, feventId, data]);

  const bugoPreviewPlaceholder = (
    <div className="mx-auto mt-[128px] flex flex-col gap-y-3 break-keep text-24 font-medium leading-8 text-black ">
      <Icon.BugoMessage className="mx-auto h-[64px] w-[64px] stroke-none" />
      <p>부고장 미리보기 생성 영역</p>
      <p>상주님을 선택해주세요</p>
      <p>해당 상주님의 부고장을 미리 보실 수 있습니다 </p>
    </div>
  );

  useEffect(() => {
    bugoId && isMobileMedia ? setBugoPreviewState(true) : setBugoPreviewState(false);
  }, [bugoId, isMobileMedia, setBugoPreviewState]);

  useMobileNavTitleHook(
    bugoId ? '부고장 미리보기' : '부고장 미리보기(상주님 선택)',
    true,
    bugoId ? false : true,
    bugoId ? `/fevent/${feventId}/bugo/preview` : '',
    bugoId ? `/fevent/${feventId}/bugo/preview` : '',
  );

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <BugoPreviewSection title="부고장 미리보기" bugoId={bugoId ? true : false}>
          {!isMobileMedia ? (
            <>
              {MournerListContent}
              {!isMobileMedia && bugoId && <BugoPreview bugoId={bugoId} />}
            </>
          ) : (
            <>
              <BasicInfoForm
                title="상주님 선택"
                desc={`상주님 별로 부고장이 다르게 생성됩니다.\n 상주님을 선택하셔서 상주님이 받으실 부고장을 미리 확인해보실 수 있습니다.`}
                leftSectionBottomComponent={MournerListContent}
              >
                {!isMobileMedia ? (
                  bugoId && <BugoPreview bugoId={bugoId} />
                ) : (
                  <div className=" min-h-[1404px] w-full border bg-[#F9FAFB]">
                    {bugoId ? <BugoPreview bugoId={bugoId} /> : bugoPreviewPlaceholder}
                  </div>
                )}
              </BasicInfoForm>
            </>
          )}
        </BugoPreviewSection>
      </Body>
      {!bugoId && (
        <Footer>
          <FooterContent />
        </Footer>
      )}
    </>
  );
};

export { BugoPreviewPage, useSeacrchParamsTemp };
