import { atom, atomFamily } from 'recoil';

import { Fevent, Member } from '@api/mongo/interfaces';
import { QueryStatus } from 'react-query';

export const courtesyAssetIdAtom = atom({
  key: 'courtesyAssetIdAtom',
  default: '',
});

export const courtesyAssetNameAtom = atom({
  key: 'courtesyAssertNameAtom',
  default: '',
});

export const courtesyAssetSrcAtom = atom({
  key: 'courtesyAssetSrcAtom',
  default: '',
});

export const courtesyContentStringAtom = atom({
  key: 'courtesyContentStringAtom',
  default: '',
});

export const currentMemberStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'currentMemberStatusAtom',
  default: 'idle',
});

export const currentMemberDataAtomFamily = atomFamily<Member<Fevent>, string>({
  key: 'currentMemberDataAtom',
  default: undefined,
});
