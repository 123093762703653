import RadioInput from '@components/RadioInput';
import _ from 'lodash';
import { UseFormSetValue } from 'react-hook-form';

const cancelReasonArr = [
  {
    value: 'orderChange',
    label: '색상/사이즈/주문정보 변경',
  },
  {
    value: 'orderMistake',
    label: '다른 상품 잘못 주문',
  },
  {
    value: 'interestLost',
    label: '구매의사 없어짐',
  },
];

const SelectCancelReasonBox = ({
  setValue,
}: {
  setValue: UseFormSetValue<{
    cancelReason: string;
  }>;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('cancelReason', event.target.value);
  };

  return (
    <div>
      <div className="bg-gray-100 p-4 text-left text-16 font-medium text-black">
        취소사유선택
      </div>

      {_.map(cancelReasonArr, (item, idx) => {
        return (
          <RadioInput
            key={idx}
            name="cancelReason"
            value={item.value}
            className=" h-5 w-5 border-2 bg-white checked:border-none  checked:bg-apricot-600   "
            frameClassName="text-black cursor-pointer bg-white border-b text-16 py-4"
            label={item.label}
            useCheckIcon
            onChange={handleChange}
          />
        );
      })}
    </div>
  );
};

export { SelectCancelReasonBox };
