import { FC } from 'react';

import { PcSelectProps } from '../state/autoSelect.interface';

const PcSelect: FC<PcSelectProps> = ({
  value,
  allowsDirectInput,
  optionList,
  selectHandler,
  directInputHandler,
  onOptionClick,
}) => {
  return (
    <div
      className={`thin-scrollbar absolute z-10 max-h-[200px] w-full overflow-hidden overflow-y-auto scroll-smooth rounded-b border-b shadow`}
    >
      {allowsDirectInput && (
        <option
          className="cursor-pointer truncate border bg-white py-2 px-4 text-left first:border-t-0 last:border-t-0 hover:bg-primary hover:text-white"
          onClick={directInputHandler}
        >
          직접 입력
        </option>
      )}
      {optionList.map((option, index) => {
        return (
          <option
            key={index}
            className={`cursor-pointer truncate border border-t-0 py-2 px-4 text-left first:border-t-0 last:border-b-0 hover:bg-primary hover:text-white ${
              value === option ? 'bg-primary text-white' : 'bg-white'
            }`}
            onClick={(e) => {
              selectHandler(option);
              onOptionClick && onOptionClick(e);
            }}
          >
            {option}
          </option>
        );
      })}
    </div>
  );
};

export { PcSelect };
