import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Map } from '@components/Map';
import { Coord, CustomCallback } from 'src/interfaces';

import SearchBar from './SearchBar/SearchBar';
import { searchTextAtomFamily } from './SearchBar/state/searchBar.atom';

const SEARCH_TEXT_ID = 'addressSearcher';

interface ISearchedItem {
  id: string;
  name: string;
  address: string;
  roadAddress: string;
  x: string;
  y: string;
}

interface IProps {
  onChange: CustomCallback<{ address: string; coord?: Coord }, any>;
}

const AddressSearcher: FC<IProps> = ({ onChange }: IProps) => {
  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ISearchedItem | null>(null);
  const [searchedItemList, setSearchedItemList] = useState<ISearchedItem[]>([]);

  const searchAddressToCoordinate = useCallback((keyword: string) => {
    const places = new window.kakao.maps.services.Places();
    places.keywordSearch(keyword, (result: any, status: any) => {
      if (status === window.kakao.maps.services.Status.OK) {
        return setSearchedItemList(
          result.map((item: any) => ({
            id: item.id,
            name: item.place_name,
            address: item.address_name,
            roadAddress: item.road_address_name,
            x: item.x,
            y: item.y,
          })),
        );
      }
    });
  }, []);

  useEffect(() => {
    if (searchText) {
      searchAddressToCoordinate(searchText);
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    setSelectedItem(null);
    onChange({ address: '', coord: undefined });
  }, [onChange, searchAddressToCoordinate, searchText]);

  const renderNoResult = useMemo(
    () => (
      <p className="whitespace-pre-line break-keep py-3 text-center text-xs font-medium">
        검색된 주소가 없습니다
      </p>
    ),
    [],
  );

  const renderSearchBox = useMemo(() => {
    const onSearchedAddressItemClick = (item: ISearchedItem) => {
      setSelectedItem(item);
      setIsSearching(false);
      onChange({ address: item.roadAddress, coord: { x: item.x, y: item.y } });
    };

    return searchedItemList ? (
      <ul className="max-h-64 space-y-2 overflow-y-auto border bg-white px-4 py-3 ">
        {searchedItemList.map((item, index) => (
          <li key={'searched-address-item-' + item.id}>
            <button
              type="button"
              className={`w-full space-y-1.5 text-start  ${
                index === 0 ? '' : 'border-t pt-3'
              }`}
              onClick={() => onSearchedAddressItemClick(item)}
            >
              <h6 className="font-bold">{item.name}</h6>
              <p className="whitespace-pre-line break-keep text-xs">
                <span className="pr-2 text-xs font-medium text-gray-500">지번</span>
                {item.address}
              </p>
              <p className="whitespace-pre-line break-keep text-xs">
                <span className="pr-2 text-xs font-medium text-gray-500">도로명</span>
                {item.roadAddress}
              </p>
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <>{renderNoResult}</>
    );
  }, [onChange, renderNoResult, searchedItemList]);

  const renderMap = useMemo(() => {
    return selectedItem ? (
      <>
        <div className="h-[200px] w-full">
          <Map
            coord={{ x: selectedItem.x, y: selectedItem.y }}
            name={selectedItem.name}
          />
        </div>
        <div className=" space-y-1">
          <h5 className="font-medium">표기주소</h5>
          <p className="whitespace-pre-line break-keep font-medium text-gray-500">
            {selectedItem.roadAddress}
          </p>
        </div>
      </>
    ) : (
      <>{renderNoResult}</>
    );
  }, [renderNoResult, selectedItem]);

  return (
    <div className="space-y-3">
      <SearchBar
        searchTextAtomId={SEARCH_TEXT_ID}
        placeholder="장례식장 이름 및 주소로 검색하세요"
      />
      <div className="space-y-3">{isSearching ? renderSearchBox : renderMap}</div>
    </div>
  );
};

export default AddressSearcher;
