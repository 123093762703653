//문자열에 지정한 start,end만큼을 '*'로 마스킹
export function maskStr(str: string, start: number, end: number) {
  if (str.length <= 1) return str;
  return `${str.substring(0, start)}${'*'.repeat(end - start + 1)}${str.substring(
    end + 1,
  )}`;
}

/**
 * 이메일 주소의 사용자 이름 일부와 도메인 이름 일부를 마스킹합니다.
 * 사용자 이름은 마지막 3분의 1만큼 '*'로 마스킹하고, 도메인 이름은 첫 글자를 제외한 나머지 부분을 '*'로 마스킹합니다.
 * @param email - 마스킹 처리할 이메일 주소
 * @returns 마스킹된 이메일 주소
 */
export const maskEmail = (email: string) => {
  const [username = '', domain = ''] = email.split('@');
  const [domainName = '', domainExtension = ''] = domain.split('.');

  if (domainName.length === 0) return '';

  const maskedUsername = maskStr(
    username,
    username.length - Math.ceil(username.length / 3),
    username.length,
  );
  const maskedDomainName = maskStr(domainName, 1, domainName.length);

  return `${maskedUsername}@${maskedDomainName}.${domainExtension}`;
};

export function maskPhoneNumber(phoneNumber: string) {
  const parts = phoneNumber.split('-');
  if (!parts[1] || !parts[2]) return phoneNumber;
  if (parts.length !== 3) return phoneNumber;
  const maskedMiddle = '*'.repeat(parts[1].length);
  const maskedLast =
    '*'.repeat(parts[2].length - 2) + parts[2].substring(parts[2].length - 2);

  return `${parts[0]}-${maskedMiddle}-${maskedLast}`;
}

const emailRegex =
  /^[\w\-\u1100-\u11FF\u3130-\u318F\uAC00-\uD7AF]+(\.[\w\-\u1100-\u11FF\u3130-\u318F\uAC00-\uD7AF]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
const passwordRegex = /^(.){5,20}$/;
const nameRegex = /\S/;
const phoneNumberRegex = /^\d{3}-\d{3,4}-\d{4}$/;

export const isValidEmail = (email: string) => {
  return emailRegex.test(email);
};

export const isVaildPassword = (password: string) => {
  return passwordRegex.test(password);
};

export const isVaildName = (name: string) => {
  return nameRegex.test(name);
};

export const isVaildPhoneNumber = (phoneNumber: string) => {
  return phoneNumberRegex.test(phoneNumber);
};
