import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { TabContentProps } from './state/tabContent.interface';

const TabContent: FC<TabContentProps> = (props) => {
  const { list, index } = props;
  const navigate = useNavigate();

  return (
    <div className="  ">
      <ul className="flex flex-col gap-1 px-4 ">
        {list &&
          list.map((item) => {
            return (
              <li
                className=" flex cursor-pointer items-center border-b px-6 py-3 text-left text-gray-500"
                key={item._id}
                onClick={() => {
                  navigate('/faq', { state: { index } });
                }}
              >
                <span className="overflow-hidden  truncate text-14 font-bold">
                  {item.question}
                </span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export { TabContent };
