import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { FlowerStoreIfnoForm } from '@components/FlowerStoreInfoForm';
import { FlowerStoreSection } from '@components/FlowerStoreSection';
import { FlowerStoreItemDetail } from '@containers/FlowerStoreItemDetail';
import { StoreItemAccountNotice } from '@containers/FlowerStoreItemDetail/components/StoreItemAccountNotice';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { Body, Header, Footer } from '@layout';
import { useParams } from 'react-router-dom';
import { useStoreItemHook } from '@containers/FlowerStoreItemDetail/state/storeItemDetail.hook';
import { storeItemStateSelector } from '@containers/FlowerStoreItemDetail/state/storeItemDetail.selector';
import { Loading } from '@components/Loading';
import { useScrollToTopHook } from '@hooks/useScrollToTop';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { purchasePolicyText, refundText } from 'src/@utils/textDats';

const ShopItemDetailPage = () => {
  const { itemId } = useParams();
  useStoreItemHook(itemId ?? '');
  useScrollToTopHook();
  useMobileNavTitleHook('화환보내기', true);
  const { data, status } = useRecoilValue(storeItemStateSelector);
  const isMobileMedia = useMobileMediaQuery();

  const Notice = useCallback(() => {
    return (
      <div>
        <StoreItemAccountNotice title="구매 배송안내" desc={purchasePolicyText} />
        <StoreItemAccountNotice title="교환/환불 관련 안내" desc={refundText} />
      </div>
    );
  }, []);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <FlowerStoreSection title="화환보내기">
          <FlowerStoreIfnoForm
            title="화환보내기"
            leftSectionTopComponent={isMobileMedia ? null : <Notice />}
          >
            {status === 'success' && data ? (
              <FlowerStoreItemDetail itemData={data} />
            ) : (
              <Loading />
            )}
            {isMobileMedia && <Notice />}
          </FlowerStoreIfnoForm>
        </FlowerStoreSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { ShopItemDetailPage };
