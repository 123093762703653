import { FC, InputHTMLAttributes, useEffect, useState } from 'react';

import { FeventFormData } from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import { UseFormSetValue } from 'react-hook-form';
import { Gender } from 'src/interfaces';

import { Label } from './Label';

interface GenderButtonProps {
  setGender: React.Dispatch<React.SetStateAction<Gender>>;
  gender: Gender;
  value: Gender;
}

const GenderButton: FC<GenderButtonProps> = ({ setGender, gender, value }) => {
  return (
    <div
      onClick={() => setGender(value)}
      className={`z-10 flex h-full w-[50%] cursor-pointer items-center justify-center transition-colors ${
        gender === value ? 'text-white' : ''
      }`}
    >
      {value}
    </div>
  );
};

interface GenderInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: Gender;
  setValue: UseFormSetValue<FeventFormData>;
}

const GenderInput: FC<GenderInputProps> = ({ label, value, setValue }) => {
  const [gender, setGender] = useState(value ?? Gender.Male);

  useEffect(() => {
    setValue('deceasedInfo.sex', gender);
  }, [gender, setValue]);

  return (
    <div>
      {label && <Label text={label} />}
      <div className={`relative flex h-10 overflow-clip rounded border`}>
        <div
          className={`absolute h-full w-1/2 bg-gray-800 transition-transform ${
            gender === Gender.Male ? '' : 'translate-x-[100%]'
          }`}
        ></div>
        <GenderButton gender={gender} setGender={setGender} value={Gender.Male} />
        <GenderButton gender={gender} setGender={setGender} value={Gender.Femail} />
      </div>
    </div>
  );
};

export { GenderInput };
