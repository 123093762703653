import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { Image } from './image.interface';

const imageListDataAtom = atom<Image[] | undefined>({
  key: 'imageListAtom',
  default: undefined,
});

const imageListStatusAtom = atom<QueryStatus>({
  key: 'imageListStatusAtom',
  default: 'idle',
});

export { imageListDataAtom, imageListStatusAtom };
