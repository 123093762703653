import { atomFamily } from 'recoil';

import { BugoData } from '@api/mongo/interfaces/bugo.interface';
import { QueryStatus } from 'react-query';

import { Member } from './../../../api/mongo/interfaces/member.interface';

export const bugoStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'bugoStatusAtomFamily',
  default: 'idle',
});

export const bugoDataAtomFamily = atomFamily<BugoData | undefined, string>({
  key: 'bugoDataAtomFamily',
  default: undefined,
});

export const memberBugoDataAtomFamily = atomFamily<Member<string, BugoData>, string>({
  key: 'memberBugoDataAtomFamily',
  default: undefined,
});

export const memberBugoStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'memberBugoStatusAtomFamily',
  default: 'idle',
});
