import { FC, PropsWithChildren } from 'react';

const CustomerGrid: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <div className="flex justify-center gap-4 pb-[72px] max-lg:flex-wrap max-sm:gap-10 max-sm:pb-10">
      {children}
    </div>
  );
};

export { CustomerGrid };
