import { selector } from 'recoil';

import {
  persistUserPasswordAtom,
  persistUsernameAtom,
} from './persistUserLoginData.atom';

type UserLoginData = {
  username: string;
  password: string;
};

export const userLoginDataSelector = selector<UserLoginData>({
  key: 'userLoginDataSelector',
  get: ({ get }) => {
    return {
      username: get(persistUsernameAtom),
      password: get(persistUserPasswordAtom),
    };
  },
});
