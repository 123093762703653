import { FC, PropsWithChildren } from 'react';

const Footer: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <div className="flex w-full items-center justify-center bg-gray-800 p-8 text-white">
      {children}
    </div>
  );
};

export { Footer };
