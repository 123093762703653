const CsCard = () => {
  return (
    <a href="tel:1577-1954">
      <div className="flex h-[150px]  w-full items-center justify-between bg-[#f3f4f6] bg-[url('src/assets/image/cs-card-bg.webp')] py-10 px-8">
        <div>
          <div className="mb-4 text-left text-24 font-bold">1577-1954</div>
          <div>AM 09:00 ~ PM 20:00</div>
        </div>
        <div>
          <div
            className={`break-keep rounded-lg border bg-[#f4f4f5]  p-2 text-left leading-7 `}
          >
            어떤 문의에도 성심성의껏
            <br /> 답변드리겠습니다.
          </div>
        </div>
      </div>
    </a>
  );
};

export { CsCard };
