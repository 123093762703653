import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { UserBugoHistroyPage } from './userBugoHistroryContent.interface';

const userBugoHistroryContentDataAtom = atom<UserBugoHistroyPage>({
  key: 'bugoMyPageContentDataAtom',
  default: undefined,
});

const userBugoHistroryContentStatusAtom = atom<QueryStatus>({
  key: 'bugoMyPageContentStatusAtom',
  default: 'idle',
});

export { userBugoHistroryContentDataAtom, userBugoHistroryContentStatusAtom };
