import { useMemo, PropsWithChildren } from 'react';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

const ApolloWrapper: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: new WebSocketLink({
        // uri: 'wss://', // use wss for a secure endpoint
        uri: process.env.REACT_APP_HASURA_ENDPOINT ?? '', // use wss for a secure endpoint
        options: {
          lazy: true,
          reconnect: true,

          connectionParams: () => {
            return {
              headers: {
                //       Authorization: `Bearer ${token}`,
                'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET ?? '',
              },
            };
          },
        },
      }),
    });
  }, []);

  // if (!process.env.REACT_APP_HASURA_ENDPOINT) return <>{children}</>;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { ApolloWrapper };
