import React, { useState, useRef, useEffect, FC } from 'react';

import { useTimePickerDrag } from '../state/timePicker.hook';
import { HourFormatProps, HoursProps } from '../state/timePicker.interface';

const HourFormat: FC<HourFormatProps> = ({ height, setHourFormat, hourFormat }) => {
  const Hours = [
    {
      number: 'AM',
      translatedValue: (height * 2).toString(),
      selected: false,
    },
    {
      number: 'PM',
      translatedValue: height.toString(),
      selected: false,
    },
  ];

  const [hours, setHours] = useState<HoursProps[]>([
    {
      number: 'AM',
      translatedValue: (height * 2).toString(),
      selected: hourFormat.hourFormat === 'AM',
    },
    {
      number: 'PM',
      translatedValue: height.toString(),
      selected: hourFormat.hourFormat === 'PM',
    },
  ]);
  const mainListRef = useRef<HTMLDivElement>(null);
  const [currentTranslatedValue, setCurrentTranslatedValue] = useState(() => {
    const selectedHour = hours.filter((item) => item.selected === true)[0];
    return selectedHour ? parseInt(selectedHour.translatedValue) : 0;
  });

  const {
    handleMouseDown,
    handleMouseUpAndMouseLeave,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleClickToSelect,
    startCapture,
    cursorPosition,
    setCursorPosition,
    showFinalTranslate,
  } = useTimePickerDrag({ setCurrentTranslatedValue });

  // preview translation
  useEffect(() => {
    if (startCapture && mainListRef.current) {
      mainListRef.current.style.transform = `translateY(${
        currentTranslatedValue + (cursorPosition ? cursorPosition : 0)
      }px)`;
    }
  }, [currentTranslatedValue, cursorPosition, startCapture]);

  // final translation here
  useEffect(() => {
    if (showFinalTranslate) {
      // setDragDuration(dragEndTime - dragStartTime);

      let finalValue = Math.round(currentTranslatedValue / height) * height;
      if (finalValue < height) finalValue = height;
      if (finalValue > height * 2) finalValue = height * 2;
      if (mainListRef.current) {
        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
      }
      setCurrentTranslatedValue(finalValue);
      setCursorPosition(0);
    }
  }, [currentTranslatedValue, height, setCursorPosition, showFinalTranslate]);

  // return to default position after drag end (handleTransitionEnd)
  const handleTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
    if (e.propertyName === 'transform') {
      const selectedValueArray = [
        {
          number: 'AM',
          translatedValue: (height * 2).toString(),
          arrayNumber: 0,
        },
        {
          number: 'PM',
          translatedValue: height.toString(),
          arrayNumber: 1,
        },
      ];
      selectedValueArray.map((item) => {
        if (parseInt(item.translatedValue) === currentTranslatedValue) {
          // setSelectedNumber(item.arrayNumber);
          setHourFormat({ mount: true, hourFormat: item.number });
          setHours(() => {
            const newValue = Hours.map((hour) => {
              if (
                hour.number === item.number &&
                hour.translatedValue === currentTranslatedValue.toString()
              ) {
                return {
                  ...hour,
                  selected: true,
                };
              }
              return hour;
            });
            return newValue;
          });
        }
      });
    }
  };

  /** ***************************   handle wheel scroll ************************* */

  const handleWheelScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY > 0) {
      if (currentTranslatedValue <= height) {
        setCurrentTranslatedValue((prev) => prev + height);
      }
    } else if (currentTranslatedValue >= height * 2) {
      setCurrentTranslatedValue((prev) => prev - height);
    }
  };

  return (
    <div
      className="react-ios-time-picker-hour-format"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUpAndMouseLeave}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUpAndMouseLeave}
      style={{ height: height * 5 }}
      onWheel={handleWheelScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUpAndMouseLeave}
    >
      <div
        ref={mainListRef}
        className={`${
          showFinalTranslate && 'react-ios-time-picker-hour-format-transition'
        }`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateY(${currentTranslatedValue}px)` }}
      >
        {hours.map((hourObj, index) => (
          <div
            key={index}
            className="react-ios-time-picker-cell-hour"
            style={{ height: `${height}px` }}
          >
            <div
              className={`react-ios-time-picker-cell-inner-hour-format${
                hourObj.selected
                  ? ' react-ios-time-picker-cell-inner-hour-format-selected'
                  : ''
              }`}
              onClick={handleClickToSelect}
              data-translated-value={hourObj.translatedValue}
            >
              {hourObj.number}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HourFormat;
