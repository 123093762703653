import { OrderPaymentContent } from '@containers/FlowerStoreOrderContent/OrderPaymentContent';
import { OrderPaymentPageHeader } from '@containers/FlowerStoreOrderContent/OrderPaymentContent/components/OrderPaymentPageHeader';
import { useNavigate } from 'react-router-dom';

const OrderPaymentPage = () => {
  const navigate = useNavigate();

  return (
    <div className=" h-[100vh] bg-gray-50">
      <OrderPaymentPageHeader rightButtonHandler={() => navigate(-1)} />
      <OrderPaymentContent />
    </div>
  );
};

export { OrderPaymentPage };
