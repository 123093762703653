// ! 05.10 쿠폰 콘텐츠 비활성화
import { SideBarProps } from '../components/MenuLinkButton';

const navigationListTable = (isLogin: boolean, userId?: string): SideBarProps => {
  const guestNavi = [
    {
      title: '부고메뉴',
      subMenuList: [
        { name: '부고 만들기', href: '/fevent/add' },
        { name: '부고 수정', href: '/fevent/list/?purpose=bugo' },
      ],
    },
    {
      title: '답례메뉴',
      subMenuList: [
        { name: '답례글 보내기', href: '/fevent/list/?purpose=courtesy' },
        { name: '답례품 보내기', href: undefined },
      ],
    },
    {
      title: '근조화환',
      subMenuList: [{ name: '근조화환 보내기', href: '/flower-store' }],
    },
    {
      title: '고객센터',
      subMenuList: [
        { name: '자주 묻는 질문', href: '/faq' },
        { name: '1:1 문의', href: '/enquiry' },
      ],
    },
    {
      title: '제휴신청',
      subMenuList: [{ name: '제휴신청 문의', href: undefined }],
    },
  ];

  const userNavi = [
    {
      title: '마이 페이지',
      subMenuList: [
        { name: '계정 관리', href: `/user/mypage` },
        // { name: '적립 포인트', href: `/user/mypage` },
        // { name: '쿠폰 관리', href: `/user/mypage?state=coupon` },
      ],
    },
    {
      title: '부고메뉴',
      subMenuList: [
        { name: '부고 만들기', href: '/fevent/add' },
        { name: '부고 수정', href: '/fevent/list/?purpose=bugo' },
      ],
    },
    {
      title: '답례메뉴',
      subMenuList: [
        { name: '답례글 보내기', href: '/fevent/list/?purpose=courtesy' },
        { name: '답례품 보내기', href: undefined },
      ],
    },
    {
      title: '근조화환',
      subMenuList: [{ name: '근조화환 보내기', href: '/flower-store' }],
    },
    {
      title: '고객센터',
      subMenuList: [
        { name: '자주 묻는 질문', href: '/faq' },
        { name: '1:1 문의', href: '/enquiry' },
        { name: '내 문의내역', href: `/user/mypage?state=enquiry` },
      ],
    },
    {
      title: '제휴신청',
      subMenuList: [{ name: '제휴신청 문의', href: undefined }],
    },
  ];

  return isLogin && userId ? userNavi : guestNavi;
};

export { navigationListTable };
