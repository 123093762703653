import { ChangeEvent, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AuthBodyBase } from '@api/mongo/controllers';
import { BasicInput } from '@components/BasicInput';
import { PasswordInput } from '@components/PasswordInput';
import { useAuth } from '@hooks/useAuth';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import {
  persistUserPasswordAtom,
  persistUsernameAtom,
} from '../state/persistUserLoginData.atom';
import { userLoginDataSelector } from '../state/userLoginData.selector';

type FormValues = Pick<AuthBodyBase, 'username' | 'password'>;

const LocalLoginForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const { loginByUsernamePassword } = useAuth();
  const [isPersist, setIsPersist] = useState(false);
  const setUsername = useSetRecoilState(persistUsernameAtom);
  const setUserPassword = useSetRecoilState(persistUserPasswordAtom);

  const userLoginData = useRecoilValue(userLoginDataSelector);
  const isStorePage = useRecoilValue(isStorePageAtom);

  useEffect(() => {
    setValue('username', userLoginData.username);
    setValue('password', userLoginData.password);
  }, [userLoginData, setValue]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await loginByUsernamePassword(data);
    console.log(data);
    if (isPersist) {
      setUsername(data.username);
      setUserPassword(data.password);
    }
  };

  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPersist(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BasicInput
        type="text"
        placeholder="아이디를 입력해주세요"
        className={`h-10 w-full rounded border px-4 ${
          errors.username && 'border-red-500'
        }`}
        {...register('username', { required: true })}
      />
      {errors.username && (
        <p className="pt-1 text-left text-12 text-red-500">아이디를 입력해주세요.</p>
      )}
      <div className="mt-2">
        <PasswordInput
          className={`h-10 w-full rounded border px-4 ${
            errors.password && 'border-red-500'
          }`}
          placeholder="비밀번호를 입력해주세요"
          {...register('password', { required: true })}
        />
        {errors.password && (
          <p className="pt-1 text-left text-12 text-red-500">비밀번호를 입력해주세요.</p>
        )}
      </div>
      <div className="my-4 flex justify-between font-['Noto_Sans'] text-14 font-medium">
        <div className="flex items-center gap-1">
          <input type="checkbox" onChange={onChangeCheckBox} checked={isPersist} />
          <span>로그인 정보 저장</span>
        </div>
        <div className="flex items-center gap-1">
          <Link to={isStorePage ? '/flower-store/find-id-password' : '/find-id-password'}>
            아이디찾기
          </Link>
          <span>|</span>
          <Link to={isStorePage ? '/flower-store/find-id-password' : '/find-id-password'}>
            비밀번호찾기
          </Link>
        </div>
      </div>
      <button type="submit" className={`block h-12 w-full rounded bg-primary text-white`}>
        로그인
      </button>
    </form>
  );
};

export { LocalLoginForm };
