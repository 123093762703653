import { FC, Fragment, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Link, useSearchParams } from 'react-router-dom';
import DismissModal from '@components/DismissModal';
import { nestMongoApi } from '@api/mongo/controllers';
import { useAuth } from '@hooks/useAuth';
import { useQueryClient } from 'react-query';
import { notificateSuccess } from 'src/@utils/noti.utils';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { UserBugoItem } from '@components/UserBugoItem';

import { searchResultFeventListDataAtom } from './state/userBugoList.atom';
import {
  feventListByCurrentUserUrl,
  useFeventListByCurrentUser,
} from './state/userBugoList.query';
import { currentUserFeventListStateSelector } from './state/userBugoList.selector';

const setMobileTitleByPurpose = (purpose: string | null) => {
  switch (purpose) {
    case 'bugo':
      return '부고수정';
    case 'courtesy':
      return '답례 인사';
    default:
      return '부고수정';
  }
};

interface UserBugoListProps {
  currentPage: number;
}

const UserBugoList: FC<UserBugoListProps> = ({ currentPage }) => {
  const [searchParams] = useSearchParams();
  const purpose = searchParams.get('purpose');
  const { data, status } = useRecoilValue(currentUserFeventListStateSelector);
  const searchResultFeventListData = useRecoilValue(searchResultFeventListDataAtom);
  const notificationInstance = useRecoilValue(notificationInstanceAtom);
  useFeventListByCurrentUser();

  const events = searchResultFeventListData || data;
  const pageStartIndex = currentPage * 5 - 5;
  const pageEndIndex = currentPage * 5;
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();

  useMobileNavTitleHook(setMobileTitleByPurpose(purpose), true);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className="flex flex-col gap-2">
      {events && events.length > 0
        ? events.slice(pageStartIndex, pageEndIndex).map((fevent) => {
            const onClickDelete = async () => {
              await nestMongoApi(`${bugoRole()}/fevent`).deleteById(fevent._id);
              await queryClient.invalidateQueries(feventListByCurrentUserUrl(bugoRole()));
              notificateSuccess(notificationInstance, '삭제에 성공하였습니다.');
              console.log('삭제');
            };
            return (
              <Fragment key={fevent._id}>
                <Link
                  to={
                    purpose === 'bugo'
                      ? `/fevent/edit/${fevent._id}`
                      : `/fevent/${fevent._id}/courtesy-send`
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <UserBugoItem
                    _id={fevent._id}
                    funeralHomeName={fevent.funeralHomeInfo.name}
                    roomName={fevent.roomInfoEmbed?.name ?? fevent.roomInfo?.name}
                    deceasedName={fevent.deceasedInfo.name}
                    memberName={fevent.memberOrderList[0]?.fullName}
                    feventStatus={fevent.status}
                    coffinOut={fevent.deceasedInfo.coffinOut}
                  />
                </Link>
                <DismissModal
                  openId={`confirm-remove-${fevent._id}`}
                  onClick={onClickDelete}
                  buttonTitle={'삭제'}
                  title={'장례(부고) 삭제'}
                  subtitle={`해당 장례를 삭제합니다. 삭제된 장례는 복원이 불가능합니다.`}
                ></DismissModal>
              </Fragment>
            );
          })
        : status === 'success' && (
            <div className="center-box flex h-[150px] text-16 text-gray-500">
              작성한 부고가 존재하지 않습니다
            </div>
          )}
    </div>
  );
};

export { UserBugoList };
