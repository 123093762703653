// src/hooks/useTheme.js
import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { isStorePageAtom } from '@state/atom/isStorePage.atom';

/**
 * @description /flower-store/* - theme sky로 변경
 */
const useStoreTheme = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);

  useEffect(() => {
    if (isStorePage) {
      document.documentElement.setAttribute('store-theme', 'sky');
    } else {
      document.documentElement.setAttribute('store-theme', 'apricot');
    }
  }, [isStorePage]);
};

export { useStoreTheme };
