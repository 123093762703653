import { FC, ReactNode } from 'react';

interface MainSectionProps {
  children: ReactNode;
  title?: string;
}

const MainSection: FC<MainSectionProps> = ({ children, title }) => {
  return (
    <div className=" min-h-[calc(100vh-136px)] bg-gray-50 max-sm:min-h-[calc(100vh-56px)]">
      <div className="mx-auto max-w-[1120px] p-8 max-md:p-4">
        {title && (
          <h2 className="mb-8 text-left text-24 font-bold text-black max-sm:hidden">
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  );
};

export { MainSection };
