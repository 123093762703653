import { QueryDataState } from 'src/interfaces';

import { Image } from './media.interface';
import { User } from './user.interface';

export enum EnquiryType {
  Bugo = 'Bugo',
  Store = 'Store',
  Seller = 'Seller',
}

export enum EnquiryStatus {
  Todo = 'Todo',
  Doing = 'Doing',
  Done = 'Done',
}

export type Enquiry<User = string, Image = string | null> = {
  _id: string;
  user: User;
  type: EnquiryType;
  title: string;
  name: string;
  phoneNumber: string;
  content: string;
  image: Image; // 이미지 없으면 ""
  answer: string;
  status: EnquiryStatus;
  createdAt: Date;
  updatedAt: Date;
};

export type EnquiryCreate = Omit<Enquiry, '_id' | 'createdAt' | 'updatedAt' | 'answer'>;

export type EnquiryList = Enquiry<User, Image>[];

export type EnquiryListState = QueryDataState<EnquiryList>;

export type UserEnquiryList = Enquiry<string, Image>[];

export type UserEnquiryListState = QueryDataState<UserEnquiryList>;

export type EnquiryUpdate = Pick<Enquiry, '_id' | 'answer' | 'status'>;
