import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { StoreItem } from './FlowerStoreItem.interface';

const flowerStoreItemListDataAtom = atom<StoreItem[]>({
  key: 'flowerStoreItemListDataAtom',
  default: undefined,
});

const flowerStoreItemListStatusAtom = atom<QueryStatus>({
  key: 'flowerStoreItemListStatusAtom',
  default: 'idle',
});

export { flowerStoreItemListDataAtom, flowerStoreItemListStatusAtom };
