import { useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useFormContext, useWatch } from 'react-hook-form';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useQueryClient } from 'react-query';
import { useAuth } from '@hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { SuccessAlertModal } from '@components/SuccessAlertModal';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';

import { FormValues } from '../state/bugoSendForm.interface';
import { useBugoSendFormValuesUpdateCheckHook } from '../state/bugoSendForm.hook';
import { modalTextContents } from '../utils/BugoSubmitModalTextContents';
import { isSubmitOnBugoSendFormAtom } from '../state/bugoSendForm.atom';

const openId = `BugoSendFormSubmitSuccess-modal`;

const BugoSubmitModal = () => {
  const setIsSubmitOnBugoSendForm = useSetRecoilState(isSubmitOnBugoSendFormAtom);
  const isEditPage = useRecoilValue(isEditPageAtom);
  const navigate = useNavigate();
  const { control } = useFormContext<FormValues>();
  const { feventId } = useParams();
  const currentFeventId = useRecoilValue(currentFeventIdAtom);

  const { bugoRole } = useAuth();
  const fieldArray = useWatch({ control, name: 'memberData' });
  const queryClient = useQueryClient();
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const [modalTextContent, setModalTextContent] = useState(
    modalTextContents.isNotUpdated,
  );

  const { updateChecked, isFeventEqualBySnapshot } = useBugoSendFormValuesUpdateCheckHook(
    fieldArray,
    feventId ?? currentFeventId ?? '',
  );

  const { isAddedMember, isMournerTextUpdated, isPhoneNumberUpdated } = updateChecked;

  useEffect(() => {
    if (!isEditPage) {
      setModalTextContent(modalTextContents.addPageOnly);
      return;
    }
    if (isPhoneNumberUpdated && isAddedMember && isMournerTextUpdated) {
      setModalTextContent(modalTextContents.isAddPhoneUpdated);
      return;
    }
    if (isPhoneNumberUpdated && isMournerTextUpdated) {
      setModalTextContent(modalTextContents.isPhoneTextUpdated);
      return;
    }
    if (isAddedMember && isMournerTextUpdated) {
      setModalTextContent(modalTextContents.isAddedTextUpdated);
      return;
    }
    if (isAddedMember && isPhoneNumberUpdated) {
      setModalTextContent(modalTextContents.isAddPhoneUpdated);
      return;
    }
    if (isAddedMember) {
      setModalTextContent(modalTextContents.isAdded);
      return;
    }
    if (isPhoneNumberUpdated) {
      setModalTextContent(modalTextContents.isPhoneNumberUpdated);
      return;
    }
    if (isMournerTextUpdated || !isFeventEqualBySnapshot) {
      setModalTextContent(modalTextContents.isTextUpdated);
      return;
    }
    if (!isAddedMember && !isMournerTextUpdated && !isPhoneNumberUpdated) {
      setModalTextContent(modalTextContents.isNotUpdated);
      return;
    }
  }, [
    isAddedMember,
    isEditPage,
    isFeventEqualBySnapshot,
    isMournerTextUpdated,
    isPhoneNumberUpdated,
    setModalTextContent,
  ]);

  return (
    <SuccessAlertModal
      openId={openId}
      onClose={async () => {
        await queryClient.invalidateQueries(
          feventByIdHookUrl(feventId ?? currentFeventId ?? '', bugoRole()),
        );
        setOpen(false);
        setIsSubmitOnBugoSendForm(true);
        navigate('/');
      }}
      showIcon={isAddedMember ?? isMournerTextUpdated ?? isPhoneNumberUpdated ?? false}
      modalText={modalTextContent}
    />
  );
};

export { BugoSubmitModal };
