// BasicInfoForm.tsx

import { FC, ReactNode } from 'react';

interface BasicInfoFormProps {
  title?: string;
  desc?: string;
  children: ReactNode;
  leftSectionTopComponent?: ReactNode;
  leftSectionBottomComponent?: ReactNode;
}

const BasicInfoForm: FC<BasicInfoFormProps> = ({
  title,
  desc,
  children,
  leftSectionTopComponent,
  leftSectionBottomComponent,
}) => {
  return (
    // 640px 모바일 레이아웃 전환
    <div className="mt-8 flex gap-8 border-t border-gray-400 py-8 max-sm:mt-0 max-sm:block max-sm:border-none max-sm:py-0">
      <div className="shrink-0 basis-[320px] text-left max-sm:shrink">
        {title && <p className="mb-4 text-16 max-sm:hidden">{title}</p>}
        {leftSectionTopComponent}
        {desc && (
          <p className="mb-4 whitespace-pre-line break-keep text-12 text-gray-500 max-sm:hidden">
            {desc}
          </p>
        )}
        {leftSectionBottomComponent}
      </div>
      <div className="shrink-0 grow basis-[300px] max-sm:shrink">{children}</div>
    </div>
  );
};

export { BasicInfoForm };
