import { FC } from 'react';

import { fileBucketUrl } from 'src/@utils/fileUtils';
import { formatFeventDateString } from 'src/@utils/formatDate';
import logo from 'src/assets/image/bugopage-header-logo.webp';
import { FeventDate, Gender } from 'src/interfaces';
import { DeathCharacter } from '@components/miscValues';

import { deceasedNameString } from '../utils/bugoString.util';

interface ObituaryHeaderProps {
  deceasedName: string;
  deceasedSex: Gender;
  deceasedAge: number;
  deceasedNameDetail?: string;
  deathDate: FeventDate;
  bugoTerm: string;
  imageUrl?: string;
}

const ObituaryHeader: FC<ObituaryHeaderProps> = ({
  deceasedName,
  deceasedSex,
  deceasedAge,
  deceasedNameDetail,
  deathDate,
  bugoTerm,
  imageUrl,
}) => {
  const setDeceasedNameString = deceasedNameString(
    deceasedName,
    deceasedSex,
    deceasedAge,
    deceasedNameDetail,
  );
  const dateString = formatFeventDateString(deathDate, false);

  return (
    <div className="min-h-[96px] bg-gray-100">
      <div className="min-h-[96px] bg-gray-800  py-2 font-bold text-white">
        {imageUrl ? (
          <div className="flex h-full gap-4 pl-4">
            <img
              className="h-[104px] w-[82px] bg-blue-50"
              src={fileBucketUrl(imageUrl, 'w400')}
            ></img>

            <div className="flex-1 space-y-3 pt-1">
              <p
                className="text-left text-30 font-bold text-white "
                style={{ fontFamily: 'BatangChe' }}
              >
                訃告
              </p>
              <div className="space-y-2 text-left text-white">
                <div className="text-18">
                  <span style={{ fontFamily: 'Batangche' }}>{DeathCharacter}</span>
                  {setDeceasedNameString}
                </div>
                <div className="text-14 font-normal">
                  {dateString}&nbsp;
                  {bugoTerm}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="pb-1 text-26" style={{ fontFamily: 'BatangChe' }}>
              訃告
            </div>
            <div className="text-18">
              <span style={{ fontFamily: 'Batangche' }}>{DeathCharacter}</span>
              {setDeceasedNameString}
            </div>
            <div className="text-18 font-normal">
              {dateString}&nbsp;
              {bugoTerm}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { ObituaryHeader };
