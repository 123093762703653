import { FC, Fragment, HTMLAttributes, useRef } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Dialog, Transition } from '@headlessui/react';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useModalHook } from '@hooks';
import { CustomCallback } from 'src/interfaces';

interface Props extends HTMLAttributes<HTMLDivElement> {
  openId: string;
  onClose?: CustomCallback<any, void>;
}

/**
 * @param openId openAtom에 사용할 openId
 * @param onClose
 * @returns 바텀팝업, MobileSelect, MobileDatePicker, MobileTimePicker 등 주로사용
 */
export const BottomPopup: FC<Props> = function BottomPopup({
  openId,
  children,
  onClose,
}: Props) {
  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));
  const clickInProgressRef = useRef(false);

  useModalHook(openId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed left-0 bottom-0 z-30 overflow-y-auto`}
        onClose={(e) => {
          if (clickInProgressRef.current) return;
          if (onClose) {
            onClose(e);
          } else {
            setOpen(false);
          }
          clickInProgressRef.current = true;
          history.back();
        }}
      >
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className={`inline-block transform shadow-xl transition-all`}>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
