import { useRecoilValue } from 'recoil';

import { DeliveryStatusFooter } from '@containers/FlowerStoreOrderContent/OrderDeliveryContent/components/DeliveryStatusFooter';
import { useParams } from 'react-router-dom';

import { flowerStoreOrderDetailStateSelectorFamily } from '../state/flowerStoreOrderContent.selector';
import { PaymentBody } from './components/PaymentBody';
import { PaymentHeader } from './components/PaymentHeader';

const OrderPaymentContent = () => {
  const { orderId } = useParams();

  const { data: orderDetailData, status: orderDetailStatus } = useRecoilValue(
    flowerStoreOrderDetailStateSelectorFamily(orderId ?? ''),
  );

  if (orderDetailStatus === 'loading') return <></>;
  else if (!orderDetailData) return <></>;
  else {
    const { orderDetail, _id, paymentDetail, createdAt, deliveryDetail, bugoDetail } =
      orderDetailData;

    const imageUrl = orderDetail.shopItem.shopItemInfo.image.url;

    return (
      <div className="mx-auto max-w-[640px]  bg-white p-4">
        <PaymentHeader orderId={orderId ?? _id} createdAt={createdAt} />
        <div className="my-4 border-t" />
        <PaymentBody
          itemName={orderDetail.shopItemEmbed.name}
          createdAt={createdAt}
          estimatedDeliveredAt={deliveryDetail.estimatedDeliveredAt}
          deliveryStatus={deliveryDetail.status}
          priceRetail={orderDetail.shopItemEmbed.priceRetail}
          priceRetailShow={orderDetail.shopItemEmbed.priceRetailShow}
          paymentByPoint={paymentDetail.paymentByPoint}
          imageUrl={imageUrl}
        />
        <DeliveryStatusFooter
          funeralAddress={deliveryDetail.receiverAddress}
          mournerName={deliveryDetail.receiverName}
          senderPhrase={bugoDetail.senderPhrase}
          condolencePhrase={bugoDetail.condolencePhrase}
        />
      </div>
    );
  }
};

export { OrderPaymentContent };
