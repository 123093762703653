import { atom, DefaultValue, selector } from 'recoil';

import { UserBugoBrand } from '@api/mongo/interfaces';
import { User } from '@api/mongo/interfaces';
import _ from 'lodash';

export const tokenAtom = atom<string | null>({
  key: 'tokenAtom',
  default: localStorage.getItem('token'),
});
export const auth0AccessTokenAtom = atom<string | null>({
  key: 'auth0AccessTokenAtom',
  default: null,
});

export const tokenSelector = selector<string | null>({
  key: 'tokenSelector',
  get: ({ get }) => {
    const token = get(tokenAtom);
    return token;
  },
  set: ({ set }, val: string | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      localStorage.removeItem('token');
    } else if (val) {
      localStorage.setItem('token', val);
      sessionStorage.removeItem('token');
    } else {
      localStorage.removeItem('token');
    }
    set(tokenAtom, val);
  },
});

//* 로그인시 사용되는 user
export const userProfileAtom = atom<User | null>({
  key: 'userProfileAtom',
  default: _.isNil(localStorage.getItem('userProfile'))
    ? null
    : (JSON.parse(localStorage.getItem('userProfile') as string) as User),
});

export const userProfileSelector = selector<User | null>({
  key: 'userProfileSelector',
  get: ({ get }) => {
    const userProfile = get(userProfileAtom);
    return userProfile;
  },
  set: ({ set }, val: User | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      localStorage.removeItem('userProfile');
    } else if (val) {
      localStorage.setItem('userProfile', JSON.stringify(val));
      sessionStorage.removeItem('userProfile');
      set(userProfileAtom, val);
    } else {
      localStorage.removeItem('userProfile');
    }
  },
});

//* 로그인시 사용되는 bugoBrand
export const userBugoBrandAtom = atom<UserBugoBrand | null>({
  key: 'userBugoBrandAtom',
  default: _.isNil(localStorage.getItem('userBugoBrand'))
    ? null
    : (JSON.parse(localStorage.getItem('userBugoBrand') as string) as UserBugoBrand),
});

export const userBugoBrandSelector = selector<UserBugoBrand | null>({
  key: 'userBugoBrandSelector',
  get: ({ get }) => {
    const userBugoBrand = get(userBugoBrandAtom);
    return userBugoBrand;
  },
  set: ({ set }, val: UserBugoBrand | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      localStorage.removeItem('userBugoBrand');
    } else if (val) {
      localStorage.setItem('userBugoBrand', JSON.stringify(val));
      sessionStorage.removeItem('userBugoBrand');
      set(userBugoBrandAtom, val);
    } else {
      localStorage.removeItem('userBugoBrand');
    }
  },
});

export const redirectAfterLoginAtom = atom<string | undefined>({
  key: 'redirectAfterLoginAtom',
  default: undefined,
});
