import { Member, Bugo, CashAccount } from '@api/mongo/interfaces';
import _ from 'lodash';

import {
  MemberIFormInput,
  MemberFormInputsEnum,
} from '../interface/mournerInfoForm.interface';

//* 초기값 세팅
export const defaultMemberInputForm = (
  memberOrderList?: Member<string, Bugo, CashAccount>[],
): MemberIFormInput[] => {
  if (memberOrderList && memberOrderList.length > 0) {
    return _.map(memberOrderList, (member) => {
      return {
        _id: member._id,
        [MemberFormInputsEnum.Name]: member.fullName,
        [MemberFormInputsEnum.RelationType]: member.relationType,
        [MemberFormInputsEnum.PhoneNumber]: member.phoneNumber,
        [MemberFormInputsEnum.CashAccountId]: member.cashAccount._id, // member.cashAccount._id
        [MemberFormInputsEnum.CashAccount]: member.cashAccount.account,
        [MemberFormInputsEnum.AccountHolder]: member.cashAccount.name,
        [MemberFormInputsEnum.Bank]: member.cashAccount.bank, // member.cashAccount.bank
        [MemberFormInputsEnum.AccountHolderSame]:
          member.fullName === member.cashAccount.name, // 예금주 동일
        isVerified: member.cashAccount.isVerified ?? false,
        cashAccountModalConfirmed: member.cashAccountModalConfirmed,
        isFold: false,
        // isNew: false,
      };
    });
  }
  return [
    {
      _id: '',
      [MemberFormInputsEnum.Name]: '',
      [MemberFormInputsEnum.RelationType]: '',
      [MemberFormInputsEnum.PhoneNumber]: '',
      [MemberFormInputsEnum.CashAccountId]: '',
      [MemberFormInputsEnum.CashAccount]: '',
      [MemberFormInputsEnum.AccountHolder]: '',
      [MemberFormInputsEnum.Bank]: '',
      [MemberFormInputsEnum.AccountHolderSame]: true,
      isVerified: false,
      cashAccountModalConfirmed: false,
      isFold: false,
      // isNew: true,
    },
  ];
};
