import { FC, useCallback } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { formatDate } from 'src/@utils/formatDate';
import { fileBucketUrl } from '@plugin/s3Image';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import DismissModal from '@components/DismissModal';
import { deleteEnquiry } from '@api/mongo/controllers/enquiry.contoroller';
import { useAuth } from '@hooks/useAuth';
import { useQueryClient } from 'react-query';
import { Chip } from '@components/Chip';

import { UserEnquiryItem } from '../state/userEnquiry.interface';
import { userEnquiryListHookUrl } from '../state/userEnquiry.query';
import { enquiryItemOpenStateFamily } from '../state/userEnquiry.atom';

const MyEnquiryItem: FC<UserEnquiryItem> = ({
  answer,
  content,
  createdAt,
  title,
  status,
  image,
  _id,
}) => {
  const queryClient = useQueryClient();
  const { assetRole } = useAuth();
  const [isOpen, setIsOpen] = useRecoilState(enquiryItemOpenStateFamily(_id));

  const removeConfirmModalOpenId = `remove-confirm-${createdAt}`;
  const setRemoveConfirmModal = useSetRecoilState(
    modalOpenAtomFamily(removeConfirmModalOpenId),
  );
  const arrowButtonHandler = useCallback(() => {
    setIsOpen((prev: boolean) => !prev);
  }, [setIsOpen]);

  return (
    <div
      className={`relative flex w-full flex-col gap-2 whitespace-pre-line break-keep bg-white  text-left font-medium text-gray-700 ${
        !isOpen && 'pb-3'
      }`}
    >
      <div className="flex justify-between px-4 pt-3">
        <div className="flex items-center gap-2">
          <Chip color={status === 'Done' ? 'apricot' : 'default'}>
            {status === 'Done' ? '답변완료' : '접수'}
          </Chip>
          <span className="text-12 ">
            {formatDate(createdAt, {
              contains: {
                hours: true,
                minutes: true,
              },
            })}
          </span>
        </div>
        <div className="mr-7">
          <Button
            onClick={() => {
              setRemoveConfirmModal(true);
            }}
            className=" rounded border border-red-500 bg-white px-4 py-1 font-medium text-red-500 shadow-none hover:bg-red-500 hover:font-bold hover:text-white"
          >
            삭제
          </Button>
        </div>
      </div>

      <p className="px-4 text-18">{title}</p>
      {isOpen && (
        <div className="flex w-full flex-col">
          <div className="break-all border-t  px-4">
            {content && <div className="py-6">{content}</div>}
            {image && (
              <div className="pb-6">
                {
                  <img
                    src={fileBucketUrl(image.url, 'w400')}
                    alt="content"
                    className="mx-auto"
                  ></img>
                }
              </div>
            )}
          </div>
          {answer ? (
            <div className="border-t border-dashed bg-apricot-600/20 py-6">
              <p className="break-all py-2 font-bold">답변내용</p>
              <p className="text-left">{answer}</p>
            </div>
          ) : (
            <div>
              <p className="border-t border-dashed bg-apricot-600/20 py-6 text-center">
                고객님의 문의에 대한 답변을 준비 중입니다.
              </p>
            </div>
          )}
        </div>
      )}

      <Button
        className="absolute right-0 top-2 border-none pl-0 shadow-none"
        onClick={arrowButtonHandler}
      >
        {isOpen ? (
          <Icon.ChevronUp className="stroke-none" />
        ) : (
          <Icon.ChevronDown className="stroke-none" />
        )}
      </Button>
      <DismissModal
        openId={removeConfirmModalOpenId}
        buttonTitle="삭제"
        title="해당 문의를 삭제하시겠습니까?"
        onClick={async () => {
          await deleteEnquiry(_id, assetRole());
          await queryClient.invalidateQueries(userEnquiryListHookUrl(assetRole()));
        }}
      />
    </div>
  );
};

export { MyEnquiryItem };
