import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  feventDataForCourtesySendPageAtomFamily,
  feventDataStatusForCourtesySendPageAtomFamily,
} from './courtesySendForm.atom';

const populate = [
  {
    path: 'memberOrderList',
    populate: [
      {
        path: 'bugo',
        select: ['_id', 'schedule'],
      },
    ],
    select: ['fullName', 'phoneNumber', 'bugo'],
  },
];

const feventForCourtesySendPageUrl = (id: string, role: string) =>
  `${role}/fevent/${id}/?${queryString({ populate, select: ['deceasedInfo'] })}`;

const useFeventForCourtesySendPageHook = (id: string, disabled?: boolean) => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    feventDataStatusForCourtesySendPageAtomFamily(id),
    feventDataForCourtesySendPageAtomFamily(id),
    feventForCourtesySendPageUrl(id, bugoRole()),
    fetcher,
    disabled,
  );
};

export { feventForCourtesySendPageUrl, useFeventForCourtesySendPageHook };
