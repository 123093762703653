import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  feventMemberOrderBugoDataAtomFamily,
  feventMemberOrderBugoStatusAtomFamily,
} from './bugoPreview.atom';

const populate = [
  {
    path: 'memberOrderList',
    populate: [
      {
        path: 'bugo',
      },
    ],
  },
];

export const feventUrl = (id: string, role: string) =>
  `${role}/fevent/${id}/?${queryString({
    populate,
  })}`;

export const useFeventMemberOrderBugoByIdHook = (
  feventId: string,
  disabled?: boolean,
) => {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    feventMemberOrderBugoStatusAtomFamily(feventId),
    feventMemberOrderBugoDataAtomFamily(feventId),
    feventUrl(feventId, bugoRole()),
    fetcher,
    disabled,
  );
};
