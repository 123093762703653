import { Icon } from '@components/Icon';

const CustomerBox = () => {
  return (
    <div className="center-box mt-8 flex w-full gap-4 bg-white py-2">
      <div className="flex flex-col gap-2 font-medium">
        <p className="text-16  text-black ">고객지원센터</p>
        <p className="relative text-24">
          <Icon.CustomerCenter className=" absolute -left-16 -top-[6px] max-sm:-left-14" />
          1577-1954
        </p>
        <p className="font-normal text-gray-500">평일 09:00~18:00</p>
      </div>
    </div>
  );
};

export { CustomerBox };
