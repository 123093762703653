import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { MobileMenuButton } from '@components/MobileMenuButton';
import { MobileSidePopup } from '@containers/MobileSidePopup';
import { useAuth } from '@hooks/useAuth';
import {
  mobileNavArrowLeftButtonToUrlAtom,
  mobileNavBarHiddenAtom,
  mobileNavBarNaviBackAtom,
  mobileNavBarTitleAtom,
  mobileNavNaviBackToUrlAtom,
  mobileNavRightMenuButtonAtom,
} from '@state/atom/mobileNavigation.atom';
import { useNavigate } from 'react-router-dom';
import { storeNavigationListTable } from '@containers/MobileSidePopup/state/storeNavigationListTable';

const StoreMobilNavigation: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userProfile } = useAuth();
  const navigateHandler = (path?: string) => {
    path ? navigate(path) : navigate(-1);
  };

  const arrowLeftButtonHandler = () => navigateHandler(setArrowLeftButtonToUrl);
  const rightMenuButtonHandler = () => navigateHandler(naviBackToUrl);
  const symbolHandler = () => navigate('/');

  const title = useRecoilValue(mobileNavBarTitleAtom);
  const naviBack = useRecoilValue(mobileNavBarNaviBackAtom);
  const rightMenuButton = useRecoilValue(mobileNavRightMenuButtonAtom);
  const naviBackToUrl = useRecoilValue(mobileNavNaviBackToUrlAtom);
  const setArrowLeftButtonToUrl = useRecoilValue(mobileNavArrowLeftButtonToUrlAtom);
  const hidden = useRecoilValue(mobileNavBarHiddenAtom);

  return (
    <>
      <div
        className={`h-[56px] w-full items-center justify-between bg-white px-4 max-sm:flex ${
          hidden ? 'hidden' : 'flex'
        }`}
      >
        <div className="flex items-center">
          <Button
            className=" cursor-pointer border-none p-0 shadow-none"
            onClick={naviBack ? arrowLeftButtonHandler : symbolHandler}
          >
            {naviBack ? (
              <Icon.ArrowLeft className="stroke-none" />
            ) : (
              <Icon.Symbol className="stroke-none" />
            )}
          </Button>
          <div className="ml-2 text-16 font-bold text-black">{title}</div>
        </div>
        <MobileMenuButton rightMenu={rightMenuButton} onClick={rightMenuButtonHandler} />
      </div>
      <MobileSidePopup
        navigation={storeNavigationListTable(
          isAuthenticated,
          userProfile ? (userProfile.info ? userProfile.info.name : '회원') : '회원',
        )}
      />
    </>
  );
};

export { StoreMobilNavigation };
