import { FC } from 'react';

import { Icon } from '@components/Icon';

const CallCenterInfo: FC = () => {
  return (
    <div className="flex justify-center gap-4 text-18 font-bold text-black">
      <div className=" flex flex-col items-center">
        <Icon.CustomerCenter />
        <p className="">전화주문</p>
        <p className="  text-primary">오전9시 - 오후6시</p>
      </div>

      <div className="flex flex-col items-center justify-center gap-1">
        <a href="tel:02-6956-9530">
          <div className="flex items-center justify-center gap-2">
            <Icon.Phone className="h-6 w-6 stroke-none" />
            <p>02-6956-9530</p>
          </div>
        </a>
        <p className="text-normal text-12 ">클릭 시 상담원에게 바로 연결됩니다.</p>
      </div>
    </div>
  );
};

export { CallCenterInfo };
