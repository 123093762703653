import { queryString, useRecoilQuery, fetcher } from '@hooks';

import { storeItemDataAtom, storeItemStatusAtom } from './storeItemDetail.atom';

const shopItemForStorePopulate = [
  {
    path: 'shopItemInfo',
    populate: [
      {
        path: 'image',
      },
      {
        path: 'detailImages',
      },
    ],
  },
  {
    path: 'image',
  },
  {
    path: 'detailImages',
  },
  {
    path: 'deliveredImageList',
  },
];

export const useStoreItemHookUrl = (itemId: string) => {
  return `public/shop-item/${itemId}?${queryString({
    populate: shopItemForStorePopulate,
  })}`;
};

export const useStoreItemHook = (itemId: string) => {
  useRecoilQuery(
    storeItemStatusAtom,
    storeItemDataAtom,
    useStoreItemHookUrl(itemId),
    fetcher,
  );
};
