import { selector } from 'recoil';

import { QueryDataState } from 'src/interfaces';

import {
  FlowerStoreOrderListDataAtom,
  FlowerStoreOrderListStatusAtom,
} from './FlowerStoreOrderList.atom';
import { OrderList } from './FlowerStoreOrderList.interface';

const flowerStoreOrderListSelector = selector<QueryDataState<OrderList>>({
  key: 'flowerStoreOrderListSelector',
  get: ({ get }) => {
    return {
      status: get(FlowerStoreOrderListStatusAtom),
      data: get(FlowerStoreOrderListDataAtom),
    };
  },
});

export { flowerStoreOrderListSelector };
