import { FC, InputHTMLAttributes } from 'react';

import { UseFormRegister } from 'react-hook-form';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';

import { BasicInput } from './BasicInput';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassname?: string;
  required?: boolean;
  scrollOnFocus?: boolean;
}

interface IProps extends TextFieldProps {
  value: string;
  register: UseFormRegister<any>;
  name: string;
  className?: string;
  wrapperClassName?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
}

const TelField: FC<IProps> = ({
  value,
  register,
  name,
  className,
  wrapperClassName,
  required,
  placeholder = '010',
  label = '휴대전화 번호',
  minLength = 12,
  maxLength = 13,
  ...props
}: IProps) => {
  return (
    <div className={wrapperClassName ? wrapperClassName : 'w-full'}>
      <BasicInput
        {...props}
        label={label}
        type="text"
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        value={phoneNumberStringFormator(value)}
        className={` pl-4  ${className}`}
        {...register(`${name}`, {
          required,
          maxLength: 11,
          minLength: 10,
          setValueAs: (value) => value.replace(/[^0-9]/gi, ''),
        })}
      />
    </div>
  );
};

export default TelField;
