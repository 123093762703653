import { FC, PropsWithChildren } from 'react';

interface BodyProps extends PropsWithChildren {
  header?: boolean;
  topNav?: boolean;
  footer?: boolean;
}

const Body: FC<BodyProps> = (props) => {
  const { children, header, topNav, footer } = props;

  const paddingTop = header ? ' pt-[136px] max-sm:pt-[56px]' : topNav ? 'pt-10' : '';

  const minHeight = footer
    ? 'min-h-[calc(100vh-393px)] max-sm:min-h-[calc(100vh-513px)]'
    : 'min-h-screen';

  return <div className={`${minHeight} ${paddingTop}`}>{children}</div>;
};

export { Body };
