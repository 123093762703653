import { atom } from 'recoil';

export const mobileNavBarTitleAtom = atom({
  key: 'mobileNavNavBarTitleAtom',
  default: '가온부고',
});

export const mobileNavBarNaviBackAtom = atom<boolean>({
  key: 'mobileNavNavBarNaviBackAtom',
  default: false,
});

export const mobileNavRightMenuButtonAtom = atom<boolean>({
  key: 'mobileNavRightMenuButtonAtom',
  default: true,
});

export const mobileNavNaviBackToUrlAtom = atom({
  key: 'mobileNavNaviBackToUrlAtom',
  default: '',
});

export const mobileNavArrowLeftButtonToUrlAtom = atom({
  key: 'mobileNavArrowLeftButtonToUrlAtom',
  default: '',
});

export const mobileNavBarHiddenAtom = atom({
  key: 'mobileNavBarHiddenAtom',
  default: true,
});
