import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';
import { BugoPage } from '@pages/Bugo/BugoPage';

const BugoRouter: FC = () => {
  return (
    <Routes>
      <Route path=":bugoId/*" element={<BugoPage />}></Route>
    </Routes>
  );
};

export { BugoRouter };
