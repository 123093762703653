import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { MyPageContent } from '@containers/BugoMyPageContent/components/MyPageContent';
import { MyPageWrapper } from '@containers/BugoMyPageContent/components/MyPageWrapper';
import { MyPageTitle } from '@containers/BugoMyPageContent/components/MyPageTitle';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { UserBugoHistoryContent } from '@containers/BugoMyPageContent/components/UserBugoHistroryContent';

const BugoHistoryPage: FC = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);

  useMobileNavTitleHook(
    '부고장 내역',
    true,
    false,
    isStorePage ? '/flower-store/user/mypage' : '/user/mypage',
  );

  return (
    <MyPageWrapper>
      <MyPageTitle title="부고장 내역" />
      <MyPageContent>
        <UserBugoHistoryContent />
      </MyPageContent>
    </MyPageWrapper>
  );
};

export { BugoHistoryPage };
