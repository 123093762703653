import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { ciUserAtom, ciUserListAtom } from '../state/ciUser.atom';
import { ConfirmByPhoneNumberForm } from './ConfirmByPhoneNumberForm';

interface FindIdFormProps {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

const FindIdForm: FC<FindIdFormProps> = ({ setSelectedTab }) => {
  const ciUser = useRecoilValue(ciUserAtom);
  const setCiUser = useSetRecoilState(ciUserAtom);
  const ciUserList = useRecoilValue(ciUserListAtom);
  const isStorePage = useRecoilValue(isStorePageAtom);

  return (
    <div>
      {!ciUserList ? (
        <ConfirmByPhoneNumberForm selectedTab={0} />
      ) : (
        <div className="mt-6f text-left">
          {_.map(ciUserList, (ciUserItem) => {
            return (
              <div
                className={` my-3 cursor-pointer border-2 py-3 ${
                  ciUser?._id === ciUserItem._id ? 'border-primary' : 'border-transparent'
                }`}
                onClick={() => {
                  setCiUser(ciUserItem);
                }}
              >
                <p className=" text-12">
                  <span className="font-bold text-black">{ciUserItem?.info?.name}</span>{' '}
                  님의 정보와 일치하는 아이디입니다.
                </p>
                <div className="mt-6 bg-white px-4 py-2">
                  <p className=" text-16 font-medium text-black">
                    {ciUserItem?.username}
                  </p>
                  <p className="mt-1 text-12">
                    {dayjs(ciUserItem?.createdAt).format('YYYY.MM.DD')} 가입
                  </p>
                </div>
              </div>
            );
          })}
          <div className="mt-6 flex gap-2">
            <Link
              to={isStorePage ? '/flower-store/login' : '/login'}
              className=" w-full rounded bg-primary px-4 py-3 text-center text-14 font-bold text-white"
            >
              <button>로그인하러 가기</button>
            </Link>
            <button
              className="w-full rounded bg-primary px-4 py-3 text-14 font-bold text-white disabled:cursor-not-allowed disabled:bg-gray-300"
              onClick={() => setSelectedTab(1)}
              disabled={ciUser ? false : true}
            >
              비밀번호 재설정
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export { FindIdForm };
