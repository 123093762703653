import { ChangeEvent, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { postImageWithRole } from '@api/mongo/controllers';
import { createEnquiry } from '@api/mongo/controllers/enquiry.contoroller';
import { EnquiryCreate, EnquiryStatus, EnquiryType } from '@api/mongo/interfaces';
import { BasicInput } from '@components/BasicInput';
import { Button } from '@components/Button';
import { Label } from '@components/Label';
import TelField from '@components/TelField';
import { TextAreaInput } from '@components/TextAreaInput';
import { useAuth } from '@hooks/useAuth';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';

import { EnquirySubmitButtonSet } from './components/EnquirySubmitButtonSet';
import { isValidFileSize, isValidFileType } from './utils/fileSizeCheck';

type EnquiryFormType = {
  name: string;
  content: string;
  phoneNumber: string;
  title: string;
  file: File | '';
};

const EnquiryForm = () => {
  const { userProfile } = useAuth();
  useMobileNavTitleHook('1:1문의하기', true);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<EnquiryFormType>({
    defaultValues: {
      name: userProfile?.info.name ?? '',
      phoneNumber: userProfile?.info.phoneNumber ?? '',
      title: '',
      content: '',
      file: '',
    },
  });
  const [fileName, setFileName] = useState('파일을 첨부 해주세요. (최대 1장)');
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const isStorePage = useRecoilValue(isStorePageAtom);

  const navigate = useNavigate();
  const { assetRole } = useAuth();

  const onSubmit = async (data: EnquiryFormType) => {
    //이미지는 스펙은 input에서 걸러서 들어옴
    let imgId = null;
    if (data.file) {
      console.log(userProfile?.roles[0]);
      const image = await postImageWithRole(data.file, assetRole());
      console.log(image);
      imgId = image._id;
    }
    //dto 생성
    const enquiryCreateDto: EnquiryCreate = {
      name: data.name,
      phoneNumber: data.phoneNumber,
      title: data.title,
      content: data.content,
      status: EnquiryStatus.Todo,
      type: isStorePage ? EnquiryType.Store : EnquiryType.Bugo,
      user: userProfile?._id ?? '',
      image: imgId,
    };
    console.log(enquiryCreateDto);
    const createdEnquiry = await createEnquiry(enquiryCreateDto);
    if (createdEnquiry) {
      notificateSuccess(notifiacationInstance, '문의가 정상적으로 요청되었습니다.');
      navigate(
        isStorePage
          ? '/flower-store/user/mypage?state=enquiry'
          : '/user/mypage?state=enquiry',
      );
    } else {
      notificateError(notifiacationInstance, '문의가 정상적으로 요청되지 않았습니다.');
    }
  };

  const fileLength = getValues('file')?.length;

  const resetInput = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = '';
    setFileName('파일을 첨부 해주세요. (최대 1장)');
  };

  const removeFileHandler = () => {
    setFileName('파일을 첨부 해주세요. (최대 1장)');
    setValue('file', '');
  };

  const selectFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      const file = event.target.files[0] as File;
      if (!isValidFileType(file)) {
        notificateError(
          notifiacationInstance,
          '이미지 파일만 첨부가능합니다. (jpeg, png, gif).',
        );
        resetInput(event);
        return;
      }
      if (!isValidFileSize(filesArray)) {
        notificateError(
          notifiacationInstance,
          '첨부파일 크기는 50mb를 넘길 수 없습니다.',
        );
        resetInput(event);
        return;
      }
      setValue('file', file);
      setFileName(file.name);
    }
  };

  //초기 이름,연락처 설정
  useEffect(() => {
    setFocus('title');
    setValue('name', userProfile?.info.name ?? '');
    setValue('phoneNumber', userProfile?.info.phoneNumber ?? '');
  }, [setFocus, setValue, userProfile?.info.name, userProfile?.info.phoneNumber]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mx-auto max-w-[608px]">
        <div className="flex flex-col gap-y-6 px-8 pt-16 pb-8 max-sm:py-8 max-sm:px-0">
          <div className="text-24 font-medium">1:1 문의</div>
          <BasicInput
            label="성함"
            placeholder="성함을 입력해주세요."
            className="px-4"
            isError={!!errors.name}
            {...register('name', { required: true })}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { value } }) => (
              <TelField
                value={phoneNumberStringFormator(value)}
                placeholder="연락처를 입력해주세요."
                label="연락처"
                wrapperClassName=""
                register={register}
                minLength={13}
                name="phoneNumber"
                required
              />
            )}
          />
          <BasicInput
            label="제목"
            placeholder="제목을 입력해주세요."
            className="px-4"
            isError={!!errors.title}
            {...register('title', { required: true })}
          />
          <div>
            <Label text="문의 내용" />
            <TextAreaInput
              {...register('content', { required: true })}
              isError={!!errors.content}
            />
          </div>
          <div>
            <Label text="첨부파일 (최대 50mb)" />
            <div className="grid h-10 grid-cols-4 gap-x-1 text-12 font-medium ">
              <label
                htmlFor="input_file"
                className="center-box col-span-2 cursor-pointer rounded border border-primary bg-white px-2 text-primary"
              >
                <p className=" truncate ">
                  {fileLength && fileLength > 1
                    ? `${fileName.slice(0, 27)}...외 ${fileLength}개`
                    : `${fileName}`}
                </p>
              </label>
              <input
                type="file"
                accept="image/*"
                id="input_file"
                className="hidden"
                {...register('file', {
                  onChange: selectFileHandler,
                })}
              ></input>
              <label
                htmlFor="input_file"
                className="center-box cursor-pointer rounded border bg-white text-12 font-medium shadow-sm"
              >
                업로드
              </label>
              <Button
                className="rounded border border-red-500 bg-white text-12 font-medium text-red-500 shadow-none"
                onClick={removeFileHandler}
              >
                삭제
              </Button>
            </div>
          </div>
          <EnquirySubmitButtonSet />
        </div>
      </div>
    </form>
  );
};

export { EnquiryForm };
