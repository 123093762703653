import { FC } from 'react';

import { StoreItemTagEnum } from 'src/interfaces';

import { getStoreItemTagColorStyle } from '../utils/getStoreItmeTagColor';

type FlowerStoreItemTagsProps = {
  tags: StoreItemTagEnum;
};

const FlowerStoreItemTags: FC<FlowerStoreItemTagsProps> = ({ tags }) => {
  return (
    <div
      className={`center-box w-fit rounded px-2 text-12 font-medium ${getStoreItemTagColorStyle(
        tags,
      )}`}
    >
      {tags}
    </div>
  );
};

export { FlowerStoreItemTags };
