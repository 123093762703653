import symbol from 'src/assets/image/gaon-symbol.webp';
import { useAlertNotSupportedHook } from '@hooks/useAlertNotSupportedHook';

const AppDownloadCard = () => {
  const { alertNotSupported } = useAlertNotSupportedHook();

  return (
    <div
      className="flex h-[150px] w-full cursor-pointer flex-col items-center justify-center bg-[#F4F4F5]"
      onClick={() => {
        alertNotSupported();
      }}
    >
      <div className="mb-5 text-left text-16">
        언제나 손쉽게
        <br />
        <span className="text-24 font-bold">모바일 부고장 서비스</span>
      </div>
      <div className="flex h-[49px] w-[194px] items-center justify-center border-[1px] border-[#1F2937] bg-white">
        <img src={symbol}></img>
        부고장 APP 다운로드
      </div>
    </div>
  );
};

export { AppDownloadCard };
