import { PaymentState } from 'src/interfaces';

const PaymentStatusItem = ({
  title,
  value = PaymentState.NOT_PAID,
}: {
  title: string;
  value?: PaymentState;
}) => {
  const getPaymentStatus = () => {
    switch (value) {
      case PaymentState.NOT_PAID:
        return '미결제';
      case PaymentState.WAITING:
        return '입금대기중';
      case PaymentState.PAID:
        return '결제완료';
      case PaymentState.CANCEL_REQUESTED:
        return '취소요청중';
      case PaymentState.CANCELED:
        return '취소';
      case PaymentState.CONFIRMED:
        return '구매 확정';
      default:
        return '확인 불가';
    }
  };

  //   status: PaymentState; //
  //    enum: NotPaid(결제 자체를 안했음),
  //     Waiting (가상계좌 발급완료 후 입금 대기중),
  //      Paid (카드든, 가상계좌든 결제가 완료),
  //      Confirmed (구매 확정),
  //       Canceled (결제 완료후 취소)

  return (
    <div className="grid  grid-cols-[74px_1fr] text-left text-12 font-medium">
      <div className="border-b border-r  border-l bg-orange-50 px-2 py-3 text-center text-apricot-600">
        {title}
      </div>
      <div className="border-b bg-white px-2 py-3 text-black">{getPaymentStatus()}</div>
    </div>
  );
};

export { PaymentStatusItem };
