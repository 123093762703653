import { useLayoutEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  mobileNavArrowLeftButtonToUrlAtom,
  mobileNavBarHiddenAtom,
  mobileNavBarNaviBackAtom,
  mobileNavBarTitleAtom,
  mobileNavNaviBackToUrlAtom,
  mobileNavRightMenuButtonAtom,
} from '@state';

/**
 *
 * @param title 현재 페이지에서 보여주고싶은 타이틀: string
 * @param naviBack 뒤로가기 버튼 활성화 여부 = default: false
 * @param rightMenu 햄버거 메뉴 활성화 여부 = default: true
 * @param naviBackToUrl X 버튼 사용 시 보낼 url. = default navigate(-1)
 * @param arrowLeftButtonTo 뒤로가기 버튼 사용 시 보낼 url. = default navigate(-1)
 * @param hidden PC에서 기본 NavBar대신 사용해야 되는 경우 false
 */
export const useMobileNavTitleHook = function (
  title: string,
  naviBack = false,
  rightMenu = true,
  naviBackToUrl?: string,
  arrowLeftButtonTo?: string,
  hidden = true,
) {
  const setTitle = useSetRecoilState(mobileNavBarTitleAtom);
  const setNaviBack = useSetRecoilState(mobileNavBarNaviBackAtom);
  const setRightMenuButton = useSetRecoilState(mobileNavRightMenuButtonAtom);
  const setRightMenuButtonToUrl = useSetRecoilState(mobileNavNaviBackToUrlAtom);
  const setArrowLeftButtonToUrl = useSetRecoilState(mobileNavArrowLeftButtonToUrlAtom);
  const setHidden = useSetRecoilState(mobileNavBarHiddenAtom);
  useLayoutEffect(() => {
    setTitle(title);
    setNaviBack(naviBack);
    setRightMenuButton(rightMenu);
    setHidden(hidden);
    if (naviBackToUrl) setRightMenuButtonToUrl(naviBackToUrl);
    if (arrowLeftButtonTo) setArrowLeftButtonToUrl(arrowLeftButtonTo);

    return () => {
      setTitle('가온부고');
      setNaviBack(false);
      setRightMenuButton(true);
      setRightMenuButtonToUrl('');
      setArrowLeftButtonToUrl('');
      setHidden(true);
    };
  }, [
    arrowLeftButtonTo,
    naviBack,
    rightMenu,
    naviBackToUrl,
    setArrowLeftButtonToUrl,
    setNaviBack,
    setRightMenuButton,
    setRightMenuButtonToUrl,
    setTitle,
    title,
    hidden,
    setHidden,
  ]);
};
