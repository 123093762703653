import { atom } from 'recoil';

export const hasuraCountQueryAtom = atom<number>({
  key: 'hasuraCountQueryAtom',
  default: 0,
});

export const hasuraCountSubscriptionAtom = atom<number>({
  key: 'hasuraCountSubscriptionAtom',
  default: 0,
});

export const hasuraCountIdAtom = atom<string>({
  key: 'hasuraCountIdAtom',
  default: process.env.REACT_APP_DEFAULT_COUNT_ID,
});
