import { useRecoilValue } from 'recoil';

import { CouponItem } from '@components/CouponItem';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { Link } from 'react-router-dom';

import { CashPointInfoSet } from './components/CashPointInfoSet';

const UpgradeContent = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);

  return (
    <div className="mx-auto mt-16 max-w-[484px] px-4 py-32 max-sm:mx-[-1rem] max-sm:mt-0 max-sm:py-16">
      <div className="flex flex-col items-center gap-4">
        <p className=" text-14 font-medium text-black">
          지금 회원으로 전환하시면 아래와 같은 혜택이 있습니다!
        </p>
        <CashPointInfoSet coupon={30000} />
        <CouponItem className="w-full max-w-full" />
      </div>
      <Link to={isStorePage ? '/flower-store/signup' : '/signup'}>
        <button className="mt-8 w-full rounded bg-primary px-4 py-3 text-14 font-bold text-white">
          회원 전환하고 혜택 받기
        </button>
      </Link>
    </div>
  );
};

export { UpgradeContent };
