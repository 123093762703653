import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  bugoDataAtomFamily,
  bugoStatusAtomFamily,
  memberBugoDataAtomFamily,
  memberBugoStatusAtomFamily,
} from './bugo.atom';

const bugoPopulate = [
  {
    path: 'fevent',
    populate: [
      {
        path: 'deceasedInfo.dpImage',
      },
      {
        path: 'memberOrderList',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'roomInfo',
      },
      {
        path: 'cashAccounts',
      },
      {
        path: 'shopOrders',
        populate: {
          path: 'orderDetail.shopItem',
          populate: [
            {
              path: 'image',
            },
            {
              path: 'shopItemInfo',
              populate: {
                path: 'image',
              },
            },
          ],
        },
      },
    ],
  },
  {
    path: 'bugoBrand',
  },
  {
    path: 'member',
    populate: {
      path: 'cashAccount',
    },
  },
];

const memberPopulate = [
  {
    path: 'bugo',
    populate: bugoPopulate,
  },
];

//* public bugo hook url
export const bugoUrl = (bugoId: string) =>
  `public/bugo/${bugoId}/?${queryString({
    populate: bugoPopulate,
  })}`;

//* public bugo hook
export const useBugoHook = function (bugoId: string, disabled?: boolean) {
  useRecoilQuery(
    bugoStatusAtomFamily(bugoId),
    bugoDataAtomFamily(bugoId),
    bugoUrl(bugoId),
    fetcher,
    disabled,
  );
};

export const memberUrl = (memberId: string, role: string) =>
  `${role}/member/${memberId}/?${queryString({
    populate: memberPopulate,
  })}`;

export const useMemberBugoDataHook = (memberId: string, disabled?: boolean) => {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    memberBugoStatusAtomFamily(memberId),
    memberBugoDataAtomFamily(memberId),
    memberUrl(memberId, bugoRole()),
    fetcher,
    disabled,
  );
};
