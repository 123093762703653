import { useCallback, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { storeItemStateSelector } from '@containers/FlowerStoreItemDetail/state/storeItemDetail.selector';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { notificateError } from 'src/@utils/noti.utils';
import { useAuth } from '@hooks/useAuth';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { UserRole } from 'src/interfaces';
import { ShopOrder, ShopOrderCreateDto } from '@api/mongo/interfaces';
import { useBugoHook } from '@containers/BugoPageContent/state/bugo.hook';
import { bugoStateSelectorFamily } from '@containers/BugoPageContent/state/bugo.selector';
import { Bootpay } from '@bootpay/client-js';
import { nestShopOrderApi } from '@api/mongo/controllers/shopOrder.guest.controller';

import { ItemReciveForm } from './components/ItemReciveForm';
import { ItemOrderInfo } from './components/ItemOrderInfo';
import {
  CheckOutState,
  FormContextType,
} from './state/flowerStoreItemCheckout.interface';
import { getShopOrderDto } from './utils/getShopOrderDto';
import { getBootpayRequestPaymentDto } from './utils/getBootpayRequestPaymentDto';

const FlowerStoreItemCheckout = () => {
  const [searchParams] = useSearchParams();
  const bugoId = searchParams.get('bugoId');
  useBugoHook(bugoId ?? '', !bugoId);
  const { storeRole, signupOrLoginStoreGuest, userProfile } = useAuth();

  const {
    create: createShopOrder,
    deleteById: deleteShopOrderById,
    isSuccesslyPaid,
  } = nestShopOrderApi<ShopOrder>();

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { data: bugoData, status: bugoStatus } = useRecoilValue(
    bugoStateSelectorFamily(bugoId ?? ''),
  );
  const { data: shopItemData, status: shopItemStatus } =
    useRecoilValue(storeItemStateSelector);

  // const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [checkoutState, setCheckoutState] = useState(CheckOutState.IDLE); // 주문결제성공 페이지로 넘어가기위한 state IDLE DOING DONE
  const [orderId, setOrderId] = useState<string | null>(null);
  const [bootpayData, setBootpayData] = useState<FormContextType>();
  const [requestState, setRequestState] = useState<boolean>(false); // bootpay request call trigger

  const navigate = useNavigate();

  //! 4. 결제 성공 및 실패에 따라서 error page, success page로 redirect
  // 결제 성공 시 Redirect.
  useEffect(() => {
    if (checkoutState === CheckOutState.DONE && !_.isNil(orderId)) {
      navigate(`/flower-store/order/:${orderId}/payment`);
    }
  }, [navigate, checkoutState, orderId]);

  //* utils
  const doneHandle = useCallback(
    async (data: any, shopOrderId: string) => {
      try {
        const shopOrder_ = {
          _id: shopOrderId,
          receiptId: data.receipt_id,
        };
        const isValid = await isSuccesslyPaid(shopOrder_._id); // /success/:id

        if (isValid) {
          // setIsSuccess(true);
          setOrderId(shopOrderId); // recoil state 바꾸는 함수
          setCheckoutState(CheckOutState.DONE);
        } else {
          throw Error('유효하지 않은 결제 시도로 인한 취소 처리');
        }
      } catch (err: any) {
        console.error(err);
        setCheckoutState(CheckOutState.IDLE);
        notificateError(
          notifiacationInstance,
          `결제 실패\n${err.response?.data?.message}`,
        );
      }
    },
    [notifiacationInstance, storeRole],
  );

  const readyHandle = async (data: any) => {
    console.log(data);
  };

  const methods = useForm<FormContextType>({
    defaultValues: {
      condolences: '',
      mournerName: '',
      orderName: '',
      phoneNumber: '',
      sender: '',
      address: '',
      point: 0,
    },
  });

  const onSubmit = useCallback(
    async (data: FormContextType) => {
      console.log('form :', data);
      setBootpayData(data);

      if (_.isNil(userProfile) || userProfile.roles.includes(UserRole.StoreGuest)) {
        try {
          // guest signup logic
          // console.log('user 가입 시켜야함');
          const guestSignupData = {
            name: data.orderName,
            phoneNumber: data.phoneNumber,
            password: String(data.phoneNumber).split('').slice(-4).join(''),
            roles: [UserRole.BugoGuest, UserRole.StoreGuest],
          };
          await signupOrLoginStoreGuest(guestSignupData, true);
          setRequestState(true);
        } catch (err) {
          console.error(err);
        }
      } else {
        setRequestState(true);
      }
    },
    [signupOrLoginStoreGuest, userProfile],
  );

  const onSubmitError: SubmitErrorHandler<FormContextType> = (e) => {
    notificateError(notifiacationInstance, '빨간 테두리 영역은 필수 정보입니다.');
  };

  const onBootpayRequest = useCallback(async () => {
    // 필요한 데이터 없을 시 return
    console.log('userProfile: ', userProfile);
    console.log('bootpayData: ', bootpayData);
    console.log('shopItemData: ', shopItemData);
    console.log('bugoData: ', bugoData);
    if (
      _.isNil(userProfile) ||
      _.isNil(bootpayData) ||
      _.isNil(shopItemData)
      // || _.isNil(bugoData)
      // !data.isAgreed
    )
      return;

    const data = bootpayData;
    console.log('data: ', data);

    //! 0. shopOrder dto 만들기
    const shopOrderDto: ShopOrderCreateDto = getShopOrderDto({
      formData: data,
      shopItem: shopItemData,
      bugo: bugoData,
      userId: userProfile._id,
    });

    // console.log('shopOrderDto: ', shopOrderDto);

    try {
      //! 1. backend에 shopOrder 생성하기
      const { data: shopOrder } = await createShopOrder(shopOrderDto);

      try {
        //! 2. checkoutState 변경하기(결제중)
        setCheckoutState(CheckOutState.DOING);

        // console.log('bootpayRequest: ', {
        //   formData: data,
        //   shopOrder,
        //   shopItem: shopItemData,
        //   userProfile,
        // });

        //! 3. shopOrder data, register data, user data 참조하여 bootpay payment callback 실행
        const paymentResponse = await Bootpay.requestPayment(
          getBootpayRequestPaymentDto({
            formData: data,
            shopOrder,
            shopItem: shopItemData,
            userProfile,
          }),
        );

        let confirmedData;
        let enable = true;

        switch (paymentResponse.event) {
          case 'issued':
            // 가상계좌 입금 완료 처리
            await readyHandle(paymentResponse.data);
            break;
          case 'done':
            //!   3-2. 결제가 완료되었을 경우 checkoutState를 변경(완료)
            await doneHandle(paymentResponse.data, shopOrder._id);
            break;
          // * confirm 사실 안씀
          case 'confirm':
            enable = true; // 재고 수량 관리 로직 혹은 다른 처리
            if (enable) {
              confirmedData = await Bootpay.confirm(); //결제를 승인한다
              if (confirmedData.event === 'done') {
                //결제 성공
              } else if (confirmedData.event === 'error') {
                //결제 승인 실패
              }
            } else {
              Bootpay.destroy(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
            }

            /**
             * 2. 서버 승인을 하고자 할때
             * // requestServerConfirm(); //예시) 서버 승인을 할 수 있도록  API를 호출한다. 서버에서는 재고확인과 로직 검증 후 서버승인을 요청한다.
             * Bootpay.destroy(); //결제창을 닫는다.
             */
            break;
          case 'close':
          // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
          // console.log('[bootpay.request.close]', paymentResponse);
        }
      } catch (error) {
        //!   3-1. 결제가 실패했을 경우, 결과에 맞게 backend shopOrder 상태 반환 또는 삭제
        switch ((error as any).event) {
          case 'cancel':
            // 사용자가 결제창을 닫을때 호출
            console.error(error);
            setCheckoutState(CheckOutState.IDLE);
            await deleteShopOrderById(shopOrder._id);
            break;
          case 'error':
            // 결제 승인 중 오류 발생시 호출
            console.error(error);
            // await deleteShopOrderById(shopOrder._id, storeRole());
            setOrderId(shopOrder._id);
            setCheckoutState(CheckOutState.DONE);
            break;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    userProfile,
    bootpayData,
    shopItemData,
    bugoData,
    createShopOrder,
    doneHandle,
    deleteShopOrderById,
  ]);

  useEffect(() => {
    if (requestState) {
      onBootpayRequest();
      setRequestState(false);
    }
  }, [requestState, onBootpayRequest, setRequestState]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
        className="grid grid-cols-2 gap-4 break-keep bg-white p-4 max-sm:mx-[-1rem] max-sm:grid-cols-1 max-sm:p-2"
      >
        <ItemReciveForm />
        <ItemOrderInfo />
      </form>
    </FormProvider>
  );
};

export { FlowerStoreItemCheckout };
