import { FC, useLayoutEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BasicInput } from '@components/BasicInput';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import TelField from '@components/TelField';
import { bugoStateSelectorFamily } from '@containers/BugoPageContent/state/bugo.selector';
import { useSearchParams } from 'react-router-dom';
import { AutoSelect } from '@components/AutoSelect';

import { FormContextType } from '../state/flowerStoreItemCheckout.interface';
import { phraseCondolenceList } from '../utils/phraseCondolenceList';

const ItemReciveForm: FC = () => {
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<FormContextType>();

  const [mournerList, setMournerList] = useState<string[] | undefined>(undefined);
  const ValueForPhoneNumberFormator = useWatch({ name: 'phoneNumber' });
  const [searchParams] = useSearchParams();

  const bugoId = searchParams.get('bugoId');
  const { data: bugoData, status: bugoStatus } = useRecoilValue(
    bugoStateSelectorFamily(bugoId ?? ''),
  );

  //bugoId 있으면 상주명 ,주소 채워놓기
  useLayoutEffect(() => {
    const address =
      bugoData?.fevent.funeralHomeInfoEmbed?.address ??
      bugoData?.fevent.funeralHomeInfo.address;
    const roomInfo =
      bugoData?.fevent.roomInfo?.name ?? bugoData?.fevent.roomInfoEmbed?.name ?? '';

    if (address) {
      setValue('address', `${address} ${roomInfo}호`);
    }

    if (bugoData?.fevent.memberOrderList) {
      const memberOrderList = bugoData?.fevent.memberOrderList.map(
        (item) => item.fullName,
      );
      setMournerList(memberOrderList);
    }
  }, [bugoData, setValue]);

  return (
    <div className="text-left">
      <div className="pb-4">
        <InputTitle text="수령하실 분" />

        {mournerList ? (
          <div className="mb-2">
            <AutoSelect
              formControl={control}
              labelText="상주님"
              optionList={mournerList}
              formFieldName="mournerName"
              validationRules={{ required: true }}
              inputPlaceholder="상주님을 선택해주세요."
              allowsDirectInput={mournerList.length === 0}
            />
          </div>
        ) : (
          <Controller
            name="mournerName"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <BasicInput
                  label="상주님"
                  placeholder="상주님"
                  value={value}
                  onChange={onChange}
                  className={`mb-2 px-4 ${error && 'border-red-500'}`}
                />
              );
            }}
          />
        )}

        <BasicInput
          label="주소"
          {...register('address', { required: true })}
          placeholder="주소"
          className={`px-4 ${errors.address && 'border-red-500'}`}
        />
      </div>

      <div className="border-b border-t py-4">
        <InputTitle text="주문하시는 분" />
        <div className={`grid grid-cols-2 gap-2 max-sm:grid-cols-1 `}>
          <BasicInput
            {...register('orderName', { required: true })}
            label="성함"
            placeholder="성함"
            className={`py-2 px-4 max-sm:mb-2 ${errors.orderName && 'border-red-500'}`}
          />
          <TelField
            name="phoneNumber"
            register={register}
            value={ValueForPhoneNumberFormator}
            label="핸드폰"
            required
            className={`py-2 px-4 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none ${
              errors.phoneNumber && 'border-red-500'
            }`}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 py-4">
        <InputTitle text="문구 작성" />
        <AutoSelect
          formFieldName="condolences"
          formControl={control}
          optionList={phraseCondolenceList}
          inputPlaceholder="조의문구를 선택 혹은 입력해주세요."
          labelText="조의문구"
          allowsDirectInput
        />
        <BasicInput
          {...register('sender', { required: true })}
          label="보내는분"
          placeholder="예) 서울대학교 동문 일동"
          className={`py-2 px-4 ${errors.sender && 'border-red-500'}`}
        />
      </div>
    </div>
  );
};

export { ItemReciveForm };

const InputTitle = ({ text }: { text: string }) => {
  return <h1 className="pb-2 text-16 font-medium text-black">{text}</h1>;
};
