import { selector } from 'recoil';

import {
  userBugoHistroryContentStatusAtom,
  userBugoHistroryContentDataAtom,
} from './userBugoHistroryContent.atom';
import { UserBugoHistroyState } from './userBugoHistroryContent.interface';

const userBugoHistroryContentStateSelector = selector<UserBugoHistroyState>({
  key: 'bugoMyPageStateSelector',
  get: ({ get }) => {
    return {
      status: get(userBugoHistroryContentStatusAtom),
      data: get(userBugoHistroryContentDataAtom),
    };
  },
});

export { userBugoHistroryContentStateSelector };
