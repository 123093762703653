import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';
import { nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { useBugoSendHook } from '@containers/FeventSubmitContent/state/feventSubmitContent.hook';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { useAuth } from '@hooks/useAuth';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { modalOpenAtomFamily } from '@state/atom/openAtom';

import { FormValue, FormValues } from './state/bugoSendForm.interface';
import BugoSendFormList from './components/BugoSendFormList';
import { BasicInfoForm } from '../BasicInfoForm';
import { setDefaultValues } from './utils/setDefaultFormValues';
import { FeventStepper } from '../FeventStepper';
import { MobileDescriptionSection } from '../DeceasedInfoForm/components';
import { isSubmitOnBugoSendFormAtom } from './state/bugoSendForm.atom';

const BugoSendForm = () => {
  const setIsSubmitOnBugoSendForm = useSetRecoilState(isSubmitOnBugoSendFormAtom);

  const isEditPage = useRecoilValue(isEditPageAtom);
  const setOpen = useSetRecoilState(
    modalOpenAtomFamily('BugoSendFormSubmitSuccess-modal'),
  );

  const queryClient = useQueryClient();

  const currentFeventId = useRecoilValue(currentFeventIdAtom);
  const { bugoRole } = useAuth();
  const { patchUpdateMembersData } = useBugoSendHook();
  const { data: fevent, status: feventStatus } = useRecoilValue(
    currentFeventStateSelectorFamily(currentFeventId ?? ''),
  );
  const { feventId } = useParams();
  const { bugoSend } = nestAlertApi();

  const method = useForm<FormValues>({
    defaultValues: {
      memberData: fevent ? setDefaultValues(fevent.memberOrderList) : undefined,
    },
  });
  const { control, getValues, handleSubmit, trigger } = method;

  const { fields } = useFieldArray({
    control,
    name: 'memberData',
  });

  /**
   * @description 완료버튼 클릭 시 업데이트된 항목이 있는지 확인
   * @returns boolean
   */
  const checkIsEqual = () => {
    return !fields.reduce((found, field, index) => {
      if (found) {
        return true; // 이미 찾은 경우, 중복된 호출 방지를 위해 바로 true 반환
      }
      return (
        !!getValues(`memberData.${index}.isPhoneNumberUpdated`) ||
        !!getValues(`memberData.${index}.isMournerTextUpdated`)
      ); // 해당 요소의 조건을 확인하여 true 또는 false 반환
    }, false);
  };

  const updateMemberData = (memberData: FormValue, index: number) => {
    if (!memberData.mournerText && fevent?.mournerText) {
      memberData.mournerText = fevent.mournerText;
    }
    memberData.bugoId = fevent?.memberOrderList[index]?.bugo?._id ?? '';
  };

  const onSubmit: SubmitHandler<FormValues> = async (data, send = true as any) => {
    data.memberData.forEach(updateMemberData);
    await patchUpdateMembersData(data);
    const memberBugoIdList = data.memberData.map((data) => data.bugoId);

    if (!send) {
      await queryClient.invalidateQueries(
        feventByIdHookUrl(feventId ?? currentFeventId ?? '', bugoRole()),
      );
      return;
    }

    if (feventId) {
      const updatedMemberBugoIdList = data.memberData
        .filter((memberData) => {
          return memberData.isPhoneNumberUpdated || memberData.isAddedMember;
        })
        .map((member) => member.bugoId);

      if (updatedMemberBugoIdList.length === 0) {
        setOpen(true);
        return;
      }

      await bugoSend({ bugos: updatedMemberBugoIdList });
      setIsSubmitOnBugoSendForm(true);
      setOpen(true);
      return;
    }

    await bugoSend({ bugos: memberBugoIdList });
    setIsSubmitOnBugoSendForm(true);
    setOpen(true);
  };

  useEffect(() => {
    if (
      fevent &&
      (fevent.memberOrderList.length !== fields.length || feventStatus === 'success')
    )
      control._reset({
        memberData: setDefaultValues(fevent.memberOrderList),
      });
  }, [fevent, control, fields.length, feventStatus]);

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FeventStepper
          onSubmit={handleSubmit((data) => onSubmit(data, false as any))}
          checkEqual={checkIsEqual}
          trigger={trigger}
        />
        <MobileDescriptionSection />
        <BasicInfoForm
          title="부고 전송"
          desc={`상주님께 부고를 보내드립니다. 각 상주님들은 받으신 부고문자 페이지에서 공유를 통하여 지인분들께 부고를 전하실 수 있습니다.
          
          부고장 미리보기를 클릭하셔서 보내실 부고장을 미리 확인하실 수 있습니다.
          
          ${
            isEditPage
              ? `수정된 부고 전송 클릭시 휴대전화번호가 변경되었거나, 
            새로 추가된 상주님들에게만 부고가 전송됩니다.
            
            추가적으로 한번더 전송이 필요한 경우 재전송 버튼을 통해 진행해주세요.`
              : ''
          }`}
        >
          <BugoSendFormList
            fevent={fevent}
            onSubmit={onSubmit}
            checkUpdated={checkIsEqual}
            fields={fields}
          />
        </BasicInfoForm>
      </form>
    </FormProvider>
  );
};

export { BugoSendForm };
