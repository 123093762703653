import { FC, useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { FeventSubmitContent } from '@containers/FeventSubmitContent';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Header } from '@layout';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StepNameEnum } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';

const FeventEditPage: FC = () => {
  const { step, feventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (_.isNil(step)) {
      navigate(StepNameEnum.DeceasedInfo, { replace: true });
    }
  }, [navigate, step]);

  const setIsEditPage = useSetRecoilState(isEditPageAtom);

  useEffect(() => {
    if (feventId) {
      setIsEditPage(true);
    } else {
      setIsEditPage(false);
    }
  }, [feventId, setIsEditPage]);

  useMobileNavTitleHook(
    location.pathname.indexOf('add') > 0 ? '부고 만들기' : '부고 수정하기',
    true,
    true,
  );

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header>
        <MainSection title={feventId ? '부고 수정하기' : '부고 만들기'}>
          <FeventSubmitContent />
        </MainSection>
      </Body>
    </>
  );
};

export { FeventEditPage };
