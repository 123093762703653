import { FC, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { MyPageContent } from '@containers/BugoMyPageContent/components/MyPageContent';
import { MyPageTitle } from '@containers/BugoMyPageContent/components/MyPageTitle';
import { MyPageWrapper } from '@containers/BugoMyPageContent/components/MyPageWrapper';
import { UserModifyContent } from '@containers/BugoMyPageContent/components/UserModifyContent';
import { hasConfirmPasswordAtom } from '@containers/BugoMyPageContent/components/UserModifyContent/state/userModifyContent.atom';
import { useNavigate } from 'react-router-dom';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';

const UserModifyPage: FC = () => {
  const hasConfirmPassword = useRecoilValue(hasConfirmPasswordAtom);
  const isStore = useRecoilValue(isStorePageAtom);
  const navaigate = useNavigate();

  useEffect(() => {
    if (hasConfirmPassword === false) {
      navaigate(
        isStore ? '/flower-store/user/confirm-password' : '/user/confirm-password',
        { replace: true },
      );
    }
  }, [hasConfirmPassword, isStore, navaigate]);

  return hasConfirmPassword ? (
    <MyPageWrapper>
      <MyPageTitle title="내 정보" />
      <MyPageContent>
        <UserModifyContent />
      </MyPageContent>
    </MyPageWrapper>
  ) : null;
};

export { UserModifyPage };
