import { FC, ReactNode } from 'react';

import { FuneralHomeInfo, FuneralHomeInfoEmbed } from '@api/mongo/interfaces';
import { BasicInput } from '@components/BasicInput';
import { Label } from '@components/Label';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { FeventFormData } from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import { Controller, useFormContext } from 'react-hook-form';

import FuneralHomeInfoSearcher from './FuneralHomeInfoSearcher';

interface FuneralInfoFormProps {
  funeralHomeInfo?: FuneralHomeInfo;
  children?: ReactNode;
}

const FuneralInfoForm: FC<FuneralInfoFormProps> = ({ funeralHomeInfo }) => {
  const {
    setValue,
    getValues,
    formState: { errors },
    register,
    control,
  } = useFormContext<FeventFormData>();

  const { funeralHomeInfoEmbed } = getValues();

  const onFuneralHomeSelect = (funeralHomeId: string) => {
    setValue('funeralHomeInfo', funeralHomeId);
  };

  //* funeralHomeEmbed 선택
  const onfuneralHomeInfoEmbedSelect = (
    funeralHomeInfoEmbed: FuneralHomeInfoEmbed,
    funeralHomeInfoId: string,
  ) => {
    setValue('funeralHomeInfo', funeralHomeInfoId);
    setValue('funeralHomeInfoEmbed', funeralHomeInfoEmbed);
  };

  const onRoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('roomInfoEmbed.name', event.currentTarget.value);
  };

  return (
    <BasicInfoForm
      title="장례식장 정보"
      desc="부고장에 표기될 장례식장 정보를 입력해주세요. 조문객분들이
오실 수 있도록 검색하셔서 맞는 장례식장을 선택해주세요."
    >
      <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1 max-sm:gap-2">
        <div className="self-end">
          <Label>장례식장 검색</Label>
          <Controller
            control={control}
            name="funeralHomeInfo"
            rules={{ required: true }}
            render={() => {
              return (
                <FuneralHomeInfoSearcher
                  value={funeralHomeInfoEmbed?.name ?? funeralHomeInfo?.name ?? ''}
                  onValueChange={onFuneralHomeSelect}
                  onEmbedSelect={onfuneralHomeInfoEmbedSelect}
                />
              );
            }}
          ></Controller>
        </div>

        <BasicInput
          label="빈소(호실)"
          className={`px-4 ${errors.roomInfoEmbed?.name ? 'border-red-500' : ''}`}
          placeholder="빈소(호실)"
          {...register('roomInfoEmbed.name', {
            required: true,
            onChange: (event) => onRoomChange(event),
          })}
        />
      </div>
    </BasicInfoForm>
  );
};

export { FuneralInfoForm };
