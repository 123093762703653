import { FC, HTMLAttributes } from 'react';

import { useRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { modalOpenAtomFamily } from '@state/atom/openAtom';

interface IStoreItemAccountNotice extends HTMLAttributes<HTMLDivElement> {
  title: string;
  desc: string;
}

const StoreItemAccountNotice: FC<IStoreItemAccountNotice> = ({
  title,
  desc,
  ...props
}) => {
  const buttonHandler = () => {
    setIsModal((prev) => !prev);
  };

  const modalId = `accountNotice-${title}`;
  const [isModal, setIsModal] = useRecoilState(modalOpenAtomFamily(modalId));

  return (
    <div
      className="whitespace-pre-line border-l border-r border-t border-b text-left last:border-t-0 max-sm:mx-[-1rem] max-sm:border-l-0 max-sm:border-r-0"
      {...props}
    >
      <div className="flex cursor-pointer py-2 px-4" onClick={buttonHandler}>
        <Icon.ChevronRight className={`mr-2 stroke-none ${isModal && 'rotate-90'}`} />
        <p className=" break-keep font-medium text-gray-600">{title}</p>
      </div>
      {isModal && (
        <div className="max-h-[300px] overflow-y-scroll break-keep border-t bg-white  p-2 text-12">
          {desc}
        </div>
      )}
    </div>
  );
};

export { StoreItemAccountNotice };
