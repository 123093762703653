const maxFileSize = 50 * 1024 * 1024; // 50MB in bytes

export const isValidFileType = (file: File) => {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  return acceptedImageTypes.includes(file.type);
};

export const isValidFileSize = (filesArray: File[]) => {
  const totalSize = filesArray.reduce((total, file) => total + file.size, 0);
  return totalSize <= maxFileSize;
};
