import { api_ } from '@plugin/axios';
import { AxiosResponse } from 'axios';
import qs from 'qs';

enum AlertMethod {
  Message = 'Message',
  Kakao = 'Kakao',
}

type AlertApiParams = {
  bugos: string[];
  method?: AlertMethod;
};

type AlertApiResponseData = {
  success: boolean;
  status: number;
  msg: string;
};

type NestAlertApi = () => {
  // bugo message send
  bugoSend: (params: AlertApiParams) => Promise<AxiosResponse<AlertApiResponseData>>;
  // courtesy message send
  courtesySend: (params: AlertApiParams) => Promise<AxiosResponse<AlertApiResponseData>>;
};

const nestAlertApi: NestAlertApi = () => {
  // bugo message send
  const bugoSend = async (params: AlertApiParams) => {
    return await api_.get(
      `/b2c/alert/bugo?${qs.stringify(
        {
          bugos: JSON.stringify(params.bugos),
          method: params.method,
        },
        {
          encode: false,
        },
      )}`,
    );
  };
  // courtesy message send
  const courtesySend = async (params: AlertApiParams) => {
    return await api_.get(
      `/b2c/alert/courtesy?${qs.stringify(
        {
          bugos: JSON.stringify(params.bugos),
          method: params.method,
        },
        {
          encode: false,
        },
      )}`,
    );
  };

  return {
    bugoSend,
    courtesySend,
  };
};

export { nestAlertApi, AlertApiParams, AlertApiResponseData, AlertMethod };
