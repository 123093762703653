import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useNavigate } from 'react-router-dom';

const EnquirySubmitButtonSet = () => {
  const navigate = useNavigate();
  const isStorePage = useRecoilValue(isStorePageAtom);

  return (
    <>
      <div className="grid grid-cols-2 gap-1 pt-5  ">
        <Button
          className="rounded bg-white py-3 text-14 font-medium shadow-sm"
          onClick={() => navigate(isStorePage ? '/flower-store' : '/')}
        >
          처음으로
        </Button>
        <Button
          className=" rounded bg-gray-800 text-14 font-medium text-white shadow-sm"
          type="submit"
        >
          문의하기
        </Button>
      </div>
    </>
  );
};

export { EnquirySubmitButtonSet };
