import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';
import { MemberBugoPage } from '@pages/Member/MemberBugoPage';
import { CourtesyPage } from '@pages/Member/CourtesyPage';

const MemberRouter: FC = () => {
  return (
    <Routes>
      <Route path=":memberId/courtesy" element={<CourtesyPage />} />
      <Route path=":memberId/bugo" element={<MemberBugoPage />} />
    </Routes>
  );
};

export { MemberRouter };
