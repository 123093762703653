import { FC } from 'react';

import { useAlertNotSupportedHook } from '@hooks/useAlertNotSupportedHook';
import { NavLink } from 'react-router-dom';

type MenuListItemProps = {
  _id: string;
  title: string;
  href: string | undefined;
};

const MenuListItem: FC<MenuListItemProps> = ({ title, href }) => {
  const { alertNotSupported } = useAlertNotSupportedHook();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!href) {
      event.preventDefault();
      alertNotSupported();
    }
  };

  return (
    <NavLink to={href ?? ''} onClick={onClick} caseSensitive>
      <li className={'cursor-pointer py-2 px-4 font-bold'}>{title}</li>
    </NavLink>
  );
};

export { MenuListItem };
