import { selector } from 'recoil';

import { imageListDataAtom, imageListStatusAtom } from './image.atom';
import { ImageListState } from './image.interface';

export const imageListStateSelector = selector<ImageListState>({
  key: 'imageListStateSelector',
  get: ({ get }) => {
    return {
      status: get(imageListStatusAtom),
      data: get(imageListDataAtom),
    };
  },
});
