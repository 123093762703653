import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { Body, Header, Footer } from '@layout';
import { FlowerStoreIfnoForm } from '@components/FlowerStoreInfoForm';
import { FlowerStoreSection } from '@components/FlowerStoreSection';
import { FlowerStoreOrderListContent } from '@containers/FlowerStoreOrderListContent';

const FlowerStoreOrderListPage = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <FlowerStoreSection title="배송조회">
          <FlowerStoreIfnoForm leftComponentClassName="hidden">
            <FlowerStoreOrderListContent />
          </FlowerStoreIfnoForm>
        </FlowerStoreSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { FlowerStoreOrderListPage };
