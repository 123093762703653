import { atom } from 'recoil';

import { QueryStatus } from 'react-query';
import { FuneralHomeInfoList } from '@api/mongo/interfaces';

export const funeralHomeInfoListStatusAtom = atom<QueryStatus>({
  key: 'funeralHomeInfoListStatusAtom',
  default: 'idle',
});

export const funeralHomeInfoListDataAtom = atom<FuneralHomeInfoList | undefined>({
  key: 'funeralHomeInfoListDataAtom',
  default: undefined,
});
