import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { useForm } from 'react-hook-form';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';
import { useAuth } from '@hooks/useAuth';
import _ from 'lodash';
import { nestAuthApi } from '@api/mongo/controllers';

import { UserModifyFormItem } from './components/UserModifyFormItem';
import {
  isVaildName,
  isVaildPassword,
  isVaildPhoneNumber,
  maskPhoneNumber,
  maskStr,
} from './utils/maskString.util';
import {
  UserModifyFormType,
  FormStateKeyType,
  FormFields,
} from './state/userModifyContent.interface';

const UserModifyContent: FC = () => {
  useMobileNavTitleHook('회원정보 수정', true, false);
  const { userProfile, refreshUserProfile } = useAuth();
  const { patchMeDetail, patchPassword } = nestAuthApi();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const [formatPhoneNumber, setFormatPhoneNumber] = useState('');
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [hasFormChanged, setHasFormChanged] = useState(true);

  const [modifyFormState, setModifyFormState] = useState<UserModifyFormType>({
    name: maskStr(userProfile?.info.name as string, 1, 1) || '',
    _id: (userProfile?.username as string) || '',
    phoneNumber: maskPhoneNumber(userProfile?.info.phoneNumber as string) || '',
    password: '',
  });

  const [ModifyFormToggle, setModifyFormToggle] = useState({
    name: false,
    _id: false,
    password: false,
    phoneNumber: false,
  });

  const { register, handleSubmit, getValues, setValue, reset } =
    useForm<UserModifyFormType>({
      defaultValues: {
        name: '',
        _id: '',
        phoneNumber: '',
        password: '',
      },
    });

  const itemSubmitHandler = (
    key: FormStateKeyType,
    validator: (input: string) => boolean,
    errorMessage: string,
  ) => {
    const value = getValues()[key];
    if (validator(value)) {
      setFormItemValue(key, value);
      toggleFormItem(key);
    } else {
      notificateError(notifiacationInstance, errorMessage);
    }
  };

  const passwordItemSubmitHandler = () => {
    itemSubmitHandler(
      FormStateKeyType.Password,
      isVaildPassword,
      '비밀번호는 최소5자 최대 20자이어야 합니다.',
    );
  };

  const idItemSubmitHandler = () => {
    itemSubmitHandler(
      FormStateKeyType.Name,
      isVaildName,
      '이름은 최소 1자 이상이어야합니다.',
    );
  };

  const phoneNumberItemSubmitHandler = () => {
    itemSubmitHandler(
      FormStateKeyType.PhoneNumber,
      isVaildPhoneNumber,
      '휴대폰 번호를 확인해주세요.',
    );
  };

  const toggleFormItem = useCallback((key: FormStateKeyType) => {
    setModifyFormToggle((prev) => {
      return { ...prev, [key]: !prev[key] };
    });
  }, []);

  const setFormItemValue = useCallback((key: FormStateKeyType, value: string) => {
    setModifyFormState((prev) => {
      return { ...prev, [key]: value };
    });
    setHasFormChanged(false);
  }, []);

  const cancelFormItemEdit = useCallback(
    (key: FormStateKeyType) => {
      toggleFormItem(key);
      setValue(key, '');
    },
    [toggleFormItem, setValue],
  );

  const changeFormItemWithToggle = useCallback(
    (key: FormStateKeyType) => {
      const getNameValue = getValues()[key];
      setFormItemValue(key, getNameValue);
      toggleFormItem(key);
    },
    [getValues, toggleFormItem, setFormItemValue],
  );

  const onSubmit = async (data: UserModifyFormType) => {
    console.log(data);
    const checkedData = {
      ...data,
      name: data.name === '' ? (userProfile?.info.name as string) : data.name,
      phoneNumber:
        data.phoneNumber === ''
          ? (userProfile?.info.phoneNumber.replace(/[^0-9]/gi, '') as string)
          : data.phoneNumber.replace(/[^0-9]/gi, ''),
    };

    const omitData = _.omit(checkedData, '_id', 'password');
    await patchMeDetail({ info: omitData });
    if (data.password !== '') {
      await patchPassword({ password: data.password });
    }
    await refreshUserProfile();
    setHasFormChanged(true);
    setIsEditingForm(true);
    notificateSuccess(notifiacationInstance, '수정 완료');
    reset();
  };

  useLayoutEffect(() => {
    const isAnyEditingForm = Object.values(ModifyFormToggle).every(
      (item) => item === false,
    );
    if (isAnyEditingForm) {
      setIsEditingForm(true);
    }
  }, [ModifyFormToggle]);

  useEffect(() => {
    setModifyFormState({
      name: maskStr(userProfile?.info.name as string, 1, 1) || '',
      _id: userProfile?.username || '',
      phoneNumber:
        maskPhoneNumber(
          phoneNumberStringFormator(userProfile?.info.phoneNumber as string),
        ) || '',
      password: '',
    });
  }, [userProfile]);

  const formFields: FormFields[] = [
    {
      label: '이름',
      key: FormStateKeyType.Name,
      registerOptions: {
        setValueAs: (value: string) => _.trim(value),
      },
      placeholder: '변경할 이름을 입력해주세요.',
      defaultValue: modifyFormState[FormStateKeyType.Name],
      cancelHandler: cancelFormItemEdit,
      submitHandler: idItemSubmitHandler,
    },
    {
      label: 'ID',
      key: FormStateKeyType.Id,
      placeholder: '변경할 ID를 입력해주세요.',
      defaultValue: modifyFormState[FormStateKeyType.Id],
      cancelHandler: cancelFormItemEdit,
      submitHandler: changeFormItemWithToggle,
      notUseModify: true,
    },
    {
      label: '휴대폰 번호',
      key: FormStateKeyType.PhoneNumber,
      registerOptions: {
        onChange(event: ChangeEvent<HTMLInputElement>) {
          setFormatPhoneNumber(phoneNumberStringFormator(event.target.value));
        },
      },
      maxLength: 13,
      placeholder: '변경할 휴대폰 번호를 입력해주세요.',
      defaultValue:
        modifyFormState[FormStateKeyType.PhoneNumber] || '등록된 휴대폰 번호가 없습니다.',

      inputValue: formatPhoneNumber,
      cancelHandler: cancelFormItemEdit,
      submitHandler: phoneNumberItemSubmitHandler,
    },
    {
      label: '비밀번호',
      key: FormStateKeyType.Password,
      placeholder: '변경할 비밀번호를 입력해주세요.',
      defaultValue: ' ',
      cancelHandler: cancelFormItemEdit,
      submitHandler: passwordItemSubmitHandler,
      className: 'border-b-0',
      type: 'password',
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
      {formFields.map((field) => (
        <UserModifyFormItem
          key={field.key}
          label={field.label}
          register={register(field.key, field.registerOptions)}
          defaultValue={field.defaultValue}
          isOpenModifyForm={ModifyFormToggle[field.key]}
          placeholder={field.placeholder}
          minLength={field.minLength}
          maxLength={field.maxLength}
          openHandler={() => toggleFormItem(field.key)}
          cancelHandler={() => field.cancelHandler && field.cancelHandler(field.key)}
          submitHandler={() => field.submitHandler && field.submitHandler(field.key)}
          notUseModify={field.notUseModify}
          className={field.className}
          inputClassName={field.inputClassName}
          inputValue={field.inputValue}
          type={field.type}
        />
      ))}

      <Button
        type="submit"
        disabled={hasFormChanged && isEditingForm}
        className=" mx-auto mt-4 w-full max-w-[180px] rounded border-none bg-primary py-3 px-4 text-white shadow-none disabled:cursor-default disabled:bg-gray-100  disabled:text-gray-400 max-sm:max-w-full"
      >
        수정완료
      </Button>
    </form>
  );
};

export { UserModifyContent };
