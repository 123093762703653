import { useRecoilValue } from 'recoil';

import { MobileNavigation } from '@components/MobileNavigation';
import { Courtesy } from '@containers/Courtesy';
import { useMemberByIdHook } from '@containers/Courtesy/state/courtesy.query';
import { currentMemberStateSelectorFamily } from '@containers/Courtesy/state/courtesy.selector';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { useParams } from 'react-router-dom';

const CourtesyPage = () => {
  useMobileNavTitleHook(
    '답례 인사 전하기(상주님 전용페이지)',
    false,
    false,
    undefined,
    undefined,
    false,
  );

  const { memberId } = useParams();

  useMemberByIdHook(memberId ?? '');

  const { data, status } = useRecoilValue(
    currentMemberStateSelectorFamily(memberId ?? ''),
  );

  return (
    <div className="mx-auto max-w-[640px]">
      <MobileNavigation />
      {data && (
        <Courtesy
          memberName={data?.fullName}
          deceasedName={data?.fevent.deceasedInfo.name}
        />
      )}
    </div>
  );
};

export { CourtesyPage };
