import React, { FC, HTMLAttributes, useState } from 'react';

import { Icon } from '@components/Icon';

interface CouponItemProps extends HTMLAttributes<HTMLDivElement> {
  use?: boolean;
  centerText?: string;
  footerText?: string;
  expirationDate?: string;
  isExpiration?: boolean;
  className?: string;
}
const CouponItem: FC<CouponItemProps> = React.memo(
  ({
    use = false,
    centerText = '5000원 할인',
    footerText = '부고 등록 지원 쿠폰',
    expirationDate = '23.04.30 - 23.05.30',
    isExpiration = false,
    className,
    ...props
  }) => {
    const [reverse, setReverse] = useState(false);

    const couponEnded = use || isExpiration;

    const reverseButtonHandler = () => {
      setReverse((prev) => !prev);
    };

    const CouponBackInfo = () => (
      <ul className="list-disc break-keep text-left text-10 text-gray-500">
        <li>최소 50,000원 이상 구매시 사용 가능합니다.</li>
        <li>다른 쿠폰과 중복 불가합니다.</li>
        <li>쿠폰 사용기간 만료 시 사용 불가합니다.</li>
        <li>쿠폰은 다른 계정으로 양도할 수 없습니다.</li>
      </ul>
    );
    const CouponFrontInfo = () => (
      <>
        <h1
          className={`py-2 text-24 font-bold ${
            couponEnded ? 'text-gray-500' : 'text-primary'
          }`}
        >
          {centerText}
        </h1>
        <div className="py-2 text-12 text-gray-500">
          <p>{footerText}</p>
          <p>
            사용기한 : <span>{expirationDate}</span>
          </p>
        </div>
      </>
    );

    return (
      <div
        className={`relative h-[132px] max-w-[320px] bg-transparent shadow-md max-sm:max-w-full ${
          reverse ? 'flex flex-row-reverse' : 'flex'
        } ${className}`}
        {...props}
      >
        <p
          className={` rounded py-10 px-4 font-bold ${
            couponEnded ? 'bg-white text-gray-500 ' : ' bg-primary text-white'
          }
        ${
          reverse
            ? 'border-l-2 border-dashed border-l-white'
            : 'border-r-2 border-dashed border-l-white'
        }
        `}
          style={{ writingMode: 'vertical-lr' }}
        >
          coupon
        </p>
        <div
          className={`relative flex w-full flex-col items-center rounded bg-white ${
            reverse ? 'justify-center' : 'justify-end'
          }`}
        >
          <button
            className={`absolute top-1 h-4 w-4 cursor-pointer rounded-full ${
              reverse ? 'left-1' : 'right-1'
            } ${couponEnded ? 'bg-gray-500' : 'bg-primary'}`}
            onClick={reverseButtonHandler}
          >
            {reverse ? (
              <Icon.ChevronRight className="h-full w-full rotate-180 fill-white stroke-none" />
            ) : (
              <Icon.Ellipsis className="h-full w-full fill-white" />
            )}
          </button>
          {reverse ? <CouponBackInfo /> : <CouponFrontInfo />}
        </div>
        {/* 기간 만료 및 사용했을 시 */}
        {couponEnded && (
          <div className="center-box absolute inset-0 h-full w-full rounded bg-gray-500 bg-opacity-70">
            <p className="text-[30px] font-bold text-white">
              {use ? '사용함' : '기간만료'}
            </p>
          </div>
        )}
      </div>
    );
  },
);
export { CouponItem };
