import { FC } from 'react';

import { CallCenterInfo } from './CallCenterInfo';

const CustomerInfoBox: FC = () => {
  return (
    <div className="mb-3">
      <p className="mb-3 text-16 font-bold text-black">
        지금 주문하시면 지체없이 <span className="text-primary">당일배송</span>
      </p>
      <CallCenterInfo />
    </div>
  );
};

export { CustomerInfoBox };
