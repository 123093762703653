import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { CustomCallback } from 'src/interfaces';

import Modal from './Modal';
import { Icon } from './Icon';

interface SuccessAlertModalProps {
  openId: string;
  modalText: string;
  onClose?: CustomCallback<any, void>;
  showIcon?: boolean;
}

const SuccessAlertModal: FC<SuccessAlertModalProps> = ({
  openId,
  modalText,
  onClose,
  showIcon = true,
}) => {
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  return (
    <Modal
      openId={openId}
      className="w-full max-w-[320px] bg-white max-sm:max-w-full"
      onClose={onClose}
    >
      {showIcon && (
        <div className="flex h-20 w-full items-center justify-center">
          <Icon.BigCheck className="stroke-none " />
        </div>
      )}
      <div className=" whitespace-pre-line break-words">{modalText}</div>
      <button
        className="mt-4 h-12 w-full rounded bg-apricot-600 text-white"
        onClick={(event) => {
          if (onClose) onClose(event);
          setOpen(false);
        }}
      >
        확인
      </button>
    </Modal>
  );
};

export { SuccessAlertModal };
