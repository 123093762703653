import { FlowerStoreIfnoForm } from '@components/FlowerStoreInfoForm';
import { FlowerStoreSection } from '@components/FlowerStoreSection';
import { OrderCancelRequestContent } from '@containers/FlowerStoreOrderContent/OrderCancelRequestContent';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { Body, Footer, Header } from '@layout';

const OrderCancelRequestPage = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <FlowerStoreSection title="취소신청">
          <FlowerStoreIfnoForm leftComponentClassName="hidden">
            <OrderCancelRequestContent />
          </FlowerStoreIfnoForm>
        </FlowerStoreSection>
      </Body>

      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { OrderCancelRequestPage };
