import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { OrderList } from './FlowerStoreOrderList.interface';

const FlowerStoreOrderListDataAtom = atom<OrderList>({
  key: 'FlowerStoreOrderListDataAtom',
  default: undefined,
});
const FlowerStoreOrderListStatusAtom = atom<QueryStatus>({
  key: 'FlowerStoreOrderListStatusAtom',
  default: 'idle',
});

export { FlowerStoreOrderListDataAtom, FlowerStoreOrderListStatusAtom };
