import { queryString, useRecoilQuery } from '@hooks';
import { useSearchParams } from 'react-router-dom';
import { nestShopItemApi } from '@api/mongo/controllers/shopItem.public.controller';

import {
  flowerStoreItemListDataAtom,
  flowerStoreItemListStatusAtom,
} from './flowerStoreMainContent.atom';

const populate = { path: 'shopItemInfo', populate: { path: 'image' } };

export const switchUrl = (bugoId?: string | null, feventId?: string | null) => {
  const baseUrl = '/public/shop-item';
  if (bugoId) {
    return `${baseUrl}/by-bugo/?${queryString({
      populate: populate,
    })}`;
  } else if (feventId) {
    return `${baseUrl}/by-fevent/?${queryString({
      populate: populate,
    })}`;
  } else {
    return `${baseUrl}/default/?${queryString({
      populate: populate,
    })}`;
  }
};

const useFlowerStoreItemList = (disabled?: boolean) => {
  const [searchParams] = useSearchParams();
  const bugoId = searchParams.get('bugoId');
  const feventId = searchParams.get('feventId');

  console.log(switchUrl(bugoId, feventId));

  const fetcher = async () => {
    if (bugoId) {
      const { data } = await nestShopItemApi().findByBugo(bugoId, {
        populate: JSON.stringify(populate),
      });
      return data;
    } else if (feventId) {
      const { data } = await nestShopItemApi().findByFevent(feventId, {
        populate: JSON.stringify(populate),
      });
      return data;
    } else {
      const { data } = await nestShopItemApi().findDefault({
        populate: JSON.stringify(populate),
      });
      return data;
    }
  };

  useRecoilQuery(
    flowerStoreItemListStatusAtom,
    flowerStoreItemListDataAtom,
    switchUrl(bugoId, feventId),
    fetcher,
    disabled,
  );
};

export { useFlowerStoreItemList };
