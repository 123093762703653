import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { Image } from '../../Image/state';
import { Room } from './room.interface';

const selectedRoomIdAtom = atom<string | undefined>({
  key: 'selectedRoomIdAtom',
  default: undefined,
});

const roomDataAtom = atom<Room<Image> | undefined>({
  key: 'roomAtom',
  default: undefined,
});

const roomStatusAtom = atom<QueryStatus>({
  key: 'roomStatusAtom',
  default: 'idle',
});

const roomListDataAtom = atom<Room[] | undefined>({
  key: 'roomListAtom',
  default: undefined,
});

const roomListStatusAtom = atom<QueryStatus>({
  key: 'roomListStatusAtom',
  default: 'idle',
});

export {
  selectedRoomIdAtom,
  roomDataAtom,
  roomStatusAtom,
  roomListDataAtom,
  roomListStatusAtom,
};
