import { useEffect, useState } from 'react';

import { nestMongoApi } from '@api/mongo/controllers';
import { api_ } from '@plugin/axios';
import { queryString } from '@hooks';
const getUserTotalCount = async () => {
  const { data: userTotalCount } = await nestMongoApi('/public/user').count();
  return userTotalCount;
};

const getUserCount = async () => {
  const date = new Date(0);
  date.setFullYear(new Date().getFullYear());
  date.setMilliseconds(date.getMilliseconds() - 1);

  const { data: userCount } = await api_.get(
    `/public/user/count/?${queryString({
      filter: {
        createdAt: { $gte: date },
      },
    })}`,
  );
  return userCount;
};

const useCountHook = () => {
  const [userTotalCount, setUserTotalCount] = useState<number | undefined>(undefined);
  const [userCount, setUserCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const result = await Promise.all([getUserTotalCount(), getUserCount()]);

        console.log(result);

        setUserTotalCount(result[0]);
        setUserCount(result[1]);
      } catch (error) {
        setUserTotalCount(undefined);
        setUserCount(undefined);
      }
    })();
  });
  return { userTotalCount, userCount };
};

export { useCountHook };
