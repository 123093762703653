import { FC, useEffect, useMemo, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import Tabs from '@components/Tabs';
import { TextAreaInput } from '@components/TextAreaInput';
import { AutoSelect } from '@components/AutoSelect';
import { useForm } from 'react-hook-form';

import { createCourtesyMessages } from '../createCourtesyMessages';
import { courtesyContentStringAtom } from '../state/courtesy.atom';

const today = new Date();

interface CourtesyContentSelectProps {
  deceasedName: string;
  memberName: string;
}

const CourtesyContentSelect: FC<CourtesyContentSelectProps> = ({
  deceasedName,
  memberName,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const testArr = useMemo(
    () => createCourtesyMessages(selectedTab, deceasedName, memberName, today),
    [deceasedName, memberName, selectedTab],
  );

  const [content, setContent] = useState('');
  const setCourtesyContentString = useSetRecoilState(courtesyContentStringAtom);
  const { control, reset } = useForm({
    defaultValues: { courtesyMessages: testArr[0]?.title },
  });

  useEffect(() => {
    setContent(testArr[0]!.content);
  }, [testArr]);

  useEffect(() => {
    reset({
      courtesyMessages: testArr[0]?.title,
    });
  }, [reset, testArr]);

  useEffect(() => {
    setCourtesyContentString(content);
  }, [content, setCourtesyContentString]);

  return (
    <div className="mt-3">
      <div className="bg-white">
        <h2 className="px-4 py-2 text-left text-16 font-bold leading-6 text-black">
          답례글 선택
        </h2>
        <Tabs
          items={['표준', '기독교', '천주교', '불교']}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="p-4">
        <AutoSelect
          formFieldName="courtesyMessages"
          formControl={control}
          inputStyleClass="text-14 text-black font-medium"
          optionList={testArr.map((item) => item.title)}
          onOptionClick={(event) => {
            setContent(
              testArr.find((item) => item.title === event.target.value)!.content,
            );
          }}
        />

        <TextAreaInput
          className="mt-4"
          rows={13}
          onChange={(event) => {
            setContent(event.target.value);
          }}
          value={content}
        />
      </div>
    </div>
  );
};

export { CourtesyContentSelect };
