const DeliveryStatusText = ({
  status,
  estimatedDeliveredAt,
}: {
  status: string;
  estimatedDeliveredAt: string;
}) => {
  return (
    <div className="flex items-center gap-2">
      <span className="text-16 font-medium text-black">{status}</span>
      <span className="text-14 font-medium text-gray-500">
        {estimatedDeliveredAt} 도착 예정
      </span>
    </div>
  );
};

export { DeliveryStatusText };
