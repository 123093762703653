import { FC, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { useSearchParams } from 'react-router-dom';
import { BugoHistoryPage } from '@pages/BugoMyPage/BugoHistoryPage';
import { UserModifyPage } from '@pages/BugoMyPage/UserModifyPage';
import { UserEnquiryPage } from '@pages/BugoMyPage/UserEnquiryPage';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

import { MyPageTabMenu } from './components/MyPageTabMenu';
import { MyPageHeaderSection } from './components/MyPageHeaderSection';
import { myPageTabMenuList } from './state/myPageTabMenuList';
import { TempContent } from './components/TempContent';

const BugoMyPageContent: FC = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const isMobileMedia = useMobileMediaQuery();

  const callbackContent = useCallback(() => {
    return (
      <>
        {!isStorePage && !state && <BugoHistoryPage />}
        {isStorePage && !state && <UserEnquiryPage />}
        {!isStorePage && state === 'bugo' && <BugoHistoryPage />}
        {state === 'modify' && <UserModifyPage />}
        {state === 'enquiry' && <UserEnquiryPage />}
        {state === 'order' && <TempContent navTitle="주문하기" />}
        {state === 'gift' && <TempContent navTitle="답례품 전하기" />}
        {state === 'bugoService' && <TempContent navTitle="부고서비스" />}
        {state === 'orderHistory' && <TempContent navTitle="주문배송내역" />}
        {/* {state === 'point' && <UserPointPage />} */}
        {/* {state === 'coupon' && <UserCouponPage />} */}
      </>
    );
  }, [state, isStorePage]);

  const callbackTabMenu = useCallback(() => {
    return <MyPageTabMenu tabList={myPageTabMenuList(isStorePage)} />;
  }, [isStorePage]);

  return (
    <div className="mx-auto max-w-[928px]">
      {/* PC일때는 무조건 렌더링 */}
      {!isMobileMedia && <MyPageHeaderSection />}

      {/* 모바일일때, state없을때 렌더링 */}
      {isMobileMedia && !state && <MyPageHeaderSection />}

      <div className="flex gap-8">
        {/* PC메뉴 */}
        {!isMobileMedia && callbackTabMenu()}
        {/* PC 컨텐츠 */}
        {!isMobileMedia && callbackContent()}

        {/* 모바일 메뉴, stateParam이 없을때 렌더링*/}
        {isMobileMedia && !state && callbackTabMenu()}
        {/* 모바일컨텐츠, 모바일이고 stateParam이 있을때 렌더링 */}
        {isMobileMedia && state && callbackContent()}
      </div>
    </div>
  );
};

export { BugoMyPageContent };
