import dayjs from 'dayjs';
import _ from 'lodash';
import { FieldPath, UseFormSetValue } from 'react-hook-form';

import { DateInfo, FeventFormData } from '../../state/interfaces';

// Date와 Time을 받아서 하나의 Date 객체를 반환하는 함수
const getDateFromDateTime = (date: Date, time: Date) => {
  let datetime: dayjs.Dayjs;
  if (_.isNil(date)) {
    datetime = dayjs(Date.now())
      .set('hour', dayjs(time).hour())
      .set('minute', dayjs(time).minute());
  } else if (_.isNil(time)) {
    datetime = dayjs(date);
  } else {
    datetime = dayjs(date)
      .set('hour', dayjs(time).hour())
      .set('minute', dayjs(time).minute());
  }
  return datetime.toDate();
};

const dateChangeHandler = (
  info: DateInfo,
  fieldName: FieldPath<FeventFormData>,
  value: Date | null,
  setValue: UseFormSetValue<FeventFormData>,
) => {
  if (_.isNull(value)) {
    return;
  }
  const infoDate = getDateFromDateTime(value, info?.date);
  setValue(fieldName, infoDate);
};

const timeChanegeHandler = (
  info: DateInfo,
  timeDefinedFieldName: FieldPath<FeventFormData>,
  fieldName: FieldPath<FeventFormData>,
  value: Date | null,
  setValue: UseFormSetValue<FeventFormData>,
) => {
  if (_.isNull(value)) {
    setValue(timeDefinedFieldName, false);
    return;
  }
  setValue(timeDefinedFieldName, true);
  const deathDate = getDateFromDateTime(
    info?.date, // register 함수를 이용해 현재 값 가져옴
    value,
  );
  setValue(fieldName, deathDate);
};

const translateTime = (timeValue: string) => {
  const [hours, minutes] = timeValue.split(':');
  const currentDate = dayjs();
  const dateWithTimeValue = currentDate
    .hour(parseInt(hours as ''))
    .minute(parseInt(minutes as ''));
  const dateObject = dateWithTimeValue.toDate();

  return dateObject;
};

export { getDateFromDateTime, dateChangeHandler, timeChanegeHandler, translateTime };
