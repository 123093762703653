import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { feventDataSnapshotAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';

import { FormValue, FormValues } from './bugoSendForm.interface';

type UpdateCheckd = {
  isPhoneNumberUpdated: boolean;
  isMournerTextUpdated: boolean;
  isAddedMember: boolean;
};

const useBugoSendFormValuesUpdateCheckHook = (fieldArray: FormValue[], id: string) => {
  const { data } = useRecoilValue(currentFeventStateSelectorFamily(id ?? ''));
  const snapshot = useRecoilValue(feventDataSnapshotAtom);
  const [updateChecked, setUpdateChecked] = useState<UpdateCheckd>({
    isPhoneNumberUpdated: false,
    isMournerTextUpdated: false,
    isAddedMember: false,
  });

  const omitData = _.omit(data, [
    'meta',
    'updatedAt',
    'createdAt',
    'bugoBrand',
    'shopOrders',
    '_id',
    'id',
  ]);
  const omitSnapshot = _.omit(snapshot, [
    'meta',
    'updatedAt',
    'createdAt',
    'bugoBrand',
    'shopOrders',
    '_id',
    'id',
  ]);
  const isFeventEqualBySnapshot = _.isEqual(omitData, omitSnapshot);

  useEffect(() => {
    if (fieldArray)
      setUpdateChecked((prevState) => ({
        ...prevState,
        isPhoneNumberUpdated: fieldArray.some((cur) => cur.isPhoneNumberUpdated),
        isMournerTextUpdated: fieldArray.some((cur) => cur.isMournerTextUpdated),
        isAddedMember: fieldArray.some((cur) => cur.isAddedMember),
      }));
  }, [fieldArray]);

  return { updateChecked, isFeventEqualBySnapshot };
};

/**
 * @description - BugoSendFormItem 컴포넌트의 각 field의 변화를 감지하여 setValue해주는 훅
 * @param index - fieldArray의 index값
 * @param memberPhoneNumber
 * @returns
 */
const useBugoSendFormItemChangeEffectHook = (
  index: number,
  memberPhoneNumber?: string,
) => {
  const isEditPage = useRecoilValue(isEditPageAtom);
  const {
    setValue,
    control,
    formState: { defaultValues },
  } = useFormContext<FormValues>();
  const snapshot = useRecoilValue(feventDataSnapshotAtom);
  const fieldArray = useWatch({ control, name: 'memberData' });
  const currentFeild = fieldArray[index]!;

  const memberSnapshot = snapshot?.memberOrderList.find(
    (memberSnapshot) => memberSnapshot?._id === currentFeild._id,
  );

  const currentDefaultValue =
    defaultValues?.memberData && defaultValues?.memberData[index];
  const currentPhoneNumber = currentFeild.phoneNumber;

  const isPhoneNumberUpdated =
    memberPhoneNumber !== currentPhoneNumber ||
    memberSnapshot?.phoneNumber !== currentPhoneNumber;

  const sendRequired = isPhoneNumberUpdated || !memberSnapshot;

  const isMournerTextUpdated =
    currentDefaultValue?.mournerText !== currentFeild?.mournerText;

  const isPhoneNumberUpdatedAddPage =
    currentDefaultValue?.phoneNumber !== currentPhoneNumber ||
    (!!memberSnapshot && memberSnapshot.phoneNumber !== currentPhoneNumber);

  useEffect(() => {
    if (!memberSnapshot && isEditPage)
      setValue(`memberData.${index}.isAddedMember`, true);
    else setValue(`memberData.${index}.isAddedMember`, false);
  }, [index, memberSnapshot, setValue, defaultValues, isEditPage]);

  //상주별 개인말씀 변경시 setValue
  useEffect(() => {
    setValue(`memberData.${index}.isMournerTextUpdated`, isMournerTextUpdated);
  }, [index, isMournerTextUpdated, setValue, defaultValues]);

  //상주별 연락처 변경시 setValue
  useEffect(() => {
    setValue(
      `memberData.${index}.isPhoneNumberUpdated`,
      isEditPage ? isPhoneNumberUpdated : isPhoneNumberUpdatedAddPage,
    );
  }, [
    index,
    isEditPage,
    isPhoneNumberUpdated,
    isPhoneNumberUpdatedAddPage,
    setValue,
    defaultValues,
  ]);

  return { sendRequired };
};

export { useBugoSendFormValuesUpdateCheckHook, useBugoSendFormItemChangeEffectHook };
