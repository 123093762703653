//* 계좌 인증 필요, 계좌인증 완료 여부 보여주는 태그
import { FC } from 'react';

import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { Icon } from '@components/Icon';

interface Props {
  isVerified: boolean;
}

export const CashAccountApprovedTag: FC<Props> = function CashAccountApprovedTag({
  isVerified,
}: Props) {
  const isMobileMedia = useMobileMediaQuery();
  if (isVerified) {
    return isMobileMedia ? (
    <Icon.MoneyBag className="stroke-none" />
    ) : (
        <div className=" rounded-full border border-apricot-600 bg-white py-1 px-4 text-12 text-apricot-600">
          계좌등록완료
        </div>
    );
  } else {
    return (
      <div>
        {isMobileMedia ? null : (
          <div className=" rounded-full border bg-white py-1 px-4 text-12 text-gray-700">
            계좌인증필요
          </div>
        )}
      </div>
    );
  }
};
