import { FC, HTMLAttributes } from 'react';

import { useAuth } from '@hooks/useAuth';

interface WelcomeMessageProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const WelcomeMessage: FC<WelcomeMessageProps> = ({ className, ...props }) => {
  const { userProfile } = useAuth();

  return (
    <div className={`text-16 max-sm:text-left ${className}`} {...props}>
      <span className="font-bold ">{`${userProfile?.info.name || '회원'}님`}</span>
      <span className="max-sm:hidden"> 항상 응원합니다.</span>
      <p className="hidden max-sm:block">안녕하세요.</p>
    </div>
  );
};

export { WelcomeMessage };
