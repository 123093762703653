import { FC, useState } from 'react';

import { Button } from '@components/Button';
import Tabs from '@components/Tabs';
import {
  bugoFAQContents,
  courtesyFAQContents,
  flowerFAQContents,
} from 'src/@utils/FAQContents';

import { TabContent } from './components/TabContent';

const CustomerGridLeftBox: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className=" relative h-[356px] w-full max-w-[624px] shrink-0 max-lg:max-w-full">
      <div className="flex justify-between text-left text-24 font-bold max-lg:px-2 ">
        자주묻는 질문
        <Button
          text="전체보기"
          to="/faq"
          className="rounded border bg-[#F9fafb] p-2 text-14 font-normal shadow-none"
        ></Button>
      </div>
      {/* 탭메뉴와 콘텐츠 */}
      <Tabs
        items={['부고장', '답례 인사', '화환 주문']}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="bg-[#f3f4f6] ">
        <div className="min-h-[149px] pt-3">
          {ContentArr.map(
            (item, index) =>
              selectedTab === index && (
                <TabContent
                  key={item._id}
                  list={ContentArr[index]?.list}
                  index={index}
                ></TabContent>
              ),
          )}
        </div>
        {/* 일대일 문의 버튼 div*/}
        <div className="mt-3 p-3">
          <p className="pb-4 font-bold">그 외 궁금하신 점은 1:1 문의를 이용해주세요.</p>
          <Button
            text="1:1 문의"
            to="/enquiry"
            className="m-auto h-[50px] w-[180px] bg-slate-700 font-normal text-white shadow-none"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export { CustomerGridLeftBox };

//mocking data
const ContentArr = [
  {
    _id: 1,
    title: '부고 서비스',
    list: bugoFAQContents,
  },
  {
    _id: 2,
    title: '답례 서비스',
    list: courtesyFAQContents,
  },
  {
    _id: 3,
    title: '화환 서비스',
    list: flowerFAQContents,
  },
];
