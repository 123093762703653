import { FC, SVGProps } from 'react';

type PromotionInfoBoxItemProps = {
  IconSVG: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  count: string;
  unit: string;
  title: string;
};

const PromotionInfoBoxItem: FC<PromotionInfoBoxItemProps> = (props) => {
  const { count, unit, IconSVG, title } = props;

  return (
    <div className="flex flex-col items-center justify-center max-sm:rounded-lg max-sm:py-2 max-sm:shadow-md ">
      <div className="center-box flex h-[48px] w-[48px]">
        <IconSVG />
      </div>
      <p className=" py-4 max-sm:py-1">
        <span className="text-24 font-bold">{count}</span>
        {unit}
      </p>
      <p>{title}</p>
    </div>
  );
};

export { PromotionInfoBoxItem };
