import { useRecoilValue } from 'recoil';

import { Button } from '@components/Button';
import { storeItemStateSelector } from '@containers/FlowerStoreItemDetail/state/storeItemDetail.selector';
import { FlowerStoreItemInfo } from '@containers/FlowerStoreMainContent/components/FlowerStoreItemInfo';
import { fileBucketUrl } from '@plugin/s3Image';
const ItemOrderInfo = () => {
  const { data } = useRecoilValue(storeItemStateSelector);

  console.log(data);

  const discountValue = (
    (data?.priceRetailShow ?? 0) - (data?.priceRetail ?? 0)
  ).toLocaleString();

  return (
    <>
      <div className="py-2 text-left ">
        <h1 className="pb-2 text-16 font-medium text-black">주문요약</h1>
        <div className="rounded border px-4 py-2 ">
          <div className="flex items-center">
            <div className="center-box basis-[40%] ">
              <img
                src={fileBucketUrl(data?.shopItemInfo.image.url ?? '', 'w400')}
                className="h-full w-full"
              ></img>
            </div>
            <FlowerStoreItemInfo
              className=" basis-[60%]"
              name={data?.shopItemInfo.name ?? ''}
              priceRetail={data?.priceRetail ?? 0}
              priceRetailShow={data?.priceRetailShow ?? 0}
            />
          </div>
          {/* 금액 계산 */}
          <div className="flex flex-col gap-4 border-t border-b py-4">
            <div className="flex justify-between text-16 font-medium text-black">
              <span>상품금액</span>
              <span>{data?.priceRetailShow.toLocaleString() ?? 0}원</span>
            </div>
            <div className="flex justify-between text-16 font-medium text-black">
              <span>배송비</span>
              <span>무료배송</span>
            </div>
            <div className="flex justify-between text-16 font-medium text-black">
              <span>할인금액</span>
              <span className="text-red-500">-{discountValue}원</span>
            </div>
          </div>
          {/* 총 금액 */}
          <div className="pt-6">
            <div className="flex justify-between text-16 font-medium text-black">
              <span>총 결제금액</span>
              <span>{data?.priceRetail.toLocaleString()}원</span>
            </div>
          </div>
        </div>
        {/* 주문버튼 */}
        <div className="grid grid-cols-10 bg-white px-2 py-4 pt-10 text-center max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:pt-4">
          <div className="col-span-4 break-keep text-16 font-medium text-black">
            총 할인 금액 <br />
            <span className="text-red-500">{`-${discountValue}원`}</span>
          </div>
          <Button
            type="submit"
            className=" col-span-6 w-full rounded border-none bg-primary  text-16 font-bold text-white shadow-none"
          >
            {data?.priceRetail.toLocaleString() ?? 0}원 결제
          </Button>
        </div>
      </div>
    </>
  );
};

export { ItemOrderInfo };
