import { atomFamily } from 'recoil';

/**
 * open, setOpen을 openId하나로 관리
 */

//* modal의 open(boolean)을 관리하기 위한 atomFamily
const modalOpenAtomFamily = atomFamily({
  key: 'modalOpenAtomFamily',
  default: false,
});

export { modalOpenAtomFamily };
