const DeceasedInfoFormBtnSet = () => {
  return (
    // 640px 모바일 레이아웃 전환
    <div className="flex justify-end gap-4 max-sm:mt-4 max-sm:flex-col">
      <button
        type="reset"
        className="text-bold rounded bg-gray-300 px-16 py-3 text-16 text-white max-sm:hidden"
      >
        취소
      </button>
      <button
        type="submit"
        className="text-bold rounded bg-apricot-600 px-16 py-3 text-16 text-white"
      >
        다음
      </button>
    </div>
  );
};

export { DeceasedInfoFormBtnSet };
