import { FC } from 'react';

import { Button, ButtonProps } from '@components/Button';
import { Icon } from '@components/Icon';
import { useNavigate } from 'react-router-dom';

export interface MyPageTabButtonProps extends ButtonProps {
  name: string;
  state: string | null;
  path: string;
  className?: string;
}

const MyPageTabButton: FC<MyPageTabButtonProps> = ({
  name,
  state,
  path,
  className,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(path)}
      className={`max-sm:border-b-1 border-t-0 border-l-0 border-r-0 bg-white px-4 text-14 font-medium shadow-none max-sm:mx-[-1rem] max-sm:py-[10px] max-sm:last:border-b-0 sm:border-b-0 sm:py-[18px] ${
        state === path.split('=')[1] && ' border-l-2 border-l-primary text-primary '
      } ${className}`}
      {...props}
    >
      <div className="flex w-full items-center justify-between">
        {name}
        {state === path.split('=')[1] ? (
          <Icon.ChevronRight className="fill-primary stroke-none" />
        ) : (
          <Icon.ChevronRight className="stroke-none" />
        )}
      </div>
    </Button>
  );
};

export { MyPageTabButton };
