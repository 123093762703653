//* member(상주) 에디터 FeventNew, FeventEdit 시 사용함
import { FC } from 'react';
import React from 'react';

import { Button } from '@components/Button';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DroppableWrapper } from '@components/DroppableWrapper';
import { Icon } from '@components/Icon';

import { MemberIFormInputs } from '../../interface/mournerInfoForm.interface';
import { defaultMemberInputForm } from '../../utils/createDefaultMemberInputForm';
import MournerItem from './MournerItem';

const MournerListEditor: FC = React.memo(() => {
  const useFieldArrayMethod = useFieldArray<MemberIFormInputs>({
    name: 'member',
  });

  const {
    register,
    setValue,
    control,
    getValues,
    formState: { errors: fieldsErrors },
  } = useFormContext<MemberIFormInputs>();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    useFieldArrayMethod.move(result.source.index, result.destination.index);
  };

  const addMember = () => {
    useFieldArrayMethod.append(defaultMemberInputForm());
  };

  const foldStatesAllToggle = () => {
    const isAnyUnfolded = useFieldArrayMethod.fields.some((item, number) => {
      const currentIsFold = getValues(`member.${number}.isFold`);
      return !currentIsFold;
    });
    useFieldArrayMethod.fields.forEach((item, number) => {
      setValue(`member.${number}.isFold`, isAnyUnfolded);
    });
  };

  return (
    <div className="space-y-3">
      <Button
        className="mx-auto rounded bg-white px-6 py-2 font-normal shadow-none"
        type="button"
        onClick={foldStatesAllToggle}
      >
        전체 펼치기/접기
      </Button>
      <DragDropContext onDragEnd={onDragEnd}>
        <DroppableWrapper droppableId="fields">
          {(provided) => (
            <div
              className="space-y-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {useFieldArrayMethod.fields.map((item, index: number) => (
                <Draggable
                  key={`drag-item-${item.id}`}
                  draggableId={`drag-item-${item.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={{ ...provided.draggableProps.style, cursor: 'default' }}
                    >
                      <MournerItem
                        register={register}
                        control={control}
                        index={index}
                        fieldsErrors={fieldsErrors}
                        remove={useFieldArrayMethod.remove}
                      ></MournerItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </DroppableWrapper>
      </DragDropContext>
      <div className="center-box pb-6">
        <Button
          type="button"
          className="group m-auto h-[44px] rounded border bg-white px-6 py-2 text-14 font-medium text-gray-600 shadow-none hover:border-none hover:bg-primary hover:text-white max-sm:py-1 max-sm:px-4"
          onClick={addMember}
        >
          <Icon.UserPlus className="w-5 fill-gray-700 stroke-none group-hover:fill-white" />
          <span className="ml-1">상주 등록 추가</span>
        </Button>
      </div>
    </div>
  );
});

export { MournerListEditor };
