import { useEffect, useLayoutEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { isEditPageAtom } from '@containers/FeventSubmitContent/state/isEditPage.atom';
import { Link, useLocation } from 'react-router-dom';
import { StepNameEnum } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import { useFormContext, useWatch } from 'react-hook-form';
import _ from 'lodash';

import { FormValues } from '../state/bugoSendForm.interface';
import { useBugoSendFormValuesUpdateCheckHook } from '../state/bugoSendForm.hook';
const BugoSendBtnSet = ({ checkUpdated }: { checkUpdated: () => boolean }) => {
  const currentFeventId = useRecoilValue(currentFeventIdAtom);

  const { control } = useFormContext<FormValues>();
  const fieldArray = useWatch({ control, name: 'memberData' });
  const { updateChecked, isFeventEqualBySnapshot } = useBugoSendFormValuesUpdateCheckHook(
    fieldArray,
    currentFeventId ?? '',
  );
  const [buttonText, setButtonText] = useState('완료');

  const [isSendPage, setIsSendPage] = useState(false);
  const { data } = useRecoilValue(
    currentFeventStateSelectorFamily(currentFeventId ?? ''),
  );

  useEffect(() => {
    const { isAddedMember, isMournerTextUpdated, isPhoneNumberUpdated } = updateChecked;
    if (isAddedMember || isPhoneNumberUpdated) {
      setButtonText('수정된 부고 전송');
    } else if (isMournerTextUpdated || !isFeventEqualBySnapshot) {
      setButtonText('수정 완료');
    } else {
      setButtonText('확인');
    }
  }, [isFeventEqualBySnapshot, updateChecked]);

  const noMember = data?.memberOrderList.length === 0;

  // const setOpenConfirmModal = useSetRecoilState(
  //   modalOpenAtomFamily('confirm-modal-BugoSendPage'),
  // );
  // const setOpenOnlyAddConfirmModal = useSetRecoilState(
  //   modalOpenAtomFamily('confirm-modal-BugoSendPage-onlyAddPage'),
  // );
  // const checkNotSendMember = () =>
  //   data?.memberOrderList.reduce((found, curr) => {
  //     if (found) return true;
  //     return !curr.bugo?.lastSentPhoneNumber;
  //   }, false);

  const isEditPage = useRecoilValue(isEditPageAtom);
  const location = useLocation();
  // const navigate = useNavigate();
  useLayoutEffect(() => {
    if (location.pathname.indexOf('edit') < 0 && location.pathname.indexOf('add') < 0) {
      setIsSendPage(true);
    }
  }, [location]);

  return (
    <div className="mt-8 flex flex-wrap justify-end gap-4 max-sm:grid max-sm:grid-cols-1 max-sm:gap-x-0">
      {isSendPage ? (
        <Link
          to={`/fevent/edit/${currentFeventId}/${StepNameEnum.DeceasedInfo}`}
          className="flex h-10 w-[158px] items-center justify-center rounded border bg-white text-16 font-bold max-sm:order-1 max-sm:w-full"
        >
          부고장 수정
        </Link>
      ) : (
        <Link
          to={`../${StepNameEnum.MournerInfo}`}
          className={`flex h-10 items-center justify-center rounded bg-gray-300 text-16 font-bold text-white max-sm:order-1 max-sm:w-full ${
            isEditPage ? 'w-[158px]' : 'w-[158px]'
          }`}
        >
          이전
        </Link>
      )}
      <Link
        to={`/fevent/${currentFeventId}/bugo/preview`}
        className={`${
          noMember ? 'hidden' : 'flex'
        } h-10 w-[158px] items-center justify-center rounded border bg-white text-16 font-bold max-sm:col-span-2 max-sm:w-full`}
      >
        부고장 미리보기
      </Link>
      <button
        type="submit"
        className={`${
          noMember && 'hidden'
        } h-10 rounded bg-apricot-600 text-16 font-bold text-white max-sm:order-[-1] max-sm:col-span-2 max-sm:w-full ${
          isEditPage ? 'w-[180px]' : 'w-[158px]'
        }`}
      >
        {isEditPage ? buttonText : '부고 전송'}
      </button>
      {/* <button
        type="button"
        className=" h-10 w-[158px] rounded bg-gray-700 text-16 font-bold text-white max-sm:order-4 max-sm:w-full"
        onClick={() => {
          if (!checkUpdated()) {
            setOpenConfirmModal(true);
          } else if (!isEditPage && checkNotSendMember()) {
            setOpenOnlyAddConfirmModal(true);
          } else {
            navigate('/');
          }
        }}
      >
        완료
      </button> */}
    </div>
  );
};
export { BugoSendBtnSet };
