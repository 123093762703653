import { useCallback, useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { nestMongoApi, postImageWithRole } from '@api/mongo/controllers';
import { Fevent, FeventCreate } from '@api/mongo/interfaces';
import { FeventFormData } from '@containers/FeventSubmitContent/components/DeceasedInfoForm/state/interfaces';
import {
  currentFeventIdAtom,
  dpImageFileAtom,
  feventDataSnapshotAtom,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { useAuth } from '@hooks/useAuth';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import _ from 'lodash';
import { useQueryClient } from 'react-query';
import { notificateSuccess } from 'src/@utils/noti.utils';
import { api_ } from '@plugin/axios';

import { FormValues } from '../components/BugoSendForm/state/bugoSendForm.interface';
import { isSubmitOnBugoSendFormAtom } from '../components/BugoSendForm/state/bugoSendForm.atom';
import { CurrentFevent } from './feventSubmitContent.interface';

const useFeventSubmitContentHooks = () => {
  const queryClient = useQueryClient();
  const {
    isAuthenticated,
    userProfile,
    signupOrLoginBugoGuest,
    getMe,
    bugoRole,
    assetRole,
  } = useAuth();
  const [currentFeventId, setCurrentFeventId] = useRecoilState(currentFeventIdAtom);
  const dpImageFile = useRecoilValue(dpImageFileAtom);

  const { updateById, create } = nestMongoApi<Fevent, FeventCreate>(
    `${bugoRole()}/fevent`,
  );
  const { create: guestCreate } = nestMongoApi<Fevent, FeventCreate>('bugo-guest/fevent');

  const createFevent = async (body: FeventFormData) => {
    const { name, phoneNumber } = body;
    const feventDto: FeventCreate = _.omit(body, ['name', 'phoneNumber']);

    feventDto.user = userProfile?._id ?? '';
    feventDto.bugoBrand = userProfile?.bugoBrand ?? '';
    if (!isAuthenticated && name && phoneNumber) {
      const token = await signupOrLoginBugoGuest({ name, phoneNumber });
      const guestUser = await getMe(token);
      const userId = guestUser?._id;
      feventDto.user = userId ?? '';
      feventDto.bugoBrand = guestUser?.bugoBrand ?? '';
    }
    if (dpImageFile) {
      const image = await postImageWithRole(dpImageFile, assetRole());
      feventDto.deceasedInfo.dpImage = image._id;
    }

    const fevent = isAuthenticated
      ? await create(feventDto)
      : await guestCreate(feventDto);
    setCurrentFeventId(fevent.data._id);
    queryClient.invalidateQueries(feventByIdHookUrl(fevent.data._id, bugoRole()));
    return fevent.data;
  };

  const updateFevent = async (body: FeventFormData) => {
    const feventDto: FeventCreate = _.omit(body, ['name', 'phoneNumber']);
    if (dpImageFile) {
      const image = await postImageWithRole(dpImageFile, assetRole());
      feventDto.deceasedInfo.dpImage = image._id;
    }

    const res = await updateById(currentFeventId ?? '', feventDto);
    queryClient.invalidateQueries(feventByIdHookUrl(currentFeventId ?? '', bugoRole()));

    console.log(res.data);
  };

  return { createFevent, updateFevent };
};

const useBugoSendHook = () => {
  const { bugoRole } = useAuth();
  const { updateById: updatePhoneNumberById } = nestMongoApi(`${bugoRole()}/member`);
  const { updateById: updatemournerTextById } = nestMongoApi(`${bugoRole()}/bugo`);
  const notificationInstance = useRecoilValue(notificationInstanceAtom);

  const patchUpdateMembersData = async (memberData: FormValues) => {
    await Promise.all(
      memberData.memberData.map(async (memberData) => {
        if (memberData.isPhoneNumberUpdated)
          await updatePhoneNumberById(memberData._id, {
            phoneNumber: memberData.phoneNumber,
          });
        if (memberData.isMournerTextUpdated)
          await updatemournerTextById(memberData.bugoId, {
            mournerText: memberData.mournerText,
          });
      }),
    );
  };

  const notificationSendSuccess = () => {
    notificateSuccess(
      notificationInstance,
      '전송에 성공하였습니다. 핸드폰을 확인해주세요',
    );
  };

  return { patchUpdateMembersData, notificationSendSuccess };
};

const useUpdateFevnetSnapshotHook = (id: string) => {
  const [isSubmitOnBugoSendForm, setIsSubmitOnBugoSendForm] = useRecoilState(
    isSubmitOnBugoSendFormAtom,
  );

  const { bugoRole } = useAuth();
  const setFeventDataSnapshot = useSetRecoilState(feventDataSnapshotAtom);
  const getFeventForUpdateSnapshot = useCallback(async () => {
    const res = await api_.get<CurrentFevent>(feventByIdHookUrl(id, bugoRole()));
    setFeventDataSnapshot(res.data);
    console.log(res);
  }, [bugoRole, id, setFeventDataSnapshot]);

  useEffect(() => {
    if (isSubmitOnBugoSendForm) {
      console.log('Fevent 스냅샷데이터 업데이트 실행');

      getFeventForUpdateSnapshot();
      setIsSubmitOnBugoSendForm(false);
    }
  }, [getFeventForUpdateSnapshot, setIsSubmitOnBugoSendForm, isSubmitOnBugoSendForm]);
};

export { useFeventSubmitContentHooks, useBugoSendHook, useUpdateFevnetSnapshotHook };
