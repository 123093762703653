import { FC, useCallback, useEffect } from 'react';
import React from 'react';

import { useRecoilValue } from 'recoil';

import { useFormContext, useWatch } from 'react-hook-form';
import TelField from '@components/TelField';
import { currentFeventIdAtom } from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateInfo, notificateSuccess } from 'src/@utils/noti.utils';
import { BasicInput } from '@components/BasicInput';
import { relationTypeCategoryList } from '@components/miscValues';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { TopLayerModal } from '@components/TopLayerModal';
import { currentFeventStateSelectorFamily } from '@containers/FeventSubmitContent/state/feventSubmitContent.selector';
import Modal from '@components/Modal';
import { AutoSelect } from '@components/AutoSelect';
import { BankSelect } from '@components/BankSelect';

import {
  IMournerListItem,
  MemberFormInputsEnum,
} from '../../interface/mournerInfoForm.interface';
import CashAccountConfirmButton from './CashAccountConfirmButton';
import { CashAccountButton } from './CashAccountButton';
import { MournerItemWrapper } from './MournerItemWrapper';
import { AccountHolderSameButton } from './AccountHolderSameButton';
import { CashAccountList } from './CashAccountList';
import { useVerifyCashAccountHandler } from '../../interface/mournerInfoForm.hook';

const MournerItem: FC<IMournerListItem> = ({
  index,
  remove,
  fieldsErrors,
  register,
  control,
}) => {
  const { setValue, trigger } = useFormContext();
  const currentItemValue = useWatch({ control, name: `member.${index}` });
  const notificationInstance = useRecoilValue(notificationInstanceAtom);
  const modalOpenId = `verifyLoadingModal-${index}`;
  const cashAccountSelectModalId = `cashAccountSelectModal-${index}`;
  const currentFeventId = useRecoilValue(currentFeventIdAtom);
  const { data: currentFeventData } = useRecoilValue(
    currentFeventStateSelectorFamily(currentFeventId ?? ''),
  );
  const cashAccountSelectModal = useRecoilValue(
    modalOpenAtomFamily(cashAccountSelectModalId),
  );
  const { onVerifyCashAccountHandler } = useVerifyCashAccountHandler({
    index,
    accountHolder: currentItemValue.accountHolder,
    cashAccount: currentItemValue.cashAccount,
    cashAccountId: currentItemValue.cashAccountId,
    isVerified: currentItemValue.isVerified,
    bank: currentItemValue.bank,
  });

  //예금주 동일이 체크되었을 때 같은 value set해주는 handler
  const onAccountHolderSameNameHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (currentItemValue.accountHolderSame) {
        setValue(`member.${index}.${MemberFormInputsEnum.AccountHolder}`, e.target.value);
      }
      setValue(`member.${index}.${MemberFormInputsEnum.Name}`, e.target.value);
    },
    [currentItemValue.accountHolderSame, setValue, index],
  );

  const onAccountHolderInputHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (currentItemValue.accountHolderSame) {
        setValue(`member.${index}.${MemberFormInputsEnum.AccountHolderSame}`, false);
      }
      setValue(`member.${index}.${MemberFormInputsEnum.AccountHolder}`, e.target.value);
    },
    [currentItemValue.accountHolderSame, setValue, index],
  );

  const removeItemWithFoldState = useCallback(() => {
    remove(index);
    notificateSuccess(notificationInstance, '삭제 되었습니다.');
  }, [index, notificationInstance, remove]);

  const onFoldBtnClickHandler = useCallback(() => {
    setValue(`member.${index}.isFold`, !currentItemValue.isFold);
  }, [index, currentItemValue.isFold, setValue]);

  const cashAccountVerifyButtonHandler = () => {
    onVerifyCashAccountHandler();
  };

  //상주 계좌관련 input 작성 여부
  const isAccountInfoIncomplete =
    currentItemValue.accountHolder &&
    currentItemValue.cashAccount &&
    currentItemValue.bank
      ? false
      : true;

  const accountHoldeeSameButtonHandler = (checked: boolean) => {
    //계좌인증이 완료되었으면 예금주 동일 상태변경 불가능하게.
    if (currentItemValue.isVerified) {
      notificateInfo(
        notificationInstance,
        '이미 인증된 예금주입니다, 계좌 삭제 후 다시 시도해 주세요.',
      );
    } else {
      setValue(`member.${index}.${MemberFormInputsEnum.AccountHolderSame}`, checked);
    }
  };
  // 예금주 동일이 체크되었을 때 예금주명을 상주 이름으로 설정.
  useEffect(() => {
    if (currentItemValue.accountHolderSame)
      setValue(
        `member.${index}.${MemberFormInputsEnum.AccountHolder}`,
        currentItemValue.name,
      );
  }, [currentItemValue.accountHolderSame, index, currentItemValue.name, setValue]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <>
      <MournerItemWrapper
        index={index}
        foldState={currentItemValue.isFold ?? false}
        removeHandler={removeItemWithFoldState}
        onFoldBtnClickHandler={onFoldBtnClickHandler}
        {...currentItemValue}
      >
        <div className={`${currentItemValue.isFold ? 'hidden' : 'block'}`}>
          <div className="mb-2 w-full border-t border-gray-300"></div>
          <div className={`grid grid-cols-8 gap-2`}>
            <div
              className={`col-span-2 max-[1140px]:col-span-4 max-[1140px]:row-start-1`}
            >
              <BasicInput
                label="상주 성함"
                placeholder="상주 성함"
                className={`pl-4 `}
                isError={fieldsErrors.member && !!fieldsErrors.member[index]?.name}
                {...register(`member.${index}.${MemberFormInputsEnum.Name}`, {
                  required: true,
                  onChange: onAccountHolderSameNameHandler,
                })}
              />
            </div>
            <div
              className={`col-span-2 max-[1140px]:col-span-4 max-[1140px]:row-start-1`}
            >
              <AutoSelect
                labelText="고인과의 관계"
                formControl={control}
                formFieldName={`member.${index}.${MemberFormInputsEnum.RelationType}`}
                optionList={relationTypeCategoryList}
                inputPlaceholder="고인과의 관계"
                validationRules={{ required: true }}
              />
            </div>

            <TelField
              name={`member.${index}.${MemberFormInputsEnum.PhoneNumber}`}
              register={register}
              value={currentItemValue.phoneNumber}
              wrapperClassName={` col-span-2 max-[1140px]:col-span-8  max-[1140px]:row-start-2 `}
            />

            <div
              className={`col-span-2 max-[1140px]:order-2 max-[1140px]:col-span-4 max-[1140px]:row-start-3`}
            >
              <AccountHolderSameButton
                checked={currentItemValue.accountHolderSame}
                onCheckedChange={accountHoldeeSameButtonHandler}
              />
            </div>

            <div
              className={`col-span-2 max-[1140px]:col-span-6 max-[1140px]:row-start-4`}
            >
              <BankSelect
                label="은행 선택"
                control={control}
                name={`member.${index}.${MemberFormInputsEnum.Bank}`}
                disabled={currentItemValue.isVerified}
                showValue={currentItemValue.bank}
              />
            </div>
            <div
              className={`col-span-2 max-[1140px]:order-1 max-[1140px]:col-span-4 max-[1140px]:row-start-3`}
            >
              <BasicInput
                label="예금주명"
                placeholder="예금주명"
                className={`pl-4`}
                disabled={currentItemValue.isVerified}
                {...register(`member.${index}.${MemberFormInputsEnum.AccountHolder}`, {
                  onChange: onAccountHolderInputHandler,
                })}
              />
            </div>
            <div
              className={`col-span-2 max-[1140px]:order-1 max-[1140px]:col-span-6 max-[1140px]:row-start-5`}
            >
              <BasicInput
                label="계좌번호"
                type="string"
                placeholder="계좌번호"
                className=" pl-4  [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                disabled={currentItemValue.isVerified}
                {...register(`member.${index}.${MemberFormInputsEnum.CashAccount}`)}
              />
            </div>

            <div className="relative top-3 order-2 col-span-1 max-[1140px]:col-span-2 max-[1140px]:row-start-5">
              <CashAccountConfirmButton
                onClick={cashAccountVerifyButtonHandler}
                isVerified={currentItemValue.isVerified}
                disabled={isAccountInfoIncomplete}
              />
            </div>
            <div className="relative top-3 order-2 col-span-1 max-[1140px]:col-span-2 max-[1140px]:row-start-4">
              <CashAccountButton
                isVerified={currentItemValue.isVerified}
                disabled={
                  currentFeventData?.cashAccounts &&
                  currentFeventData?.cashAccounts[0]?.account !== '' &&
                  currentFeventData?.cashAccounts.length > 0
                    ? false
                    : true
                }
                index={index}
              />
            </div>
          </div>
        </div>
      </MournerItemWrapper>
      {cashAccountSelectModal && currentFeventData?.cashAccounts && (
        <Modal openId={cashAccountSelectModalId} className="bg-white ">
          <div className="rounded ">
            <CashAccountList
              accounts={currentFeventData.cashAccounts}
              index={index}
              cashAccountSelectModalId={cashAccountSelectModalId}
            />
          </div>
        </Modal>
      )}
      <TopLayerModal text="인증 중" openId={modalOpenId} />
    </>
  );
};

export default React.memo(MournerItem);
