import { ChangeEvent, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BasicInput } from '@components/BasicInput';
import { Icon } from '@components/Icon';
import { Loading } from '@components/Loading';
import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { BasicInfoForm } from '@containers/FeventSubmitContent/components/BasicInfoForm';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { UserBugoList } from '@containers/UserBugoList';
import { searchResultFeventListDataAtom } from '@containers/UserBugoList/state/userBugoList.atom';
import { currentUserFeventListStateSelector } from '@containers/UserBugoList/state/userBugoList.selector';
import { Body, Footer, Header } from '@layout';
import { Pagination, PaginationProps } from 'antd';
import FuzzySearch from 'fuzzy-search';
import { useSearchParams } from 'react-router-dom';

const pageDescription = {
  bugo: `부고를 검색하시거나 오른쪽 목록에서 수정하시고자 하는 부고를 클릭하셔서 부고를 수정할 수 있습니다.
            
  수정된 부고는 재발송 없이 기존에 보내신분들에게도 자동 업데이트 반영됩니다.`,
  courtesy: `답례인사를 보내실 부고를 선택해주세요. 검색하셔서 찾을 수 있습니다.
  
  답례 인사는 해당 부고 발인 후 익일 오전 9시에 상주님께 자동발송 됩니다.
  또한 직접 수동으로 원하시는 때에 답례글 링크를 상주님께 발송할 수 있습니다.`,
};

const FeventListPage = () => {
  const [totalFeventCount, setTotalFeventCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState('');
  const setSearchResultFeventListData = useSetRecoilState(searchResultFeventListDataAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, status } = useRecoilValue(currentUserFeventListStateSelector);
  const purpose = searchParams.get('purpose');

  const onClickPage: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  const onChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  useEffect(() => {
    if (!purpose || (purpose !== 'bugo' && purpose !== 'courtesy'))
      setSearchParams({ purpose: 'bugo' }, { replace: true });
  }, [purpose, setSearchParams]);

  useEffect(() => {
    if (searchInputValue === '' && data) {
      setSearchResultFeventListData(null);
      setTotalFeventCount(data.length);
    } else if (data && searchInputValue !== '') {
      const searcher = new FuzzySearch(data, [
        'deceasedInfo.name',
        'funeralHomeInfoEmbed.name',
        'funeralHomeInfo.name',
        'roomInfoEmbed.name',
        'roomInfo.name',
      ]);

      const result = searcher.search(searchInputValue);

      const timer = setTimeout(() => {
        setSearchResultFeventListData(result);
        setTotalFeventCount(result.length);
      }, 200);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchInputValue, data, setSearchResultFeventListData]);

  useEffect(() => {
    if (totalFeventCount > 0 && totalFeventCount / 5 < currentPage) {
      setCurrentPage(Math.ceil(totalFeventCount / 5));
    }
  }, [totalFeventCount, setCurrentPage, currentPage]);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body header footer>
        <MainSection title={purpose === 'bugo' ? '부고 수정' : '답례 인사 전하기'}>
          <BasicInfoForm
            leftSectionTopComponent={
              <div className="mb-4">
                <BasicInput
                  className="pl-4 pr-8"
                  placeholder="성함/장례식장/호실로 검색하세요."
                  SuffixEl={<Icon.MagnifyingGlassRegular className="w-5 fill-gray-400" />}
                  value={searchInputValue}
                  onChange={onChangeInputValue}
                />
              </div>
            }
            desc={purpose === 'bugo' ? pageDescription.bugo : pageDescription.courtesy}
          >
            {status === 'loading' && <Loading />}

            <UserBugoList currentPage={currentPage} />
          </BasicInfoForm>
          {status === 'success' && data && data.length > 0 && (
            <Pagination
              current={currentPage}
              defaultPageSize={5}
              total={totalFeventCount}
              showSizeChanger={false}
              onChange={onClickPage}
              rootClassName="mt-8"
            />
          )}
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { FeventListPage };
