import { useRecoilValue } from 'recoil';

import { AuthBodyBase } from '@api/mongo/controllers';
import { BasicInput } from '@components/BasicInput';
import { useAuth } from '@hooks/useAuth';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { phoneNumberStringFormator } from 'src/@utils/phoneNumberStringFormator';

type FormValues = Pick<AuthBodyBase, 'name' | 'phoneNumber'>;

const GuestLoginForm = () => {
  const { loginBugoGuest, loginStoreGuest } = useAuth();
  const isStorePage = useRecoilValue(isStorePageAtom);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const phoneNumber = watch('phoneNumber');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (isStorePage) {
      await loginStoreGuest(data);
    } else {
      await loginBugoGuest(data);
    }
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
      <BasicInput
        className={`px-4 ${errors.name && 'border-red-500'}`}
        placeholder={`${isStorePage ? '주문자' : '작성자'} 성함을 입력해주세요.`}
        {...register('name', { required: true })}
      />
      {errors.name && (
        <p className={`text-left text-12 text-red-500 `}>성함을 입력해주세요.</p>
      )}
      <BasicInput
        className={`mt-2 px-4 ${errors.phoneNumber && 'border-red-500'}`}
        placeholder={`${isStorePage ? '주문자' : '작성자'} 휴대전화번호를 입력해주세요.`}
        value={phoneNumberStringFormator(phoneNumber)}
        {...register('phoneNumber', {
          required: { value: true, message: '휴대전화번호를 입력해주세요.' },
          maxLength: {
            value: 11,
            message: '휴대전화번호는 10~11자리의 숫자를 입력해주세요.',
          },
          minLength: {
            value: 10,
            message: '휴대전화번호는 10~11자리의 숫자를 입력해주세요.',
          },
          setValueAs: (phoneNumber) => phoneNumber.replace(/[^0-9]/gi, ''),
        })}
      />
      {errors.phoneNumber && (
        <p className={`text-left text-12 text-red-500 `}>{errors.phoneNumber.message}</p>
      )}
      <button
        type="submit"
        className={`mt-12 block h-12 w-full rounded  bg-primary text-white`}
      >
        검색
      </button>
    </form>
  );
};

export { GuestLoginForm };
