import { Fragment, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { nestMongoApi } from '@api/mongo/controllers';
import { nestAlertApi } from '@api/mongo/controllers/alert.controller';
import { feventByIdHookUrl } from '@containers/FeventSubmitContent/state/feventSubmitContent.query';
import { useAuth } from '@hooks/useAuth';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ConfirmModal } from '@components/ConfirmModal';
import _ from 'lodash';

import { CourtesySendFormBtnSet } from './CourtesySendFormBtnSet';
import { CourtesySendFormItem } from './CourtesySendFormItem';
import { useNotificateSendHook } from '../state/courtesySendForm.hook';
import {
  CourtesySendFormValues,
  MemberForCourtesySendPage,
} from '../state/courtesySendForm.interface';
import { feventStateForCourtesySendPageSelectorFamily } from '../state/courtesySendForm.selector';

const setDefaultValues = (memberOrderList?: MemberForCourtesySendPage[]) => {
  if (memberOrderList)
    return memberOrderList.map((member) => {
      return {
        _id: member._id,
        phoneNumber: member.phoneNumber,
        bugoId: member.bugo?._id,
      };
    });
  return [
    {
      _id: '',
      phoneNumber: '',
      bugoId: '',
    },
  ];
};

const CourtesySendFormList = () => {
  const navigate = useNavigate();
  const { notiBySendStatus } = useNotificateSendHook();
  const { feventId } = useParams();
  const { bugoRole } = useAuth();
  const queryClient = useQueryClient();
  const { data: feventData, status: feventDataStatus } = useRecoilValue(
    feventStateForCourtesySendPageSelectorFamily(feventId ?? ''),
  );
  const method = useForm<CourtesySendFormValues>({
    defaultValues: {
      formData: setDefaultValues(feventData?.memberOrderList),
    },
  });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    formState: { defaultValues },
  } = method;

  const { fields } = useFieldArray({
    control,
    name: 'formData',
  });

  useEffect(() => {
    if (feventData) {
      control._reset({
        formData: setDefaultValues(feventData.memberOrderList),
      });
    }
  }, [control, feventData]);

  const isEqual = () => _.isEqual(defaultValues?.formData, getValues().formData);

  const patchUpdateMembersData = async (formData: CourtesySendFormValues) => {
    await Promise.all(
      formData.formData.map(async (formData) => {
        await nestMongoApi(`${bugoRole()}/member`).updateById(formData._id, {
          phoneNumber: formData.phoneNumber,
        });
      }),
    );
    return queryClient.invalidateQueries(feventByIdHookUrl(feventId ?? '', bugoRole()));
  };

  useEffect(() => {
    if (
      feventData &&
      (feventData.memberOrderList.length !== fields.length ||
        feventDataStatus === 'success')
    )
      control._reset({
        formData: setDefaultValues(feventData.memberOrderList),
      });
  }, [feventData, control, fields.length, feventDataStatus]);

  const onSubmit: SubmitHandler<CourtesySendFormValues> = async (data) => {
    await patchUpdateMembersData(data);
    const memberBugoIdList = data.formData.map((formData) => formData.bugoId);
    const sendResponse = await nestAlertApi().courtesySend({ bugos: memberBugoIdList });
    notiBySendStatus(sendResponse.data.success);
  };

  // useEffect(() => {
  //   console.log('defaultValues', defaultValues?.formData);
  //   console.log('getValues', getValues().formData);

  //   console.log(isEqual());
  // }, [defaultValues, getValues, isEqual, control]);

  return (
    <FormProvider {...method}>
      <form
        className="flex flex-col gap-2 max-sm:gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {feventData && feventData.memberOrderList.length === 0 ? (
          <div>
            <div className="flex h-[163px] items-center justify-center text-16 text-gray-500">
              등록된 상주가 없습니다.
            </div>
            <Link
              to={`/fevent/edit/${feventData._id}/mourner-info`}
              className="ml-auto flex h-10 w-[180px] items-center justify-center rounded border bg-white text-16 font-bold max-sm:w-full"
            >
              상주 등록하기
            </Link>
          </div>
        ) : (
          fields.map((field, index) => {
            const member = feventData?.memberOrderList[index];
            if (member)
              return (
                <Fragment key={field.id}>
                  <CourtesySendFormItem
                    memberName={member.fullName}
                    phoneNumber={member.phoneNumber}
                    bugoId={field.bugoId}
                    memberId={field._id}
                    index={index}
                    isSended={member.bugo?.schedule.courtesySend}
                  />
                </Fragment>
              );
          })
        )}
        <CourtesySendFormBtnSet isEqual={isEqual} />
        <ConfirmModal
          openId="confirm-modal-courtesySendForm"
          onClick={async () => {
            await handleSubmit(onSubmit)();
            navigate('/');
          }}
          buttonTitle="전송"
          title="변경사항이 존재합니다. 적용 후 전송하시겠습니까?"
        />
      </form>
    </FormProvider>
  );
};

export { CourtesySendFormList };
