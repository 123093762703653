import { Carousel as AntdCarousel } from 'antd';
import mainBanner from 'src/assets/image/main_bn_new.png';

const MainBanner = () => {
  const images = [mainBanner, mainBanner, mainBanner];

  return (
    <AntdCarousel
      autoplay={true}
      dotPosition="bottom"
      speed={1000}
      autoplaySpeed={3000}
      draggable
    >
      {images.map((image) => (
        <div
          key={image}
          className="m-auto flex h-[360px] w-full items-center justify-center"
        >
          <img src={image} className="h-full w-full object-cover"></img>
        </div>
      ))}
    </AntdCarousel>
  );
};

export { MainBanner };
