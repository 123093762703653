import { FC, ReactNode } from 'react';

interface ChipProps {
  children: ReactNode;
  color?: 'gray' | 'orange' | 'apricot' | 'red' | 'default';
}

const Chip: FC<ChipProps> = ({ children, color }) => {
  const setColor = () => {
    switch (color) {
      case 'gray':
        return 'bg-gray-800 text-white border-gray-800';
      case 'orange':
        return 'bg-orange-100 border-orange-100';
      case 'apricot':
        return 'border-apricot-600 bg-white text-apricot-600';
      case 'red':
        return 'border-red-500 bg-white text-red-500';
      case 'default':
        return 'text-gray-600 bg-white';
      default:
        'text-gray-600 bg-white';
    }
  };

  return (
    <div
      className={`h-fit rounded-full border py-1 px-4 text-14 font-medium ${setColor()}`}
    >
      {children}
    </div>
  );
};

export { Chip };
