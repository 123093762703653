import { PropsWithChildren } from 'react';

import {
  Control,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form';

//* 실제 입력으로 사용하는 것
export enum MemberFormInputsEnum {
  Name = 'name',
  RelationType = 'relationType',
  PhoneNumber = 'phoneNumber',
  CashAccountId = 'cashAccountId',
  CashAccount = 'cashAccount',
  AccountHolder = 'accountHolder',
  Bank = 'bank',
  AccountHolderSame = 'accountHolderSame',
}

export interface MemberIFormInput {
  _id: string; // member._id
  [MemberFormInputsEnum.Name]: string; // member.fullname
  [MemberFormInputsEnum.RelationType]: string; // member.relationType
  [MemberFormInputsEnum.PhoneNumber]: string; // member.phoneNumber
  [MemberFormInputsEnum.CashAccountId]?: string; // member.cashAccount._id
  [MemberFormInputsEnum.CashAccount]: string; // member.cashAccount.account
  [MemberFormInputsEnum.AccountHolder]: string; // 예금주
  [MemberFormInputsEnum.Bank]: string; // member.cashAccount.bank
  [MemberFormInputsEnum.AccountHolderSame]: boolean; // 예금주 동일
  isVerified: boolean; // member.cashAccount.isVerified
  cashAccountModalConfirmed: boolean;
  isFold: boolean;
  // isNew: boolean;
}

export interface MemberIFormInputs {
  member: MemberIFormInput[];
}

export interface IMournerListItem {
  register: UseFormRegister<MemberIFormInputs>;
  control: Control<MemberIFormInputs, any>;
  index: number;
  remove: UseFieldArrayRemove;
  fieldsErrors: FieldErrors<MemberIFormInputs>;
}

export interface MournerItemWrapperProps extends PropsWithChildren, MemberIFormInput {
  index: number;
  foldState: boolean;
  onFoldBtnClickHandler: () => void;
  removeHandler: () => void;
}

export interface MemberIFormInputsRefactor {
  member: Array<MemberIFormInput & { id: string }>;
}

export const vacantMemberInputForm: MemberIFormInput[] = [
  {
    _id: '',
    [MemberFormInputsEnum.Name]: '',
    [MemberFormInputsEnum.RelationType]: '',
    [MemberFormInputsEnum.PhoneNumber]: '',
    [MemberFormInputsEnum.CashAccount]: '',
    [MemberFormInputsEnum.AccountHolder]: '',
    [MemberFormInputsEnum.Bank]: '',
    [MemberFormInputsEnum.AccountHolderSame]: true,
    isVerified: false,
    cashAccountModalConfirmed: false,
    isFold: false,
    // isNew: false,
  },
];
