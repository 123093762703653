import { ShopItemCheckoutPage } from '@pages/FlowerStore/StoreItem/ShopItemCheckoutPage';
import { ShopItemDetailPage } from '@pages/FlowerStore/StoreItem/ShopItemDetailPage';
import { Route, Routes } from 'react-router-dom';

const StoreItemRouter = () => {
  return (
    <Routes>
      <Route path=":itemId" element={<ShopItemDetailPage />}></Route>
      <Route path=":itemId/checkout" element={<ShopItemCheckoutPage />}></Route>
    </Routes>
  );
};

export { StoreItemRouter };
