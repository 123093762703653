import dayjs from 'dayjs';

const createCourtesyMessages = (
  index: number,
  deceasedName: string,
  memberName: string,
  today: Date,
) => {
  const todayFormat = dayjs(today).format('YYYY년 MM월 DD일');

  switch (index) {
    case 0:
      return [
        {
          title: '표준(장문-1)',
          content: `삼가 감사의 인사를 올립니다.
    
금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
        {
          title: '표준(장문-2)',
          content: `삼가 감사의 인사를 올립니다.
    
( 표준(장문-2) )금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
      ];
    case 1:
      return [
        {
          title: '기독교(장문-1)',
          content: `삼가 감사의 인사를 올립니다.
    
( 기독교(장문-1) ) 금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
      ];
    case 2:
      return [
        {
          title: '천주교(장문-1)',
          content: `삼가 감사의 인사를 올립니다.
    
( 천주교(장문-1) ) 금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
      ];
    case 3:
      return [
        {
          title: '불교(장문-1)',
          content: `삼가 감사의 인사를 올립니다.
    
금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
      ];
    default:
      return [
        {
          title: '표준(장문-1)',
          content: `삼가 감사의 인사를 올립니다.
    
금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
        {
          title: '표준(장문-2)',
          content: `삼가 감사의 인사를 올립니다.
    
금번 저희 故${deceasedName}님 장례에 바쁘신 중에도 찾아주시고,
저희 가족 위로해주심을 진심으로 감사드립니다.

큰 슬픔에 찬 저희 가족에게 따듯한 위로가 되었습니다.
고마움 오래오래 간직하겠습니다.

내내 건강하시옵고 귀댁의 가정에
항상 행운이 함께 하시길 기원합니다.

${todayFormat}
${memberName} 배상`,
        },
      ];
  }
};

export { createCourtesyMessages };
