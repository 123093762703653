import { selectorFamily } from 'recoil';

import { CurrentFeventState } from '@containers/FeventSubmitContent/state/feventSubmitContent.interface';
import {
  currentFeventDataAtomFamily,
  currentFeventStatusAtomFamily,
} from '@containers/FeventSubmitContent/state/feventSubmitContent.atom';

const currentFeventStateSelectorFamily = selectorFamily<CurrentFeventState, string>({
  key: 'currentFeventStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(currentFeventStatusAtomFamily(id)),
        data: get(currentFeventDataAtomFamily(id)),
      };
    },
});

export { currentFeventStateSelectorFamily };
