import { FC, ReactNode } from 'react';

interface FlowerStoreSectionProps {
  children: ReactNode;
  title?: string;
}

const FlowerStoreSection: FC<FlowerStoreSectionProps> = ({ children, title }) => {
  return (
    <div className="mx-auto min-h-[calc(100vh-136px)] bg-gray-50 max-md:px-0 max-sm:min-h-[calc(100vh-56px)] ">
      <div className="mx-auto max-w-[1120px] p-8 max-md:p-4 max-md:pt-0">
        {title && (
          <h2 className="text-left text-24 font-bold text-black max-sm:hidden">
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  );
};

export { FlowerStoreSection };
