import { NotificationInstance } from 'antd/es/notification/interface';

import { unsecuredCopyToClipboard } from './clipBoard.util';
import { notificateSuccess } from './noti.utils';

export const addressCopyHandler = async (
  writeText: string,
  notifiacationInstance: NotificationInstance | null,
  notiText: string,
) => {
  try {
    await window.navigator.clipboard.writeText(writeText);
    notificateSuccess(notifiacationInstance, notiText);
  } catch {
    unsecuredCopyToClipboard(writeText, notiText, notifiacationInstance);
  }
};
