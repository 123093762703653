import { FC } from 'react';

import { FAQTabContentItem } from '@containers/FAQContent/components/FAQTabContentItem';

import {
  cancelFAQContents,
  orderFAQContents,
  returnFAQContents,
} from '../state/storeFAQContent';

interface FAQTabContentListProps {
  selectedTab: number;
}

const FlowerStoreFAQTabContentList: FC<FAQTabContentListProps> = ({ selectedTab }) => {
  switch (selectedTab) {
    case 0:
      return (
        // 주문/결제 탭
        <div>
          {orderFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
    case 1:
      return (
        // 취소/환불 탭
        <div>
          {cancelFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
    case 2:
      return (
        // 반품/교환 탭
        <div>
          {returnFAQContents.map((content) => (
            <FAQTabContentItem key={content._id} content={content} />
          ))}
        </div>
      );
    default:
      return <div>none</div>;
  }
};

export { FlowerStoreFAQTabContentList };
