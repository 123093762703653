import { useLayoutEffect, useState } from 'react';

import Tabs from '@components/Tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { FAQTabContentList } from './components/FAQTabContentList';

const FAQContent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  useLayoutEffect(() => {
    if (state && state.index) {
      setSelectedTab(state.index ?? 0);
    }
  }, [state]);

  return (
    <div className="max-sm:m-[-1rem]">
      <div className="max-sm:bg-white">
        <Tabs
          items={['부고장', '답례 인사', '화환 주문']}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onTabClick={(e) => {
            navigate('', { replace: true, state: { index: e } });
          }}
        />
      </div>
      <FAQTabContentList selectedTab={selectedTab} />
      {
        <div className="hidden max-sm:block">
          <p className=" mt-8 mb-3 text-12 font-medium">
            그 외 궁금하신 점은 1:1 문의를 이용해 주세요
          </p>
          <Link to="/enquiry">
            <button className="w-[160px] rounded bg-gray-800 px-4 py-3 text-white">
              1:1문의
            </button>
          </Link>
        </div>
      }
    </div>
  );
};

export { FAQContent };
