import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { CustomCallback } from 'src/interfaces';

import Modal, { Props } from './Modal';

interface ConfirmModalProps extends Props {
  title: string;
  subtitle?: string;
  buttonTitle: string;
  onClick: CustomCallback<any, void>;
  onCancelClick?: CustomCallback<any, void>;
  disabled?: boolean;
  openId: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  subtitle,
  buttonTitle,
  onClick,
  onCancelClick,
  openId,
  disabled,
  ...props
}) => {
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal {...props} openId={openId} className="bg-white">
      <>
        <div className="mt-3 text-center">
          <p className="text-xl font-medium leading-6 text-gray-900">{title}</p>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{subtitle}</p>
          </div>
        </div>
      </>
      <div className="mt-5 grid grid-flow-row-dense grid-cols-2 gap-3">
        <button
          type="button"
          className={`col-start-2 w-full rounded border border-transparent bg-gray-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none`}
          disabled={disabled}
          onClick={(e) => {
            onClick(e);
            onCloseModal();
          }}
        >
          {buttonTitle}
        </button>
        <button
          type="button"
          className="col-start-1 w-full rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
          onClick={(event) => {
            if (onCancelClick) {
              onCancelClick(event);
            }
            onCloseModal();
          }}
        >
          취소
        </button>
      </div>
    </Modal>
  );
};

export { ConfirmModal };
