import { FC, Ref, TextareaHTMLAttributes, forwardRef } from 'react';

import { Label } from './Label';

interface TextAreaInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  ref?: Ref<HTMLTextAreaElement>;
  isError?: boolean;
}

const TextAreaInput: FC<TextAreaInputProps> = forwardRef(
  ({ className, label, isError, ...props }, ref) => {
    return (
      <div>
        {label && <Label text={label} />}
        <textarea
          cols={30}
          rows={2}
          ref={ref}
          {...props}
          className={`w-full border border-[#d1d5db] py-2 px-4 ${className} ${
            isError && 'border-red-500'
          }`}
        ></textarea>
      </div>
    );
  },
);

export { TextAreaInput };
