const MobileDescriptionSection = () => {
  return (
    // 640px 모바일 레이아웃 전환
    <div className="my-4 hidden break-keep bg-white p-1 text-left text-12 text-gray-500 max-sm:block">
      ※ 상주님 정보를 작성해주세요. 접기 기능을 통해 등록된 상주님들을 쉽게 확인하실 수
      있습니다. 또한 드래그하여 순서를 쉽게 변경 가능합니다.
      <br /> ※ 계좌정보 작성 후 계좌확인 버튼을 클릭하셔서 계좌번호를 인증해주세요. <br />
      ※ 등록한 계좌번호를 계좌선택을 통해 인증 없이 재사용 가능합니다.
    </div>
  );
};

export { MobileDescriptionSection };
