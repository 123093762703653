import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { ConfirmPasswordForm } from '@containers/ConfirmPasswordForm';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { Body, Footer, Header } from '@layout';

const ConfirmPasswordPage = () => {
  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection>
          <ConfirmPasswordForm />
        </MainSection>
      </Body>

      <Footer>
        <FooterContent />
      </Footer>
    </>
  );
};

export { ConfirmPasswordPage };
