import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import Modal from '@components/Modal';
import { useFlowerStoreItemList } from '@containers/FlowerStoreMainContent/state/flowerStoreMainContent.query';
import { flowerStoreItemListStateSelector } from '@containers/FlowerStoreMainContent/state/flowerStoreMainContent.selector';
import { fileBucketUrl } from '@plugin/s3Image';

interface Props {
  bugoId: string;
}

export const FlowerEasyBuyModalButton: FC<Props> = function FlowerEasyBuyModalButton({
  bugoId,
}: Props) {
  useFlowerStoreItemList();
  const openId = `easy-buy-${bugoId}`;
  const navigate = useNavigate();
  const { data } = useRecoilValue(flowerStoreItemListStateSelector);
  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const filter = data?.filter((item) => {
    return item.shopItemInfo.name.indexOf('바구니') === -1;
  });
  const sortedFilter = filter?.sort((a, b) => a.priceRetail - b.priceRetail);

  return (
    <>
      <Button
        className="h-10 rounded border-none bg-primary px-3.5 text-16 font-medium text-white shadow-none"
        onClick={() => {
          setOpen(true);
        }}
      >
        간편구매
      </Button>
      <Modal className="bg-white" openId={openId} position="center">
        <div className="grid w-[580px] grid-cols-3 max-sm:w-full">
          {sortedFilter &&
            sortedFilter.slice(0, 3).map((shopItem) => (
              <div
                key={shopItem._id}
                className="flex cursor-pointer flex-col items-center justify-end "
                onClick={() => {
                  navigate(
                    `/flower-store/item/${shopItem._id}/checkout?bugoId=${bugoId}`,
                  );
                }}
              >
                <div className="w-full">
                  <img
                    src={`${fileBucketUrl(shopItem.shopItemInfo?.image?.url, 'w400')}`}
                    alt="상품이미지"
                  />
                </div>
                <div className="text-center">
                  <p className="break-keep text-14 font-bold">
                    {shopItem.name ?? shopItem.shopItemInfo.name}
                  </p>
                  <div>
                    <p className="text-16 font-bold text-black">
                      {shopItem.priceRetail.toLocaleString()}원
                    </p>
                  </div>
                </div>
                <div className="pt-2">
                  <Button className="h-7 rounded border-0 bg-primary px-3.5 text-white shadow-none">
                    주문하기
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};
