// ! 05.10 쿠폰 콘텐츠 비활성화
import { FC, HTMLAttributes } from 'react';

import { useAuth } from '@hooks/useAuth';

interface MyCashInfoBoxProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const MyCashInfoBox: FC<MyCashInfoBoxProps> = ({ className, ...props }) => {
  const { userProfile } = useAuth();

  const myPoint = userProfile?.storeCustomerDetail?.shopMembershipPoint ?? 0;

  return (
    <div
      {...props}
      className={`flex w-full max-w-[306px] items-center justify-start py-6 text-24 ${className} max-sm:py-4  max-sm:text-20`}
    >
      <div className="flex items-center pr-4">
        <div className="relative mr-1 text-20 max-sm:text-16">G포인트 :</div>
        <div className="font-bold text-primary ">
          {`${Number(myPoint).toLocaleString()}`}
          <span className=" font-normal">P</span>
        </div>
      </div>
      {/* <div className=" px-4">
        <span>내 쿠폰 </span>
        <span className="font-bold text-primary">
          {`${Number(3).toLocaleString()}`}
          <span className="font-normal">장</span>
        </span>
      </div> */}
    </div>
  );
};

export { MyCashInfoBox };
