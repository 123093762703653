import { useEffect } from 'react';

import { MainSection } from '@components/MainSection';
import { GuestLoginForm } from '@containers/GuestLoginForm';
import { TopNavigation } from '@containers/TopNavigation';
import { SubNavigation } from '@containers/TopNavigation/components';
import { StoreMainNavigation } from '@containers/TopNavigation/components/StoreMainNavigation';
import { StoreMobilNavigation } from '@containers/TopNavigation/components/StoreMobileNavigation';
import { useAuth } from '@hooks/useAuth';
import { useLoginBtnHandler } from '@hooks/useLoginBtnHandler';
import { Body, Header } from '@layout';
import { Link, useNavigate } from 'react-router-dom';

const FlowerStoreGuestLoginPage = () => {
  const { redirectUrl } = useLoginBtnHandler();
  const navigate = useNavigate();
  const { isAuthenticated, redirectAfterLogin } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectUrl ?? redirectAfterLogin ?? '/', { replace: true });
    }
  }, [isAuthenticated, navigate, redirectAfterLogin, redirectUrl]);

  return (
    <>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <StoreMainNavigation />
          <StoreMobilNavigation />
        </TopNavigation>
      </Header>
      <Body header>
        <MainSection>
          <div className="mx-auto mt-[135px] w-[352px] text-gray-700 max-sm:mt-[78px] max-sm:w-full">
            <h2 className="text-36 font-bold text-black">비회원 주문조회</h2>
            <h3 className="mt-6 text-24 font-medium text-black">주문자 정보로 검색</h3>
            <GuestLoginForm />
            <div className="mt-8">
              <p className="font-['Noto_Sans'] text-12 text-gray-500">회원이신가요?</p>
              <Link to={`/flower-store/login?redirect=${redirectUrl}`}>
                <button className="mt-2 w-full rounded border border-primary bg-white px-8 py-3 text-18 text-primary">
                  회원 로그인
                </button>
              </Link>
            </div>
          </div>
        </MainSection>
      </Body>
    </>
  );
};

export { FlowerStoreGuestLoginPage };
