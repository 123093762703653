import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { ProfileIconButton } from '@components/ProfileIconButton';
import { useAuth } from '@hooks/useAuth';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useLoginBtnHandler } from '@hooks/useLoginBtnHandler';

import { mobileSidePopupModalId } from '..';

const UserRow: FC = () => {
  const { isAuthenticated, userProfile, isGuest, logout } = useAuth();
  const setMobileSidePopupOpen = useSetRecoilState(
    modalOpenAtomFamily(mobileSidePopupModalId),
  );
  const { loginBtnClickHandler } = useLoginBtnHandler();

  const userRowloginBtnClickHandler = () => {
    setMobileSidePopupOpen(false);
    loginBtnClickHandler();
  };

  return (
    <>
      <div className=" box-border w-full cursor-pointer justify-between bg-white px-2 py-4">
        {!isAuthenticated ? (
          <div
            className="flex items-center justify-between"
            onClick={userRowloginBtnClickHandler}
          >
            <div className="flex items-center">
              <Icon.EmptyUser className="mr-2 h-[32px] w-[32px] " />
              <span className="text-16 font-bold text-black">로그인</span>
            </div>
            <Icon.ArrowRight className=" stroke-none" />
          </div>
        ) : (
          <div className="flex items-center justify-between ">
            <ProfileIconButton
              isLogin={isAuthenticated}
              isGuest={isGuest}
              name={userProfile ? userProfile.info.name : '회원'}
              svgClassName="mr-2"
            />
            <button
              onClick={logout}
              className=" shrink-0 rounded-full border border-gray-300 bg-white px-4 py-1 text-12  text-gray-600 shadow-sm"
            >
              로그아웃
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export { UserRow };
