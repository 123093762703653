import { selectorFamily } from 'recoil';

import { Fevent, Member } from '@api/mongo/interfaces';
import { QueryDataState } from 'src/interfaces';

import {
  currentMemberDataAtomFamily,
  currentMemberStatusAtomFamily,
} from './courtesy.atom';

const currentMemberStateSelectorFamily = selectorFamily<
  QueryDataState<Member<Fevent>>,
  string
>({
  key: 'currentMemberStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(currentMemberStatusAtomFamily(id)),
        data: get(currentMemberDataAtomFamily(id)),
      };
    },
});

export { currentMemberStateSelectorFamily };
