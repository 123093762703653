import { FC } from 'react';

import { FuneralHomeInfo } from '@api/mongo/interfaces';
import { BasicInput } from '@components/BasicInput';
import { Button } from '@components/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Coord } from 'src/interfaces/map.interface';

import AddressSearcher from './AddressSearcher';

enum FuneralHomeInfoFormEnum {
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  Address = 'address',
  ParkingAddress = 'parkingAddress',
  Coord = 'coord',
}

export interface IFuneralHomeInfoForm {
  [FuneralHomeInfoFormEnum.Name]: string;
  [FuneralHomeInfoFormEnum.PhoneNumber]: string;
  [FuneralHomeInfoFormEnum.Address]: string;
  [FuneralHomeInfoFormEnum.ParkingAddress]: string;
  [FuneralHomeInfoFormEnum.Coord]: { x: string; y: string };
}

interface IProps {
  onSubmit: SubmitHandler<IFuneralHomeInfoForm>;
  funeralHomeInfo?: FuneralHomeInfo;
}

const FuneralHomeInfoSearchForm: FC<IProps> = ({ onSubmit, funeralHomeInfo }: IProps) => {
  const { register, handleSubmit, setValue } = useForm<IFuneralHomeInfoForm>({
    defaultValues: {
      name: funeralHomeInfo?.name,
      phoneNumber: funeralHomeInfo?.phoneNumber,
      address: funeralHomeInfo?.address,
      parkingAddress: funeralHomeInfo?.parkingAddress,
      coord: funeralHomeInfo?.coord,
    },
  });

  const onAddressChange = (addressItem: { address: string; coord?: Coord }) => {
    setValue(FuneralHomeInfoFormEnum.Address, addressItem.address);
    if (addressItem.coord) setValue(FuneralHomeInfoFormEnum.Coord, addressItem.coord);
  };

  return (
    <div className="space-y-3 text-sm">
      {/* Name */}
      <div className="space-y-2">
        <h5 className="font-medium">장례식장 이름</h5>
        <BasicInput
          autoComplete="off"
          className="h-11 w-full rounded-none px-4 font-bold text-black transition duration-300 hover:border-apricot-600 focus:border-apricot-600"
          placeholder="장례식장 이름"
          {...register(FuneralHomeInfoFormEnum.Name, {
            required: true,
          })}
        ></BasicInput>
      </div>
      {/* PhoneNumber */}
      <div className="space-y-2">
        <h5 className="font-medium">전화번호</h5>
        <BasicInput
          autoComplete="off"
          className="h-11 w-full rounded-none px-4 font-bold text-black transition duration-300 hover:border-apricot-600 focus:border-apricot-600"
          placeholder="전화번호"
          {...register(FuneralHomeInfoFormEnum.PhoneNumber)}
        ></BasicInput>
      </div>
      {/* Address */}
      <div className="space-y-2">
        <h5 className="font-medium">주소검색</h5>
        <AddressSearcher onChange={onAddressChange} />
      </div>
      {/* parkingAddress */}
      <div className="space-y-2">
        <h5 className="font-medium">주차안내</h5>
        <BasicInput
          autoComplete="off"
          className="h-11 w-full rounded-none px-4 font-bold text-black transition duration-300 hover:border-apricot-600 focus:border-apricot-600"
          placeholder="주차안내"
          {...register(FuneralHomeInfoFormEnum.ParkingAddress)}
        ></BasicInput>
      </div>
      {/* Confirm Button */}
      <div>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          className="button mt-8 h-[52px] w-full rounded-none border-0 bg-apricot-600 text-white shadow-none"
        >
          다음
        </Button>
      </div>
    </div>
  );
};

export default FuneralHomeInfoSearchForm;
