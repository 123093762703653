import { FC, useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';
import { useId } from 'react-id-generator';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { getBankLogo } from 'src/@utils/getBankLogo';
import { Control, Controller } from 'react-hook-form';

import { BankEnum, bankCategoryList } from './miscValues';
import { Icon } from './Icon';
import Modal from './Modal';
import { Label } from './Label';

interface BankSelectProps {
  control: Control<any, any>;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  label: string;
  showValue: string;
}

const BankSelect: FC<BankSelectProps> = ({
  control,
  disabled,
  name,
  defaultValue,
  label,
  showValue,
}) => {
  const options = bankCategoryList;
  const isMobileMedia = useMobileMediaQuery();
  const [id] = useId(1, 'bankSelect');
  const openId = `bankSelect-${id}`;

  const setOpen = useSetRecoilState(modalOpenAtomFamily(openId));

  const modalContents = useCallback(
    (value: any, onChange: (...event: any[]) => void, containerClassName = '') => {
      const onConfirm = (option: BankEnum, onChange: (...event: any[]) => void) => {
        // set value in react hook form
        onChange(option);
        // close modal
        setOpen(false);
      };

      return (
        <div className={`z-50 space-y-3 p-6 text-sm ${containerClassName}`}>
          {/* Title */}
          <h4 className="text-center font-bold">{'은행 선택'}</h4>
          {/* Item List */}
          <ul className="grid h-[600px] max-h-[calc(100vh-180px)] grid-cols-2 overflow-y-auto pt-3 pb-4">
            {options.map((option, index) => (
              <li
                key={`${index}-${option}-${option}`}
                onClick={() => onConfirm(option, onChange)}
                className={`border-1-primary flex cursor-pointer items-center justify-start gap-2 px-4 py-2 ${
                  option === value ? 'bg-primary text-white' : 'hover:bg-primary'
                }`}
              >
                <img
                  src={getBankLogo(option)}
                  alt="Bank Logo"
                  className="aspect-square w-8"
                />
                <p className="font-medium leading-5">{option}</p>
              </li>
            ))}
          </ul>
        </div>
      );
    },
    [options, setOpen],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(
        { field: { onChange, value, onBlur, ref } }, // value: bankEnum;
      ) => (
        <>
          {label && <Label text={label} />}
          <div
            className={`select relative flex cursor-pointer items-center justify-between rounded border px-4 py-2 text-15 ${
              disabled && 'bg-gray-50'
            } `}
            onClick={() => {
              if (!disabled) {
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement) {
                  activeElement.blur();
                }
                setOpen(true);
              }
            }}
            ref={ref}
            onBlur={onBlur}
          >
            <div>{showValue || '은행 선택'}</div>
            <div>
              <Icon.ChevronDownFit className="h-3 w-3" />
            </div>
          </div>
          {isMobileMedia ? (
            <Modal openId={openId} className="bg-white max-sm:my-0" position="end">
              {modalContents(value, onChange)}
            </Modal>
          ) : (
            <Modal openId={openId} className="bg-white">
              {modalContents(value, onChange)}
            </Modal>
          )}
        </>
      )}
    />
  );
};

export { BankSelect };
