import { useEffect } from 'react';

import { MainSection } from '@components/MainSection';
import { MobileNavigation } from '@components/MobileNavigation';
import { FindIdPassword } from '@containers/FindIdPassword';
import { useResetCiState } from '@containers/FindIdPassword/state/ciUser.hook';
import { FooterContent } from '@containers/FooterContent';
import { TopNavigation } from '@containers/TopNavigation';
import { MainNavigation, SubNavigation } from '@containers/TopNavigation/components';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { Body, Footer, Header } from '@layout';

const FindIdPasswordPage = () => {
  useMobileNavTitleHook('계정정보 찾기', true);
  const { resetState } = useResetCiState();

  useEffect(() => {
    return () => {
      resetState(); // page에서 사용되는 ci관련 recoilState reset시키는 함수 호출
    };
  }, [resetState]);

  return (
    <div>
      <Header>
        <TopNavigation>
          <SubNavigation />
          <MainNavigation />
          <MobileNavigation />
        </TopNavigation>
      </Header>
      <Body footer header>
        <MainSection>
          <FindIdPassword />
        </MainSection>
      </Body>
      <Footer>
        <FooterContent />
      </Footer>
    </div>
  );
};

export { FindIdPasswordPage };
