import { Link } from 'react-router-dom';

const PromotionCouponCard = () => {
  return (
    <Link
      to={'/flower-store'}
      className="relative flex h-[200px] w-[384px] shrink-0 bg-[#C9906A] text-black max-sm:mt-4 max-sm:w-full max-sm:justify-around"
    >
      <div className="shrink-0 p-8 text-left text-18 ">
        <p className="mb-6 font-medium">부고장 작성만 해도</p>
        <p className="mb-6 font-medium">
          최대 <span className="text-24 font-bold text-white">10,000원 혜택</span>
        </p>

        <div className="inline-block rounded bg-white px-8 py-2 text-12 font-bold text-[#C9906A]">
          할인받고 주문하기
        </div>
      </div>
      <div className="relative right-3 h-full w-[136px] bg-[url('src/assets/image/Wreath.webp')] max-sm:right-0"></div>
    </Link>
  );
};

export { PromotionCouponCard };
