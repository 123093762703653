import { FC } from 'react';

import { CustomCallback } from 'src/interfaces';

interface IProps {
  items: readonly string[];
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
  onTabClick?: CustomCallback<number, void>;
}

const Tabs: FC<IProps> = ({ items, selectedTab, setSelectedTab, onTabClick }: IProps) => {
  const onClick = (tab: number) => {
    if (onTabClick) {
      onTabClick(tab);
    }
    setSelectedTab(tab);
  };

  return (
    <>
      <div className="flex text-14 font-bold text-gray-500">
        {items.map((item, index) => (
          <button
            key={index}
            onClick={() => onClick(index)}
            className={`h-[43px] w-full border-b-2 border-b-transparent py-2 text-center ${
              index === selectedTab ? 'font-bold text-black' : ''
            }`}
          >
            {item}
          </button>
        ))}
      </div>
      {/* Underline of selected tab */}
      <div
        style={{
          width: `calc(100%/${items.length})`,
          transform: `translateX(calc(100%*${selectedTab}))`,
        }}
        className="h-0.5 -translate-y-0.5 bg-primary transition-transform"
      ></div>
    </>
  );
};

export default Tabs;
