import { useRecoilValue } from 'recoil';

import { Icon } from '@components/Icon';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { Link } from 'react-router-dom';

import { LocalLoginForm } from './components/LocalLoginForm';

const LoginForm = () => {
  const isStorePage = useRecoilValue(isStorePageAtom);

  return (
    <div className="mt-8">
      <LocalLoginForm />
      <Link
        to={
          isStorePage ? '/flower-store/signup/terms-agreement' : '/signup/terms-agreement'
        }
        className="mx-auto mt-4 flex h-10 w-[108px] items-center justify-center"
      >
        <div className="relative font-bold">
          회원가입
          <Icon.ChevronRight className="absolute bottom-[-1px] right-[-24px] stroke-none" />
        </div>
      </Link>
    </div>
  );
};

export { LoginForm };
