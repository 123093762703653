import { fetcher, queryString, useRecoilQuery } from '@hooks';
import { useAuth } from '@hooks/useAuth';

import {
  FlowerStoreOrderListStatusAtom,
  FlowerStoreOrderListDataAtom,
} from './FlowerStoreOrderList.atom';

const select = [
  '_id',
  'deliveryDetail.estimatedDeliveredAt',
  'deliveryDetail.status',
  'deliveryDetail.receiverName',
  'deliveryDetail.receiverAddress',
  'paymentDetail.method',
  'paymentDetail.receiptUrl',
  'paymentDetail.requestedAt',
  'orderDetail.shopItem',
  'orderDetail.shopItemEmbed.name',
  'orderDetail.shopItemEmbed.priceRetail',
  'bugoDetail',
];

const populate = [
  {
    path: 'orderDetail.shopItem',
    select: ['shopItemInfo'],
    populate: [
      {
        path: 'shopItemInfo',
        select: ['image'],
        populate: [
          {
            path: 'image',
            select: ['url'],
          },
        ],
      },
    ],
  },
];

const orderListByUserUrl = (role: string) => {
  return `${role}/shop-order/?${queryString({
    select: select,
    populate: populate,
    options: { sort: { createdAt: -1 } },
  })}`;
};
const useFlowerStoreUserOrderListHook = (disabled?: boolean) => {
  const { storeRole } = useAuth();
  useRecoilQuery(
    FlowerStoreOrderListStatusAtom,
    FlowerStoreOrderListDataAtom,
    orderListByUserUrl(storeRole()),
    fetcher,
    disabled,
  );
};

export { useFlowerStoreUserOrderListHook, orderListByUserUrl };
