import { useLayoutEffect, useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  standardTermsText,
  privacyPolicyText,
  purchasePolicyText,
} from 'src/@utils/textDats';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';

const TermsPageContent = () => {
  useMobileNavTitleHook('이용약관 및 정보처리 방침', true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabItems = [
    { lable: '이용약관', value: 'use' },
    { lable: '개인정보 처리방침', value: 'privacy' },
    { lable: '배송 및 환불규정', value: 'delivery' },
  ];
  const title = searchParams.get('title');

  useLayoutEffect(() => {
    switch (title) {
      case 'use':
        setSelectedTab(0);
        break;
      case 'privacy':
        setSelectedTab(1);
        break;
      case 'delivery':
        setSelectedTab(2);
        break;
      default:
        setSelectedTab(0);
    }
  }, [searchParams, title]);

  const tabContent = useMemo(() => {
    switch (title) {
      case 'use':
        return <div>{standardTermsText}</div>;
      case 'privacy':
        return <div>{privacyPolicyText}</div>;
      case 'delivery':
        return <div>{purchasePolicyText}</div>;
      default:
        return <div>{standardTermsText}</div>;
    }
  }, [title]);

  return (
    <>
      <div className="mx-auto max-w-[1120px] whitespace-pre-line text-left">
        <div>
          <>
            <div className="flex text-14 font-bold text-gray-500">
              {tabItems.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    navigate(`?title=${item.value}`, { replace: true });
                    setSelectedTab(index);
                  }}
                  className={`h-[43px] w-full border-b-2 border-b-transparent py-2 text-center ${
                    index === selectedTab ? 'font-bold text-black' : ''
                  }`}
                >
                  {item.lable}
                </button>
              ))}
            </div>
            <div
              style={{
                width: `calc(100%/${tabItems.length})`,
                transform: `translateX(calc(100%*${selectedTab}))`,
              }}
              className="h-0.5 -translate-y-0.5 bg-primary transition-transform"
            ></div>
          </>
        </div>
        <div className="theme-bg-1 theme-border-main h-screen-15 overflow-y-scroll whitespace-pre-line border bg-white p-4 text-12">
          {tabContent}
        </div>
      </div>
    </>
  );
};

export { TermsPageContent };
