import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { useSearchParams } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';
import { useMobileNavTitleHook } from '@hooks/useMobileNavTitleHook';
import { isStorePageAtom } from '@state/atom/isStorePage.atom';
import { useMobileMediaQuery } from '@hooks/useMobileMediaQuery';

import { MyPageTabButton } from './MyPageTabButton';
import { MyPageTabButtonType } from '../state/myPageTabMenuList';

interface MyPageTabMenuProps {
  tabList: MyPageTabButtonType[];
}

const MyPageTabMenu: FC<MyPageTabMenuProps> = ({ tabList }) => {
  useMobileNavTitleHook('마이페이지');
  const isStorePage = useRecoilValue(isStorePageAtom);
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const { logout } = useAuth();
  const isMobileMedia = useMobileMediaQuery();

  return (
    <div className="flex w-full max-w-[255px] flex-col max-sm:max-w-full">
      <div className="flex flex-col">
        {tabList.map((tab) => (
          <MyPageTabButton key={tab.id} state={state} name={tab.name} path={tab.path} />
        ))}
      </div>
      {isMobileMedia && (
        <div className="mt-2 flex flex-col">
          <MyPageTabButton
            key={'userMenu1'}
            state={state}
            name={'고객센터 / 자주묻는 질문'}
            path={isStorePage ? '/flower-store/faq' : '/faq'}
          />
          <MyPageTabButton
            key={'userMenu2'}
            state={state}
            name={'로그아웃'}
            path={''}
            onClick={logout}
          />
        </div>
      )}
    </div>
  );
};

export { MyPageTabMenu };
