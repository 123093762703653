import { FC } from 'react';
import React from 'react';

import { useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import DismissModal from '@components/DismissModal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useFormContext } from 'react-hook-form';

import { CashAccountApprovedTag } from './CashAccountApprovedTag';
import { MournerItemWrapperProps } from '../../interface/mournerInfoForm.interface';

const MournerItemWrapper: FC<MournerItemWrapperProps> = React.memo((props) => {
  const {
    children,
    foldState,
    removeHandler,
    relationType,
    cashAccount,
    isVerified,
    name,
    phoneNumber,
    _id,
    index,
  } = props;

  const removeModalOpenId = `remove-modal-${_id}-${index}`;

  const setRemoveoModalOpen = useSetRecoilState(modalOpenAtomFamily(removeModalOpenId));

  const { setValue, getValues } = useFormContext();
  const onFoldBtnClickHandler = () => {
    const fold = getValues(`member.${index}.isFold`);
    setValue(`member.${index}.isFold`, !fold);
  };

  return (
    <>
      <div className="box-border min-h-[44px] rounded border  bg-white font-medium hover:border-primary">
        <div className="flex justify-between pl-4 text-12">
          <div className="flex items-center gap-2  max-sm:gap-1">
            {relationType && <div className="text-black ">{relationType}</div>}
            {name && <div className="rounded-full bg-apricot-100 py-1 px-4 ">{name}</div>}
            {cashAccount && <CashAccountApprovedTag isVerified={isVerified} />}
            {phoneNumber && (
              <Icon.Phone className="h-[24px] w-[24px]  fill-green-500 stroke-none" />
            )}
          </div>
          <div className="flex items-center gap-2">
            <div
              className="cursor-pointer py-3 px-1 text-red-500 hover:font-bold"
              onClick={() => {
                setRemoveoModalOpen(true);
              }}
            >
              삭제
            </div>

            <div
              className={`cursor-pointer py-3 pl-1 pr-4 hover:font-bold ${
                !foldState ? 'text-gray-400' : 'text-primary'
              }`}
              onClick={onFoldBtnClickHandler}
            >
              {foldState ? '펼치기' : '접기'}
            </div>
          </div>
        </div>
        <div className={`${foldState ? '' : 'px-4 pb-4'}`}>{children}</div>
      </div>

      <DismissModal
        openId={removeModalOpenId}
        onClick={removeHandler}
        buttonTitle={'삭제'}
        title={'상주 삭제'}
        subtitle={`${name} 상주님을 삭제하시겠습니까?`}
      ></DismissModal>
    </>
  );
});

export { MournerItemWrapper };
