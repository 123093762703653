import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { modalOpenAtomFamily } from '@state/atom/openAtom';

import { isSubmitAtom } from '../state/courtesySendForm.atom';

interface CourtesySendFormBtnSetProps {
  isEqual: () => boolean;
}

const CourtesySendFormBtnSet: FC<CourtesySendFormBtnSetProps> = ({ isEqual }) => {
  const { feventId } = useParams();
  const setIsSubmit = useSetRecoilState(isSubmitAtom);
  const navigate = useNavigate();
  const setConfirmModalOpen = useSetRecoilState(
    modalOpenAtomFamily('confirm-modal-courtesySendForm'),
  );

  return (
    <div className="mt-4 flex justify-end gap-4 max-sm:grid max-sm:grid-cols-2 max-sm:gap-x-2">
      <Link
        to={`/fevent/${feventId}/courtesy/preview`}
        className="flex h-10 w-[158px] items-center justify-center rounded border bg-white text-16 font-bold max-sm:order-2 max-sm:w-full "
      >
        답례글 미리보기
      </Link>
      <button
        type="submit"
        className="h-10 rounded border border-transparent bg-apricot-600 px-5 text-16 font-bold text-white max-sm:col-span-2 max-sm:w-full"
        onClick={() => setIsSubmit(true)}
      >
        전체 상주님께 답례글 <span className="hidden max-sm:inline">링크</span> 전송
      </button>
      <button
        type="button"
        className=" hidden h-10 w-[158px] rounded bg-gray-700 text-16 font-bold text-white max-sm:order-4 max-sm:block max-sm:w-full"
        onClick={() => {
          console.log(isEqual());

          if (isEqual()) {
            navigate('/');
          } else {
            setConfirmModalOpen(true);
          }
        }}
      >
        완료
      </button>
    </div>
  );
};

export { CourtesySendFormBtnSet };
