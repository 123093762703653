//* Modal: 가운데 중심에 특정 팝업(모달) 띄우기용으로 사용 모바일에서는 아래쪽이 default로 뜸

import { Fragment, HTMLAttributes, useRef } from 'react';

import { useRecoilState } from 'recoil';

import { Dialog, Transition } from '@headlessui/react';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useModalHook } from 'src/@hooks/useModalHooks';
import { CustomCallback } from 'src/interfaces';

import { Icon } from './Icon';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  openId: string;
  onClose?: CustomCallback<any, void>;
  className?: string;
  frameClassName?: string;
  position?: 'start' | 'center' | 'end';
}
/**
 * modal
 * @param openId
 * @param onClose: (option) 종료시 callback 함수
 * @param className: (option) 해당 모달의 className
 * @param frameClassName: (option) 해당 모달의 위치 조정 가능
 * @param position: (option) start 위 center 중간 end 아래
 * @returns
 */
export default function Modal({
  openId,
  children,
  onClose,
  className,
  frameClassName,
  position = 'center',
}: Props) {
  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));
  useModalHook(openId);
  const clickInProgressRef = useRef(false);

  const setPosition = () => {
    switch (position) {
      case 'start':
        return ' right-0 left-0 top-0';
      case 'center':
        return 'inset-0';
      case 'end':
        return 'bottom-0 right-0 left-0';
      default:
        'inset-0';
    }
  };

  const setVerticalAlign = () => {
    switch (position) {
      case 'start':
        return 'self-start';
      case 'center':
        return 'self-center';
      case 'end':
        return 'self-end';
      default:
        'inset-0';
    }
  };

  const setRounded = () => {
    switch (position) {
      case 'start':
        return 'rounded-b-lg';
      case 'center':
        return 'rounded-lg';
      case 'end':
        return 'rounded-t-lg';
      default:
        'rounded-lg';
    }
  };

  const setMarginX = () => {
    if (position === 'center') {
      return 'max-sm:mx-[1rem]';
    }
  };

  const onCloseModal = (event: any) => {
    if (onClose) {
      onClose(event);
    } else {
      setOpen(false);
    }
    if (clickInProgressRef.current) return;
    clickInProgressRef.current = true;
    history.back();
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-30 grid h-screen overflow-y-auto bg-gray-500 bg-opacity-75 pt-[136px] max-sm:pt-[56px] ${setPosition()}`}
        onClose={onCloseModal}
      >
        <div
          className={`${frameClassName} flex h-fit justify-center text-center ${setVerticalAlign()}`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className={`hidden h-screen`} aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-4 translate-y-0 scale-95"
          >
            <Dialog.Panel
              className={`${className} max-h-fit transform p-6 pt-7 pb-4 text-left transition-all max-sm:w-full max-sm:min-w-0 max-sm:max-w-full ${setMarginX()} ${setRounded()}`}
            >
              <div className="absolute top-0 right-0">
                <Icon.X className="cursor-pointer" onClick={onCloseModal}></Icon.X>
              </div>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
        <button className="sr-only"></button>
      </Dialog>
    </Transition>
  );
}
