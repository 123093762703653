import { useSetRecoilState } from 'recoil';

import { tokenSelector } from '@state/atom/auth.atom';

import { ciUserAtom, ciUserListAtom, selectUserIdAtom } from './ciUser.atom';
import { impUidAtom } from './imp.atom';

const useResetCiState = () => {
  const setCiUser = useSetRecoilState(ciUserAtom);
  const setCiUserList = useSetRecoilState(ciUserListAtom);
  const setSelectUserId = useSetRecoilState(selectUserIdAtom);
  const setToken = useSetRecoilState(tokenSelector);
  const setImpUid = useSetRecoilState(impUidAtom);

  const resetState = () => {
    setCiUser(undefined);
    setCiUserList(undefined);
    setSelectUserId(undefined);
    setToken(null);
    setImpUid(null);
  };
  return { resetState };
};

export { useResetCiState };
