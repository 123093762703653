import { FC } from 'react';

import { Button } from '@components/Button';
import { useNavigate } from 'react-router-dom';

import { MyCashInfoBox } from './MyCashInfoBox';
import { WelcomeMessage } from './WelcomeMessage';

const MyPageHeaderSection: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex w-full items-center gap-4 break-keep pt-10 max-sm:flex-col max-sm:gap-4 max-sm:pt-0 max-sm:pb-0">
        <p className="text-24 font-bold text-black max-sm:hidden">마이페이지</p>
        <div className="block max-sm:flex max-sm:w-full max-sm:items-center max-sm:justify-between">
          <WelcomeMessage className="mr-4" />
          <Button
            className="hidden max-h-[36px] rounded bg-white text-12 font-medium text-gray-700 shadow-sm max-sm:block"
            onClick={() => {
              navigate('.?state=modify');
            }}
          >
            회원정보수정
          </Button>
        </div>
      </div>
      <MyCashInfoBox />
    </>
  );
};

export { MyPageHeaderSection };
