import { selector, selectorFamily } from 'recoil';

import {
  userEnquiryListStatusAtom,
  userEnquiryListDataAtom,
  enquiryItemOpenStateFamily,
} from './userEnquiry.atom';
import { UserEnquiryList } from './userEnquiry.interface';

export const userEnquiryListStateSelector = selector({
  key: 'userEnquiryListStateSelector',
  get: ({ get }) => {
    return {
      status: get(userEnquiryListStatusAtom),
      data: get(userEnquiryListDataAtom),
    };
  },
});

export const isAnyItemOpenSelector = selectorFamily({
  key: 'isAnyItemOpenSelector',
  get:
    (enquiryData: UserEnquiryList) =>
    ({ get }) => {
      return enquiryData.some((item) => get(enquiryItemOpenStateFamily(item._id)));
    },
});
