import React, { FC, PropsWithChildren } from 'react';

interface MyPageContentProps extends PropsWithChildren {
  className?: string;
  children?: React.ReactNode;
}

const MyPageContent: FC<MyPageContentProps> = (props) => {
  const { children, className } = props;

  return <div className={` h-full w-full ${className}`}>{children}</div>;
};

export { MyPageContent };
